import React, { useState, useEffect, useRef } from "react";
import { getJwtToken, useAuth } from "../../../../../contexts/authentication";
import { usePreload } from "../../../../../contexts/preloading";
import { useUser } from "../../../../../contexts/user";
import api from "../../../../../services/Api";
import {
  ConfigItemText,
  GridThumbConfiguracoesHome,
  GridThumbFreios,
  GridThumbDecrementarMotor,
  GridThumbIncrementarMotor,
  ConfigItemSelect,
  ConfigItemButtons,
  ConfigItemSlider,
  ConfigItemSliderRange,
} from "../../../../../ui/General";
import { Button, Toogle } from "../../../../../ui/InputControls";
import {
  TitleSessaoPendente,
  SubtitleSessaoPendente,
  GridThumbWrapper,
  GridConfigWrapper,
  WrapperEstimulacaoEletrica,
  TestarWrapper,
  TestButton,
  ToogleWrapper,
} from "./styled";
import { useAlert } from "../../../../../contexts/alert";
import { useCiclos } from "../../../../../contexts/ciclos";
import { commandosAtuacao } from "../../../../../assets/comandosAtuacao";
import { useItemCiclo } from "../../../../../contexts/itemCiclo";
import { useSession } from "../../../../../contexts/session";
import { estadosCiclo } from "../../../../../assets/estadosCiclo";

const ConfigCicloCiclo = ({
  pacienteid,
  sessaoid,
  configCiclo,
  configCadeira,
  activeConfigCadeira,
  setActiveConfigCadeira,
}) => {
  const { user } = useUser();
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const {
    ciclos,
    setCiclos,
    getCiclos,
    ciclosFiltered,
    getCiclosFiltered,
    getSelectedCiclo,
  } = useCiclos();
  const { session, setSession } = useSession();
  const { publishMqtt, subscribeMqtt } = useAuth();
  const { itemCiclo, setItemCiclo, loadConfig, sendConfiguraSessao } =
    useItemCiclo({
      pacienteid: pacienteid,
      sessaoid: sessaoid,
      name: "Cicloergômetro",
      config: null,
    });
  const [itemCadeira, setItemCadeira] = useState({
    pacienteid: pacienteid,
    sessaoid: sessaoid,
    name: "Cadeira",
  });
  const [toogleCadeira, setToogleCadeira] = useState(false);
  const [freio, setFreio] = useState({
    name: "Frear / Liberar pedal",
    active: false,
  });
  const timer = useRef(null);
  const clone = require("rfdc")();

  const changeConfigCiclo = (itemValue, categoryName, itemName) => {
    let tempItemCiclo = clone(itemCiclo);
    tempItemCiclo.config.map((value, index) => {
      if (value.name === categoryName) {
        value.value.map((value2, index2) => {
          if (value2.name === itemName) {
            value2.value = itemValue;
          }
        });
      }
    });
    setItemCiclo(tempItemCiclo);
  };

  const changeConfigCicloRange = (itemValue, categoryName, itemName) => {
    let tempItemCiclo = clone(itemCiclo);

    tempItemCiclo.config.map((value, index) => {
      if (value.name === categoryName) {
        value.value.map((value2, index2) => {
          if (value2.name === itemName) {
            value2.valuemin = itemValue[0];
            value2.valuemax = itemValue[1];
          }
        });
      }
    });
    setItemCiclo(tempItemCiclo);
  };

  const changeConfigCicloSelect = (item, categoryName, itemName) => {
    // console.log(e.target.getAttribute("value"), categoryName, itemName);
    let tempItemCiclo = clone(itemCiclo);

    tempItemCiclo.config.map((value, index) => {
      if (value.name === categoryName) {
        value.value.map((value2, index2) => {
          if (value2.name === itemName) {
            value2.value = parseInt(item.getAttribute("value"));
            console.log(value2.name, itemName, item.getAttribute("value"));
          }
        });
      }
    });
    setItemCiclo(tempItemCiclo);
  };

  const toogleFreio = async () => {
    let newEstado = !freio.active;

    let comando = 11; //comando encerra corrida

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando liberar/frear pedal enviado. packet id: " + result.packet_id
        );
        setFreio((values) => ({
          ...values,
          active: newEstado,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const salvarConfiguracao = async () => {
    let sendConfig = await sendConfiguraSessao();
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .post(`/MQTT/PostConfiguraSessao`, sendConfig, config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setAlertText("Configurção da sessão salva!");
          setAlertType("success");
          setClasseAlert("on");
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao salvar a configuração da sessão no servidor!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  const testMinMaxValue = async (e) => {
    sendConfiguraSessao();

    let comando = commandosAtuacao.filter(
      (value) => value.key === e.target.dataset.value
    )[0].value;
    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      0,
      false
    )
      .then((result) => {
        console.log(
          "Comando Teste Estímulo enviado. packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // }, 1000);

    // document.addEventListener(
    //   "pointerup",
    //   () => {
    //     try {
    //       clearInterval(timer.current);
    //     } catch (err) {}
    //   },
    //   { once: true }
    // );
  };

  const cancelTestMinMaxValue = () => {
    // console.log(e.target);

    try {
      clearInterval(timer.current);
    } catch (err) {}
  };

  useEffect(() => {
    loadConfig(pacienteid, sessaoid)
      .then((response) => {
        setItemCiclo((values) => ({
          ...values,
          pacienteid: pacienteid,
          sessaoid: sessaoid,
        }));
        setItemCadeira((values) => ({
          ...values,
          pacienteid: pacienteid,
          sessaoid: sessaoid,
        }));
      })
      .catch((err) => {});
  }, [pacienteid, sessaoid]);

  useEffect(() => {
    setToogleCadeira(activeConfigCadeira);
  }, [activeConfigCadeira]);

  useEffect(() => {
    // console.log("item ciclo", itemCiclo);
  }, [itemCiclo]);

  return (
    <>
      <TitleSessaoPendente>Configurações</TitleSessaoPendente>
      <GridThumbWrapper>
        {/* {user.currentRole === "Terapeuta" &&
          session.state &&
          session.state !== "" &&
          (session.state === "Configuração" ||
            session.state === "Pedal freado") &&
          session.sessionid === sessaoid &&
          ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
            <ToogleWrapper>
              <Toogle
                checked={toogleCadeira}
                onChange={() => setActiveConfigCadeira(!activeConfigCadeira)}
              />
            </ToogleWrapper>
          )} */}
        {/* {user.currentRole === "Terapeuta" &&
          session.state &&
          session.state !== "" &&
          (session.state === "Configuração" ||
            session.state === "Pedal freado") &&
          session.sessionid === sessaoid &&
          ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
            <GridThumbConfiguracoesHome
              item={itemCiclo}
              clickFunction={configCiclo}
            />
          )} */}
        {activeConfigCadeira &&
          session.state &&
          session.state !== "" &&
          (session.state === "Configuração" ||
            session.state === "Pedal freado") &&
          session.sessionid === sessaoid &&
          ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
            <GridThumbConfiguracoesHome
              item={itemCadeira}
              clickFunction={configCadeira}
            />
          )}
        {session.state &&
          session.state !== "" &&
          (session.state === "Configuração" ||
            session.state === "Pedal freado") &&
          session.sessionid === sessaoid &&
          ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
            <GridThumbFreios
              item={freio}
              freado={session.state === "Pedal freado" ? true : false}
              clickFunction={toogleFreio}
            />
          )}
      </GridThumbWrapper>

      <GridConfigWrapper
        className={
          (user.currentRole === "Paciente" && "disabled") ||
          (user.currentRole === "Terapeuta" &&
            session.state &&
            session.state !== "" &&
            (session.state === "Aquecimento" ||
              session.state === "Corrida" ||
              session.state === "Resfriamento" ||
              session.state === "Repouso" ||
              session.state === "Trata Resumo/Estatistica" ||
              session.state === "Final de corrida") &&
            "disabled")
        }
      >
        {itemCiclo.config &&
          session.state &&
          session.state !== "" &&
          (session.state === "Configuração" ||
            session.state === "Aquecimento" ||
            session.state === "Corrida" ||
            session.state === "Resfriamento" ||
            session.state === "Repouso" ||
            session.state === "Trata Resumo/Estatistica" ||
            session.state === "Final de corrida" ||
            session.state === "Pedal freado") &&
          session.sessionid === sessaoid &&
          ciclos.filter((ciclo) => ciclo.selected).length > 0 &&
          itemCiclo.config.map((value, index) => {
            if (value.type === "category" && value.name !== "Disabled") {
              let valueTipoFibra = null;
              return (
                <React.Fragment key={index}>
                  <SubtitleSessaoPendente>{value.name}</SubtitleSessaoPendente>
                  {value.value.map((value2, index2) => {
                    if (value2.key === "tipoFibra") {
                      valueTipoFibra = value2.value;
                    }
                    if (value2.type === "text") {
                      return (
                        value2.key !== "accountId" && (
                          <ConfigItemText
                            key={index2}
                            item={{
                              name: value2.name,
                              value:
                                value2.key !== "qtdCorridasExe"
                                  ? value2.value
                                  : !session.currentRun
                                  ? 0
                                  : session.currentRun,
                            }}
                            readOnly={value2.readOnly ? value2.readOnly : false}
                            // categoryName={value.name}
                            // itemName={value2.name}
                            valueChange={(itemValue) =>
                              changeConfigCiclo(
                                itemValue,
                                value.name,
                                value2.name
                              )
                            }
                          />
                        )
                      );
                    } else if (value2.type === "slider") {
                      if (
                        (value2.key === "frequencia" ||
                          value2.key === "larguraPulso") &&
                        valueTipoFibra !== 3
                      ) {
                        if (valueTipoFibra === 1) {
                          if (value2.key === "frequencia") {
                            return (
                              <ConfigItemSlider
                                key={index2}
                                item={{
                                  name:
                                    value2.name + " (Preset Tipo de Fibra I)",
                                  min: value2.min,
                                  max: value2.max,
                                  value: 20,
                                  unit: value2.unit,
                                }}
                                valueChange={(itemValue) =>
                                  changeConfigCiclo(
                                    itemValue,
                                    value.name,
                                    value2.name
                                  )
                                }
                                classe={"disable"}
                              />
                            );
                          } else if (value2.key === "larguraPulso") {
                            return (
                              <ConfigItemSlider
                                key={index2}
                                item={{
                                  name:
                                    value2.name + " (Preset Tipo de Fibra I)",
                                  min: value2.min,
                                  max: value2.max,
                                  value: 600,
                                  unit: value2.unit,
                                }}
                                valueChange={(itemValue) =>
                                  changeConfigCiclo(
                                    itemValue,
                                    value.name,
                                    value2.name
                                  )
                                }
                                classe={"disable"}
                              />
                            );
                          }
                        } else if (valueTipoFibra === 2) {
                          if (value2.key === "frequencia") {
                            return (
                              <ConfigItemSlider
                                key={index2}
                                item={{
                                  name:
                                    value2.name + " (Preset Tipo de Fibra II)",
                                  min: value2.min,
                                  max: value2.max,
                                  value: 60,
                                  unit: value2.unit,
                                }}
                                valueChange={(itemValue) =>
                                  changeConfigCiclo(
                                    itemValue,
                                    value.name,
                                    value2.name
                                  )
                                }
                                classe={"disable"}
                              />
                            );
                          } else if (value2.key === "larguraPulso") {
                            return (
                              <ConfigItemSlider
                                key={index2}
                                item={{
                                  name:
                                    value2.name + " (Preset Tipo de Fibra II)",
                                  min: value2.min,
                                  max: value2.max,
                                  value: 600,
                                  unit: value2.unit,
                                }}
                                valueChange={(itemValue) =>
                                  changeConfigCiclo(
                                    itemValue,
                                    value.name,
                                    value2.name
                                  )
                                }
                                classe={"disable"}
                              />
                            );
                          }
                        }
                      } else {
                        return (
                          <ConfigItemSlider
                            key={index2}
                            item={{
                              name: value2.name,
                              min: value2.min,
                              max: value2.max,
                              value: value2.value,
                              unit: value2.unit,
                            }}
                            valueChange={(itemValue) =>
                              changeConfigCiclo(
                                itemValue,
                                value.name,
                                value2.name
                              )
                            }
                          />
                        );
                      }
                    } else if (value2.type === "slider-range") {
                      if (value.name === "Estimulação Elétrica") {
                        return (
                          <WrapperEstimulacaoEletrica key={index2}>
                            <TestarWrapper>
                              <TestButton
                                data-value={value2.key[0]}
                                onClick={(e) => testMinMaxValue(e)}
                                // onPointerUp={() => cancelTestMinMaxValue()}
                              >
                                Teste Mín
                              </TestButton>
                              <TestButton
                                data-value={value2.key[1]}
                                onClick={(e) => testMinMaxValue(e)}
                                // onPointerUp={() => cancelTestMinMaxValue()}
                              >
                                Teste Max
                              </TestButton>
                            </TestarWrapper>
                            <ConfigItemSliderRange
                              key={index2}
                              item={{
                                name: value2.name,
                                unit: value2.unit,
                                min: value2.min,
                                max: value2.max,
                                valuemin: value2.valuemin,
                                valuemax: value2.valuemax,
                              }}
                              valueChange={(itemValue) =>
                                changeConfigCicloRange(
                                  itemValue,
                                  value.name,
                                  value2.name
                                )
                              }
                            />
                          </WrapperEstimulacaoEletrica>
                        );
                      } else {
                        return (
                          <ConfigItemSliderRange
                            key={index2}
                            item={{
                              name: value2.name,
                              unit: value2.unit,
                              min: value2.min,
                              max: value2.max,
                              valuemin: value2.valuemin,
                              valuemax: value2.valuemax,
                            }}
                            valueChange={(itemValue) =>
                              changeConfigCicloRange(
                                itemValue,
                                value.name,
                                value2.name
                              )
                            }
                          />
                        );
                      }
                    } else if (value2.type === "select") {
                      return (
                        <ConfigItemButtons
                          key={index2}
                          item={{
                            name: value2.name,
                            value: value2.value,
                            values: value2.values,
                          }}
                          valueChange={(e) =>
                            changeConfigCicloSelect(
                              e.target,
                              value.name,
                              value2.name
                            )
                          }
                        />
                      );
                    }
                  })}
                </React.Fragment>
              );
            }
          })}
        {user.currentRole === "Terapeuta" &&
          session.state &&
          session.state !== "" &&
          (session.state === "Configuração" ||
            session.state === "Pedal freado") &&
          session.sessionid === sessaoid &&
          ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
            <Button
              width="200px"
              title="Salvar Configuração"
              color="primary"
              margin="20px"
              onClick={(e) => salvarConfiguracao()}
            />
          )}
      </GridConfigWrapper>
    </>
  );
};

export default ConfigCicloCiclo;
