import { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { usePreload } from "../../../../contexts/preloading";
import {
  PreloadWrapper,
  ProfileMainLoader,
  CircularLoader,
  LoaderPath,
} from "./styled";

const Preloader = () => {
  const theme = useTheme();
  const { preloading, setPreloading } = usePreload();
  const [classname, setClassname] = useState("");

  useEffect(() => {
    if (preloading) {
      setClassname("on");
    } else {
      setClassname("");
    }
  }, [preloading]);

  return (
    <PreloadWrapper className={classname}>
      <ProfileMainLoader>
        <div className="loader">
          <CircularLoader viewBox="25 25 50 50">
            <LoaderPath
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke={theme.colors.orange}
              strokeWidth="2"
            />
          </CircularLoader>
        </div>
      </ProfileMainLoader>
    </PreloadWrapper>
  );
};

export default Preloader;
