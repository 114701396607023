export const sessaoPendingDoing = {
  note: {
    borg: null,
    text: null,
  },
  sessaoConfig: [
    {
      name: "Geral",
      type: "category",

      value: [
        { name: "Número da Sessão", type: "text", value: 110 },
        {
          name: "ID Terapeuta",
          type: "text",

          value: "BGY938011",
        },
      ],
    },
    {
      name: "Paciente",
      type: "category",
      value: [
        { name: "ID Paciente", type: "text", value: "GFH374489" },
        {
          name: "Peso",
          type: "slider",
          min: 5,
          max: 200,
          unit: "kg",
          value: 62,
        },
        {
          name: "Altura",
          type: "slider",
          min: 0.2,
          max: 3,
          unit: "m",
          value: 1.5,
        },
        {
          name: "Tipo Sanguíneo",
          type: "select",
          values: ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"],
          value: "AB+",
        },
      ],
    },
    {
      name: "Duração",
      type: "category",
      value: [
        {
          name: "Duração da Corrida",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 30,
        },
        {
          name: "Duração do Aquecimento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Resfriamento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Repouso",
          type: "slider",
          min: 1,
          max: 5,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração de aceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
        {
          name: "Duração de desaceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
      ],
    },
    {
      name: "Rotação",
      type: "category",
      value: [
        {
          name: "Rotação do Aquecimento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação do Resfriamento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 60,
          unit: "rpm",
          valuemin: 5,
          valuemax: 20,
        },
      ],
    },
    {
      name: "Tipo",
      type: "category",
      value: [
        {
          name: "Tipo de Fibra",
          type: "select",
          values: ["Tipo 1", "Tipo 2", "Tipo 3", "Tipo 4", "Tipo 5"],
          value: "Tipo 1",
        },
        {
          name: "Tipo de Carga",
          type: "select",
          values: ["Manual", "Automática"],
          value: "Manual",
        },
      ],
    },
    {
      name: "Saturação",
      type: "category",
      value: [
        {
          name: "Saturação de O2 Mínima",
          type: "slider",
          min: 1,
          max: 100,
          unit: "%",
          value: 90,
        },
      ],
    },
    {
      name: "Frequência",
      type: "category",
      value: [
        {
          name: "Frequência Cardiaca Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 300,
          unit: "bpm",
          valuemin: 40,
          valuemax: 180,
        },
      ],
    },
    {
      name: "Pressão",
      type: "category",
      value: [
        {
          name: "Pressão Arterial Sistólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
        {
          name: "Pressão Arterial Diastólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
      ],
    },
    {
      name: "Temperatura",
      type: "category",
      value: [
        {
          name: "Temperatura Corporea Mín / Máx",
          type: "slider-range",
          min: 15,
          max: 50,
          unit: "°C",
          valuemin: 25,
          valuemax: 40,
        },
      ],
    },
    {
      name: "Estimulação Elétrica",
      type: "category",
      value: [
        {
          name: "Quadriceps Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Quadriceps Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Frequência do Pulso",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "Hz",
          valuemin: 20,
          valuemax: 100,
        },
        {
          name: "Largura do Pulso",
          type: "slider-range",
          min: 1,
          max: 2000,
          unit: "us",
          valuemin: 50,
          valuemax: 1000,
        },
      ],
    },
  ],
  cicloData: {
    bodyData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Sistólica",
        variable: "Corpo_Sistolica",
        id: "Sis",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: false,
        category: "corpo",
      },
      {
        name: "Diastólica",
        variable: "Corpo_Diastolica",
        id: "Diast",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Saturação Oxigênio",
        variable: "Corpo_SaturaOxi",
        id: "Oxi",
        value: "000",
        unit: "%",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Frequência Cardíaca",
        variable: "Corpo_FreqCardiaca",
        id: "FCard",
        value: "000",
        unit: "bpm",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Temperatura",
        variable: "Corpo_Temperatura",
        id: "Temp",
        value: "000",
        unit: "°C",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "GPS Lat",
        variable: "Localização GPS LAT",
        id: "Lat",
        value: "-23.4556",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "hardware",
      },
      {
        name: "GPS Long",
        variable: "Localização GPS LONG",
        id: "Lon",
        value: "-45,7896",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
    generalData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Tempo",
        variable: "Acomp_Tempo",
        id: "Tmp",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Rotação",
        variable: "Acomp_Rot",
        id: "Rot",
        value: "0000",
        unit: "rpm",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Carga",
        variable: "Acomp_Carga",
        id: "Carg",
        value: "0000",
        unit: "%",
        type: "value",
        size: "big",
        show: false,
        category: "hardware",
      },
      {
        name: "Estímulo Quadril Direito",
        variable: "Acomp_EstimQuaD",
        id: "EQD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Direito",
        variable: "Acomp_EstimGluD",
        id: "EGD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Direito",
        variable: "Acomp_EstimIsqD",
        id: "EID",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Quadril Esquerdo",
        variable: "Acomp_EstimQuaE",
        id: "EQE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Esquerdo",
        variable: "Acomp_EstimGluE",
        id: "EGE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Esquerdo",
        variable: "Acomp_EstimIsqE",
        id: "EIE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Quantidade Corridas",
        variable: "Param_QuantidadeCorridas",
        id: "QtdCor",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Quantidade Corridas Executadas",
        variable: "Param_QtdCorridasExecutadas",
        id: "QtdCorEx",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Fase da Corrida",
        variable: "Fase da corrida",
        id: "FasCor",
        value: "0",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Botão Emergência",
        variable: "BotaoEmergencia",
        id: "Emerg",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Botão Desconforto Paciente",
        variable: "BotaoDesconforto do paciente",
        id: "Descof",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
  },
  sessionSummary: [
    {
      name: "Número de Corridas",
      type: "value",
      category: "sessao",
      unit: "",
      value: 2,
      borderColor: null,
      graphicHidden: null,
      graphic: null,
    },
    {
      name: "Distância",
      type: "value",
      category: "sessao",
      unit: "m",
      value: 1590,
      borderColor: "#ff0000",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "13",
        },
        {
          time: "00:30",
          value: "26",
        },
        {
          time: "00:45",
          value: "39",
        },
        {
          time: "01:00",
          value: "52",
        },
        {
          time: "01:15",
          value: "65",
        },
        {
          time: "01:30",
          value: "80",
        },
        {
          time: "01:45",
          value: "92",
        },
        {
          time: "02:00",
          value: "105",
        },
        {
          time: "02:15",
          value: "115",
        },
        {
          time: "02:30",
          value: "130",
        },
        {
          time: "02:45",
          value: "150",
        },
        {
          time: "03:00",
          value: "170",
        },
        {
          time: "03:15",
          value: "193",
        },
        {
          time: "03:30",
          value: "220",
        },
        {
          time: "03:45",
          value: "250",
        },
        {
          time: "04:00",
          value: "270",
        },
        {
          time: "04:15",
          value: "270",
        },
        {
          time: "04:30",
          value: "270",
        },
        {
          time: "04:45",
          value: "270",
        },
        {
          time: "05:00",
          value: "290",
        },
        {
          time: "05:15",
          value: "300",
        },
        {
          time: "05:30",
          value: "310",
        },
        {
          time: "05:45",
          value: "320",
        },
        {
          time: "06:00",
          value: "320",
        },
        {
          time: "06:15",
          value: "330",
        },
        {
          time: "06:30",
          value: "330",
        },
        {
          time: "06:45",
          value: "350",
        },
        {
          time: "07:00",
          value: "380",
        },
        {
          time: "07:15",
          value: "400",
        },
        {
          time: "07:30",
          value: "420",
        },
        {
          time: "07:45",
          value: "420",
        },
        {
          time: "08:00",
          value: "425",
        },
        {
          time: "08:15",
          value: "450",
        },
        {
          time: "08:30",
          value: "460",
        },
        {
          time: "08:45",
          value: "460",
        },
        {
          time: "09:00",
          value: "470",
        },
        {
          time: "09:15",
          value: "480",
        },
        {
          time: "09:30",
          value: "490",
        },
        {
          time: "09:45",
          value: "500",
        },
        {
          time: "10:00",
          value: "500",
        },
        {
          time: "10:15",
          value: "505",
        },
        {
          time: "10:30",
          value: "510",
        },
        {
          time: "10:45",
          value: "515",
        },
        {
          time: "11:00",
          value: "520",
        },
        {
          time: "11:15",
          value: "525",
        },
        {
          time: "11:30",
          value: "530",
        },
        {
          time: "11:45",
          value: "540",
        },
        {
          time: "12:00",
          value: "550",
        },
        {
          time: "12:15",
          value: "555",
        },
        {
          time: "12:30",
          value: "555",
        },
        {
          time: "12:45",
          value: "560",
        },
        {
          time: "13:00",
          value: "560",
        },
        {
          time: "13:15",
          value: "565",
        },
        {
          time: "13:30",
          value: "565",
        },
        {
          time: "13:45",
          value: "565",
        },
        {
          time: "14:00",
          value: "570",
        },
        {
          time: "14:15",
          value: "590",
        },
        {
          time: "14:30",
          value: "630",
        },
        {
          time: "14:45",
          value: "660",
        },
        {
          time: "15:00",
          value: "690",
        },
        {
          time: "15:15",
          value: "720",
        },
        {
          time: "15:30",
          value: "760",
        },
        {
          time: "15:45",
          value: "800",
        },
        {
          time: "16:00",
          value: "810",
        },
        {
          time: "16:15",
          value: "820",
        },
        {
          time: "16:30",
          value: "830",
        },
        {
          time: "16:45",
          value: "840",
        },
        {
          time: "17:00",
          value: "850",
        },
        {
          time: "17:15",
          value: "860",
        },
        {
          time: "17:30",
          value: "870",
        },
        {
          time: "17:45",
          value: "880",
        },
        {
          time: "18:00",
          value: "890",
        },
        {
          time: "18:15",
          value: "900",
        },
        {
          time: "18:30",
          value: "910",
        },
        {
          time: "18:45",
          value: "920",
        },
        {
          time: "19:00",
          value: "930",
        },
        {
          time: "19:15",
          value: "940",
        },
        {
          time: "19:30",
          value: "950",
        },
        {
          time: "19:45",
          value: "960",
        },
        {
          time: "20:00",
          value: "970",
        },
        {
          time: "20:15",
          value: "970",
        },
        {
          time: "20:30",
          value: "970",
        },
        {
          time: "20:45",
          value: "990",
        },
        {
          time: "21:00",
          value: "1010",
        },
        {
          time: "21:15",
          value: "1020",
        },
        {
          time: "21:30",
          value: "1030",
        },
        {
          time: "21:45",
          value: "1050",
        },
        {
          time: "22:00",
          value: "1100",
        },
        {
          time: "22:15",
          value: "1150",
        },
        {
          time: "22:30",
          value: "1200",
        },
        {
          time: "22:45",
          value: "1300",
        },
        {
          time: "23:00",
          value: "1350",
        },
        {
          time: "23:15",
          value: "1400",
        },
        {
          time: "23:30",
          value: "1410",
        },
        {
          time: "23:45",
          value: "1420",
        },
        {
          time: "24:00",
          value: "1430",
        },
        {
          time: "24:15",
          value: "1440",
        },
        {
          time: "24:30",
          value: "1450",
        },
        {
          time: "24:45",
          value: "1460",
        },
        {
          time: "25:00",
          value: "1470",
        },
        {
          time: "25:15",
          value: "1480",
        },
        {
          time: "25:30",
          value: "1490",
        },
        {
          time: "25:45",
          value: "1500",
        },
        {
          time: "26:00",
          value: "1510",
        },
        {
          time: "26:15",
          value: "1520",
        },
        {
          time: "26:30",
          value: "1530",
        },
        {
          time: "26:45",
          value: "1540",
        },
        {
          time: "27:00",
          value: "1545",
        },
        {
          time: "27:15",
          value: "1546",
        },
        {
          time: "27:30",
          value: "1547",
        },
        {
          time: "27:45",
          value: "1548",
        },
        {
          time: "28:00",
          value: "1549",
        },
        {
          time: "28:15",
          value: "1545",
        },
        {
          time: "28:30",
          value: "1555",
        },
        {
          time: "28:45",
          value: "1560",
        },
        {
          time: "29:00",
          value: "1570",
        },
        {
          time: "29:15",
          value: "1580",
        },
        {
          time: "29:30",
          value: "1580",
        },
        {
          time: "29:45",
          value: "1580",
        },
        {
          time: "30:00",
          value: "1590",
        },
      ],
    },
    {
      name: "Tempo",
      type: "value",
      category: "sessao",
      unit: "",
      value: "30:00",
      borderColor: null,
      graphicHidden: null,
      graphic: null,
    },
    {
      name: "Rotação",
      type: "value",
      category: "hardware",
      unit: "rpm",
      value: "120",
      borderColor: "#00ff00",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "30",
        },
        {
          time: "00:30",
          value: "40",
        },
        {
          time: "00:45",
          value: "60",
        },
        {
          time: "01:00",
          value: "90",
        },
        {
          time: "01:15",
          value: "90",
        },
        {
          time: "01:30",
          value: "90",
        },
        {
          time: "01:45",
          value: "90",
        },
        {
          time: "02:00",
          value: "90",
        },
        {
          time: "02:15",
          value: "90",
        },
        {
          time: "02:30",
          value: "90",
        },
        {
          time: "02:45",
          value: "100",
        },
        {
          time: "03:00",
          value: "100",
        },
        {
          time: "03:15",
          value: "100",
        },
        {
          time: "03:30",
          value: "100",
        },
        {
          time: "03:45",
          value: "100",
        },
        {
          time: "04:00",
          value: "100",
        },
        {
          time: "04:15",
          value: "100",
        },
        {
          time: "04:30",
          value: "100",
        },
        {
          time: "04:45",
          value: "100",
        },
        {
          time: "05:00",
          value: "100",
        },
        {
          time: "05:15",
          value: "100",
        },
        {
          time: "05:30",
          value: "100",
        },
        {
          time: "05:45",
          value: "100",
        },
        {
          time: "06:00",
          value: "110",
        },
        {
          time: "06:15",
          value: "110",
        },
        {
          time: "06:30",
          value: "110",
        },
        {
          time: "06:45",
          value: "110",
        },
        {
          time: "07:00",
          value: "110",
        },
        {
          time: "07:15",
          value: "110",
        },
        {
          time: "07:30",
          value: "110",
        },
        {
          time: "07:45",
          value: "110",
        },
        {
          time: "08:00",
          value: "110",
        },
        {
          time: "08:15",
          value: "110",
        },
        {
          time: "08:30",
          value: "110",
        },
        {
          time: "08:45",
          value: "110",
        },
        {
          time: "09:00",
          value: "110",
        },
        {
          time: "09:15",
          value: "110",
        },
        {
          time: "09:30",
          value: "110",
        },
        {
          time: "09:45",
          value: "110",
        },
        {
          time: "10:00",
          value: "110",
        },
        {
          time: "10:15",
          value: "110",
        },
        {
          time: "10:30",
          value: "110",
        },
        {
          time: "10:45",
          value: "110",
        },
        {
          time: "11:00",
          value: "110",
        },
        {
          time: "11:15",
          value: "120",
        },
        {
          time: "11:30",
          value: "120",
        },
        {
          time: "11:45",
          value: "120",
        },
        {
          time: "12:00",
          value: "120",
        },
        {
          time: "12:15",
          value: "120",
        },
        {
          time: "12:30",
          value: "120",
        },
        {
          time: "12:45",
          value: "120",
        },
        {
          time: "13:00",
          value: "120",
        },
        {
          time: "13:15",
          value: "120",
        },
        {
          time: "13:30",
          value: "120",
        },
        {
          time: "13:45",
          value: "120",
        },
        {
          time: "14:00",
          value: "120",
        },
        {
          time: "14:15",
          value: "120",
        },
        {
          time: "14:30",
          value: "120",
        },
        {
          time: "14:45",
          value: "120",
        },
        {
          time: "15:00",
          value: "120",
        },
        {
          time: "15:15",
          value: "120",
        },
        {
          time: "15:30",
          value: "120",
        },
        {
          time: "15:45",
          value: "120",
        },
        {
          time: "16:00",
          value: "120",
        },
        {
          time: "16:15",
          value: "120",
        },
        {
          time: "16:30",
          value: "120",
        },
        {
          time: "16:45",
          value: "120",
        },
        {
          time: "17:00",
          value: "120",
        },
        {
          time: "17:15",
          value: "120",
        },
        {
          time: "17:30",
          value: "120",
        },
        {
          time: "17:45",
          value: "120",
        },
        {
          time: "18:00",
          value: "120",
        },
        {
          time: "18:15",
          value: "120",
        },
        {
          time: "18:30",
          value: "120",
        },
        {
          time: "18:45",
          value: "120",
        },
        {
          time: "19:00",
          value: "120",
        },
        {
          time: "19:15",
          value: "120",
        },
        {
          time: "19:30",
          value: "120",
        },
        {
          time: "19:45",
          value: "120",
        },
        {
          time: "20:00",
          value: "120",
        },
        {
          time: "20:15",
          value: "120",
        },
        {
          time: "20:30",
          value: "120",
        },
        {
          time: "20:45",
          value: "120",
        },
        {
          time: "21:00",
          value: "120",
        },
        {
          time: "21:15",
          value: "120",
        },
        {
          time: "21:30",
          value: "120",
        },
        {
          time: "21:45",
          value: "120",
        },
        {
          time: "22:00",
          value: "120",
        },
        {
          time: "22:15",
          value: "120",
        },
        {
          time: "22:30",
          value: "120",
        },
        {
          time: "22:45",
          value: "120",
        },
        {
          time: "23:00",
          value: "120",
        },
        {
          time: "23:15",
          value: "120",
        },
        {
          time: "23:30",
          value: "120",
        },
        {
          time: "23:45",
          value: "120",
        },
        {
          time: "24:00",
          value: "110",
        },
        {
          time: "24:15",
          value: "110",
        },
        {
          time: "24:30",
          value: "110",
        },
        {
          time: "24:45",
          value: "110",
        },
        {
          time: "25:00",
          value: "90",
        },
        {
          time: "25:15",
          value: "90",
        },
        {
          time: "25:30",
          value: "90",
        },
        {
          time: "25:45",
          value: "90",
        },
        {
          time: "26:00",
          value: "90",
        },
        {
          time: "26:15",
          value: "60",
        },
        {
          time: "26:30",
          value: "60",
        },
        {
          time: "26:45",
          value: "60",
        },
        {
          time: "27:00",
          value: "60",
        },
        {
          time: "27:15",
          value: "60",
        },
        {
          time: "27:30",
          value: "60",
        },
        {
          time: "27:45",
          value: "60",
        },
        {
          time: "28:00",
          value: "60",
        },
        {
          time: "28:15",
          value: "60",
        },
        {
          time: "28:30",
          value: "60",
        },
        {
          time: "28:45",
          value: "60",
        },
        {
          time: "29:00",
          value: "60",
        },
        {
          time: "29:15",
          value: "40",
        },
        {
          time: "29:30",
          value: "40",
        },
        {
          time: "29:45",
          value: "40",
        },
        {
          time: "30:00",
          value: "20",
        },
      ],
    },
    {
      name: "Carga",
      type: "value",
      category: "sessao",
      unit: "%",
      value: "6",
      borderColor: "#0000ff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "10",
        },
        {
          time: "00:30",
          value: "20",
        },
        {
          time: "00:45",
          value: "30",
        },
        {
          time: "01:00",
          value: "40",
        },
        {
          time: "01:15",
          value: "50",
        },
        {
          time: "01:30",
          value: "60",
        },
        {
          time: "01:45",
          value: "60",
        },
        {
          time: "02:00",
          value: "60",
        },
        {
          time: "02:15",
          value: "60",
        },
        {
          time: "02:30",
          value: "60",
        },
        {
          time: "02:45",
          value: "60",
        },
        {
          time: "03:00",
          value: "60",
        },
        {
          time: "03:15",
          value: "60",
        },
        {
          time: "03:30",
          value: "60",
        },
        {
          time: "03:45",
          value: "60",
        },
        {
          time: "04:00",
          value: "60",
        },
        {
          time: "04:15",
          value: "60",
        },
        {
          time: "04:30",
          value: "60",
        },
        {
          time: "04:45",
          value: "60",
        },
        {
          time: "05:00",
          value: "60",
        },
        {
          time: "05:15",
          value: "60",
        },
        {
          time: "05:30",
          value: "60",
        },
        {
          time: "05:45",
          value: "60",
        },
        {
          time: "06:00",
          value: "60",
        },
        {
          time: "06:15",
          value: "60",
        },
        {
          time: "06:30",
          value: "60",
        },
        {
          time: "06:45",
          value: "60",
        },
        {
          time: "07:00",
          value: "60",
        },
        {
          time: "07:15",
          value: "60",
        },
        {
          time: "07:30",
          value: "60",
        },
        {
          time: "07:45",
          value: "60",
        },
        {
          time: "08:00",
          value: "60",
        },
        {
          time: "08:15",
          value: "60",
        },
        {
          time: "08:30",
          value: "60",
        },
        {
          time: "08:45",
          value: "60",
        },
        {
          time: "09:00",
          value: "60",
        },
        {
          time: "09:15",
          value: "70",
        },
        {
          time: "09:30",
          value: "70",
        },
        {
          time: "09:45",
          value: "70",
        },
        {
          time: "10:00",
          value: "70",
        },
        {
          time: "10:15",
          value: "70",
        },
        {
          time: "10:30",
          value: "70",
        },
        {
          time: "10:45",
          value: "70",
        },
        {
          time: "11:00",
          value: "70",
        },
        {
          time: "11:15",
          value: "70",
        },
        {
          time: "11:30",
          value: "70",
        },
        {
          time: "11:45",
          value: "70",
        },
        {
          time: "12:00",
          value: "70",
        },
        {
          time: "12:15",
          value: "70",
        },
        {
          time: "12:30",
          value: "70",
        },
        {
          time: "12:45",
          value: "70",
        },
        {
          time: "13:00",
          value: "70",
        },
        {
          time: "13:15",
          value: "70",
        },
        {
          time: "13:30",
          value: "70",
        },
        {
          time: "13:45",
          value: "70",
        },
        {
          time: "14:00",
          value: "70",
        },
        {
          time: "14:15",
          value: "70",
        },
        {
          time: "14:30",
          value: "70",
        },
        {
          time: "14:45",
          value: "70",
        },
        {
          time: "15:00",
          value: "70",
        },
        {
          time: "15:15",
          value: "70",
        },
        {
          time: "15:30",
          value: "70",
        },
        {
          time: "15:45",
          value: "70",
        },
        {
          time: "16:00",
          value: "70",
        },
        {
          time: "16:15",
          value: "70",
        },
        {
          time: "16:30",
          value: "70",
        },
        {
          time: "16:45",
          value: "70",
        },
        {
          time: "17:00",
          value: "70",
        },
        {
          time: "17:15",
          value: "70",
        },
        {
          time: "17:30",
          value: "70",
        },
        {
          time: "17:45",
          value: "70",
        },
        {
          time: "18:00",
          value: "70",
        },
        {
          time: "18:15",
          value: "90",
        },
        {
          time: "18:30",
          value: "90",
        },
        {
          time: "18:45",
          value: "90",
        },
        {
          time: "19:00",
          value: "90",
        },
        {
          time: "19:15",
          value: "90",
        },
        {
          time: "19:30",
          value: "90",
        },
        {
          time: "19:45",
          value: "90",
        },
        {
          time: "20:00",
          value: "90",
        },
        {
          time: "20:15",
          value: "90",
        },
        {
          time: "20:30",
          value: "90",
        },
        {
          time: "20:45",
          value: "90",
        },
        {
          time: "21:00",
          value: "90",
        },
        {
          time: "21:15",
          value: "90",
        },
        {
          time: "21:30",
          value: "90",
        },
        {
          time: "21:45",
          value: "90",
        },
        {
          time: "22:00",
          value: "90",
        },
        {
          time: "22:15",
          value: "90",
        },
        {
          time: "22:30",
          value: "90",
        },
        {
          time: "22:45",
          value: "90",
        },
        {
          time: "23:00",
          value: "90",
        },
        {
          time: "23:15",
          value: "90",
        },
        {
          time: "23:30",
          value: "90",
        },
        {
          time: "23:45",
          value: "90",
        },
        {
          time: "24:00",
          value: "80",
        },
        {
          time: "24:15",
          value: "80",
        },
        {
          time: "24:30",
          value: "80",
        },
        {
          time: "24:45",
          value: "80",
        },
        {
          time: "25:00",
          value: "80",
        },
        {
          time: "25:15",
          value: "80",
        },
        {
          time: "25:30",
          value: "80",
        },
        {
          time: "25:45",
          value: "80",
        },
        {
          time: "26:00",
          value: "80",
        },
        {
          time: "26:15",
          value: "80",
        },
        {
          time: "26:30",
          value: "80",
        },
        {
          time: "26:45",
          value: "80",
        },
        {
          time: "27:00",
          value: "80",
        },
        {
          time: "27:15",
          value: "70",
        },
        {
          time: "27:30",
          value: "70",
        },
        {
          time: "27:45",
          value: "70",
        },
        {
          time: "28:00",
          value: "70",
        },
        {
          time: "28:15",
          value: "30",
        },
        {
          time: "28:30",
          value: "30",
        },
        {
          time: "28:45",
          value: "30",
        },
        {
          time: "29:00",
          value: "30",
        },
        {
          time: "29:15",
          value: "30",
        },
        {
          time: "29:30",
          value: "10",
        },
        {
          time: "29:45",
          value: "10",
        },
        {
          time: "30:00",
          value: "10",
        },
      ],
    },
    {
      name: "Estímulo Médio",
      type: "value",
      category: "estimulo",
      unit: "mA",
      value: "150",
      borderColor: "#ffff00",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "100",
        },
        {
          time: "00:30",
          value: "100",
        },
        {
          time: "00:45",
          value: "100",
        },
        {
          time: "01:00",
          value: "100",
        },
        {
          time: "01:15",
          value: "100",
        },
        {
          time: "01:30",
          value: "100",
        },
        {
          time: "01:45",
          value: "100",
        },
        {
          time: "02:00",
          value: "100",
        },
        {
          time: "02:15",
          value: "100",
        },
        {
          time: "02:30",
          value: "100",
        },
        {
          time: "02:45",
          value: "100",
        },
        {
          time: "03:00",
          value: "100",
        },
        {
          time: "03:15",
          value: "100",
        },
        {
          time: "03:30",
          value: "100",
        },
        {
          time: "03:45",
          value: "100",
        },
        {
          time: "04:00",
          value: "100",
        },
        {
          time: "04:15",
          value: "100",
        },
        {
          time: "04:30",
          value: "100",
        },
        {
          time: "04:45",
          value: "100",
        },
        {
          time: "05:00",
          value: "100",
        },
        {
          time: "05:15",
          value: "100",
        },
        {
          time: "05:30",
          value: "100",
        },
        {
          time: "05:45",
          value: "100",
        },
        {
          time: "06:00",
          value: "150",
        },
        {
          time: "06:15",
          value: "150",
        },
        {
          time: "06:30",
          value: "150",
        },
        {
          time: "06:45",
          value: "150",
        },
        {
          time: "07:00",
          value: "150",
        },
        {
          time: "07:15",
          value: "150",
        },
        {
          time: "07:30",
          value: "150",
        },
        {
          time: "07:45",
          value: "150",
        },
        {
          time: "08:00",
          value: "100",
        },
        {
          time: "08:15",
          value: "100",
        },
        {
          time: "08:30",
          value: "100",
        },
        {
          time: "08:45",
          value: "100",
        },
        {
          time: "09:00",
          value: "100",
        },
        {
          time: "09:15",
          value: "100",
        },
        {
          time: "09:30",
          value: "100",
        },
        {
          time: "09:45",
          value: "100",
        },
        {
          time: "10:00",
          value: "100",
        },
        {
          time: "10:15",
          value: "100",
        },
        {
          time: "10:30",
          value: "100",
        },
        {
          time: "10:45",
          value: "100",
        },
        {
          time: "11:00",
          value: "100",
        },
        {
          time: "11:15",
          value: "100",
        },
        {
          time: "11:30",
          value: "100",
        },
        {
          time: "11:45",
          value: "200",
        },
        {
          time: "12:00",
          value: "200",
        },
        {
          time: "12:15",
          value: "200",
        },
        {
          time: "12:30",
          value: "200",
        },
        {
          time: "12:45",
          value: "200",
        },
        {
          time: "13:00",
          value: "200",
        },
        {
          time: "13:15",
          value: "200",
        },
        {
          time: "13:30",
          value: "200",
        },
        {
          time: "13:45",
          value: "200",
        },
        {
          time: "14:00",
          value: "200",
        },
        {
          time: "14:15",
          value: "200",
        },
        {
          time: "14:30",
          value: "200",
        },
        {
          time: "14:45",
          value: "200",
        },
        {
          time: "15:00",
          value: "200",
        },
        {
          time: "15:15",
          value: "200",
        },
        {
          time: "15:30",
          value: "200",
        },
        {
          time: "15:45",
          value: "200",
        },
        {
          time: "16:00",
          value: "200",
        },
        {
          time: "16:15",
          value: "200",
        },
        {
          time: "16:30",
          value: "200",
        },
        {
          time: "16:45",
          value: "200",
        },
        {
          time: "17:00",
          value: "200",
        },
        {
          time: "17:15",
          value: "100",
        },
        {
          time: "17:30",
          value: "100",
        },
        {
          time: "17:45",
          value: "100",
        },
        {
          time: "18:00",
          value: "100",
        },
        {
          time: "18:15",
          value: "100",
        },
        {
          time: "18:30",
          value: "100",
        },
        {
          time: "18:45",
          value: "100",
        },
        {
          time: "19:00",
          value: "100",
        },
        {
          time: "19:15",
          value: "100",
        },
        {
          time: "19:30",
          value: "100",
        },
        {
          time: "19:45",
          value: "100",
        },
        {
          time: "20:00",
          value: "100",
        },
        {
          time: "20:15",
          value: "100",
        },
        {
          time: "20:30",
          value: "100",
        },
        {
          time: "20:45",
          value: "100",
        },
        {
          time: "21:00",
          value: "100",
        },
        {
          time: "21:15",
          value: "100",
        },
        {
          time: "21:30",
          value: "150",
        },
        {
          time: "21:45",
          value: "150",
        },
        {
          time: "22:00",
          value: "150",
        },
        {
          time: "22:15",
          value: "150",
        },
        {
          time: "22:30",
          value: "150",
        },
        {
          time: "22:45",
          value: "150",
        },
        {
          time: "23:00",
          value: "150",
        },
        {
          time: "23:15",
          value: "150",
        },
        {
          time: "23:30",
          value: "150",
        },
        {
          time: "23:45",
          value: "150",
        },
        {
          time: "24:00",
          value: "150",
        },
        {
          time: "24:15",
          value: "150",
        },
        {
          time: "24:30",
          value: "150",
        },
        {
          time: "24:45",
          value: "150",
        },
        {
          time: "25:00",
          value: "150",
        },
        {
          time: "25:15",
          value: "150",
        },
        {
          time: "25:30",
          value: "150",
        },
        {
          time: "25:45",
          value: "150",
        },
        {
          time: "26:00",
          value: "150",
        },
        {
          time: "26:15",
          value: "150",
        },
        {
          time: "26:30",
          value: "100",
        },
        {
          time: "26:45",
          value: "100",
        },
        {
          time: "27:00",
          value: "100",
        },
        {
          time: "27:15",
          value: "100",
        },
        {
          time: "27:30",
          value: "100",
        },
        {
          time: "27:45",
          value: "100",
        },
        {
          time: "28:00",
          value: "100",
        },
        {
          time: "28:15",
          value: "100",
        },
        {
          time: "28:30",
          value: "100",
        },
        {
          time: "28:45",
          value: "100",
        },
        {
          time: "29:00",
          value: "100",
        },
        {
          time: "29:15",
          value: "100",
        },
        {
          time: "29:30",
          value: "100",
        },
        {
          time: "29:45",
          value: "100",
        },
        {
          time: "30:00",
          value: "0",
        },
      ],
    },
    {
      name: "Sistólica",
      type: "minmax",
      category: "corpo",
      unit: "mmHg",
      value: ["200", "300"],
      borderColor: "#FF00FF",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "200",
        },
        {
          time: "00:15",
          value: "200",
        },
        {
          time: "00:30",
          value: "200",
        },
        {
          time: "00:45",
          value: "200",
        },
        {
          time: "01:00",
          value: "200",
        },
        {
          time: "01:15",
          value: "200",
        },
        {
          time: "01:30",
          value: "200",
        },
        {
          time: "01:45",
          value: "200",
        },
        {
          time: "02:00",
          value: "200",
        },
        {
          time: "02:15",
          value: "200",
        },
        {
          time: "02:30",
          value: "200",
        },
        {
          time: "02:45",
          value: "200",
        },
        {
          time: "03:00",
          value: "200",
        },
        {
          time: "03:15",
          value: "200",
        },
        {
          time: "03:30",
          value: "200",
        },
        {
          time: "03:45",
          value: "200",
        },
        {
          time: "04:00",
          value: "200",
        },
        {
          time: "04:15",
          value: "200",
        },
        {
          time: "04:30",
          value: "200",
        },
        {
          time: "04:45",
          value: "200",
        },
        {
          time: "05:00",
          value: "300",
        },
        {
          time: "05:15",
          value: "300",
        },
        {
          time: "05:30",
          value: "300",
        },
        {
          time: "05:45",
          value: "300",
        },
        {
          time: "06:00",
          value: "300",
        },
        {
          time: "06:15",
          value: "300",
        },
        {
          time: "06:30",
          value: "300",
        },
        {
          time: "06:45",
          value: "300",
        },
        {
          time: "07:00",
          value: "300",
        },
        {
          time: "07:15",
          value: "300",
        },
        {
          time: "07:30",
          value: "300",
        },
        {
          time: "07:45",
          value: "300",
        },
        {
          time: "08:00",
          value: "300",
        },
        {
          time: "08:15",
          value: "300",
        },
        {
          time: "08:30",
          value: "300",
        },
        {
          time: "08:45",
          value: "300",
        },
        {
          time: "09:00",
          value: "300",
        },
        {
          time: "09:15",
          value: "300",
        },
        {
          time: "09:30",
          value: "300",
        },
        {
          time: "09:45",
          value: "300",
        },
        {
          time: "10:00",
          value: "300",
        },
        {
          time: "10:15",
          value: "300",
        },
        {
          time: "10:30",
          value: "300",
        },
        {
          time: "10:45",
          value: "300",
        },
        {
          time: "11:00",
          value: "300",
        },
        {
          time: "11:15",
          value: "300",
        },
        {
          time: "11:30",
          value: "300",
        },
        {
          time: "11:45",
          value: "300",
        },
        {
          time: "12:00",
          value: "300",
        },
        {
          time: "12:15",
          value: "300",
        },
        {
          time: "12:30",
          value: "300",
        },
        {
          time: "12:45",
          value: "300",
        },
        {
          time: "13:00",
          value: "300",
        },
        {
          time: "13:15",
          value: "300",
        },
        {
          time: "13:30",
          value: "300",
        },
        {
          time: "13:45",
          value: "300",
        },
        {
          time: "14:00",
          value: "300",
        },
        {
          time: "14:15",
          value: "300",
        },
        {
          time: "14:30",
          value: "300",
        },
        {
          time: "14:45",
          value: "300",
        },
        {
          time: "15:00",
          value: "300",
        },
        {
          time: "15:15",
          value: "300",
        },
        {
          time: "15:30",
          value: "300",
        },
        {
          time: "15:45",
          value: "300",
        },
        {
          time: "16:00",
          value: "300",
        },
        {
          time: "16:15",
          value: "300",
        },
        {
          time: "16:30",
          value: "300",
        },
        {
          time: "16:45",
          value: "300",
        },
        {
          time: "17:00",
          value: "300",
        },
        {
          time: "17:15",
          value: "300",
        },
        {
          time: "17:30",
          value: "300",
        },
        {
          time: "17:45",
          value: "300",
        },
        {
          time: "18:00",
          value: "300",
        },
        {
          time: "18:15",
          value: "300",
        },
        {
          time: "18:30",
          value: "300",
        },
        {
          time: "18:45",
          value: "300",
        },
        {
          time: "19:00",
          value: "300",
        },
        {
          time: "19:15",
          value: "300",
        },
        {
          time: "19:30",
          value: "300",
        },
        {
          time: "19:45",
          value: "300",
        },
        {
          time: "20:00",
          value: "300",
        },
        {
          time: "20:15",
          value: "300",
        },
        {
          time: "20:30",
          value: "300",
        },
        {
          time: "20:45",
          value: "300",
        },
        {
          time: "21:00",
          value: "300",
        },
        {
          time: "21:15",
          value: "300",
        },
        {
          time: "21:30",
          value: "300",
        },
        {
          time: "21:45",
          value: "300",
        },
        {
          time: "22:00",
          value: "300",
        },
        {
          time: "22:15",
          value: "300",
        },
        {
          time: "22:30",
          value: "300",
        },
        {
          time: "22:45",
          value: "300",
        },
        {
          time: "23:00",
          value: "300",
        },
        {
          time: "23:15",
          value: "250",
        },
        {
          time: "23:30",
          value: "250",
        },
        {
          time: "23:45",
          value: "250",
        },
        {
          time: "24:00",
          value: "250",
        },
        {
          time: "24:15",
          value: "250",
        },
        {
          time: "24:30",
          value: "250",
        },
        {
          time: "24:45",
          value: "250",
        },
        {
          time: "25:00",
          value: "250",
        },
        {
          time: "25:15",
          value: "250",
        },
        {
          time: "25:30",
          value: "250",
        },
        {
          time: "25:45",
          value: "250",
        },
        {
          time: "26:00",
          value: "250",
        },
        {
          time: "26:15",
          value: "250",
        },
        {
          time: "26:30",
          value: "250",
        },
        {
          time: "26:45",
          value: "250",
        },
        {
          time: "27:00",
          value: "250",
        },
        {
          time: "27:15",
          value: "250",
        },
        {
          time: "27:30",
          value: "250",
        },
        {
          time: "27:45",
          value: "250",
        },
        {
          time: "28:00",
          value: "250",
        },
        {
          time: "28:15",
          value: "250",
        },
        {
          time: "28:30",
          value: "250",
        },
        {
          time: "28:45",
          value: "250",
        },
        {
          time: "29:00",
          value: "200",
        },
        {
          time: "29:15",
          value: "200",
        },
        {
          time: "29:30",
          value: "200",
        },
        {
          time: "29:45",
          value: "200",
        },
        {
          time: "30:00",
          value: "200",
        },
      ],
    },

    {
      name: "Diastólica",
      type: "minmax",
      category: "corpo",
      unit: "mmHg",
      value: ["200", "300"],
      borderColor: "#00ffff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "200",
        },
        {
          time: "00:15",
          value: "200",
        },
        {
          time: "00:30",
          value: "200",
        },
        {
          time: "00:45",
          value: "200",
        },
        {
          time: "01:00",
          value: "200",
        },
        {
          time: "01:15",
          value: "200",
        },
        {
          time: "01:30",
          value: "200",
        },
        {
          time: "01:45",
          value: "200",
        },
        {
          time: "02:00",
          value: "200",
        },
        {
          time: "02:15",
          value: "200",
        },
        {
          time: "02:30",
          value: "200",
        },
        {
          time: "02:45",
          value: "200",
        },
        {
          time: "03:00",
          value: "200",
        },
        {
          time: "03:15",
          value: "200",
        },
        {
          time: "03:30",
          value: "200",
        },
        {
          time: "03:45",
          value: "200",
        },
        {
          time: "04:00",
          value: "200",
        },
        {
          time: "04:15",
          value: "200",
        },
        {
          time: "04:30",
          value: "200",
        },
        {
          time: "04:45",
          value: "200",
        },
        {
          time: "05:00",
          value: "200",
        },
        {
          time: "05:15",
          value: "200",
        },
        {
          time: "05:30",
          value: "200",
        },
        {
          time: "05:45",
          value: "300",
        },
        {
          time: "06:00",
          value: "300",
        },
        {
          time: "06:15",
          value: "300",
        },
        {
          time: "06:30",
          value: "300",
        },
        {
          time: "06:45",
          value: "300",
        },
        {
          time: "07:00",
          value: "300",
        },
        {
          time: "07:15",
          value: "300",
        },
        {
          time: "07:30",
          value: "300",
        },
        {
          time: "07:45",
          value: "300",
        },
        {
          time: "08:00",
          value: "300",
        },
        {
          time: "08:15",
          value: "300",
        },
        {
          time: "08:30",
          value: "300",
        },
        {
          time: "08:45",
          value: "300",
        },
        {
          time: "09:00",
          value: "300",
        },
        {
          time: "09:15",
          value: "300",
        },
        {
          time: "09:30",
          value: "300",
        },
        {
          time: "09:45",
          value: "300",
        },
        {
          time: "10:00",
          value: "300",
        },
        {
          time: "10:15",
          value: "300",
        },
        {
          time: "10:30",
          value: "300",
        },
        {
          time: "10:45",
          value: "300",
        },
        {
          time: "11:00",
          value: "300",
        },
        {
          time: "11:15",
          value: "300",
        },
        {
          time: "11:30",
          value: "300",
        },
        {
          time: "11:45",
          value: "300",
        },
        {
          time: "12:00",
          value: "300",
        },
        {
          time: "12:15",
          value: "300",
        },
        {
          time: "12:30",
          value: "300",
        },
        {
          time: "12:45",
          value: "300",
        },
        {
          time: "13:00",
          value: "300",
        },
        {
          time: "13:15",
          value: "250",
        },
        {
          time: "13:30",
          value: "250",
        },
        {
          time: "13:45",
          value: "250",
        },
        {
          time: "14:00",
          value: "250",
        },
        {
          time: "14:15",
          value: "250",
        },
        {
          time: "14:30",
          value: "250",
        },
        {
          time: "14:45",
          value: "250",
        },
        {
          time: "15:00",
          value: "250",
        },
        {
          time: "15:15",
          value: "250",
        },
        {
          time: "15:30",
          value: "250",
        },
        {
          time: "15:45",
          value: "250",
        },
        {
          time: "16:00",
          value: "250",
        },
        {
          time: "16:15",
          value: "250",
        },
        {
          time: "16:30",
          value: "250",
        },
        {
          time: "16:45",
          value: "250",
        },
        {
          time: "17:00",
          value: "250",
        },
        {
          time: "17:15",
          value: "250",
        },
        {
          time: "17:30",
          value: "250",
        },
        {
          time: "17:45",
          value: "250",
        },
        {
          time: "18:00",
          value: "250",
        },
        {
          time: "18:15",
          value: "250",
        },
        {
          time: "18:30",
          value: "250",
        },
        {
          time: "18:45",
          value: "250",
        },
        {
          time: "19:00",
          value: "250",
        },
        {
          time: "19:15",
          value: "250",
        },
        {
          time: "19:30",
          value: "250",
        },
        {
          time: "19:45",
          value: "250",
        },
        {
          time: "20:00",
          value: "250",
        },
        {
          time: "20:15",
          value: "250",
        },
        {
          time: "20:30",
          value: "300",
        },
        {
          time: "20:45",
          value: "300",
        },
        {
          time: "21:00",
          value: "300",
        },
        {
          time: "21:15",
          value: "300",
        },
        {
          time: "21:30",
          value: "300",
        },
        {
          time: "21:45",
          value: "300",
        },
        {
          time: "22:00",
          value: "300",
        },
        {
          time: "22:15",
          value: "300",
        },
        {
          time: "22:30",
          value: "300",
        },
        {
          time: "22:45",
          value: "300",
        },
        {
          time: "23:00",
          value: "300",
        },
        {
          time: "23:15",
          value: "300",
        },
        {
          time: "23:30",
          value: "300",
        },
        {
          time: "23:45",
          value: "300",
        },
        {
          time: "24:00",
          value: "300",
        },
        {
          time: "24:15",
          value: "300",
        },
        {
          time: "24:30",
          value: "300",
        },
        {
          time: "24:45",
          value: "300",
        },
        {
          time: "25:00",
          value: "300",
        },
        {
          time: "25:15",
          value: "220",
        },
        {
          time: "25:30",
          value: "220",
        },
        {
          time: "25:45",
          value: "220",
        },
        {
          time: "26:00",
          value: "220",
        },
        {
          time: "26:15",
          value: "220",
        },
        {
          time: "26:30",
          value: "220",
        },
        {
          time: "26:45",
          value: "220",
        },
        {
          time: "27:00",
          value: "220",
        },
        {
          time: "27:15",
          value: "220",
        },
        {
          time: "27:30",
          value: "220",
        },
        {
          time: "27:45",
          value: "220",
        },
        {
          time: "28:00",
          value: "220",
        },
        {
          time: "28:15",
          value: "220",
        },
        {
          time: "28:30",
          value: "220",
        },
        {
          time: "28:45",
          value: "220",
        },
        {
          time: "29:00",
          value: "220",
        },
        {
          time: "29:15",
          value: "220",
        },
        {
          time: "29:30",
          value: "200",
        },
        {
          time: "29:45",
          value: "200",
        },
        {
          time: "30:00",
          value: "200",
        },
      ],
    },
    {
      name: "Saturação Oxigênio Mínima",
      type: "value",
      category: "corpo",
      unit: "%",
      value: "96",
      borderColor: "#00aaff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "99",
        },
        {
          time: "00:15",
          value: "99",
        },
        {
          time: "00:30",
          value: "99",
        },
        {
          time: "00:45",
          value: "99",
        },
        {
          time: "01:00",
          value: "99",
        },
        {
          time: "01:15",
          value: "99",
        },
        {
          time: "01:30",
          value: "99",
        },
        {
          time: "01:45",
          value: "99",
        },
        {
          time: "02:00",
          value: "99",
        },
        {
          time: "02:15",
          value: "99",
        },
        {
          time: "02:30",
          value: "99",
        },
        {
          time: "02:45",
          value: "99",
        },
        {
          time: "03:00",
          value: "99",
        },
        {
          time: "03:15",
          value: "99",
        },
        {
          time: "03:30",
          value: "99",
        },
        {
          time: "03:45",
          value: "99",
        },
        {
          time: "04:00",
          value: "99",
        },
        {
          time: "04:15",
          value: "99",
        },
        {
          time: "04:30",
          value: "99",
        },
        {
          time: "04:45",
          value: "99",
        },
        {
          time: "05:00",
          value: "99",
        },
        {
          time: "05:15",
          value: "99",
        },
        {
          time: "05:30",
          value: "99",
        },
        {
          time: "05:45",
          value: "99",
        },
        {
          time: "06:00",
          value: "99",
        },
        {
          time: "06:15",
          value: "99",
        },
        {
          time: "06:30",
          value: "99",
        },
        {
          time: "06:45",
          value: "99",
        },
        {
          time: "07:00",
          value: "99",
        },
        {
          time: "07:15",
          value: "99",
        },
        {
          time: "07:30",
          value: "99",
        },
        {
          time: "07:45",
          value: "97",
        },
        {
          time: "08:00",
          value: "97",
        },
        {
          time: "08:15",
          value: "97",
        },
        {
          time: "08:30",
          value: "97",
        },
        {
          time: "08:45",
          value: "97",
        },
        {
          time: "09:00",
          value: "97",
        },
        {
          time: "09:15",
          value: "97",
        },
        {
          time: "09:30",
          value: "97",
        },
        {
          time: "09:45",
          value: "97",
        },
        {
          time: "10:00",
          value: "97",
        },
        {
          time: "10:15",
          value: "97",
        },
        {
          time: "10:30",
          value: "97",
        },
        {
          time: "10:45",
          value: "97",
        },
        {
          time: "11:00",
          value: "97",
        },
        {
          time: "11:15",
          value: "97",
        },
        {
          time: "11:30",
          value: "97",
        },
        {
          time: "11:45",
          value: "97",
        },
        {
          time: "12:00",
          value: "97",
        },
        {
          time: "12:15",
          value: "97",
        },
        {
          time: "12:30",
          value: "97",
        },
        {
          time: "12:45",
          value: "97",
        },
        {
          time: "13:00",
          value: "97",
        },
        {
          time: "13:15",
          value: "97",
        },
        {
          time: "13:30",
          value: "97",
        },
        {
          time: "13:45",
          value: "97",
        },
        {
          time: "14:00",
          value: "97",
        },
        {
          time: "14:15",
          value: "97",
        },
        {
          time: "14:30",
          value: "97",
        },
        {
          time: "14:45",
          value: "97",
        },
        {
          time: "15:00",
          value: "97",
        },
        {
          time: "15:15",
          value: "96",
        },
        {
          time: "15:30",
          value: "96",
        },
        {
          time: "15:45",
          value: "96",
        },
        {
          time: "16:00",
          value: "96",
        },
        {
          time: "16:15",
          value: "96",
        },
        {
          time: "16:30",
          value: "96",
        },
        {
          time: "16:45",
          value: "96",
        },
        {
          time: "17:00",
          value: "96",
        },
        {
          time: "17:15",
          value: "96",
        },
        {
          time: "17:30",
          value: "96",
        },
        {
          time: "17:45",
          value: "96",
        },
        {
          time: "18:00",
          value: "96",
        },
        {
          time: "18:15",
          value: "96",
        },
        {
          time: "18:30",
          value: "96",
        },
        {
          time: "18:45",
          value: "96",
        },
        {
          time: "19:00",
          value: "96",
        },
        {
          time: "19:15",
          value: "96",
        },
        {
          time: "19:30",
          value: "96",
        },
        {
          time: "19:45",
          value: "96",
        },
        {
          time: "20:00",
          value: "96",
        },
        {
          time: "20:15",
          value: "96",
        },
        {
          time: "20:30",
          value: "96",
        },
        {
          time: "20:45",
          value: "96",
        },
        {
          time: "21:00",
          value: "96",
        },
        {
          time: "21:15",
          value: "96",
        },
        {
          time: "21:30",
          value: "96",
        },
        {
          time: "21:45",
          value: "96",
        },
        {
          time: "22:00",
          value: "96",
        },
        {
          time: "22:15",
          value: "98",
        },
        {
          time: "22:30",
          value: "98",
        },
        {
          time: "22:45",
          value: "98",
        },
        {
          time: "23:00",
          value: "98",
        },
        {
          time: "23:15",
          value: "98",
        },
        {
          time: "23:30",
          value: "98",
        },
        {
          time: "23:45",
          value: "98",
        },
        {
          time: "24:00",
          value: "98",
        },
        {
          time: "24:15",
          value: "98",
        },
        {
          time: "24:30",
          value: "98",
        },
        {
          time: "24:45",
          value: "98",
        },
        {
          time: "25:00",
          value: "98",
        },
        {
          time: "25:15",
          value: "98",
        },
        {
          time: "25:30",
          value: "98",
        },
        {
          time: "25:45",
          value: "98",
        },
        {
          time: "26:00",
          value: "98",
        },
        {
          time: "26:15",
          value: "98",
        },
        {
          time: "26:30",
          value: "98",
        },
        {
          time: "26:45",
          value: "98",
        },
        {
          time: "27:00",
          value: "98",
        },
        {
          time: "27:15",
          value: "99",
        },
        {
          time: "27:30",
          value: "99",
        },
        {
          time: "27:45",
          value: "99",
        },
        {
          time: "28:00",
          value: "99",
        },
        {
          time: "28:15",
          value: "99",
        },
        {
          time: "28:30",
          value: "99",
        },
        {
          time: "28:45",
          value: "99",
        },
        {
          time: "29:00",
          value: "99",
        },
        {
          time: "29:15",
          value: "99",
        },
        {
          time: "29:30",
          value: "99",
        },
        {
          time: "29:45",
          value: "99",
        },
        {
          time: "30:00",
          value: "99",
        },
      ],
    },
    {
      name: "Frequência Cardíaca",
      type: "minmax",
      category: "corpo",
      unit: "bpm",
      value: ["60", "150"],
      borderColor: "#ff6600",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "60",
        },
        {
          time: "00:15",
          value: "60",
        },
        {
          time: "00:30",
          value: "70",
        },
        {
          time: "00:45",
          value: "70",
        },
        {
          time: "01:00",
          value: "70",
        },
        {
          time: "01:15",
          value: "70",
        },
        {
          time: "01:30",
          value: "70",
        },
        {
          time: "01:45",
          value: "80",
        },
        {
          time: "02:00",
          value: "80",
        },
        {
          time: "02:15",
          value: "80",
        },
        {
          time: "02:30",
          value: "80",
        },
        {
          time: "02:45",
          value: "80",
        },
        {
          time: "03:00",
          value: "80",
        },
        {
          time: "03:15",
          value: "80",
        },
        {
          time: "03:30",
          value: "80",
        },
        {
          time: "03:45",
          value: "80",
        },
        {
          time: "04:00",
          value: "80",
        },
        {
          time: "04:15",
          value: "80",
        },
        {
          time: "04:30",
          value: "80",
        },
        {
          time: "04:45",
          value: "80",
        },
        {
          time: "05:00",
          value: "80",
        },
        {
          time: "05:15",
          value: "80",
        },
        {
          time: "05:30",
          value: "80",
        },
        {
          time: "05:45",
          value: "80",
        },
        {
          time: "06:00",
          value: "90",
        },
        {
          time: "06:15",
          value: "90",
        },
        {
          time: "06:30",
          value: "90",
        },
        {
          time: "06:45",
          value: "90",
        },
        {
          time: "07:00",
          value: "90",
        },
        {
          time: "07:15",
          value: "90",
        },
        {
          time: "07:30",
          value: "90",
        },
        {
          time: "07:45",
          value: "90",
        },
        {
          time: "08:00",
          value: "90",
        },
        {
          time: "08:15",
          value: "90",
        },
        {
          time: "08:30",
          value: "90",
        },
        {
          time: "08:45",
          value: "90",
        },
        {
          time: "09:00",
          value: "120",
        },
        {
          time: "09:15",
          value: "120",
        },
        {
          time: "09:30",
          value: "120",
        },
        {
          time: "09:45",
          value: "120",
        },
        {
          time: "10:00",
          value: "120",
        },
        {
          time: "10:15",
          value: "120",
        },
        {
          time: "10:30",
          value: "120",
        },
        {
          time: "10:45",
          value: "120",
        },
        {
          time: "11:00",
          value: "120",
        },
        {
          time: "11:15",
          value: "120",
        },
        {
          time: "11:30",
          value: "120",
        },
        {
          time: "11:45",
          value: "120",
        },
        {
          time: "12:00",
          value: "120",
        },
        {
          time: "12:15",
          value: "120",
        },
        {
          time: "12:30",
          value: "120",
        },
        {
          time: "12:45",
          value: "120",
        },
        {
          time: "13:00",
          value: "150",
        },
        {
          time: "13:15",
          value: "150",
        },
        {
          time: "13:30",
          value: "150",
        },
        {
          time: "13:45",
          value: "150",
        },
        {
          time: "14:00",
          value: "150",
        },
        {
          time: "14:15",
          value: "150",
        },
        {
          time: "14:30",
          value: "150",
        },
        {
          time: "14:45",
          value: "150",
        },
        {
          time: "15:00",
          value: "150",
        },
        {
          time: "15:15",
          value: "150",
        },
        {
          time: "15:30",
          value: "150",
        },
        {
          time: "15:45",
          value: "150",
        },
        {
          time: "16:00",
          value: "150",
        },
        {
          time: "16:15",
          value: "150",
        },
        {
          time: "16:30",
          value: "150",
        },
        {
          time: "16:45",
          value: "150",
        },
        {
          time: "17:00",
          value: "150",
        },
        {
          time: "17:15",
          value: "150",
        },
        {
          time: "17:30",
          value: "150",
        },
        {
          time: "17:45",
          value: "140",
        },
        {
          time: "18:00",
          value: "140",
        },
        {
          time: "18:15",
          value: "140",
        },
        {
          time: "18:30",
          value: "140",
        },
        {
          time: "18:45",
          value: "140",
        },
        {
          time: "19:00",
          value: "140",
        },
        {
          time: "19:15",
          value: "140",
        },
        {
          time: "19:30",
          value: "140",
        },
        {
          time: "19:45",
          value: "140",
        },
        {
          time: "20:00",
          value: "140",
        },
        {
          time: "20:15",
          value: "140",
        },
        {
          time: "20:30",
          value: "140",
        },
        {
          time: "20:45",
          value: "140",
        },
        {
          time: "21:00",
          value: "140",
        },
        {
          time: "21:15",
          value: "140",
        },
        {
          time: "21:30",
          value: "140",
        },
        {
          time: "21:45",
          value: "140",
        },
        {
          time: "22:00",
          value: "140",
        },
        {
          time: "22:15",
          value: "150",
        },
        {
          time: "22:30",
          value: "150",
        },
        {
          time: "22:45",
          value: "150",
        },
        {
          time: "23:00",
          value: "150",
        },
        {
          time: "23:15",
          value: "150",
        },
        {
          time: "23:30",
          value: "150",
        },
        {
          time: "23:45",
          value: "150",
        },
        {
          time: "24:00",
          value: "150",
        },
        {
          time: "24:15",
          value: "150",
        },
        {
          time: "24:30",
          value: "150",
        },
        {
          time: "24:45",
          value: "90",
        },
        {
          time: "25:00",
          value: "90",
        },
        {
          time: "25:15",
          value: "90",
        },
        {
          time: "25:30",
          value: "90",
        },
        {
          time: "25:45",
          value: "90",
        },
        {
          time: "26:00",
          value: "90",
        },
        {
          time: "26:15",
          value: "90",
        },
        {
          time: "26:30",
          value: "90",
        },
        {
          time: "26:45",
          value: "90",
        },
        {
          time: "27:00",
          value: "90",
        },
        {
          time: "27:15",
          value: "90",
        },
        {
          time: "27:30",
          value: "90",
        },
        {
          time: "27:45",
          value: "90",
        },
        {
          time: "28:00",
          value: "90",
        },
        {
          time: "28:15",
          value: "70",
        },
        {
          time: "28:30",
          value: "70",
        },
        {
          time: "28:45",
          value: "70",
        },
        {
          time: "29:00",
          value: "70",
        },
        {
          time: "29:15",
          value: "70",
        },
        {
          time: "29:30",
          value: "70",
        },
        {
          time: "29:45",
          value: "70",
        },
        {
          time: "30:00",
          value: "70",
        },
      ],
    },
    {
      name: "Temperatura Corporal",
      type: "minmax",
      category: "corpo",
      unit: "°C",
      value: ["36", "38"],
      borderColor: "#006600",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "36",
        },
        {
          time: "00:15",
          value: "36",
        },
        {
          time: "00:30",
          value: "36",
        },
        {
          time: "00:45",
          value: "36",
        },
        {
          time: "01:00",
          value: "36",
        },
        {
          time: "01:15",
          value: "36",
        },
        {
          time: "01:30",
          value: "36",
        },
        {
          time: "01:45",
          value: "36",
        },
        {
          time: "02:00",
          value: "36",
        },
        {
          time: "02:15",
          value: "36",
        },
        {
          time: "02:30",
          value: "36",
        },
        {
          time: "02:45",
          value: "36",
        },
        {
          time: "03:00",
          value: "36",
        },
        {
          time: "03:15",
          value: "36",
        },
        {
          time: "03:30",
          value: "36",
        },
        {
          time: "03:45",
          value: "36",
        },
        {
          time: "04:00",
          value: "36",
        },
        {
          time: "04:15",
          value: "36",
        },
        {
          time: "04:30",
          value: "36",
        },
        {
          time: "04:45",
          value: "36",
        },
        {
          time: "05:00",
          value: "36",
        },
        {
          time: "05:15",
          value: "36",
        },
        {
          time: "05:30",
          value: "36",
        },
        {
          time: "05:45",
          value: "36",
        },
        {
          time: "06:00",
          value: "36",
        },
        {
          time: "06:15",
          value: "36.8",
        },
        {
          time: "06:30",
          value: "36.8",
        },
        {
          time: "06:45",
          value: "36.8",
        },
        {
          time: "07:00",
          value: "36.8",
        },
        {
          time: "07:15",
          value: "36.8",
        },
        {
          time: "07:30",
          value: "36.8",
        },
        {
          time: "07:45",
          value: "36.8",
        },
        {
          time: "08:00",
          value: "36.8",
        },
        {
          time: "08:15",
          value: "36.8",
        },
        {
          time: "08:30",
          value: "36.8",
        },
        {
          time: "08:45",
          value: "36.8",
        },
        {
          time: "09:00",
          value: "36.8",
        },
        {
          time: "09:15",
          value: "36.8",
        },
        {
          time: "09:30",
          value: "36.8",
        },
        {
          time: "09:45",
          value: "36.8",
        },
        {
          time: "10:00",
          value: "36.8",
        },
        {
          time: "10:15",
          value: "36.8",
        },
        {
          time: "10:30",
          value: "36.8",
        },
        {
          time: "10:45",
          value: "36.8",
        },
        {
          time: "11:00",
          value: "37.5",
        },
        {
          time: "11:15",
          value: "37.5",
        },
        {
          time: "11:30",
          value: "37.5",
        },
        {
          time: "11:45",
          value: "37.5",
        },
        {
          time: "12:00",
          value: "37.5",
        },
        {
          time: "12:15",
          value: "37.5",
        },
        {
          time: "12:30",
          value: "37.5",
        },
        {
          time: "12:45",
          value: "37.5",
        },
        {
          time: "13:00",
          value: "37.5",
        },
        {
          time: "13:15",
          value: "37.5",
        },
        {
          time: "13:30",
          value: "37.5",
        },
        {
          time: "13:45",
          value: "37.5",
        },
        {
          time: "14:00",
          value: "37.5",
        },
        {
          time: "14:15",
          value: "37.5",
        },
        {
          time: "14:30",
          value: "37.5",
        },
        {
          time: "14:45",
          value: "38",
        },
        {
          time: "15:00",
          value: "38",
        },
        {
          time: "15:15",
          value: "38",
        },
        {
          time: "15:30",
          value: "38",
        },
        {
          time: "15:45",
          value: "38",
        },
        {
          time: "16:00",
          value: "38",
        },
        {
          time: "16:15",
          value: "38",
        },
        {
          time: "16:30",
          value: "38",
        },
        {
          time: "16:45",
          value: "38",
        },
        {
          time: "17:00",
          value: "38",
        },
        {
          time: "17:15",
          value: "38",
        },
        {
          time: "17:30",
          value: "38",
        },
        {
          time: "17:45",
          value: "38",
        },
        {
          time: "18:00",
          value: "38",
        },
        {
          time: "18:15",
          value: "38",
        },
        {
          time: "18:30",
          value: "38",
        },
        {
          time: "18:45",
          value: "38",
        },
        {
          time: "19:00",
          value: "38",
        },
        {
          time: "19:15",
          value: "38",
        },
        {
          time: "19:30",
          value: "38",
        },
        {
          time: "19:45",
          value: "38",
        },
        {
          time: "20:00",
          value: "38",
        },
        {
          time: "20:15",
          value: "38",
        },
        {
          time: "20:30",
          value: "38",
        },
        {
          time: "20:45",
          value: "38",
        },
        {
          time: "21:00",
          value: "38",
        },
        {
          time: "21:15",
          value: "38",
        },
        {
          time: "21:30",
          value: "38",
        },
        {
          time: "21:45",
          value: "38",
        },
        {
          time: "22:00",
          value: "38",
        },
        {
          time: "22:15",
          value: "38",
        },
        {
          time: "22:30",
          value: "38",
        },
        {
          time: "22:45",
          value: "38",
        },
        {
          time: "23:00",
          value: "37",
        },
        {
          time: "23:15",
          value: "37",
        },
        {
          time: "23:30",
          value: "37",
        },
        {
          time: "23:45",
          value: "37",
        },
        {
          time: "24:00",
          value: "37",
        },
        {
          time: "24:15",
          value: "37",
        },
        {
          time: "24:30",
          value: "37",
        },
        {
          time: "24:45",
          value: "37",
        },
        {
          time: "25:00",
          value: "37",
        },
        {
          time: "25:15",
          value: "37",
        },
        {
          time: "25:30",
          value: "37",
        },
        {
          time: "25:45",
          value: "37",
        },
        {
          time: "26:00",
          value: "37",
        },
        {
          time: "26:15",
          value: "37",
        },
        {
          time: "26:30",
          value: "37",
        },
        {
          time: "26:45",
          value: "37",
        },
        {
          time: "27:00",
          value: "37",
        },
        {
          time: "27:15",
          value: "37",
        },
        {
          time: "27:30",
          value: "37",
        },
        {
          time: "27:45",
          value: "37",
        },
        {
          time: "28:00",
          value: "37",
        },
        {
          time: "28:15",
          value: "37",
        },
        {
          time: "28:30",
          value: "37",
        },
        {
          time: "28:45",
          value: "36.5",
        },
        {
          time: "29:00",
          value: "36.5",
        },
        {
          time: "29:15",
          value: "36.5",
        },
        {
          time: "29:30",
          value: "36.5",
        },
        {
          time: "29:45",
          value: "36.5",
        },
        {
          time: "30:00",
          value: "36.5",
        },
      ],
    },
    {
      name: "Umidade",
      type: "value",
      category: "corpo",
      unit: "%",
      value: "20",
      borderColor: "#6600ff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "0",
        },
        {
          time: "00:30",
          value: "0",
        },
        {
          time: "00:45",
          value: "0",
        },
        {
          time: "01:00",
          value: "0",
        },
        {
          time: "01:15",
          value: "0",
        },
        {
          time: "01:30",
          value: "0",
        },
        {
          time: "01:45",
          value: "0",
        },
        {
          time: "02:00",
          value: "0",
        },
        {
          time: "02:15",
          value: "0",
        },
        {
          time: "02:30",
          value: "0",
        },
        {
          time: "02:45",
          value: "0",
        },
        {
          time: "03:00",
          value: "0",
        },
        {
          time: "03:15",
          value: "0",
        },
        {
          time: "03:30",
          value: "0",
        },
        {
          time: "03:45",
          value: "0",
        },
        {
          time: "04:00",
          value: "0",
        },
        {
          time: "04:15",
          value: "0",
        },
        {
          time: "04:30",
          value: "0",
        },
        {
          time: "04:45",
          value: "0",
        },
        {
          time: "05:00",
          value: "0",
        },
        {
          time: "05:15",
          value: "0",
        },
        {
          time: "05:30",
          value: "0",
        },
        {
          time: "05:45",
          value: "0",
        },
        {
          time: "06:00",
          value: "0",
        },
        {
          time: "06:15",
          value: "0",
        },
        {
          time: "06:30",
          value: "0",
        },
        {
          time: "06:45",
          value: "10",
        },
        {
          time: "07:00",
          value: "10",
        },
        {
          time: "07:15",
          value: "10",
        },
        {
          time: "07:30",
          value: "10",
        },
        {
          time: "07:45",
          value: "10",
        },
        {
          time: "08:00",
          value: "10",
        },
        {
          time: "08:15",
          value: "10",
        },
        {
          time: "08:30",
          value: "10",
        },
        {
          time: "08:45",
          value: "10",
        },
        {
          time: "09:00",
          value: "10",
        },
        {
          time: "09:15",
          value: "10",
        },
        {
          time: "09:30",
          value: "10",
        },
        {
          time: "09:45",
          value: "10",
        },
        {
          time: "10:00",
          value: "10",
        },
        {
          time: "10:15",
          value: "10",
        },
        {
          time: "10:30",
          value: "10",
        },
        {
          time: "10:45",
          value: "10",
        },
        {
          time: "11:00",
          value: "30",
        },
        {
          time: "11:15",
          value: "30",
        },
        {
          time: "11:30",
          value: "30",
        },
        {
          time: "11:45",
          value: "30",
        },
        {
          time: "12:00",
          value: "30",
        },
        {
          time: "12:15",
          value: "30",
        },
        {
          time: "12:30",
          value: "30",
        },
        {
          time: "12:45",
          value: "30",
        },
        {
          time: "13:00",
          value: "30",
        },
        {
          time: "13:15",
          value: "30",
        },
        {
          time: "13:30",
          value: "30",
        },
        {
          time: "13:45",
          value: "30",
        },
        {
          time: "14:00",
          value: "30",
        },
        {
          time: "14:15",
          value: "30",
        },
        {
          time: "14:30",
          value: "30",
        },
        {
          time: "14:45",
          value: "30",
        },
        {
          time: "15:00",
          value: "30",
        },
        {
          time: "15:15",
          value: "30",
        },
        {
          time: "15:30",
          value: "30",
        },
        {
          time: "15:45",
          value: "30",
        },
        {
          time: "16:00",
          value: "30",
        },
        {
          time: "16:15",
          value: "30",
        },
        {
          time: "16:30",
          value: "30",
        },
        {
          time: "16:45",
          value: "30",
        },
        {
          time: "17:00",
          value: "30",
        },
        {
          time: "17:15",
          value: "30",
        },
        {
          time: "17:30",
          value: "30",
        },
        {
          time: "17:45",
          value: "30",
        },
        {
          time: "18:00",
          value: "30",
        },
        {
          time: "18:15",
          value: "30",
        },
        {
          time: "18:30",
          value: "30",
        },
        {
          time: "18:45",
          value: "30",
        },
        {
          time: "19:00",
          value: "30",
        },
        {
          time: "19:15",
          value: "30",
        },
        {
          time: "19:30",
          value: "30",
        },
        {
          time: "19:45",
          value: "30",
        },
        {
          time: "20:00",
          value: "30",
        },
        {
          time: "20:15",
          value: "30",
        },
        {
          time: "20:30",
          value: "30",
        },
        {
          time: "20:45",
          value: "30",
        },
        {
          time: "21:00",
          value: "30",
        },
        {
          time: "21:15",
          value: "30",
        },
        {
          time: "21:30",
          value: "30",
        },
        {
          time: "21:45",
          value: "30",
        },
        {
          time: "22:00",
          value: "30",
        },
        {
          time: "22:15",
          value: "30",
        },
        {
          time: "22:30",
          value: "30",
        },
        {
          time: "22:45",
          value: "30",
        },
        {
          time: "23:00",
          value: "25",
        },
        {
          time: "23:15",
          value: "25",
        },
        {
          time: "23:30",
          value: "25",
        },
        {
          time: "23:45",
          value: "25",
        },
        {
          time: "24:00",
          value: "25",
        },
        {
          time: "24:15",
          value: "25",
        },
        {
          time: "24:30",
          value: "25",
        },
        {
          time: "24:45",
          value: "25",
        },
        {
          time: "25:00",
          value: "25",
        },
        {
          time: "25:15",
          value: "25",
        },
        {
          time: "25:30",
          value: "25",
        },
        {
          time: "25:45",
          value: "25",
        },
        {
          time: "26:00",
          value: "25",
        },
        {
          time: "26:15",
          value: "25",
        },
        {
          time: "26:30",
          value: "25",
        },
        {
          time: "26:45",
          value: "25",
        },
        {
          time: "27:00",
          value: "25",
        },
        {
          time: "27:15",
          value: "25",
        },
        {
          time: "27:30",
          value: "25",
        },
        {
          time: "27:45",
          value: "25",
        },
        {
          time: "28:00",
          value: "25",
        },
        {
          time: "28:15",
          value: "25",
        },
        {
          time: "28:30",
          value: "25",
        },
        {
          time: "28:45",
          value: "25",
        },
        {
          time: "29:00",
          value: "25",
        },
        {
          time: "29:15",
          value: "25",
        },
        {
          time: "29:30",
          value: "25",
        },
        {
          time: "29:45",
          value: "25",
        },
        {
          time: "30:00",
          value: "25",
        },
      ],
    },
  ],
};

export const sessaoCanceled = {
  note: {
    borg: null,
    text: `Sessão cancelada porque paciente não compareceu no local.`,
  },
  sessaoConfig: [
    {
      name: "Geral",
      type: "category",

      value: [
        { name: "Número da Sessão", type: "text", value: 110 },
        {
          name: "ID Terapeuta",
          type: "text",

          value: "BGY938011",
        },
      ],
    },
    {
      name: "Paciente",
      type: "category",
      value: [
        { name: "ID Paciente", type: "text", value: "GFH374489" },
        {
          name: "Peso",
          type: "slider",
          min: 5,
          max: 200,
          unit: "kg",
          value: 62,
        },
        {
          name: "Altura",
          type: "slider",
          min: 0.2,
          max: 3,
          unit: "m",
          value: 1.5,
        },
        {
          name: "Tipo Sanguíneo",
          type: "select",
          values: ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"],
          value: "AB+",
        },
      ],
    },
    {
      name: "Duração",
      type: "category",
      value: [
        {
          name: "Duração da Corrida",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 30,
        },
        {
          name: "Duração do Aquecimento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Resfriamento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Repouso",
          type: "slider",
          min: 1,
          max: 5,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração de aceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
        {
          name: "Duração de desaceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
      ],
    },
    {
      name: "Rotação",
      type: "category",
      value: [
        {
          name: "Rotação do Aquecimento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação do Resfriamento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 60,
          unit: "rpm",
          valuemin: 5,
          valuemax: 20,
        },
      ],
    },
    {
      name: "Tipo",
      type: "category",
      value: [
        {
          name: "Tipo de Fibra",
          type: "select",
          values: ["Tipo 1", "Tipo 2", "Tipo 3", "Tipo 4", "Tipo 5"],
          value: "Tipo 1",
        },
        {
          name: "Tipo de Carga",
          type: "select",
          values: ["Manual", "Automática"],
          value: "Manual",
        },
      ],
    },
    {
      name: "Saturação",
      type: "category",
      value: [
        {
          name: "Saturação de O2 Mínima",
          type: "slider",
          min: 1,
          max: 100,
          unit: "%",
          value: 90,
        },
      ],
    },
    {
      name: "Frequência",
      type: "category",
      value: [
        {
          name: "Frequência Cardiaca Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 300,
          unit: "bpm",
          valuemin: 40,
          valuemax: 180,
        },
      ],
    },
    {
      name: "Pressão",
      type: "category",
      value: [
        {
          name: "Pressão Arterial Sistólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
        {
          name: "Pressão Arterial Diastólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
      ],
    },
    {
      name: "Temperatura",
      type: "category",
      value: [
        {
          name: "Temperatura Corporea Mín / Máx",
          type: "slider-range",
          min: 15,
          max: 50,
          unit: "°C",
          valuemin: 25,
          valuemax: 40,
        },
      ],
    },
    {
      name: "Estimulação Elétrica",
      type: "category",
      value: [
        {
          name: "Quadriceps Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Quadriceps Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Frequência do Pulso",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "Hz",
          valuemin: 20,
          valuemax: 100,
        },
        {
          name: "Largura do Pulso",
          type: "slider-range",
          min: 1,
          max: 2000,
          unit: "us",
          valuemin: 50,
          valuemax: 1000,
        },
      ],
    },
  ],
  cicloData: {
    bodyData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Sistólica",
        variable: "Corpo_Sistolica",
        id: "Sis",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: false,
        category: "corpo",
      },
      {
        name: "Diastólica",
        variable: "Corpo_Diastolica",
        id: "Diast",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Saturação Oxigênio",
        variable: "Corpo_SaturaOxi",
        id: "Oxi",
        value: "000",
        unit: "%",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Frequência Cardíaca",
        variable: "Corpo_FreqCardiaca",
        id: "FCard",
        value: "000",
        unit: "bpm",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Temperatura",
        variable: "Corpo_Temperatura",
        id: "Temp",
        value: "000",
        unit: "°C",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "GPS Lat",
        variable: "Localização GPS LAT",
        id: "Lat",
        value: "-23.4556",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "hardware",
      },
      {
        name: "GPS Long",
        variable: "Localização GPS LONG",
        id: "Lon",
        value: "-45,7896",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
    generalData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Tempo",
        variable: "Acomp_Tempo",
        id: "Tmp",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Rotação",
        variable: "Acomp_Rot",
        id: "Rot",
        value: "0000",
        unit: "rpm",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Carga",
        variable: "Acomp_Carga",
        id: "Carg",
        value: "0000",
        unit: "%",
        type: "value",
        size: "big",
        show: false,
        category: "hardware",
      },
      {
        name: "Estímulo Quadril Direito",
        variable: "Acomp_EstimQuaD",
        id: "EQD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Direito",
        variable: "Acomp_EstimGluD",
        id: "EGD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Direito",
        variable: "Acomp_EstimIsqD",
        id: "EID",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Quadril Esquerdo",
        variable: "Acomp_EstimQuaE",
        id: "EQE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Esquerdo",
        variable: "Acomp_EstimGluE",
        id: "EGE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Esquerdo",
        variable: "Acomp_EstimIsqE",
        id: "EIE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Quantidade Corridas",
        variable: "Param_QuantidadeCorridas",
        id: "QtdCor",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Quantidade Corridas Executadas",
        variable: "Param_QtdCorridasExecutadas",
        id: "QtdCorEx",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Fase da Corrida",
        variable: "Fase da corrida",
        id: "FasCor",
        value: "0",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Botão Emergência",
        variable: "BotaoEmergencia",
        id: "Emerg",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Botão Desconforto Paciente",
        variable: "BotaoDesconforto do paciente",
        id: "Descof",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
  },
  sessionSummary: null,
};

export const sessaoFailed = {
  note: {
    borg: null,
    text: `Paciente não aguentou fazer a sessão, com 3 corridas sem sucesso.`,
  },
  sessaoConfig: [
    {
      name: "Geral",
      type: "category",

      value: [
        { name: "Número da Sessão", type: "text", value: 110 },
        {
          name: "ID Terapeuta",
          type: "text",

          value: "BGY938011",
        },
      ],
    },
    {
      name: "Paciente",
      type: "category",
      value: [
        { name: "ID Paciente", type: "text", value: "GFH374489" },
        {
          name: "Peso",
          type: "slider",
          min: 5,
          max: 200,
          unit: "kg",
          value: 62,
        },
        {
          name: "Altura",
          type: "slider",
          min: 0.2,
          max: 3,
          unit: "m",
          value: 1.5,
        },
        {
          name: "Tipo Sanguíneo",
          type: "select",
          values: ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"],
          value: "AB+",
        },
      ],
    },
    {
      name: "Duração",
      type: "category",
      value: [
        {
          name: "Duração da Corrida",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 30,
        },
        {
          name: "Duração do Aquecimento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Resfriamento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Repouso",
          type: "slider",
          min: 1,
          max: 5,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração de aceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
        {
          name: "Duração de desaceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
      ],
    },
    {
      name: "Rotação",
      type: "category",
      value: [
        {
          name: "Rotação do Aquecimento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação do Resfriamento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 60,
          unit: "rpm",
          valuemin: 5,
          valuemax: 20,
        },
      ],
    },
    {
      name: "Tipo",
      type: "category",
      value: [
        {
          name: "Tipo de Fibra",
          type: "select",
          values: ["Tipo 1", "Tipo 2", "Tipo 3", "Tipo 4", "Tipo 5"],
          value: "Tipo 1",
        },
        {
          name: "Tipo de Carga",
          type: "select",
          values: ["Manual", "Automática"],
          value: "Manual",
        },
      ],
    },
    {
      name: "Saturação",
      type: "category",
      value: [
        {
          name: "Saturação de O2 Mínima",
          type: "slider",
          min: 1,
          max: 100,
          unit: "%",
          value: 90,
        },
      ],
    },
    {
      name: "Frequência",
      type: "category",
      value: [
        {
          name: "Frequência Cardiaca Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 300,
          unit: "bpm",
          valuemin: 40,
          valuemax: 180,
        },
      ],
    },
    {
      name: "Pressão",
      type: "category",
      value: [
        {
          name: "Pressão Arterial Sistólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
        {
          name: "Pressão Arterial Diastólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
      ],
    },
    {
      name: "Temperatura",
      type: "category",
      value: [
        {
          name: "Temperatura Corporea Mín / Máx",
          type: "slider-range",
          min: 15,
          max: 50,
          unit: "°C",
          valuemin: 25,
          valuemax: 40,
        },
      ],
    },
    {
      name: "Estimulação Elétrica",
      type: "category",
      value: [
        {
          name: "Quadriceps Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Quadriceps Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Frequência do Pulso",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "Hz",
          valuemin: 20,
          valuemax: 100,
        },
        {
          name: "Largura do Pulso",
          type: "slider-range",
          min: 1,
          max: 2000,
          unit: "us",
          valuemin: 50,
          valuemax: 1000,
        },
      ],
    },
  ],
  cicloData: {
    bodyData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Sistólica",
        variable: "Corpo_Sistolica",
        id: "Sis",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: false,
        category: "corpo",
      },
      {
        name: "Diastólica",
        variable: "Corpo_Diastolica",
        id: "Diast",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Saturação Oxigênio",
        variable: "Corpo_SaturaOxi",
        id: "Oxi",
        value: "000",
        unit: "%",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Frequência Cardíaca",
        variable: "Corpo_FreqCardiaca",
        id: "FCard",
        value: "000",
        unit: "bpm",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Temperatura",
        variable: "Corpo_Temperatura",
        id: "Temp",
        value: "000",
        unit: "°C",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "GPS Lat",
        variable: "Localização GPS LAT",
        id: "Lat",
        value: "-23.4556",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "hardware",
      },
      {
        name: "GPS Long",
        variable: "Localização GPS LONG",
        id: "Lon",
        value: "-45,7896",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
    generalData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Tempo",
        variable: "Acomp_Tempo",
        id: "Tmp",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Rotação",
        variable: "Acomp_Rot",
        id: "Rot",
        value: "0000",
        unit: "rpm",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Carga",
        variable: "Acomp_Carga",
        id: "Carg",
        value: "0000",
        unit: "%",
        type: "value",
        size: "big",
        show: false,
        category: "hardware",
      },
      {
        name: "Estímulo Quadril Direito",
        variable: "Acomp_EstimQuaD",
        id: "EQD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Direito",
        variable: "Acomp_EstimGluD",
        id: "EGD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Direito",
        variable: "Acomp_EstimIsqD",
        id: "EID",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Quadril Esquerdo",
        variable: "Acomp_EstimQuaE",
        id: "EQE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Esquerdo",
        variable: "Acomp_EstimGluE",
        id: "EGE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Esquerdo",
        variable: "Acomp_EstimIsqE",
        id: "EIE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Quantidade Corridas",
        variable: "Param_QuantidadeCorridas",
        id: "QtdCor",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Quantidade Corridas Executadas",
        variable: "Param_QtdCorridasExecutadas",
        id: "QtdCorEx",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Fase da Corrida",
        variable: "Fase da corrida",
        id: "FasCor",
        value: "0",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Botão Emergência",
        variable: "BotaoEmergencia",
        id: "Emerg",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Botão Desconforto Paciente",
        variable: "BotaoDesconforto do paciente",
        id: "Descof",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
  },
  sessionSummary: null,
};

export const sessaoDone = {
  note: {
    borg: [
      { time: "00:10:15", value: "15" },
      { time: "00:20:00", value: "18" },
      { time: "00:28:45", value: "15" },
    ],
    text: `Paciente desenvolveu as atividades com bom ritmo, em apenas 1 corrida.`,
  },
  sessaoConfig: [
    {
      name: "Geral",
      type: "category",

      value: [
        { name: "Número da Sessão", type: "text", value: 110 },
        {
          name: "ID Terapeuta",
          type: "text",

          value: "BGY938011",
        },
      ],
    },
    {
      name: "Paciente",
      type: "category",
      value: [
        { name: "ID Paciente", type: "text", value: "GFH374489" },
        {
          name: "Peso",
          type: "slider",
          min: 5,
          max: 200,
          unit: "kg",
          value: 62,
        },
        {
          name: "Altura",
          type: "slider",
          min: 0.2,
          max: 3,
          unit: "m",
          value: 1.5,
        },
        {
          name: "Tipo Sanguíneo",
          type: "select",
          values: ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"],
          value: "AB+",
        },
      ],
    },
    {
      name: "Duração",
      type: "category",
      value: [
        {
          name: "Duração da Corrida",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 30,
        },
        {
          name: "Duração do Aquecimento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Resfriamento",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração do Repouso",
          type: "slider",
          min: 1,
          max: 5,
          unit: "min",
          value: 5,
        },
        {
          name: "Duração de aceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
        {
          name: "Duração de desaceleração da rotação",
          type: "slider",
          min: 1,
          max: 30,
          unit: "min",
          value: 2,
        },
      ],
    },
    {
      name: "Rotação",
      type: "category",
      value: [
        {
          name: "Rotação do Aquecimento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação do Resfriamento",
          type: "slider",
          min: 1,
          max: 60,
          unit: "rpm",
          value: 10,
        },
        {
          name: "Rotação Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 60,
          unit: "rpm",
          valuemin: 5,
          valuemax: 20,
        },
      ],
    },
    {
      name: "Tipo",
      type: "category",
      value: [
        {
          name: "Tipo de Fibra",
          type: "select",
          values: ["Tipo 1", "Tipo 2", "Tipo 3", "Tipo 4", "Tipo 5"],
          value: "Tipo 1",
        },
        {
          name: "Tipo de Carga",
          type: "select",
          values: ["Manual", "Automática"],
          value: "Manual",
        },
      ],
    },
    {
      name: "Saturação",
      type: "category",
      value: [
        {
          name: "Saturação de O2 Mínima",
          type: "slider",
          min: 1,
          max: 100,
          unit: "%",
          value: 90,
        },
      ],
    },
    {
      name: "Frequência",
      type: "category",
      value: [
        {
          name: "Frequência Cardiaca Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 300,
          unit: "bpm",
          valuemin: 40,
          valuemax: 180,
        },
      ],
    },
    {
      name: "Pressão",
      type: "category",
      value: [
        {
          name: "Pressão Arterial Sistólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
        {
          name: "Pressão Arterial Diastólica Mín / Máx",
          type: "slider-range",
          min: 1,
          max: 500,
          unit: "mmHg",
          valuemin: 200,
          valuemax: 300,
        },
      ],
    },
    {
      name: "Temperatura",
      type: "category",
      value: [
        {
          name: "Temperatura Corporea Mín / Máx",
          type: "slider-range",
          min: 15,
          max: 50,
          unit: "°C",
          valuemin: 25,
          valuemax: 40,
        },
      ],
    },
    {
      name: "Estimulação Elétrica",
      type: "category",
      value: [
        {
          name: "Quadriceps Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Quadriceps Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Gluteo Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Direito",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Ísquio Esquerdo",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "mA",
          valuemin: 30,
          valuemax: 150,
        },
        {
          name: "Frequência do Pulso",
          type: "slider-range",
          min: 1,
          max: 1000,
          unit: "Hz",
          valuemin: 20,
          valuemax: 100,
        },
        {
          name: "Largura do Pulso",
          type: "slider-range",
          min: 1,
          max: 2000,
          unit: "us",
          valuemin: 50,
          valuemax: 1000,
        },
      ],
    },
  ],
  cicloData: {
    bodyData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Sistólica",
        variable: "Corpo_Sistolica",
        id: "Sis",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: false,
        category: "corpo",
      },
      {
        name: "Diastólica",
        variable: "Corpo_Diastolica",
        id: "Diast",
        value: "000",
        unit: "mmHg",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Saturação Oxigênio",
        variable: "Corpo_SaturaOxi",
        id: "Oxi",
        value: "000",
        unit: "%",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Frequência Cardíaca",
        variable: "Corpo_FreqCardiaca",
        id: "FCard",
        value: "000",
        unit: "bpm",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "Temperatura",
        variable: "Corpo_Temperatura",
        id: "Temp",
        value: "000",
        unit: "°C",
        type: "value",
        size: "small",
        show: true,
        category: "corpo",
      },
      {
        name: "GPS Lat",
        variable: "Localização GPS LAT",
        id: "Lat",
        value: "-23.4556",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "hardware",
      },
      {
        name: "GPS Long",
        variable: "Localização GPS LONG",
        id: "Lon",
        value: "-45,7896",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
    generalData: [
      {
        name: "Código da Sessão",
        variable: "Sessao_Contagem",
        id: "IdS",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Tempo",
        variable: "Acomp_Tempo",
        id: "Tmp",
        value: "0000",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Rotação",
        variable: "Acomp_Rot",
        id: "Rot",
        value: "0000",
        unit: "rpm",
        type: "value",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Carga",
        variable: "Acomp_Carga",
        id: "Carg",
        value: "0000",
        unit: "%",
        type: "value",
        size: "big",
        show: false,
        category: "hardware",
      },
      {
        name: "Estímulo Quadril Direito",
        variable: "Acomp_EstimQuaD",
        id: "EQD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Direito",
        variable: "Acomp_EstimGluD",
        id: "EGD",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Direito",
        variable: "Acomp_EstimIsqD",
        id: "EID",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Quadril Esquerdo",
        variable: "Acomp_EstimQuaE",
        id: "EQE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Estímulo Glúteo Esquerdo",
        variable: "Acomp_EstimGluE",
        id: "EGE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: false,
        category: "estimulo",
      },
      {
        name: "Estímulo Ísquio Esquerdo",
        variable: "Acomp_EstimIsqE",
        id: "EIE",
        value: "0000",
        unit: "mA",
        type: "value",
        size: "small",
        show: true,
        category: "estimulo",
      },
      {
        name: "Quantidade Corridas",
        variable: "Param_QuantidadeCorridas",
        id: "QtdCor",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Quantidade Corridas Executadas",
        variable: "Param_QtdCorridasExecutadas",
        id: "QtdCorEx",
        value: "00",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
      {
        name: "Fase da Corrida",
        variable: "Fase da corrida",
        id: "FasCor",
        value: "0",
        unit: "",
        type: "value",
        size: "small",
        show: false,
        category: "sessao",
      },
      {
        name: "Botão Emergência",
        variable: "BotaoEmergencia",
        id: "Emerg",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Botão Desconforto Paciente",
        variable: "BotaoDesconforto do paciente",
        id: "Descof",
        value: "0",
        unit: "",
        type: "button",
        size: "small",
        show: true,
        category: "hardware",
      },
      {
        name: "Time",
        variable: "TimeStamp",
        id: "TS",
        value: "123456789",
        unit: "",
        type: "value",
        size: "small",
        show: true,
        category: "sessao",
      },
    ],
  },
  sessionSummary: [
    {
      name: "Número de Corridas",
      type: "value",
      category: "sessao",
      unit: "",
      value: 2,
      borderColor: null,
      graphicHidden: null,
      graphic: null,
    },
    {
      name: "Distância",
      type: "value",
      category: "sessao",
      unit: "m",
      value: 1590,
      borderColor: "#ff0000",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "13",
        },
        {
          time: "00:30",
          value: "26",
        },
        {
          time: "00:45",
          value: "39",
        },
        {
          time: "01:00",
          value: "52",
        },
        {
          time: "01:15",
          value: "65",
        },
        {
          time: "01:30",
          value: "80",
        },
        {
          time: "01:45",
          value: "92",
        },
        {
          time: "02:00",
          value: "105",
        },
        {
          time: "02:15",
          value: "115",
        },
        {
          time: "02:30",
          value: "130",
        },
        {
          time: "02:45",
          value: "150",
        },
        {
          time: "03:00",
          value: "170",
        },
        {
          time: "03:15",
          value: "193",
        },
        {
          time: "03:30",
          value: "220",
        },
        {
          time: "03:45",
          value: "250",
        },
        {
          time: "04:00",
          value: "270",
        },
        {
          time: "04:15",
          value: "270",
        },
        {
          time: "04:30",
          value: "270",
        },
        {
          time: "04:45",
          value: "270",
        },
        {
          time: "05:00",
          value: "290",
        },
        {
          time: "05:15",
          value: "300",
        },
        {
          time: "05:30",
          value: "310",
        },
        {
          time: "05:45",
          value: "320",
        },
        {
          time: "06:00",
          value: "320",
        },
        {
          time: "06:15",
          value: "330",
        },
        {
          time: "06:30",
          value: "330",
        },
        {
          time: "06:45",
          value: "350",
        },
        {
          time: "07:00",
          value: "380",
        },
        {
          time: "07:15",
          value: "400",
        },
        {
          time: "07:30",
          value: "420",
        },
        {
          time: "07:45",
          value: "420",
        },
        {
          time: "08:00",
          value: "425",
        },
        {
          time: "08:15",
          value: "450",
        },
        {
          time: "08:30",
          value: "460",
        },
        {
          time: "08:45",
          value: "460",
        },
        {
          time: "09:00",
          value: "470",
        },
        {
          time: "09:15",
          value: "480",
        },
        {
          time: "09:30",
          value: "490",
        },
        {
          time: "09:45",
          value: "500",
        },
        {
          time: "10:00",
          value: "500",
        },
        {
          time: "10:15",
          value: "505",
        },
        {
          time: "10:30",
          value: "510",
        },
        {
          time: "10:45",
          value: "515",
        },
        {
          time: "11:00",
          value: "520",
        },
        {
          time: "11:15",
          value: "525",
        },
        {
          time: "11:30",
          value: "530",
        },
        {
          time: "11:45",
          value: "540",
        },
        {
          time: "12:00",
          value: "550",
        },
        {
          time: "12:15",
          value: "555",
        },
        {
          time: "12:30",
          value: "555",
        },
        {
          time: "12:45",
          value: "560",
        },
        {
          time: "13:00",
          value: "560",
        },
        {
          time: "13:15",
          value: "565",
        },
        {
          time: "13:30",
          value: "565",
        },
        {
          time: "13:45",
          value: "565",
        },
        {
          time: "14:00",
          value: "570",
        },
        {
          time: "14:15",
          value: "590",
        },
        {
          time: "14:30",
          value: "630",
        },
        {
          time: "14:45",
          value: "660",
        },
        {
          time: "15:00",
          value: "690",
        },
        {
          time: "15:15",
          value: "720",
        },
        {
          time: "15:30",
          value: "760",
        },
        {
          time: "15:45",
          value: "800",
        },
        {
          time: "16:00",
          value: "810",
        },
        {
          time: "16:15",
          value: "820",
        },
        {
          time: "16:30",
          value: "830",
        },
        {
          time: "16:45",
          value: "840",
        },
        {
          time: "17:00",
          value: "850",
        },
        {
          time: "17:15",
          value: "860",
        },
        {
          time: "17:30",
          value: "870",
        },
        {
          time: "17:45",
          value: "880",
        },
        {
          time: "18:00",
          value: "890",
        },
        {
          time: "18:15",
          value: "900",
        },
        {
          time: "18:30",
          value: "910",
        },
        {
          time: "18:45",
          value: "920",
        },
        {
          time: "19:00",
          value: "930",
        },
        {
          time: "19:15",
          value: "940",
        },
        {
          time: "19:30",
          value: "950",
        },
        {
          time: "19:45",
          value: "960",
        },
        {
          time: "20:00",
          value: "970",
        },
        {
          time: "20:15",
          value: "970",
        },
        {
          time: "20:30",
          value: "970",
        },
        {
          time: "20:45",
          value: "990",
        },
        {
          time: "21:00",
          value: "1010",
        },
        {
          time: "21:15",
          value: "1020",
        },
        {
          time: "21:30",
          value: "1030",
        },
        {
          time: "21:45",
          value: "1050",
        },
        {
          time: "22:00",
          value: "1100",
        },
        {
          time: "22:15",
          value: "1150",
        },
        {
          time: "22:30",
          value: "1200",
        },
        {
          time: "22:45",
          value: "1300",
        },
        {
          time: "23:00",
          value: "1350",
        },
        {
          time: "23:15",
          value: "1400",
        },
        {
          time: "23:30",
          value: "1410",
        },
        {
          time: "23:45",
          value: "1420",
        },
        {
          time: "24:00",
          value: "1430",
        },
        {
          time: "24:15",
          value: "1440",
        },
        {
          time: "24:30",
          value: "1450",
        },
        {
          time: "24:45",
          value: "1460",
        },
        {
          time: "25:00",
          value: "1470",
        },
        {
          time: "25:15",
          value: "1480",
        },
        {
          time: "25:30",
          value: "1490",
        },
        {
          time: "25:45",
          value: "1500",
        },
        {
          time: "26:00",
          value: "1510",
        },
        {
          time: "26:15",
          value: "1520",
        },
        {
          time: "26:30",
          value: "1530",
        },
        {
          time: "26:45",
          value: "1540",
        },
        {
          time: "27:00",
          value: "1545",
        },
        {
          time: "27:15",
          value: "1546",
        },
        {
          time: "27:30",
          value: "1547",
        },
        {
          time: "27:45",
          value: "1548",
        },
        {
          time: "28:00",
          value: "1549",
        },
        {
          time: "28:15",
          value: "1545",
        },
        {
          time: "28:30",
          value: "1555",
        },
        {
          time: "28:45",
          value: "1560",
        },
        {
          time: "29:00",
          value: "1570",
        },
        {
          time: "29:15",
          value: "1580",
        },
        {
          time: "29:30",
          value: "1580",
        },
        {
          time: "29:45",
          value: "1580",
        },
        {
          time: "30:00",
          value: "1590",
        },
      ],
    },
    {
      name: "Tempo",
      type: "value",
      category: "sessao",
      unit: "",
      value: "30:00",
      borderColor: null,
      graphicHidden: null,
      graphic: null,
    },
    {
      name: "Rotação",
      type: "value",
      category: "hardware",
      unit: "rpm",
      value: "120",
      borderColor: "#00ff00",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "30",
        },
        {
          time: "00:30",
          value: "40",
        },
        {
          time: "00:45",
          value: "60",
        },
        {
          time: "01:00",
          value: "90",
        },
        {
          time: "01:15",
          value: "90",
        },
        {
          time: "01:30",
          value: "90",
        },
        {
          time: "01:45",
          value: "90",
        },
        {
          time: "02:00",
          value: "90",
        },
        {
          time: "02:15",
          value: "90",
        },
        {
          time: "02:30",
          value: "90",
        },
        {
          time: "02:45",
          value: "100",
        },
        {
          time: "03:00",
          value: "100",
        },
        {
          time: "03:15",
          value: "100",
        },
        {
          time: "03:30",
          value: "100",
        },
        {
          time: "03:45",
          value: "100",
        },
        {
          time: "04:00",
          value: "100",
        },
        {
          time: "04:15",
          value: "100",
        },
        {
          time: "04:30",
          value: "100",
        },
        {
          time: "04:45",
          value: "100",
        },
        {
          time: "05:00",
          value: "100",
        },
        {
          time: "05:15",
          value: "100",
        },
        {
          time: "05:30",
          value: "100",
        },
        {
          time: "05:45",
          value: "100",
        },
        {
          time: "06:00",
          value: "110",
        },
        {
          time: "06:15",
          value: "110",
        },
        {
          time: "06:30",
          value: "110",
        },
        {
          time: "06:45",
          value: "110",
        },
        {
          time: "07:00",
          value: "110",
        },
        {
          time: "07:15",
          value: "110",
        },
        {
          time: "07:30",
          value: "110",
        },
        {
          time: "07:45",
          value: "110",
        },
        {
          time: "08:00",
          value: "110",
        },
        {
          time: "08:15",
          value: "110",
        },
        {
          time: "08:30",
          value: "110",
        },
        {
          time: "08:45",
          value: "110",
        },
        {
          time: "09:00",
          value: "110",
        },
        {
          time: "09:15",
          value: "110",
        },
        {
          time: "09:30",
          value: "110",
        },
        {
          time: "09:45",
          value: "110",
        },
        {
          time: "10:00",
          value: "110",
        },
        {
          time: "10:15",
          value: "110",
        },
        {
          time: "10:30",
          value: "110",
        },
        {
          time: "10:45",
          value: "110",
        },
        {
          time: "11:00",
          value: "110",
        },
        {
          time: "11:15",
          value: "120",
        },
        {
          time: "11:30",
          value: "120",
        },
        {
          time: "11:45",
          value: "120",
        },
        {
          time: "12:00",
          value: "120",
        },
        {
          time: "12:15",
          value: "120",
        },
        {
          time: "12:30",
          value: "120",
        },
        {
          time: "12:45",
          value: "120",
        },
        {
          time: "13:00",
          value: "120",
        },
        {
          time: "13:15",
          value: "120",
        },
        {
          time: "13:30",
          value: "120",
        },
        {
          time: "13:45",
          value: "120",
        },
        {
          time: "14:00",
          value: "120",
        },
        {
          time: "14:15",
          value: "120",
        },
        {
          time: "14:30",
          value: "120",
        },
        {
          time: "14:45",
          value: "120",
        },
        {
          time: "15:00",
          value: "120",
        },
        {
          time: "15:15",
          value: "120",
        },
        {
          time: "15:30",
          value: "120",
        },
        {
          time: "15:45",
          value: "120",
        },
        {
          time: "16:00",
          value: "120",
        },
        {
          time: "16:15",
          value: "120",
        },
        {
          time: "16:30",
          value: "120",
        },
        {
          time: "16:45",
          value: "120",
        },
        {
          time: "17:00",
          value: "120",
        },
        {
          time: "17:15",
          value: "120",
        },
        {
          time: "17:30",
          value: "120",
        },
        {
          time: "17:45",
          value: "120",
        },
        {
          time: "18:00",
          value: "120",
        },
        {
          time: "18:15",
          value: "120",
        },
        {
          time: "18:30",
          value: "120",
        },
        {
          time: "18:45",
          value: "120",
        },
        {
          time: "19:00",
          value: "120",
        },
        {
          time: "19:15",
          value: "120",
        },
        {
          time: "19:30",
          value: "120",
        },
        {
          time: "19:45",
          value: "120",
        },
        {
          time: "20:00",
          value: "120",
        },
        {
          time: "20:15",
          value: "120",
        },
        {
          time: "20:30",
          value: "120",
        },
        {
          time: "20:45",
          value: "120",
        },
        {
          time: "21:00",
          value: "120",
        },
        {
          time: "21:15",
          value: "120",
        },
        {
          time: "21:30",
          value: "120",
        },
        {
          time: "21:45",
          value: "120",
        },
        {
          time: "22:00",
          value: "120",
        },
        {
          time: "22:15",
          value: "120",
        },
        {
          time: "22:30",
          value: "120",
        },
        {
          time: "22:45",
          value: "120",
        },
        {
          time: "23:00",
          value: "120",
        },
        {
          time: "23:15",
          value: "120",
        },
        {
          time: "23:30",
          value: "120",
        },
        {
          time: "23:45",
          value: "120",
        },
        {
          time: "24:00",
          value: "110",
        },
        {
          time: "24:15",
          value: "110",
        },
        {
          time: "24:30",
          value: "110",
        },
        {
          time: "24:45",
          value: "110",
        },
        {
          time: "25:00",
          value: "90",
        },
        {
          time: "25:15",
          value: "90",
        },
        {
          time: "25:30",
          value: "90",
        },
        {
          time: "25:45",
          value: "90",
        },
        {
          time: "26:00",
          value: "90",
        },
        {
          time: "26:15",
          value: "60",
        },
        {
          time: "26:30",
          value: "60",
        },
        {
          time: "26:45",
          value: "60",
        },
        {
          time: "27:00",
          value: "60",
        },
        {
          time: "27:15",
          value: "60",
        },
        {
          time: "27:30",
          value: "60",
        },
        {
          time: "27:45",
          value: "60",
        },
        {
          time: "28:00",
          value: "60",
        },
        {
          time: "28:15",
          value: "60",
        },
        {
          time: "28:30",
          value: "60",
        },
        {
          time: "28:45",
          value: "60",
        },
        {
          time: "29:00",
          value: "60",
        },
        {
          time: "29:15",
          value: "40",
        },
        {
          time: "29:30",
          value: "40",
        },
        {
          time: "29:45",
          value: "40",
        },
        {
          time: "30:00",
          value: "20",
        },
      ],
    },
    {
      name: "Carga",
      type: "value",
      category: "sessao",
      unit: "%",
      value: "6",
      borderColor: "#0000ff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "10",
        },
        {
          time: "00:30",
          value: "20",
        },
        {
          time: "00:45",
          value: "30",
        },
        {
          time: "01:00",
          value: "40",
        },
        {
          time: "01:15",
          value: "50",
        },
        {
          time: "01:30",
          value: "60",
        },
        {
          time: "01:45",
          value: "60",
        },
        {
          time: "02:00",
          value: "60",
        },
        {
          time: "02:15",
          value: "60",
        },
        {
          time: "02:30",
          value: "60",
        },
        {
          time: "02:45",
          value: "60",
        },
        {
          time: "03:00",
          value: "60",
        },
        {
          time: "03:15",
          value: "60",
        },
        {
          time: "03:30",
          value: "60",
        },
        {
          time: "03:45",
          value: "60",
        },
        {
          time: "04:00",
          value: "60",
        },
        {
          time: "04:15",
          value: "60",
        },
        {
          time: "04:30",
          value: "60",
        },
        {
          time: "04:45",
          value: "60",
        },
        {
          time: "05:00",
          value: "60",
        },
        {
          time: "05:15",
          value: "60",
        },
        {
          time: "05:30",
          value: "60",
        },
        {
          time: "05:45",
          value: "60",
        },
        {
          time: "06:00",
          value: "60",
        },
        {
          time: "06:15",
          value: "60",
        },
        {
          time: "06:30",
          value: "60",
        },
        {
          time: "06:45",
          value: "60",
        },
        {
          time: "07:00",
          value: "60",
        },
        {
          time: "07:15",
          value: "60",
        },
        {
          time: "07:30",
          value: "60",
        },
        {
          time: "07:45",
          value: "60",
        },
        {
          time: "08:00",
          value: "60",
        },
        {
          time: "08:15",
          value: "60",
        },
        {
          time: "08:30",
          value: "60",
        },
        {
          time: "08:45",
          value: "60",
        },
        {
          time: "09:00",
          value: "60",
        },
        {
          time: "09:15",
          value: "70",
        },
        {
          time: "09:30",
          value: "70",
        },
        {
          time: "09:45",
          value: "70",
        },
        {
          time: "10:00",
          value: "70",
        },
        {
          time: "10:15",
          value: "70",
        },
        {
          time: "10:30",
          value: "70",
        },
        {
          time: "10:45",
          value: "70",
        },
        {
          time: "11:00",
          value: "70",
        },
        {
          time: "11:15",
          value: "70",
        },
        {
          time: "11:30",
          value: "70",
        },
        {
          time: "11:45",
          value: "70",
        },
        {
          time: "12:00",
          value: "70",
        },
        {
          time: "12:15",
          value: "70",
        },
        {
          time: "12:30",
          value: "70",
        },
        {
          time: "12:45",
          value: "70",
        },
        {
          time: "13:00",
          value: "70",
        },
        {
          time: "13:15",
          value: "70",
        },
        {
          time: "13:30",
          value: "70",
        },
        {
          time: "13:45",
          value: "70",
        },
        {
          time: "14:00",
          value: "70",
        },
        {
          time: "14:15",
          value: "70",
        },
        {
          time: "14:30",
          value: "70",
        },
        {
          time: "14:45",
          value: "70",
        },
        {
          time: "15:00",
          value: "70",
        },
        {
          time: "15:15",
          value: "70",
        },
        {
          time: "15:30",
          value: "70",
        },
        {
          time: "15:45",
          value: "70",
        },
        {
          time: "16:00",
          value: "70",
        },
        {
          time: "16:15",
          value: "70",
        },
        {
          time: "16:30",
          value: "70",
        },
        {
          time: "16:45",
          value: "70",
        },
        {
          time: "17:00",
          value: "70",
        },
        {
          time: "17:15",
          value: "70",
        },
        {
          time: "17:30",
          value: "70",
        },
        {
          time: "17:45",
          value: "70",
        },
        {
          time: "18:00",
          value: "70",
        },
        {
          time: "18:15",
          value: "90",
        },
        {
          time: "18:30",
          value: "90",
        },
        {
          time: "18:45",
          value: "90",
        },
        {
          time: "19:00",
          value: "90",
        },
        {
          time: "19:15",
          value: "90",
        },
        {
          time: "19:30",
          value: "90",
        },
        {
          time: "19:45",
          value: "90",
        },
        {
          time: "20:00",
          value: "90",
        },
        {
          time: "20:15",
          value: "90",
        },
        {
          time: "20:30",
          value: "90",
        },
        {
          time: "20:45",
          value: "90",
        },
        {
          time: "21:00",
          value: "90",
        },
        {
          time: "21:15",
          value: "90",
        },
        {
          time: "21:30",
          value: "90",
        },
        {
          time: "21:45",
          value: "90",
        },
        {
          time: "22:00",
          value: "90",
        },
        {
          time: "22:15",
          value: "90",
        },
        {
          time: "22:30",
          value: "90",
        },
        {
          time: "22:45",
          value: "90",
        },
        {
          time: "23:00",
          value: "90",
        },
        {
          time: "23:15",
          value: "90",
        },
        {
          time: "23:30",
          value: "90",
        },
        {
          time: "23:45",
          value: "90",
        },
        {
          time: "24:00",
          value: "80",
        },
        {
          time: "24:15",
          value: "80",
        },
        {
          time: "24:30",
          value: "80",
        },
        {
          time: "24:45",
          value: "80",
        },
        {
          time: "25:00",
          value: "80",
        },
        {
          time: "25:15",
          value: "80",
        },
        {
          time: "25:30",
          value: "80",
        },
        {
          time: "25:45",
          value: "80",
        },
        {
          time: "26:00",
          value: "80",
        },
        {
          time: "26:15",
          value: "80",
        },
        {
          time: "26:30",
          value: "80",
        },
        {
          time: "26:45",
          value: "80",
        },
        {
          time: "27:00",
          value: "80",
        },
        {
          time: "27:15",
          value: "70",
        },
        {
          time: "27:30",
          value: "70",
        },
        {
          time: "27:45",
          value: "70",
        },
        {
          time: "28:00",
          value: "70",
        },
        {
          time: "28:15",
          value: "30",
        },
        {
          time: "28:30",
          value: "30",
        },
        {
          time: "28:45",
          value: "30",
        },
        {
          time: "29:00",
          value: "30",
        },
        {
          time: "29:15",
          value: "30",
        },
        {
          time: "29:30",
          value: "10",
        },
        {
          time: "29:45",
          value: "10",
        },
        {
          time: "30:00",
          value: "10",
        },
      ],
    },
    {
      name: "Estímulo Médio",
      type: "value",
      category: "estimulo",
      unit: "mA",
      value: "150",
      borderColor: "#ffff00",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "100",
        },
        {
          time: "00:30",
          value: "100",
        },
        {
          time: "00:45",
          value: "100",
        },
        {
          time: "01:00",
          value: "100",
        },
        {
          time: "01:15",
          value: "100",
        },
        {
          time: "01:30",
          value: "100",
        },
        {
          time: "01:45",
          value: "100",
        },
        {
          time: "02:00",
          value: "100",
        },
        {
          time: "02:15",
          value: "100",
        },
        {
          time: "02:30",
          value: "100",
        },
        {
          time: "02:45",
          value: "100",
        },
        {
          time: "03:00",
          value: "100",
        },
        {
          time: "03:15",
          value: "100",
        },
        {
          time: "03:30",
          value: "100",
        },
        {
          time: "03:45",
          value: "100",
        },
        {
          time: "04:00",
          value: "100",
        },
        {
          time: "04:15",
          value: "100",
        },
        {
          time: "04:30",
          value: "100",
        },
        {
          time: "04:45",
          value: "100",
        },
        {
          time: "05:00",
          value: "100",
        },
        {
          time: "05:15",
          value: "100",
        },
        {
          time: "05:30",
          value: "100",
        },
        {
          time: "05:45",
          value: "100",
        },
        {
          time: "06:00",
          value: "150",
        },
        {
          time: "06:15",
          value: "150",
        },
        {
          time: "06:30",
          value: "150",
        },
        {
          time: "06:45",
          value: "150",
        },
        {
          time: "07:00",
          value: "150",
        },
        {
          time: "07:15",
          value: "150",
        },
        {
          time: "07:30",
          value: "150",
        },
        {
          time: "07:45",
          value: "150",
        },
        {
          time: "08:00",
          value: "100",
        },
        {
          time: "08:15",
          value: "100",
        },
        {
          time: "08:30",
          value: "100",
        },
        {
          time: "08:45",
          value: "100",
        },
        {
          time: "09:00",
          value: "100",
        },
        {
          time: "09:15",
          value: "100",
        },
        {
          time: "09:30",
          value: "100",
        },
        {
          time: "09:45",
          value: "100",
        },
        {
          time: "10:00",
          value: "100",
        },
        {
          time: "10:15",
          value: "100",
        },
        {
          time: "10:30",
          value: "100",
        },
        {
          time: "10:45",
          value: "100",
        },
        {
          time: "11:00",
          value: "100",
        },
        {
          time: "11:15",
          value: "100",
        },
        {
          time: "11:30",
          value: "100",
        },
        {
          time: "11:45",
          value: "200",
        },
        {
          time: "12:00",
          value: "200",
        },
        {
          time: "12:15",
          value: "200",
        },
        {
          time: "12:30",
          value: "200",
        },
        {
          time: "12:45",
          value: "200",
        },
        {
          time: "13:00",
          value: "200",
        },
        {
          time: "13:15",
          value: "200",
        },
        {
          time: "13:30",
          value: "200",
        },
        {
          time: "13:45",
          value: "200",
        },
        {
          time: "14:00",
          value: "200",
        },
        {
          time: "14:15",
          value: "200",
        },
        {
          time: "14:30",
          value: "200",
        },
        {
          time: "14:45",
          value: "200",
        },
        {
          time: "15:00",
          value: "200",
        },
        {
          time: "15:15",
          value: "200",
        },
        {
          time: "15:30",
          value: "200",
        },
        {
          time: "15:45",
          value: "200",
        },
        {
          time: "16:00",
          value: "200",
        },
        {
          time: "16:15",
          value: "200",
        },
        {
          time: "16:30",
          value: "200",
        },
        {
          time: "16:45",
          value: "200",
        },
        {
          time: "17:00",
          value: "200",
        },
        {
          time: "17:15",
          value: "100",
        },
        {
          time: "17:30",
          value: "100",
        },
        {
          time: "17:45",
          value: "100",
        },
        {
          time: "18:00",
          value: "100",
        },
        {
          time: "18:15",
          value: "100",
        },
        {
          time: "18:30",
          value: "100",
        },
        {
          time: "18:45",
          value: "100",
        },
        {
          time: "19:00",
          value: "100",
        },
        {
          time: "19:15",
          value: "100",
        },
        {
          time: "19:30",
          value: "100",
        },
        {
          time: "19:45",
          value: "100",
        },
        {
          time: "20:00",
          value: "100",
        },
        {
          time: "20:15",
          value: "100",
        },
        {
          time: "20:30",
          value: "100",
        },
        {
          time: "20:45",
          value: "100",
        },
        {
          time: "21:00",
          value: "100",
        },
        {
          time: "21:15",
          value: "100",
        },
        {
          time: "21:30",
          value: "150",
        },
        {
          time: "21:45",
          value: "150",
        },
        {
          time: "22:00",
          value: "150",
        },
        {
          time: "22:15",
          value: "150",
        },
        {
          time: "22:30",
          value: "150",
        },
        {
          time: "22:45",
          value: "150",
        },
        {
          time: "23:00",
          value: "150",
        },
        {
          time: "23:15",
          value: "150",
        },
        {
          time: "23:30",
          value: "150",
        },
        {
          time: "23:45",
          value: "150",
        },
        {
          time: "24:00",
          value: "150",
        },
        {
          time: "24:15",
          value: "150",
        },
        {
          time: "24:30",
          value: "150",
        },
        {
          time: "24:45",
          value: "150",
        },
        {
          time: "25:00",
          value: "150",
        },
        {
          time: "25:15",
          value: "150",
        },
        {
          time: "25:30",
          value: "150",
        },
        {
          time: "25:45",
          value: "150",
        },
        {
          time: "26:00",
          value: "150",
        },
        {
          time: "26:15",
          value: "150",
        },
        {
          time: "26:30",
          value: "100",
        },
        {
          time: "26:45",
          value: "100",
        },
        {
          time: "27:00",
          value: "100",
        },
        {
          time: "27:15",
          value: "100",
        },
        {
          time: "27:30",
          value: "100",
        },
        {
          time: "27:45",
          value: "100",
        },
        {
          time: "28:00",
          value: "100",
        },
        {
          time: "28:15",
          value: "100",
        },
        {
          time: "28:30",
          value: "100",
        },
        {
          time: "28:45",
          value: "100",
        },
        {
          time: "29:00",
          value: "100",
        },
        {
          time: "29:15",
          value: "100",
        },
        {
          time: "29:30",
          value: "100",
        },
        {
          time: "29:45",
          value: "100",
        },
        {
          time: "30:00",
          value: "0",
        },
      ],
    },
    {
      name: "Sistólica",
      type: "minmax",
      category: "corpo",
      unit: "mmHg",
      value: ["200", "300"],
      borderColor: "#FF00FF",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "200",
        },
        {
          time: "00:15",
          value: "200",
        },
        {
          time: "00:30",
          value: "200",
        },
        {
          time: "00:45",
          value: "200",
        },
        {
          time: "01:00",
          value: "200",
        },
        {
          time: "01:15",
          value: "200",
        },
        {
          time: "01:30",
          value: "200",
        },
        {
          time: "01:45",
          value: "200",
        },
        {
          time: "02:00",
          value: "200",
        },
        {
          time: "02:15",
          value: "200",
        },
        {
          time: "02:30",
          value: "200",
        },
        {
          time: "02:45",
          value: "200",
        },
        {
          time: "03:00",
          value: "200",
        },
        {
          time: "03:15",
          value: "200",
        },
        {
          time: "03:30",
          value: "200",
        },
        {
          time: "03:45",
          value: "200",
        },
        {
          time: "04:00",
          value: "200",
        },
        {
          time: "04:15",
          value: "200",
        },
        {
          time: "04:30",
          value: "200",
        },
        {
          time: "04:45",
          value: "200",
        },
        {
          time: "05:00",
          value: "300",
        },
        {
          time: "05:15",
          value: "300",
        },
        {
          time: "05:30",
          value: "300",
        },
        {
          time: "05:45",
          value: "300",
        },
        {
          time: "06:00",
          value: "300",
        },
        {
          time: "06:15",
          value: "300",
        },
        {
          time: "06:30",
          value: "300",
        },
        {
          time: "06:45",
          value: "300",
        },
        {
          time: "07:00",
          value: "300",
        },
        {
          time: "07:15",
          value: "300",
        },
        {
          time: "07:30",
          value: "300",
        },
        {
          time: "07:45",
          value: "300",
        },
        {
          time: "08:00",
          value: "300",
        },
        {
          time: "08:15",
          value: "300",
        },
        {
          time: "08:30",
          value: "300",
        },
        {
          time: "08:45",
          value: "300",
        },
        {
          time: "09:00",
          value: "300",
        },
        {
          time: "09:15",
          value: "300",
        },
        {
          time: "09:30",
          value: "300",
        },
        {
          time: "09:45",
          value: "300",
        },
        {
          time: "10:00",
          value: "300",
        },
        {
          time: "10:15",
          value: "300",
        },
        {
          time: "10:30",
          value: "300",
        },
        {
          time: "10:45",
          value: "300",
        },
        {
          time: "11:00",
          value: "300",
        },
        {
          time: "11:15",
          value: "300",
        },
        {
          time: "11:30",
          value: "300",
        },
        {
          time: "11:45",
          value: "300",
        },
        {
          time: "12:00",
          value: "300",
        },
        {
          time: "12:15",
          value: "300",
        },
        {
          time: "12:30",
          value: "300",
        },
        {
          time: "12:45",
          value: "300",
        },
        {
          time: "13:00",
          value: "300",
        },
        {
          time: "13:15",
          value: "300",
        },
        {
          time: "13:30",
          value: "300",
        },
        {
          time: "13:45",
          value: "300",
        },
        {
          time: "14:00",
          value: "300",
        },
        {
          time: "14:15",
          value: "300",
        },
        {
          time: "14:30",
          value: "300",
        },
        {
          time: "14:45",
          value: "300",
        },
        {
          time: "15:00",
          value: "300",
        },
        {
          time: "15:15",
          value: "300",
        },
        {
          time: "15:30",
          value: "300",
        },
        {
          time: "15:45",
          value: "300",
        },
        {
          time: "16:00",
          value: "300",
        },
        {
          time: "16:15",
          value: "300",
        },
        {
          time: "16:30",
          value: "300",
        },
        {
          time: "16:45",
          value: "300",
        },
        {
          time: "17:00",
          value: "300",
        },
        {
          time: "17:15",
          value: "300",
        },
        {
          time: "17:30",
          value: "300",
        },
        {
          time: "17:45",
          value: "300",
        },
        {
          time: "18:00",
          value: "300",
        },
        {
          time: "18:15",
          value: "300",
        },
        {
          time: "18:30",
          value: "300",
        },
        {
          time: "18:45",
          value: "300",
        },
        {
          time: "19:00",
          value: "300",
        },
        {
          time: "19:15",
          value: "300",
        },
        {
          time: "19:30",
          value: "300",
        },
        {
          time: "19:45",
          value: "300",
        },
        {
          time: "20:00",
          value: "300",
        },
        {
          time: "20:15",
          value: "300",
        },
        {
          time: "20:30",
          value: "300",
        },
        {
          time: "20:45",
          value: "300",
        },
        {
          time: "21:00",
          value: "300",
        },
        {
          time: "21:15",
          value: "300",
        },
        {
          time: "21:30",
          value: "300",
        },
        {
          time: "21:45",
          value: "300",
        },
        {
          time: "22:00",
          value: "300",
        },
        {
          time: "22:15",
          value: "300",
        },
        {
          time: "22:30",
          value: "300",
        },
        {
          time: "22:45",
          value: "300",
        },
        {
          time: "23:00",
          value: "300",
        },
        {
          time: "23:15",
          value: "250",
        },
        {
          time: "23:30",
          value: "250",
        },
        {
          time: "23:45",
          value: "250",
        },
        {
          time: "24:00",
          value: "250",
        },
        {
          time: "24:15",
          value: "250",
        },
        {
          time: "24:30",
          value: "250",
        },
        {
          time: "24:45",
          value: "250",
        },
        {
          time: "25:00",
          value: "250",
        },
        {
          time: "25:15",
          value: "250",
        },
        {
          time: "25:30",
          value: "250",
        },
        {
          time: "25:45",
          value: "250",
        },
        {
          time: "26:00",
          value: "250",
        },
        {
          time: "26:15",
          value: "250",
        },
        {
          time: "26:30",
          value: "250",
        },
        {
          time: "26:45",
          value: "250",
        },
        {
          time: "27:00",
          value: "250",
        },
        {
          time: "27:15",
          value: "250",
        },
        {
          time: "27:30",
          value: "250",
        },
        {
          time: "27:45",
          value: "250",
        },
        {
          time: "28:00",
          value: "250",
        },
        {
          time: "28:15",
          value: "250",
        },
        {
          time: "28:30",
          value: "250",
        },
        {
          time: "28:45",
          value: "250",
        },
        {
          time: "29:00",
          value: "200",
        },
        {
          time: "29:15",
          value: "200",
        },
        {
          time: "29:30",
          value: "200",
        },
        {
          time: "29:45",
          value: "200",
        },
        {
          time: "30:00",
          value: "200",
        },
      ],
    },

    {
      name: "Diastólica",
      type: "minmax",
      category: "corpo",
      unit: "mmHg",
      value: ["200", "300"],
      borderColor: "#00ffff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "200",
        },
        {
          time: "00:15",
          value: "200",
        },
        {
          time: "00:30",
          value: "200",
        },
        {
          time: "00:45",
          value: "200",
        },
        {
          time: "01:00",
          value: "200",
        },
        {
          time: "01:15",
          value: "200",
        },
        {
          time: "01:30",
          value: "200",
        },
        {
          time: "01:45",
          value: "200",
        },
        {
          time: "02:00",
          value: "200",
        },
        {
          time: "02:15",
          value: "200",
        },
        {
          time: "02:30",
          value: "200",
        },
        {
          time: "02:45",
          value: "200",
        },
        {
          time: "03:00",
          value: "200",
        },
        {
          time: "03:15",
          value: "200",
        },
        {
          time: "03:30",
          value: "200",
        },
        {
          time: "03:45",
          value: "200",
        },
        {
          time: "04:00",
          value: "200",
        },
        {
          time: "04:15",
          value: "200",
        },
        {
          time: "04:30",
          value: "200",
        },
        {
          time: "04:45",
          value: "200",
        },
        {
          time: "05:00",
          value: "200",
        },
        {
          time: "05:15",
          value: "200",
        },
        {
          time: "05:30",
          value: "200",
        },
        {
          time: "05:45",
          value: "300",
        },
        {
          time: "06:00",
          value: "300",
        },
        {
          time: "06:15",
          value: "300",
        },
        {
          time: "06:30",
          value: "300",
        },
        {
          time: "06:45",
          value: "300",
        },
        {
          time: "07:00",
          value: "300",
        },
        {
          time: "07:15",
          value: "300",
        },
        {
          time: "07:30",
          value: "300",
        },
        {
          time: "07:45",
          value: "300",
        },
        {
          time: "08:00",
          value: "300",
        },
        {
          time: "08:15",
          value: "300",
        },
        {
          time: "08:30",
          value: "300",
        },
        {
          time: "08:45",
          value: "300",
        },
        {
          time: "09:00",
          value: "300",
        },
        {
          time: "09:15",
          value: "300",
        },
        {
          time: "09:30",
          value: "300",
        },
        {
          time: "09:45",
          value: "300",
        },
        {
          time: "10:00",
          value: "300",
        },
        {
          time: "10:15",
          value: "300",
        },
        {
          time: "10:30",
          value: "300",
        },
        {
          time: "10:45",
          value: "300",
        },
        {
          time: "11:00",
          value: "300",
        },
        {
          time: "11:15",
          value: "300",
        },
        {
          time: "11:30",
          value: "300",
        },
        {
          time: "11:45",
          value: "300",
        },
        {
          time: "12:00",
          value: "300",
        },
        {
          time: "12:15",
          value: "300",
        },
        {
          time: "12:30",
          value: "300",
        },
        {
          time: "12:45",
          value: "300",
        },
        {
          time: "13:00",
          value: "300",
        },
        {
          time: "13:15",
          value: "250",
        },
        {
          time: "13:30",
          value: "250",
        },
        {
          time: "13:45",
          value: "250",
        },
        {
          time: "14:00",
          value: "250",
        },
        {
          time: "14:15",
          value: "250",
        },
        {
          time: "14:30",
          value: "250",
        },
        {
          time: "14:45",
          value: "250",
        },
        {
          time: "15:00",
          value: "250",
        },
        {
          time: "15:15",
          value: "250",
        },
        {
          time: "15:30",
          value: "250",
        },
        {
          time: "15:45",
          value: "250",
        },
        {
          time: "16:00",
          value: "250",
        },
        {
          time: "16:15",
          value: "250",
        },
        {
          time: "16:30",
          value: "250",
        },
        {
          time: "16:45",
          value: "250",
        },
        {
          time: "17:00",
          value: "250",
        },
        {
          time: "17:15",
          value: "250",
        },
        {
          time: "17:30",
          value: "250",
        },
        {
          time: "17:45",
          value: "250",
        },
        {
          time: "18:00",
          value: "250",
        },
        {
          time: "18:15",
          value: "250",
        },
        {
          time: "18:30",
          value: "250",
        },
        {
          time: "18:45",
          value: "250",
        },
        {
          time: "19:00",
          value: "250",
        },
        {
          time: "19:15",
          value: "250",
        },
        {
          time: "19:30",
          value: "250",
        },
        {
          time: "19:45",
          value: "250",
        },
        {
          time: "20:00",
          value: "250",
        },
        {
          time: "20:15",
          value: "250",
        },
        {
          time: "20:30",
          value: "300",
        },
        {
          time: "20:45",
          value: "300",
        },
        {
          time: "21:00",
          value: "300",
        },
        {
          time: "21:15",
          value: "300",
        },
        {
          time: "21:30",
          value: "300",
        },
        {
          time: "21:45",
          value: "300",
        },
        {
          time: "22:00",
          value: "300",
        },
        {
          time: "22:15",
          value: "300",
        },
        {
          time: "22:30",
          value: "300",
        },
        {
          time: "22:45",
          value: "300",
        },
        {
          time: "23:00",
          value: "300",
        },
        {
          time: "23:15",
          value: "300",
        },
        {
          time: "23:30",
          value: "300",
        },
        {
          time: "23:45",
          value: "300",
        },
        {
          time: "24:00",
          value: "300",
        },
        {
          time: "24:15",
          value: "300",
        },
        {
          time: "24:30",
          value: "300",
        },
        {
          time: "24:45",
          value: "300",
        },
        {
          time: "25:00",
          value: "300",
        },
        {
          time: "25:15",
          value: "220",
        },
        {
          time: "25:30",
          value: "220",
        },
        {
          time: "25:45",
          value: "220",
        },
        {
          time: "26:00",
          value: "220",
        },
        {
          time: "26:15",
          value: "220",
        },
        {
          time: "26:30",
          value: "220",
        },
        {
          time: "26:45",
          value: "220",
        },
        {
          time: "27:00",
          value: "220",
        },
        {
          time: "27:15",
          value: "220",
        },
        {
          time: "27:30",
          value: "220",
        },
        {
          time: "27:45",
          value: "220",
        },
        {
          time: "28:00",
          value: "220",
        },
        {
          time: "28:15",
          value: "220",
        },
        {
          time: "28:30",
          value: "220",
        },
        {
          time: "28:45",
          value: "220",
        },
        {
          time: "29:00",
          value: "220",
        },
        {
          time: "29:15",
          value: "220",
        },
        {
          time: "29:30",
          value: "200",
        },
        {
          time: "29:45",
          value: "200",
        },
        {
          time: "30:00",
          value: "200",
        },
      ],
    },
    {
      name: "Saturação Oxigênio Mínima",
      type: "value",
      category: "corpo",
      unit: "%",
      value: "96",
      borderColor: "#00aaff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "99",
        },
        {
          time: "00:15",
          value: "99",
        },
        {
          time: "00:30",
          value: "99",
        },
        {
          time: "00:45",
          value: "99",
        },
        {
          time: "01:00",
          value: "99",
        },
        {
          time: "01:15",
          value: "99",
        },
        {
          time: "01:30",
          value: "99",
        },
        {
          time: "01:45",
          value: "99",
        },
        {
          time: "02:00",
          value: "99",
        },
        {
          time: "02:15",
          value: "99",
        },
        {
          time: "02:30",
          value: "99",
        },
        {
          time: "02:45",
          value: "99",
        },
        {
          time: "03:00",
          value: "99",
        },
        {
          time: "03:15",
          value: "99",
        },
        {
          time: "03:30",
          value: "99",
        },
        {
          time: "03:45",
          value: "99",
        },
        {
          time: "04:00",
          value: "99",
        },
        {
          time: "04:15",
          value: "99",
        },
        {
          time: "04:30",
          value: "99",
        },
        {
          time: "04:45",
          value: "99",
        },
        {
          time: "05:00",
          value: "99",
        },
        {
          time: "05:15",
          value: "99",
        },
        {
          time: "05:30",
          value: "99",
        },
        {
          time: "05:45",
          value: "99",
        },
        {
          time: "06:00",
          value: "99",
        },
        {
          time: "06:15",
          value: "99",
        },
        {
          time: "06:30",
          value: "99",
        },
        {
          time: "06:45",
          value: "99",
        },
        {
          time: "07:00",
          value: "99",
        },
        {
          time: "07:15",
          value: "99",
        },
        {
          time: "07:30",
          value: "99",
        },
        {
          time: "07:45",
          value: "97",
        },
        {
          time: "08:00",
          value: "97",
        },
        {
          time: "08:15",
          value: "97",
        },
        {
          time: "08:30",
          value: "97",
        },
        {
          time: "08:45",
          value: "97",
        },
        {
          time: "09:00",
          value: "97",
        },
        {
          time: "09:15",
          value: "97",
        },
        {
          time: "09:30",
          value: "97",
        },
        {
          time: "09:45",
          value: "97",
        },
        {
          time: "10:00",
          value: "97",
        },
        {
          time: "10:15",
          value: "97",
        },
        {
          time: "10:30",
          value: "97",
        },
        {
          time: "10:45",
          value: "97",
        },
        {
          time: "11:00",
          value: "97",
        },
        {
          time: "11:15",
          value: "97",
        },
        {
          time: "11:30",
          value: "97",
        },
        {
          time: "11:45",
          value: "97",
        },
        {
          time: "12:00",
          value: "97",
        },
        {
          time: "12:15",
          value: "97",
        },
        {
          time: "12:30",
          value: "97",
        },
        {
          time: "12:45",
          value: "97",
        },
        {
          time: "13:00",
          value: "97",
        },
        {
          time: "13:15",
          value: "97",
        },
        {
          time: "13:30",
          value: "97",
        },
        {
          time: "13:45",
          value: "97",
        },
        {
          time: "14:00",
          value: "97",
        },
        {
          time: "14:15",
          value: "97",
        },
        {
          time: "14:30",
          value: "97",
        },
        {
          time: "14:45",
          value: "97",
        },
        {
          time: "15:00",
          value: "97",
        },
        {
          time: "15:15",
          value: "96",
        },
        {
          time: "15:30",
          value: "96",
        },
        {
          time: "15:45",
          value: "96",
        },
        {
          time: "16:00",
          value: "96",
        },
        {
          time: "16:15",
          value: "96",
        },
        {
          time: "16:30",
          value: "96",
        },
        {
          time: "16:45",
          value: "96",
        },
        {
          time: "17:00",
          value: "96",
        },
        {
          time: "17:15",
          value: "96",
        },
        {
          time: "17:30",
          value: "96",
        },
        {
          time: "17:45",
          value: "96",
        },
        {
          time: "18:00",
          value: "96",
        },
        {
          time: "18:15",
          value: "96",
        },
        {
          time: "18:30",
          value: "96",
        },
        {
          time: "18:45",
          value: "96",
        },
        {
          time: "19:00",
          value: "96",
        },
        {
          time: "19:15",
          value: "96",
        },
        {
          time: "19:30",
          value: "96",
        },
        {
          time: "19:45",
          value: "96",
        },
        {
          time: "20:00",
          value: "96",
        },
        {
          time: "20:15",
          value: "96",
        },
        {
          time: "20:30",
          value: "96",
        },
        {
          time: "20:45",
          value: "96",
        },
        {
          time: "21:00",
          value: "96",
        },
        {
          time: "21:15",
          value: "96",
        },
        {
          time: "21:30",
          value: "96",
        },
        {
          time: "21:45",
          value: "96",
        },
        {
          time: "22:00",
          value: "96",
        },
        {
          time: "22:15",
          value: "98",
        },
        {
          time: "22:30",
          value: "98",
        },
        {
          time: "22:45",
          value: "98",
        },
        {
          time: "23:00",
          value: "98",
        },
        {
          time: "23:15",
          value: "98",
        },
        {
          time: "23:30",
          value: "98",
        },
        {
          time: "23:45",
          value: "98",
        },
        {
          time: "24:00",
          value: "98",
        },
        {
          time: "24:15",
          value: "98",
        },
        {
          time: "24:30",
          value: "98",
        },
        {
          time: "24:45",
          value: "98",
        },
        {
          time: "25:00",
          value: "98",
        },
        {
          time: "25:15",
          value: "98",
        },
        {
          time: "25:30",
          value: "98",
        },
        {
          time: "25:45",
          value: "98",
        },
        {
          time: "26:00",
          value: "98",
        },
        {
          time: "26:15",
          value: "98",
        },
        {
          time: "26:30",
          value: "98",
        },
        {
          time: "26:45",
          value: "98",
        },
        {
          time: "27:00",
          value: "98",
        },
        {
          time: "27:15",
          value: "99",
        },
        {
          time: "27:30",
          value: "99",
        },
        {
          time: "27:45",
          value: "99",
        },
        {
          time: "28:00",
          value: "99",
        },
        {
          time: "28:15",
          value: "99",
        },
        {
          time: "28:30",
          value: "99",
        },
        {
          time: "28:45",
          value: "99",
        },
        {
          time: "29:00",
          value: "99",
        },
        {
          time: "29:15",
          value: "99",
        },
        {
          time: "29:30",
          value: "99",
        },
        {
          time: "29:45",
          value: "99",
        },
        {
          time: "30:00",
          value: "99",
        },
      ],
    },
    {
      name: "Frequência Cardíaca",
      type: "minmax",
      category: "corpo",
      unit: "bpm",
      value: ["60", "150"],
      borderColor: "#ff6600",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "60",
        },
        {
          time: "00:15",
          value: "60",
        },
        {
          time: "00:30",
          value: "70",
        },
        {
          time: "00:45",
          value: "70",
        },
        {
          time: "01:00",
          value: "70",
        },
        {
          time: "01:15",
          value: "70",
        },
        {
          time: "01:30",
          value: "70",
        },
        {
          time: "01:45",
          value: "80",
        },
        {
          time: "02:00",
          value: "80",
        },
        {
          time: "02:15",
          value: "80",
        },
        {
          time: "02:30",
          value: "80",
        },
        {
          time: "02:45",
          value: "80",
        },
        {
          time: "03:00",
          value: "80",
        },
        {
          time: "03:15",
          value: "80",
        },
        {
          time: "03:30",
          value: "80",
        },
        {
          time: "03:45",
          value: "80",
        },
        {
          time: "04:00",
          value: "80",
        },
        {
          time: "04:15",
          value: "80",
        },
        {
          time: "04:30",
          value: "80",
        },
        {
          time: "04:45",
          value: "80",
        },
        {
          time: "05:00",
          value: "80",
        },
        {
          time: "05:15",
          value: "80",
        },
        {
          time: "05:30",
          value: "80",
        },
        {
          time: "05:45",
          value: "80",
        },
        {
          time: "06:00",
          value: "90",
        },
        {
          time: "06:15",
          value: "90",
        },
        {
          time: "06:30",
          value: "90",
        },
        {
          time: "06:45",
          value: "90",
        },
        {
          time: "07:00",
          value: "90",
        },
        {
          time: "07:15",
          value: "90",
        },
        {
          time: "07:30",
          value: "90",
        },
        {
          time: "07:45",
          value: "90",
        },
        {
          time: "08:00",
          value: "90",
        },
        {
          time: "08:15",
          value: "90",
        },
        {
          time: "08:30",
          value: "90",
        },
        {
          time: "08:45",
          value: "90",
        },
        {
          time: "09:00",
          value: "120",
        },
        {
          time: "09:15",
          value: "120",
        },
        {
          time: "09:30",
          value: "120",
        },
        {
          time: "09:45",
          value: "120",
        },
        {
          time: "10:00",
          value: "120",
        },
        {
          time: "10:15",
          value: "120",
        },
        {
          time: "10:30",
          value: "120",
        },
        {
          time: "10:45",
          value: "120",
        },
        {
          time: "11:00",
          value: "120",
        },
        {
          time: "11:15",
          value: "120",
        },
        {
          time: "11:30",
          value: "120",
        },
        {
          time: "11:45",
          value: "120",
        },
        {
          time: "12:00",
          value: "120",
        },
        {
          time: "12:15",
          value: "120",
        },
        {
          time: "12:30",
          value: "120",
        },
        {
          time: "12:45",
          value: "120",
        },
        {
          time: "13:00",
          value: "150",
        },
        {
          time: "13:15",
          value: "150",
        },
        {
          time: "13:30",
          value: "150",
        },
        {
          time: "13:45",
          value: "150",
        },
        {
          time: "14:00",
          value: "150",
        },
        {
          time: "14:15",
          value: "150",
        },
        {
          time: "14:30",
          value: "150",
        },
        {
          time: "14:45",
          value: "150",
        },
        {
          time: "15:00",
          value: "150",
        },
        {
          time: "15:15",
          value: "150",
        },
        {
          time: "15:30",
          value: "150",
        },
        {
          time: "15:45",
          value: "150",
        },
        {
          time: "16:00",
          value: "150",
        },
        {
          time: "16:15",
          value: "150",
        },
        {
          time: "16:30",
          value: "150",
        },
        {
          time: "16:45",
          value: "150",
        },
        {
          time: "17:00",
          value: "150",
        },
        {
          time: "17:15",
          value: "150",
        },
        {
          time: "17:30",
          value: "150",
        },
        {
          time: "17:45",
          value: "140",
        },
        {
          time: "18:00",
          value: "140",
        },
        {
          time: "18:15",
          value: "140",
        },
        {
          time: "18:30",
          value: "140",
        },
        {
          time: "18:45",
          value: "140",
        },
        {
          time: "19:00",
          value: "140",
        },
        {
          time: "19:15",
          value: "140",
        },
        {
          time: "19:30",
          value: "140",
        },
        {
          time: "19:45",
          value: "140",
        },
        {
          time: "20:00",
          value: "140",
        },
        {
          time: "20:15",
          value: "140",
        },
        {
          time: "20:30",
          value: "140",
        },
        {
          time: "20:45",
          value: "140",
        },
        {
          time: "21:00",
          value: "140",
        },
        {
          time: "21:15",
          value: "140",
        },
        {
          time: "21:30",
          value: "140",
        },
        {
          time: "21:45",
          value: "140",
        },
        {
          time: "22:00",
          value: "140",
        },
        {
          time: "22:15",
          value: "150",
        },
        {
          time: "22:30",
          value: "150",
        },
        {
          time: "22:45",
          value: "150",
        },
        {
          time: "23:00",
          value: "150",
        },
        {
          time: "23:15",
          value: "150",
        },
        {
          time: "23:30",
          value: "150",
        },
        {
          time: "23:45",
          value: "150",
        },
        {
          time: "24:00",
          value: "150",
        },
        {
          time: "24:15",
          value: "150",
        },
        {
          time: "24:30",
          value: "150",
        },
        {
          time: "24:45",
          value: "90",
        },
        {
          time: "25:00",
          value: "90",
        },
        {
          time: "25:15",
          value: "90",
        },
        {
          time: "25:30",
          value: "90",
        },
        {
          time: "25:45",
          value: "90",
        },
        {
          time: "26:00",
          value: "90",
        },
        {
          time: "26:15",
          value: "90",
        },
        {
          time: "26:30",
          value: "90",
        },
        {
          time: "26:45",
          value: "90",
        },
        {
          time: "27:00",
          value: "90",
        },
        {
          time: "27:15",
          value: "90",
        },
        {
          time: "27:30",
          value: "90",
        },
        {
          time: "27:45",
          value: "90",
        },
        {
          time: "28:00",
          value: "90",
        },
        {
          time: "28:15",
          value: "70",
        },
        {
          time: "28:30",
          value: "70",
        },
        {
          time: "28:45",
          value: "70",
        },
        {
          time: "29:00",
          value: "70",
        },
        {
          time: "29:15",
          value: "70",
        },
        {
          time: "29:30",
          value: "70",
        },
        {
          time: "29:45",
          value: "70",
        },
        {
          time: "30:00",
          value: "70",
        },
      ],
    },
    {
      name: "Temperatura Corporal",
      type: "minmax",
      category: "corpo",
      unit: "°C",
      value: ["36", "38"],
      borderColor: "#006600",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "36",
        },
        {
          time: "00:15",
          value: "36",
        },
        {
          time: "00:30",
          value: "36",
        },
        {
          time: "00:45",
          value: "36",
        },
        {
          time: "01:00",
          value: "36",
        },
        {
          time: "01:15",
          value: "36",
        },
        {
          time: "01:30",
          value: "36",
        },
        {
          time: "01:45",
          value: "36",
        },
        {
          time: "02:00",
          value: "36",
        },
        {
          time: "02:15",
          value: "36",
        },
        {
          time: "02:30",
          value: "36",
        },
        {
          time: "02:45",
          value: "36",
        },
        {
          time: "03:00",
          value: "36",
        },
        {
          time: "03:15",
          value: "36",
        },
        {
          time: "03:30",
          value: "36",
        },
        {
          time: "03:45",
          value: "36",
        },
        {
          time: "04:00",
          value: "36",
        },
        {
          time: "04:15",
          value: "36",
        },
        {
          time: "04:30",
          value: "36",
        },
        {
          time: "04:45",
          value: "36",
        },
        {
          time: "05:00",
          value: "36",
        },
        {
          time: "05:15",
          value: "36",
        },
        {
          time: "05:30",
          value: "36",
        },
        {
          time: "05:45",
          value: "36",
        },
        {
          time: "06:00",
          value: "36",
        },
        {
          time: "06:15",
          value: "36.8",
        },
        {
          time: "06:30",
          value: "36.8",
        },
        {
          time: "06:45",
          value: "36.8",
        },
        {
          time: "07:00",
          value: "36.8",
        },
        {
          time: "07:15",
          value: "36.8",
        },
        {
          time: "07:30",
          value: "36.8",
        },
        {
          time: "07:45",
          value: "36.8",
        },
        {
          time: "08:00",
          value: "36.8",
        },
        {
          time: "08:15",
          value: "36.8",
        },
        {
          time: "08:30",
          value: "36.8",
        },
        {
          time: "08:45",
          value: "36.8",
        },
        {
          time: "09:00",
          value: "36.8",
        },
        {
          time: "09:15",
          value: "36.8",
        },
        {
          time: "09:30",
          value: "36.8",
        },
        {
          time: "09:45",
          value: "36.8",
        },
        {
          time: "10:00",
          value: "36.8",
        },
        {
          time: "10:15",
          value: "36.8",
        },
        {
          time: "10:30",
          value: "36.8",
        },
        {
          time: "10:45",
          value: "36.8",
        },
        {
          time: "11:00",
          value: "37.5",
        },
        {
          time: "11:15",
          value: "37.5",
        },
        {
          time: "11:30",
          value: "37.5",
        },
        {
          time: "11:45",
          value: "37.5",
        },
        {
          time: "12:00",
          value: "37.5",
        },
        {
          time: "12:15",
          value: "37.5",
        },
        {
          time: "12:30",
          value: "37.5",
        },
        {
          time: "12:45",
          value: "37.5",
        },
        {
          time: "13:00",
          value: "37.5",
        },
        {
          time: "13:15",
          value: "37.5",
        },
        {
          time: "13:30",
          value: "37.5",
        },
        {
          time: "13:45",
          value: "37.5",
        },
        {
          time: "14:00",
          value: "37.5",
        },
        {
          time: "14:15",
          value: "37.5",
        },
        {
          time: "14:30",
          value: "37.5",
        },
        {
          time: "14:45",
          value: "38",
        },
        {
          time: "15:00",
          value: "38",
        },
        {
          time: "15:15",
          value: "38",
        },
        {
          time: "15:30",
          value: "38",
        },
        {
          time: "15:45",
          value: "38",
        },
        {
          time: "16:00",
          value: "38",
        },
        {
          time: "16:15",
          value: "38",
        },
        {
          time: "16:30",
          value: "38",
        },
        {
          time: "16:45",
          value: "38",
        },
        {
          time: "17:00",
          value: "38",
        },
        {
          time: "17:15",
          value: "38",
        },
        {
          time: "17:30",
          value: "38",
        },
        {
          time: "17:45",
          value: "38",
        },
        {
          time: "18:00",
          value: "38",
        },
        {
          time: "18:15",
          value: "38",
        },
        {
          time: "18:30",
          value: "38",
        },
        {
          time: "18:45",
          value: "38",
        },
        {
          time: "19:00",
          value: "38",
        },
        {
          time: "19:15",
          value: "38",
        },
        {
          time: "19:30",
          value: "38",
        },
        {
          time: "19:45",
          value: "38",
        },
        {
          time: "20:00",
          value: "38",
        },
        {
          time: "20:15",
          value: "38",
        },
        {
          time: "20:30",
          value: "38",
        },
        {
          time: "20:45",
          value: "38",
        },
        {
          time: "21:00",
          value: "38",
        },
        {
          time: "21:15",
          value: "38",
        },
        {
          time: "21:30",
          value: "38",
        },
        {
          time: "21:45",
          value: "38",
        },
        {
          time: "22:00",
          value: "38",
        },
        {
          time: "22:15",
          value: "38",
        },
        {
          time: "22:30",
          value: "38",
        },
        {
          time: "22:45",
          value: "38",
        },
        {
          time: "23:00",
          value: "37",
        },
        {
          time: "23:15",
          value: "37",
        },
        {
          time: "23:30",
          value: "37",
        },
        {
          time: "23:45",
          value: "37",
        },
        {
          time: "24:00",
          value: "37",
        },
        {
          time: "24:15",
          value: "37",
        },
        {
          time: "24:30",
          value: "37",
        },
        {
          time: "24:45",
          value: "37",
        },
        {
          time: "25:00",
          value: "37",
        },
        {
          time: "25:15",
          value: "37",
        },
        {
          time: "25:30",
          value: "37",
        },
        {
          time: "25:45",
          value: "37",
        },
        {
          time: "26:00",
          value: "37",
        },
        {
          time: "26:15",
          value: "37",
        },
        {
          time: "26:30",
          value: "37",
        },
        {
          time: "26:45",
          value: "37",
        },
        {
          time: "27:00",
          value: "37",
        },
        {
          time: "27:15",
          value: "37",
        },
        {
          time: "27:30",
          value: "37",
        },
        {
          time: "27:45",
          value: "37",
        },
        {
          time: "28:00",
          value: "37",
        },
        {
          time: "28:15",
          value: "37",
        },
        {
          time: "28:30",
          value: "37",
        },
        {
          time: "28:45",
          value: "36.5",
        },
        {
          time: "29:00",
          value: "36.5",
        },
        {
          time: "29:15",
          value: "36.5",
        },
        {
          time: "29:30",
          value: "36.5",
        },
        {
          time: "29:45",
          value: "36.5",
        },
        {
          time: "30:00",
          value: "36.5",
        },
      ],
    },
    {
      name: "Umidade",
      type: "value",
      category: "corpo",
      unit: "%",
      value: "20",
      borderColor: "#6600ff",
      graphicHidden: false,
      graphic: [
        {
          time: "00:00",
          value: "0",
        },
        {
          time: "00:15",
          value: "0",
        },
        {
          time: "00:30",
          value: "0",
        },
        {
          time: "00:45",
          value: "0",
        },
        {
          time: "01:00",
          value: "0",
        },
        {
          time: "01:15",
          value: "0",
        },
        {
          time: "01:30",
          value: "0",
        },
        {
          time: "01:45",
          value: "0",
        },
        {
          time: "02:00",
          value: "0",
        },
        {
          time: "02:15",
          value: "0",
        },
        {
          time: "02:30",
          value: "0",
        },
        {
          time: "02:45",
          value: "0",
        },
        {
          time: "03:00",
          value: "0",
        },
        {
          time: "03:15",
          value: "0",
        },
        {
          time: "03:30",
          value: "0",
        },
        {
          time: "03:45",
          value: "0",
        },
        {
          time: "04:00",
          value: "0",
        },
        {
          time: "04:15",
          value: "0",
        },
        {
          time: "04:30",
          value: "0",
        },
        {
          time: "04:45",
          value: "0",
        },
        {
          time: "05:00",
          value: "0",
        },
        {
          time: "05:15",
          value: "0",
        },
        {
          time: "05:30",
          value: "0",
        },
        {
          time: "05:45",
          value: "0",
        },
        {
          time: "06:00",
          value: "0",
        },
        {
          time: "06:15",
          value: "0",
        },
        {
          time: "06:30",
          value: "0",
        },
        {
          time: "06:45",
          value: "10",
        },
        {
          time: "07:00",
          value: "10",
        },
        {
          time: "07:15",
          value: "10",
        },
        {
          time: "07:30",
          value: "10",
        },
        {
          time: "07:45",
          value: "10",
        },
        {
          time: "08:00",
          value: "10",
        },
        {
          time: "08:15",
          value: "10",
        },
        {
          time: "08:30",
          value: "10",
        },
        {
          time: "08:45",
          value: "10",
        },
        {
          time: "09:00",
          value: "10",
        },
        {
          time: "09:15",
          value: "10",
        },
        {
          time: "09:30",
          value: "10",
        },
        {
          time: "09:45",
          value: "10",
        },
        {
          time: "10:00",
          value: "10",
        },
        {
          time: "10:15",
          value: "10",
        },
        {
          time: "10:30",
          value: "10",
        },
        {
          time: "10:45",
          value: "10",
        },
        {
          time: "11:00",
          value: "30",
        },
        {
          time: "11:15",
          value: "30",
        },
        {
          time: "11:30",
          value: "30",
        },
        {
          time: "11:45",
          value: "30",
        },
        {
          time: "12:00",
          value: "30",
        },
        {
          time: "12:15",
          value: "30",
        },
        {
          time: "12:30",
          value: "30",
        },
        {
          time: "12:45",
          value: "30",
        },
        {
          time: "13:00",
          value: "30",
        },
        {
          time: "13:15",
          value: "30",
        },
        {
          time: "13:30",
          value: "30",
        },
        {
          time: "13:45",
          value: "30",
        },
        {
          time: "14:00",
          value: "30",
        },
        {
          time: "14:15",
          value: "30",
        },
        {
          time: "14:30",
          value: "30",
        },
        {
          time: "14:45",
          value: "30",
        },
        {
          time: "15:00",
          value: "30",
        },
        {
          time: "15:15",
          value: "30",
        },
        {
          time: "15:30",
          value: "30",
        },
        {
          time: "15:45",
          value: "30",
        },
        {
          time: "16:00",
          value: "30",
        },
        {
          time: "16:15",
          value: "30",
        },
        {
          time: "16:30",
          value: "30",
        },
        {
          time: "16:45",
          value: "30",
        },
        {
          time: "17:00",
          value: "30",
        },
        {
          time: "17:15",
          value: "30",
        },
        {
          time: "17:30",
          value: "30",
        },
        {
          time: "17:45",
          value: "30",
        },
        {
          time: "18:00",
          value: "30",
        },
        {
          time: "18:15",
          value: "30",
        },
        {
          time: "18:30",
          value: "30",
        },
        {
          time: "18:45",
          value: "30",
        },
        {
          time: "19:00",
          value: "30",
        },
        {
          time: "19:15",
          value: "30",
        },
        {
          time: "19:30",
          value: "30",
        },
        {
          time: "19:45",
          value: "30",
        },
        {
          time: "20:00",
          value: "30",
        },
        {
          time: "20:15",
          value: "30",
        },
        {
          time: "20:30",
          value: "30",
        },
        {
          time: "20:45",
          value: "30",
        },
        {
          time: "21:00",
          value: "30",
        },
        {
          time: "21:15",
          value: "30",
        },
        {
          time: "21:30",
          value: "30",
        },
        {
          time: "21:45",
          value: "30",
        },
        {
          time: "22:00",
          value: "30",
        },
        {
          time: "22:15",
          value: "30",
        },
        {
          time: "22:30",
          value: "30",
        },
        {
          time: "22:45",
          value: "30",
        },
        {
          time: "23:00",
          value: "25",
        },
        {
          time: "23:15",
          value: "25",
        },
        {
          time: "23:30",
          value: "25",
        },
        {
          time: "23:45",
          value: "25",
        },
        {
          time: "24:00",
          value: "25",
        },
        {
          time: "24:15",
          value: "25",
        },
        {
          time: "24:30",
          value: "25",
        },
        {
          time: "24:45",
          value: "25",
        },
        {
          time: "25:00",
          value: "25",
        },
        {
          time: "25:15",
          value: "25",
        },
        {
          time: "25:30",
          value: "25",
        },
        {
          time: "25:45",
          value: "25",
        },
        {
          time: "26:00",
          value: "25",
        },
        {
          time: "26:15",
          value: "25",
        },
        {
          time: "26:30",
          value: "25",
        },
        {
          time: "26:45",
          value: "25",
        },
        {
          time: "27:00",
          value: "25",
        },
        {
          time: "27:15",
          value: "25",
        },
        {
          time: "27:30",
          value: "25",
        },
        {
          time: "27:45",
          value: "25",
        },
        {
          time: "28:00",
          value: "25",
        },
        {
          time: "28:15",
          value: "25",
        },
        {
          time: "28:30",
          value: "25",
        },
        {
          time: "28:45",
          value: "25",
        },
        {
          time: "29:00",
          value: "25",
        },
        {
          time: "29:15",
          value: "25",
        },
        {
          time: "29:30",
          value: "25",
        },
        {
          time: "29:45",
          value: "25",
        },
        {
          time: "30:00",
          value: "25",
        },
      ],
    },
  ],
};

export const pacientesFake = [
  {
    name: "Anderson Silva",
    photo:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2080&q=80",
    id: "8492333",
    currentDate: "12/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "8492333" },
      { key: "idade", name: "Idade", value: "20 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Estella Gauz",
    photo:
      "https://images.unsplash.com/photo-1645378999013-95abebf5f3c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "8237874",
    currentDate: "13/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "8237874" },
      { key: "idade", name: "Idade", value: "35 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Roni Wedder",
    photo:
      "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "103920202",
    currentDate: "13/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "103920202" },
      { key: "idade", name: "Idade", value: "22 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Raquel Dumond",
    photo:
      "https://images.unsplash.com/photo-1645378999514-7ec78b1b9eaa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "45675732",
    currentDate: "13/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "45675732" },
      { key: "idade", name: "Idade", value: "46 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Estevão da Cunha",
    photo:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "6456777",
    currentDate: "14/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "6456777" },
      { key: "idade", name: "Idade", value: "24 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Lívia Andrade",
    photo:
      "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "154325",
    currentDate: "14/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "154325" },
      { key: "idade", name: "Idade", value: "54 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Melvin Dulchen",
    photo:
      "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "07656",
    currentDate: "15/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "07656" },
      { key: "idade", name: "Idade", value: "26 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Sahyd Nevado",
    photo:
      "https://images.unsplash.com/photo-1474176857210-7287d38d27c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "324557",
    currentDate: "15/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "324557" },
      { key: "idade", name: "Idade", value: "24 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Jessica Orlando",
    photo:
      "https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "8976456",
    currentDate: "16/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "8976456" },
      { key: "idade", name: "Idade", value: "56 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Vanessa Gomes",
    photo:
      "https://images.unsplash.com/photo-1573496527892-904f897eb744?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
    id: "23546787",
    currentDate: "17/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "23546787" },
      { key: "idade", name: "Idade", value: "24 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Rosana Verom ",
    photo:
      "https://images.unsplash.com/photo-1573497019236-17f8177b81e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "12343",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "12343" },
      { key: "idade", name: "Idade", value: "34 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Ricardo Geraldo",
    photo:
      "https://images.unsplash.com/photo-1558203728-00f45181dd84?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2074&q=80",
    id: "564756",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "564756" },
      { key: "idade", name: "Idade", value: "56 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Alisson Simon",
    photo:
      "https://images.unsplash.com/photo-1566753323558-f4e0952af115?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1021&q=80",
    id: "2323424",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "2323424" },
      { key: "idade", name: "Idade", value: "33 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Boris de Moraes",
    photo:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
    id: "3293899",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "3293899" },
      { key: "idade", name: "Idade", value: "53 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Renata Pacheco",
    photo:
      "https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80",
    id: "897661",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "897661" },
      { key: "idade", name: "Idade", value: "36 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Neide Camões",
    photo:
      "https://images.unsplash.com/photo-1544179932-fadfa08128a5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    id: "5793922",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "5793922" },
      { key: "idade", name: "Idade", value: "25 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "André Novaes",
    photo:
      "https://images.unsplash.com/photo-1601455763557-db1bea8a9a5a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1212&q=80",
    id: "2393840",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "2393840" },
      { key: "idade", name: "Idade", value: "28 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Paulo Spitz",
    photo:
      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    id: "3982811",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "3982811" },
      { key: "idade", name: "Idade", value: "46 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Ana Paula Mendez",
    photo:
      "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
    id: "1928477",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "1928477" },
      { key: "idade", name: "Idade", value: "38 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Júlio Simões",
    photo:
      "https://images.unsplash.com/photo-1624298357597-fd92dfbec01d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "39807710",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "39807710" },
      { key: "idade", name: "Idade", value: "35 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Igor Takeshi",
    photo:
      "https://images.unsplash.com/photo-1594616838951-c155f8d978a0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    id: "390178674",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "390178674" },
      { key: "idade", name: "Idade", value: "27 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Vivian Bueno",
    photo:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "12874749",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "12874749" },
      { key: "idade", name: "Idade", value: "23 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
  {
    name: "Developer Umantech",
    photo:
      "https://images.unsplash.com/photo-1645378999013-95abebf5f3c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "12345678",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Paciente", name: "Paciente" }],
    currentRole: "Paciente",
    data: [
      { key: "id", name: "ID", value: "12874749" },
      { key: "idade", name: "Idade", value: "23 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
    agenda: [
      {
        id: 1,
        titulo: "Sessão",
        status: "done",
        data: "12/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 2,
        titulo: "Sessão",
        status: "done",
        data: "13/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 3,
        titulo: "Sessão",
        status: "done",
        data: "25/03/2022",
        sessao: sessaoDone,
      },
      {
        id: 4,
        titulo: "Sessão",
        status: "done",
        data: "12/04/2022",
        sessao: sessaoDone,
      },
      {
        id: 5,
        titulo: "Sessão",
        status: "canceled",
        data: "23/04/2022",
        sessao: sessaoCanceled,
      },
      {
        id: 6,
        titulo: "Sessão",
        status: "done",
        data: "10/05/2022",
        sessao: sessaoDone,
      },
      {
        id: 7,
        titulo: "Sessão",
        status: "failed",
        data: "12/06/2022",
        sessao: sessaoFailed,
      },
      {
        id: 8,
        titulo: "Sessão",
        status: "done",
        data: "12/07/2022",
        sessao: sessaoDone,
      },
      {
        id: 9,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 10,
        titulo: "Sessão",
        status: "pending",
        data: "13/09/2022",
        sessao: sessaoPendingDoing,
      },
      {
        id: 11,
        titulo: "Sessão",
        status: "pending",
        data: "09/01/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 12,
        titulo: "Sessão",
        status: "pending",
        data: "12/02/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 13,
        titulo: "Sessão",
        status: "pending",
        data: "17/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 14,
        titulo: "Sessão",
        status: "pending",
        data: "23/04/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 15,
        titulo: "Sessão",
        status: "pending",
        data: "12/05/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 16,
        titulo: "Sessão",
        status: "pending",
        data: "15/06/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 17,
        titulo: "Sessão",
        status: "pending",
        data: "07/07/2023",
        sessao: sessaoPendingDoing,
      },
      {
        id: 18,
        titulo: "Sessão",
        status: "pending",
        data: "12/08/2023",
        sessao: sessaoPendingDoing,
      },
    ],
  },
];

export const accountsFake = [
  {
    name: "Anderson Silva",
    photo:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2080&q=80",
    id: "8492333",
    currentDate: "12/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "8492333" },
      { key: "idade", name: "Idade", value: "20 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Estella Gauz",
    photo:
      "https://images.unsplash.com/photo-1645378999013-95abebf5f3c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "8237874",
    currentDate: "13/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "8237874" },
      { key: "idade", name: "Idade", value: "35 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Roni Wedder",
    photo:
      "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "103920202",
    currentDate: "13/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "103920202" },
      { key: "idade", name: "Idade", value: "22 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Raquel Dumond",
    photo:
      "https://images.unsplash.com/photo-1645378999514-7ec78b1b9eaa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "45675732",
    currentDate: "13/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "45675732" },
      { key: "idade", name: "Idade", value: "46 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Estevão da Cunha",
    photo:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "6456777",
    currentDate: "14/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "6456777" },
      { key: "idade", name: "Idade", value: "24 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Lívia Andrade",
    photo:
      "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "154325",
    currentDate: "14/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "154325" },
      { key: "idade", name: "Idade", value: "54 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Melvin Dulchen",
    photo:
      "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "07656",
    currentDate: "15/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "07656" },
      { key: "idade", name: "Idade", value: "26 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Sahyd Nevado",
    photo:
      "https://images.unsplash.com/photo-1474176857210-7287d38d27c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "324557",
    currentDate: "15/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "324557" },
      { key: "idade", name: "Idade", value: "24 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Jessica Orlando",
    photo:
      "https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "8976456",
    currentDate: "16/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "8976456" },
      { key: "idade", name: "Idade", value: "56 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Vanessa Gomes",
    photo:
      "https://images.unsplash.com/photo-1573496527892-904f897eb744?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
    id: "23546787",
    currentDate: "17/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "23546787" },
      { key: "idade", name: "Idade", value: "24 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Rosana Verom ",
    photo:
      "https://images.unsplash.com/photo-1573497019236-17f8177b81e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    id: "12343",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Terapeuta", name: "Terapeuta" }],
    currentRole: "Terapeuta",
    data: [
      { key: "id", name: "ID", value: "12343" },
      { key: "idade", name: "Idade", value: "34 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Ricardo Geraldo",
    photo:
      "https://images.unsplash.com/photo-1558203728-00f45181dd84?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2074&q=80",
    id: "564756",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "564756" },
      { key: "idade", name: "Idade", value: "56 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Alisson Simon",
    photo:
      "https://images.unsplash.com/photo-1566753323558-f4e0952af115?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1021&q=80",
    id: "2323424",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "2323424" },
      { key: "idade", name: "Idade", value: "33 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Boris de Moraes",
    photo:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
    id: "3293899",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "3293899" },
      { key: "idade", name: "Idade", value: "53 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Renata Pacheco",
    photo:
      "https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80",
    id: "897661",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "897661" },
      { key: "idade", name: "Idade", value: "36 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Neide Camões",
    photo:
      "https://images.unsplash.com/photo-1544179932-fadfa08128a5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    id: "5793922",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "5793922" },
      { key: "idade", name: "Idade", value: "25 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "André Novaes",
    photo:
      "https://images.unsplash.com/photo-1601455763557-db1bea8a9a5a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1212&q=80",
    id: "2393840",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "2393840" },
      { key: "idade", name: "Idade", value: "28 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Paulo Spitz",
    photo:
      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    id: "3982811",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "3982811" },
      { key: "idade", name: "Idade", value: "46 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Ana Paula Mendez",
    photo:
      "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
    id: "1928477",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "1928477" },
      { key: "idade", name: "Idade", value: "38 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Júlio Simões",
    photo:
      "https://images.unsplash.com/photo-1624298357597-fd92dfbec01d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "39807710",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "39807710" },
      { key: "idade", name: "Idade", value: "35 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Igor Takeshi",
    photo:
      "https://images.unsplash.com/photo-1594616838951-c155f8d978a0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    id: "390178674",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "390178674" },
      { key: "idade", name: "Idade", value: "27 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Masculino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
  {
    name: "Vivian Bueno",
    photo:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    id: "12874749",
    currentDate: "18/03/2022",
    currentTime: "14:30",
    active: true,
    roles: [{ id: "Medico", name: "Médico" }],
    currentRole: "Medico",
    data: [
      { key: "id", name: "ID", value: "12874749" },
      { key: "idade", name: "Idade", value: "23 anos" },
      { key: "nascimento", name: "Nascimento", value: "01/02/1983" },
      { key: "sexo", name: "Sexo", value: "Feminino" },
      { key: "rg", name: "RG", value: "28.077.621-4" },
      {
        key: "lesao",
        name: "Lesão",
        value: "Rasgo muscular GRAU III",
      },
    ],
  },
];

export const bottomMenuSectionsFake = {
  terapeuta: [
    {
      name: "Home",
      route: "/",
      active: true,
      selected: true,
    },
    {
      name: "Pacientes",
      route: "/pacientes",
      active: true,
      selected: false,
    },
  ],
  terapeutaPacientes: [
    {
      name: "Home",
      route: "/",
      active: true,
      selected: false,
    },
    {
      name: "Pacientes",
      route: "/pacientes",
      active: true,
      selected: true,
    },
  ],
  terapeutaSessaoConfig: [
    {
      name: "Configurações",
      route: null,
      active: true,
      selected: true,
    },
  ],
  terapeutaSessao: [
    {
      name: "Configurações",
      route: null,
      active: true,
      selected: true,
    },
    {
      name: "AR",
      route: null,
      active: true,
      selected: false,
    },
    {
      name: "Sessão",
      route: null,
      active: true,
      selected: false,
    },
    {
      name: "Borg",
      route: null,
      active: true,
      selected: false,
    },
    {
      name: "Resumo",
      route: null,
      active: true,
      selected: false,
    },
  ],
  medico: [
    {
      name: "Home",
      route: "/",
      active: true,
      selected: true,
    },
    {
      name: "Pacientes",
      route: "/pacientes",
      active: true,
      selected: false,
    },
  ],
  medicoPacientes: [
    {
      name: "Home",
      route: "/",
      active: true,
      selected: false,
    },
    {
      name: "Pacientes",
      route: "/pacientes",
      active: true,
      selected: true,
    },
  ],
  medicoSessaoConfig: [
    {
      name: "Configurações",
      route: null,
      active: true,
      selected: true,
    },
  ],
  medicoSessao: [
    {
      name: "Configurações",
      route: null,
      active: true,
      selected: false,
    },
    {
      name: "AR",
      route: null,
      active: true,
      selected: false,
    },
    {
      name: "Sessão",
      route: null,
      active: true,
      selected: true,
    },
    {
      name: "Borg",
      route: null,
      active: true,
      selected: false,
    },
  ],
  paciente: [
    {
      name: "Home",
      route: "/",
      active: true,
      selected: true,
    },
  ],
  manutencao: [
    {
      name: "Home",
      route: "/",
      active: true,
      selected: true,
    },
    {
      name: "Todos os Cicloergômetros",
      route: "/ciclos",
      active: true,
      selected: false,
    },
  ],
  manutencaoCiclos: [
    {
      name: "Home",
      route: "/",
      active: true,
      selected: false,
    },
    {
      name: "Todos os Cicloergômetros",
      route: "/ciclos",
      active: true,
      selected: true,
    },
  ],
  admin: [
    {
      name: "Usuários",
      route: "/",
      active: true,
      selected: true,
    },
    {
      name: "Cicloergômetros",
      route: "/ciclos",
      active: true,
      selected: false,
    },
  ],
  adminCiclos: [
    {
      name: "Usuários",
      route: "/",
      active: true,
      selected: false,
    },
    {
      name: "Cicloergômetros",
      route: "/ciclos",
      active: true,
      selected: true,
    },
  ],
  auxiliar: [
    {
      name: "Pacientes",
      route: "/",
      active: true,
      selected: true,
    },
  ],
  auxiliarAccountDados: [
    {
      name: "Dados Gerais",
      route: null,
      active: true,
      selected: true,
    },
    {
      name: "Agenda",
      route: null,
      active: true,
      selected: false,
    },
  ],
  auxiliarAccountAgenda: [
    {
      name: "Dados Gerais",
      route: null,
      active: true,
      selected: false,
    },
    {
      name: "Agenda",
      route: null,
      active: true,
      selected: true,
    },
  ],
};

export const ciclosFake = [
  {
    id: "00:00:5e:00:53:af",
    name: "Ciclo 1",
    active: true,
    status: "operando",
    state: "emUso",
    selected: false,
    lastActivity: "05/09/2022 22:12:33",
    activities: [
      {
        time: "05/09/2022 22:12:33",
        value: "Status: operando / Estado: em uso",
        userID: "12345678",
      },
      {
        time: "04/09/2022 12:15:18",
        value: "Status: operando / Estado: pronto para uso",
        userID: "12345678",
      },
    ],
  },
  {
    id: "01:00:5e:00:53:af",
    name: "Ciclo 2",
    active: true,
    status: "em manutenção",
    state: "desligado",
    selected: true,
    lastActivity: "05/09/2022 22:12:33",
    activities: [
      {
        time: "05/09/2022 22:12:33",
        value: "Status: em manutenção / Estado: desligado",
        userID: "12345678",
      },
      {
        time: "04/09/2022 12:15:18",
        value: "Status: operando / Estado: desligado",
        userID: "12345678",
      },
    ],
  },
  {
    id: "02:00:5e:00:53:ea",
    name: "Ciclo 3",
    active: true,
    status: "operando",
    state: "carregando",
    selected: false,
    lastActivity: "05/09/2022 22:12:33",
    activities: [
      {
        time: "05/09/2022 22:12:33",
        value: "Status: operando / Estado: em carregamento",
        userID: "12345678",
      },
      {
        time: "05/09/2022 22:10:25",
        value: "Status: operando / Estado: desligado",
        userID: "12345678",
      },
    ],
  },
  {
    id: "10:10:5e:00:53:ko",
    name: "Ciclo 4",
    active: true,
    status: "operando",
    state: "prontoParaUso",
    selected: false,
    lastActivity: "05/09/2022 22:12:33",
    activities: [
      {
        time: "05/09/2022 22:12:33",
        value: "Status: operando / Estado: pronto para uso",
        userID: "12345678",
      },
      {
        time: "05/09/2022 17:06:43",
        value: "Status: operando / Estado: desligado",
        userID: "12345678",
      },
    ],
  },
  {
    id: "98:00:5e:00:50:op",
    name: "Ciclo 5",
    active: true,
    status: "operando",
    state: "emUso",
    selected: false,
    lastActivity: "04/09/2022 22:10:33",
    activities: [
      {
        time: "04/09/2022 22:10:33",
        value: "Status: operando / Estado: em uso",
        userID: "12345678",
      },
      {
        time: "04/09/2022 22:10:32",
        value: "Status: operando / Estado: desligado",
        userID: "12345678",
      },
    ],
  },
  {
    id: "98:00:5e:00:53:op",
    name: "Ciclo 6",
    active: true,
    status: "operando",
    state: "prontoParaUso",
    selected: false,
    lastActivity: "04/09/2022 22:10:33",
    activities: [
      {
        time: "04/09/2022 22:10:33",
        value: "Status: operando / Estado: pronto para uso",
        userID: "12345678",
      },
      {
        time: "04/09/2022 22:10:00",
        value: "Status: operando / Estado: desligado",
        userID: "12345678",
      },
    ],
  },
  {
    id: "98:00:5e:ae:53:op",
    name: "Ciclo 7",
    active: true,
    status: "operando",
    state: "emUso",
    selected: false,
    lastActivity: "04/09/2022 22:10:33",
    activities: [
      {
        time: "04/09/2022 22:10:33",
        value: "Status: operando / Estado: em uso",
        userID: "12345678",
      },
      {
        time: "04/09/2022 22:10:17",
        value: "Status: operando / Estado: desligado",
        userID: "12345678",
      },
    ],
  },
];

export const escala = [
  { value: "0", description: "Sem esforço" },
  { value: "1", description: "" },
  { value: "2", description: "" },
  { value: "3", description: "Pouco esforço" },
  { value: "4", description: "" },
  { value: "5", description: "" },
  { value: "6", description: "Esforço leve" },
  { value: "7", description: "" },
  { value: "8", description: "" },
  { value: "9", description: "Esforço Médio" },
  { value: "10", description: "" },
  { value: "11", description: "" },
  { value: "12", description: "Pouco intenso" },
  { value: "13", description: "" },
  { value: "14", description: "" },
  { value: "15", description: "Intenso" },
  { value: "16", description: "" },
  { value: "17", description: "" },
  { value: "18", description: "Muito intenso" },
  { value: "19", description: "" },
  { value: "20", description: "Máximo esforço" },
];
