import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, getJwtToken } from "../../../../contexts/authentication";
import api from "../../../../services/Api";
import { MainSchedulesWrapper, CalendarWrapper } from "./styled";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ListCilosLastActivities } from "../../../../ui/General";

const MainCalendar = ({ date, setDate }) => {
  const changeDate = (date) => {
    setDate(date);
  };

  useEffect(() => {
    console.log(date);
  }, [date]);

  return (
    <CalendarWrapper>
      <Calendar onChange={changeDate} value={date} />
    </CalendarWrapper>
  );
};

const CalendarFirstList = (props) => {
  return <ListCilosLastActivities {...props} />;
};

const MainSchedulesManutencao = ({ ciclos, date, setDate }) => {
  const navigate = useNavigate();
  const [cycleErgometerLogs, setCycleErgometerLogs] = useState(null);

  const clickThumb = (id) => {
    navigate(`/ciclo/${id}`);
  };

  const getLogs = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };

    api
      .get(
        `/CycleErgometer/GetAllLogsByDay/${
          date ? date.toISOString() : new Date().toISOString()
        }`,
        config
      )
      .then(async (res) => {
        if (res.status === 200) {
          let logs = res.data;
          logs.sort(
            (a, b) => new Date(b.messageDate) - new Date(a.messageDate)
          );
          setCycleErgometerLogs(logs);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (date) {
      getLogs();
    }
  }, [date]);

  return (
    <MainSchedulesWrapper>
      <MainCalendar date={date} setDate={setDate} />
      <CalendarFirstList
        width="100%"
        title={`Logs na data de ${
          date &&
          date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear()
        }`}
        ciclos={ciclos}
        cycleErgometerLogs={cycleErgometerLogs}
        date={date}
        clickFunction={clickThumb}
      />
    </MainSchedulesWrapper>
  );
};

export default MainSchedulesManutencao;
