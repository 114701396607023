import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { getJwtToken } from "../../../../contexts/authentication";
import api from "../../../../services/Api";

import Header from "../../Header";
import SidebarPaciente from "../../General/SidebarPaciente";
import MeusPacientes from "../../General/MeusPacientes";
import MainSchedules from "../../General/MainSchedules";
import {
  ContainerFullwidthLeft,
  MainContainer,
} from "../../../../ui/Containers";
import { SectionMenu } from "../../../../ui/NavigationComponents";
import { useAuth } from "../../../../contexts/authentication";
import { usePaciente } from "../../../../contexts/paciente";
import { useUser } from "../../../../contexts/user";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { usePreload } from "../../../../contexts/preloading";
import {
  sessaoPendingDoing,
  sessaoCanceled,
  sessaoFailed,
  sessaoDone,
} from "../../../../assets/mock.js";

const PacienteIn = ({ pacientes, autoChangeTabIndex }) => {
  const { preloading, setPreloading } = usePreload();
  const { user } = useUser();
  const { pacienteid } = useParams();
  const { setBottomMenuSections } = useBottomMenuSections();
  const { logout } = useAuth();
  const [pacienteIndex, setPacienteIndex] = useState(null);
  const [index, setIndex] = useState(null);
  const { paciente, setPaciente } = usePaciente();

  const clone = require("rfdc")();

  const loadPaciente = async (index) => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Schedule/GetScheduleByAccountId/${index}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          let tempUser = clone(paciente);
          let data = clone(res.data);
          data.map(
            (value) =>
              (value.scheduleAt = new Date(value.scheduleAt).toLocaleString())
          );
          data.map((schedule, index) => {
            if (schedule.status === "Pending" || schedule.status === "Doing") {
              schedule.sessao = sessaoPendingDoing;
            } else if (schedule.status === "Canceled") {
              schedule.sessao = sessaoCanceled;
            } else if (schedule.status === "Failed") {
              schedule.sessao = sessaoDone;
            } else if (schedule.status === "Done") {
              schedule.sessao = sessaoDone;
            }
          });
          // console.log(data);
          if (typeof tempUser === "object") tempUser.agenda = data;
          api
            .get(`/Application/GetUserBasicByAccountId/${index}`, config)
            .then(async (res2) => {
              if (res2.status === 200) {
                if (typeof tempUser === "object")
                  tempUser.data = res2.data.data;
                setPaciente(tempUser);
                setPreloading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  useEffect(() => {
    if (pacientes) {
      // console.log(pacientes);
      let tempIndex = pacientes.findIndex((paciente) => {
        return paciente.accountId.toString() === pacienteid;
      });
      setIndex(tempIndex);
      setPaciente(pacientes[tempIndex]);
    }
  }, [pacientes]);

  useEffect(() => {}, [paciente]);

  useEffect(() => {
    if (user && user.currentRole === "Terapeuta") {
      setBottomMenuSections("terapeutaPacientes");
    } else if (user && user.currentRole === "Medico") {
      setBottomMenuSections("medicoPacientes");
    }
  }, [user]);

  useEffect(() => {
    if (index !== null) {
      loadPaciente(pacienteid);
    }
  }, [index]);

  useEffect(() => {
    // console.log(paciente);
  }, [paciente]);

  return (
    paciente && (
      <React.Fragment>
        <ContainerFullwidthLeft>
          <SidebarPaciente paciente={paciente} setPaciente={setPaciente} />
          <MainContainer>
            <Outlet />
          </MainContainer>
        </ContainerFullwidthLeft>
        <SectionMenu />
      </React.Fragment>
    )
  );
};

export default PacienteIn;
