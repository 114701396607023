import React, { useState, useEffect } from "react";
import { usePaciente } from "../../../../contexts/paciente";
import { useSession } from "../../../../contexts/session";
import ARCicloContainer from "./ARCicloContainer";

const ARCiclo = ({
  pacienteid,
  sessaoid,
  classe,
  enablePip,
  setEnablePip,
  freio,
  setFreio,
  toogleFreio,
  decrementarMotor,
  incrementarMotor,
  incrementarCarga,
  decrementarCarga,
  incrementarEstimulo,
  decrementarEstimulo,
}) => {
  const { paciente } = usePaciente();
  const { session, setSession } = useSession();
  return paciente && paciente.agenda ? (
    <>
      {sessaoid &&
        session.sessionid === sessaoid &&
        session.state &&
        session.state !== "" &&
        session.state !== "Cicloergômetro Disponível" &&
        session.state !== "Configuração" && (
          <ARCicloContainer
            classe={classe}
            enablePip={enablePip}
            setEnablePip={setEnablePip}
            pacienteid={pacienteid}
            sessaoid={sessaoid}
            config={paciente.agenda[0].sessao.cicloData}
            freio={freio}
            setFreio={setFreio}
            toogleFreio={toogleFreio}
            decrementarMotor={decrementarMotor}
            incrementarMotor={incrementarMotor}
            incrementarCarga={incrementarCarga}
            decrementarCarga={decrementarCarga}
            incrementarEstimulo={incrementarEstimulo}
            decrementarEstimulo={decrementarEstimulo}
          />
        )}
    </>
  ) : (
    <></>
  );
};

export default ARCiclo;
