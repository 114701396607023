import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MeusCiclosWrapper,
  MeusCiclosTitle,
  MeusCiclosItems,
  CicloItemWrapper,
  CicloPhoto,
  CicloTitle,
  CicloName,
  CicloValue,
} from "./styled";

const MeusCiclos = ({ ciclos }) => {
  const navigate = useNavigate();
  const clickThumb = (id) => {
    navigate(`/ciclo/${id}`);
  };

  return (
    <MeusCiclosWrapper>
      <MeusCiclosTitle>Cicloergômetro</MeusCiclosTitle>
      <MeusCiclosItems>
        {ciclos &&
          ciclos.map(
            (value, index) =>
              value.active && (
                <CicloItem
                  clickFunction={clickThumb}
                  id={value.cycleErgometerId}
                  key={index}
                  name={value.name}
                  status={value.status}
                  state={value.state}
                />
              )
          )}
      </MeusCiclosItems>
    </MeusCiclosWrapper>
  );
};

const CicloItem = ({ id, name, status, state, clickFunction }) => {
  return (
    <CicloItemWrapper
      onClick={(e) => {
        e.preventDefault();
        clickFunction(id);
      }}
    >
      <CicloTitle>{name}</CicloTitle>
      <CicloName>Status:</CicloName>
      <CicloValue>{status}</CicloValue>
      <CicloName>Estado Atual:</CicloName>
      <CicloValue>{state}</CicloValue>
    </CicloItemWrapper>
  );
};

export default MeusCiclos;
