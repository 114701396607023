import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  height: 100%;
`;

export const AddUserResultWrapper = styled.div`
  width: 80%;
  max-height: 70%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const TooglesWrapper = styled.div`
  width: 220px;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  background-color: transparent;
`;
export const ToogleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin: 5px 0px;
  padding: 0px 10px;
  color: ${({ theme }) => theme.colors.orange};
  background-color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  white-space: pre-wrap;
  box-sizing: border-box;
`;

export const ConfigItemTextWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #212121 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 22px #0000006e;
  border-radius: 14px;
  margin-bottom: 5px;

  &.roles {
    height: 350px;
  }
`;

export const ConfigItemTextTitle = styled.div`
  width: 30%;
  text-align: left;
  font: normal normal 200 16px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};

  &.roles {
    width: 30%;
  }
`;

export const ConfigItemTextValue = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: auto;
  text-align: left;
  font: normal normal 600 22px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  -webkit-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;

  &.roles {
    justify-content: space-evenly;
    width: 60%;
    height: 100%;
  }
`;
