import styled from "styled-components";
import { Settings } from "@styled-icons/feather/Settings";

export const MenuLines = styled.div`
  max-width: 60%;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 0;
  align-self: flex-start;
  margin-top: 10px;
`;

export const MenuFirstLine = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const MenuSecondLine = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonSettings = styled(Settings)`
  width: 35px;
  color: ${({ theme }) => theme.colors.white};
`;
