import styled from "styled-components";

export const TitleSecao = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const CicloData = styled.div`
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 20px;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
`;

export const CicloDataItem = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 35px;
`;

export const CicloDataItemTitle = styled.div`
  font: normal normal 800 18px/30px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  white-space: pre;
`;

export const CicloDataItemText = styled.div`
  font: normal normal 400 18px/30px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
`;

export const LogsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 530px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.grey};
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  overflow: hidden;
  margin-bottom: 100px;
`;

export const LogsText = styled.textarea`
  width: 98%;
  height: 97%;
  border-radius: 24px;
  font: normal normal 400 18px/30px ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 25px;
  resize: none;
`;

export const GridThumbWrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const ToogleWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 150px;
  height: 150px;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.orange};
  background-color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  white-space: pre-wrap;

  &:before {
    content: "Status Ciclo: \\A Operante";
    white-space: pre-wrap;
  }

  &.manutencao {
    &:before {
      content: "Status Ciclo: \\A Manutenção";
      white-space: pre-wrap;
    }
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 426px;
  margin-top: 0px;
  margin-bottom: 30px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 12px;

  /* ~~~ navigation styles ~~~ */
  .react-calendar {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;

    .react-calendar__navigation {
      color: ${({ theme }) => theme.colors.white};

      button {
        color: ${({ theme }) => theme.colors.white};

        &:hover {
          background-color: transparent;
        }
      }

      .react-calendar__navigation__label {
        font: normal normal bold 30px/48px
          ${({ theme }) => theme.fonts.secondary};
      }
    }

    .react-calendar__viewContainer {
      height: 300px;
      margin-top: 50px;
      color: ${({ theme }) => theme.colors.white};

      /* react-calendar__tile--now
      react-calendar__tile--range
      react-calendar__tile--rangeStart
      react-calendar__tile--rangeEnd
      react-calendar__tile--rangeBothEnds
      react-calendar__month-view__days__day */
      .react-calendar__month-view__weekdays {
        font: normal normal bold 10px/15px
          ${({ theme }) => theme.fonts.secondary};
        color: ${({ theme }) => theme.colors.white};
      }

      .react-calendar__month-view {
        height: 100%;
        > div:first-child {
          height: 100%;
          > div:first-child {
            height: 100%;
            .react-calendar__month-view__days {
              height: 100%;
            }
          }
        }
      }

      .react-calendar__tile {
        &.react-calendar__tile--active {
          background: ${({ theme }) => theme.colors.orange};
        }

        &.react-calendar__tile--now {
          background-color: ${({ theme }) => theme.colors.black};
        }

        &:enabled {
          &:hover {
            background-color: ${({ theme }) => theme.colors.blue};
          }
        }

        &.react-calendar__month-view__days__day {
          color: ${({ theme }) => theme.colors.white};

          &.react-calendar__month-view__days__day--weekend {
            color: ${({ theme }) => theme.colors.orange};
          }
          &.react-calendar__month-view__days__day--neighboringMonth {
            color: ${({ theme }) => theme.colors.greyLight};
          }
        }
      }
    }

    .react-calendar__year-view {
      .react-calendar__year-view__months {
        button {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;
