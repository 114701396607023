import styled from "styled-components";

export const AlertWrapper = styled.div`
  cursor: pointer;
  user-select: none;
  position: fixed;
  right: 30px;
  top: 0px;
  transform: unset;
  font: normal normal 400 15px ${({ theme }) => theme.fonts.primary};
  text-align: center;
  line-height: 15px;
  white-space: pre-wrap;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  box-sizing: border-box;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s linear 0s, top 0.5s ease-in-out 0s,
    z-index 0s linear 0.5s;

  &.on {
    opacity: 1;
    top: 30px;
    z-index: 9999999;
    transition: opacity 0.5s linear 0s, top 0.5s ease-in-out 0s,
      z-index 0s linear 0s;
  }

  .close-bt {
    position: absolute;
    width: 30px;
    height: 30px;
    right: -15px;
    top: -15px;
    border-radius: 20px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    margin: 0;
    background-color: ${({ theme }) => theme.colors.greyDark};
    font: normal normal 600 15px ${({ theme }) => theme.fonts.primary};
    line-height: 25px;
    box-sizing: border-box;

    &:before {
      content: "X";
    }
  }

  &.warning {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};

    .close-bt {
      border: 2px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.greyDark};
    }
  }

  &.white {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
    .close-bt {
      border: 2px solid ${({ theme }) => theme.colors.orange};
      background-color: ${({ theme }) => theme.colors.greyDark};
    }
  }

  &.error {
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
    .close-bt {
      border: 2px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.greyDark};
    }
  }

  &.autoCancel {
    right: unset;
    top: 50vh !important;
    left: 50vw;
    transform: translateX(-50%) translateY(-50%);
    font: normal normal 400 22px ${({ theme }) => theme.fonts.primary};
    line-height: 38px;
    padding: 20px 30px;
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
    .close-bt {
      border: 2px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.greyDark};
    }
  }

  &.success {
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.black};
    .close-bt {
      border: 2px solid ${({ theme }) => theme.colors.black};
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;
