import styled from "styled-components";

export const MeusPacientesWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  overflow-x: hidden;
  width: 100%;
  height: 327px;
  flex-flow: column nowrap;
  background: ${({ theme }) => theme.colors.grey} 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

export const MeusPacientesTitle = styled.div`
  position: absolute;
  font: normal normal 600 22px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
`;
export const MeusPacientesItems = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 80%;
  margin-top: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PacienteItemWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-flow: column wrap;
  width: 158px;
  height: 200px;
  overflow: hidden;
  margin: 20px 30px;
`;

export const PacientePhoto = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 158px;
  height: 158px;
  background-color: ${({ theme }) => theme.colors.black};
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 80px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PacienteName = styled.div`
  font: normal normal 400 16px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;
