import { useEffect } from "react";
import { GridThumbPacientes } from "../../../../ui/General";
import { useNavigate } from "react-router-dom";
import { useAccounts } from "../../../../contexts/accounts";
import { AccountsWrapper } from "./styled";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";

const Accounts = () => {
  const { accounts, getAccounts, accountsFiltered, getAccountsFiltered } =
    useAccounts();
  const navigate = useNavigate();

  const { setBottomMenuSections } = useBottomMenuSections();

  useEffect(() => {
    setBottomMenuSections("auxiliar");
  }, []);

  const clickThumb = (id) => {
    navigate(`/account/${id}`);
  };

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (accounts) {
      setTimeout(() => {
        getAccountsFiltered("");
      }, 1000);
    }
  }, [accounts]);

  return (
    <AccountsWrapper>
      {accounts &&
        accountsFiltered &&
        accountsFiltered.map((item, index) => {
          let isPaciente = false;
          for (let i = 0; i < item.roles.length; i++) {
            if (item.roles[i] === "Paciente") {
              isPaciente = true;
            }
          }
          {
            return (
              isPaciente && (
                <GridThumbPacientes
                  key={index}
                  item={item}
                  clickFunction={clickThumb}
                />
              )
            );
          }
        })}
    </AccountsWrapper>
  );
};

export default Accounts;
