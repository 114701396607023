import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 350px;
`;
