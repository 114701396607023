import styled, { keyframes } from "styled-components";
import bgCadeira from "../../../../../assets/images/cadeira-icon.svg";

export const TitleSessaoPendente = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
`;

export const GridThumbWrapper = styled.div`
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const GridConfigWrapper = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const CadeiraControls = styled.div`
  position: relative;
  width: 450px;
  height: 450px;
  background-image: url(${bgCadeira});
  background-repeat: no-repeat;
  background-size: contain;
`;

const pulse = keyframes`
 0% {
  transform: translateX(-100%) translateY(-14%) rotate(16deg);

  }
  50% {
    transform: translateX(100%) translateY(45%) rotate(16deg);

  }
  100% {
    transform: translateX(-100%) translateY(-14%) rotate(16deg);

  }
`;

const pulseVertical = keyframes`
 0% {
  transform: translateX(30%) translateY(-200%) rotate(90deg);

  }
  50% {
    transform: translateX(30%) translateY(0%) rotate(90deg);

  }
  100% {
    transform: translateX(30%) translateY(-200%) rotate(90deg);

  }
`;

const pulseReclinar = keyframes`
 0% {
  transform: translateX(-100%) translateY(-14%) rotate(-90deg);

  }
  50% {
    transform: translateX(50%) translateY(150%) rotate(0deg);

  }
  100% {
    transform: translateX(-100%) translateY(-14%) rotate(-90deg);

  }
`;

export const MoverContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  bottom: 0px;
  left: 30%;
  transform: translateX(-50%) translateY(0%) rotate(16deg);
  animation: ${pulse} 2s infinite ease-in-out;
`;
export const MoverVerticalContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  bottom: 0px;
  left: 30%;
  animation: ${pulseVertical} 2s infinite ease-in-out;
`;

export const ReclinarContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  top: 40%;
  left: 60%;
  animation: ${pulseReclinar} 2s infinite ease-in-out;
`;

export const JoystickContainer = styled.div`
  position: relative;
  width: 460px;
  height: 650px;
  margin-top: -100px;
`;

export const JoystickWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 460px;
  height: 300px;
  transform: scale(90%);
  margin-bottom: 100px;
`;

export const Circle = styled.div`
  pointer-events: none;
  position: absolute;
  width: 200px;
  height: 200px;
  border: 2px solid ${({ theme }) => theme.colors.orange};
  border-radius: 200px;
`;

export const LeftText = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 200px;
  height: 20px;
  text-align: right;
  right: 350px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.white};
`;

export const RightText = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: 20px;
  text-align: left;
  left: 350px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.white};
`;

export const TopText = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  bottom: 270px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.white};
`;

export const BottomText = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 270px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.white};
`;

export const ToogleWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 150px;
  height: 150px;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.orange};
  background-color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  white-space: pre-wrap;

  &:before {
    content: "Ativar Config \\A Cadeira";
    white-space: pre-wrap;
  }
`;
