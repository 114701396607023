export const resumoEstatistica = [
  {
    id: "Sessao_Contagem",
    name: "Sessão Id",
    unit: "",
    category: "",
    value: "0",
  },
  { id: "Corrida", name: "Corrida", unit: "", category: "", value: "0" },
  { id: "Tempo", name: "Tempo", unit: "s", category: "", value: "0" },
  { id: "Dist", name: "Distância", unit: "m", category: "", value: "0" },
  { id: "Rot", name: "Rotação", unit: "RPM", category: "", value: "0" },
  { id: "Carga", name: "Carga", unit: "%", category: "", value: "0" },
  {
    id: "EstMed",
    name: "Estímulo Médio",
    unit: "mA",
    category: "",
    value: "0",
  },
  {
    id: "EstimGluD",
    name: "Estímulo Glúteo Direito",
    unit: "mA",
    category: "",
    value: "0",
  },
  {
    id: "EstimGluE",
    name: "Estímulo Glúteo Esquerdo",
    unit: "mA",
    category: "",
    value: "0",
  },
  {
    id: "EstimIsqD",
    name: "Estímulo Ísquio Direito",
    unit: "mA",
    category: "",
    value: "0",
  },
  {
    id: "EstimIsqE",
    name: "Estímulo Ísquio Esquerdo",
    unit: "mA",
    category: "",
    value: "0",
  },
  {
    id: "EstimQuaD",
    name: "Estímulo Quadríceps Direito",
    unit: "mA",
    category: "",
    value: "0",
  },
  {
    id: "EstimQuaE",
    name: "Estímulo Quadríceps Esquerdo",
    unit: "mA",
    category: "",
    value: "0",
  },
  {
    id: "SistMax",
    name: "Sistólica Máx",
    unit: "mmHg",
    category: "",
    value: "0",
  },
  {
    id: "SistMin",
    name: "Sistólica Mín",
    unit: "mmHg",
    category: "",
    value: "0",
  },
  {
    id: "DiastMax",
    name: "Diastólica Máx",
    unit: "mmHg",
    category: "",
    value: "0",
  },
  {
    id: "DiastMin",
    name: "Diastólica Min",
    unit: "mmHg",
    category: "",
    value: "0",
  },
  {
    id: "SaturaOxiMin",
    name: "Saturação Oxigênio Mín",
    unit: "%",
    category: "",
    value: "0",
  },
  {
    id: "FreqCardMax",
    name: "Frequencia Cardíaca Máx",
    unit: "BPM",
    category: "",
    value: "0",
  },
  {
    id: "FreqCardMin",
    name: "Frequencia Cardíaca Mín",
    unit: "BPM",
    category: "",
    value: "0",
  },
  {
    id: "TempCorpMax",
    name: "Temperatura Corporal Máx",
    unit: "°C",
    category: "",
    value: "0",
  },
  {
    id: "TempCorpMin",
    name: "Temperatura Coporal Mín",
    unit: "°C",
    category: "",
    value: "0",
  },
  { id: "UmidAcao", name: "Umidade", unit: "", category: "%", value: "0" },
  {
    id: "TimeStamp",
    name: "Data e Hora",
    unit: "",
    category: "",
    value: "0",
  },
];
