import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../../contexts/authentication";

const PaginasSessao = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default PaginasSessao;
