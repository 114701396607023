export const commandosAtuacao = [
  { name: "SEM COMANDO", key: "", value: 0 },
  { name: "PROTOC_20HZ", key: "", value: 1 },
  { name: "PROTOC_60HZ", key: "", value: 2 },
  { name: "ENTER", key: "", value: 9 },
  { name: "INICIAR", key: "", value: 10 },
  { name: "PAUSAR", key: "", value: 11 },
  { name: "ENCERRAR CORRIDA", key: "", value: 12 },
  { name: "INC_CARGA", key: "", value: 13 },
  { name: "DEC_CARGA", key: "", value: 14 },
  { name: "INC_ESTIM", key: "", value: 15 },
  { name: "DEC_ESTIM", key: "", value: 16 },
  { name: "INC_MOTOR", key: "", value: 17 },
  { name: "DEC_MOTOR", key: "", value: 18 },
  {
    name: "APLICA ESTÍMULO NO QUADRICEPS DIREITO - MAX",
    key: "estimMaxQuaD",
    value: 19,
  },
  {
    name: "APLICA ESTÍMULO NO GLÚTEO DIREITO - MAX",
    key: "estimMaxGluD",
    value: 20,
  },
  {
    name: "APLICA ESTÍMULO NO ISQUIOS DIREITO - MAX",
    key: "estimMaxIsqD",
    value: 21,
  },
  {
    name: "APLICA ESTÍMULO NO QUADRICEPS ESQUERDO - MAX",
    key: "estimMaxQuaE",
    value: 22,
  },
  {
    name: "APLICA ESTÍMULO NO GLÚTEO ESQUERDO - MAX",
    key: "estimMaxGluE",
    value: 23,
  },
  {
    name: "APLICA ESTÍMULO NO ISQUIOS ESQUERDO - MAX",
    key: "estimMaxIsqE",
    value: 24,
  },
  {
    name: "APLICA ESTÍMULO NO QUADRICEPS DIREITO - MIN",
    key: "estimMinQuaD",
    value: 25,
  },
  {
    name: "APLICA ESTÍMULO NO GLÚTEO DIREITO - MIN",
    key: "estimMinGluD",
    value: 26,
  },
  {
    name: "APLICA ESTÍMULO NO ISQUIOS DIREITO - MIN",
    key: "estimMinIsqD",
    value: 27,
  },
  {
    name: "APLICA ESTÍMULO NO QUADRICEPS ESQUERDO - MIN",
    key: "estimMinQuaE",
    value: 28,
  },
  {
    name: "APLICA ESTÍMULO NO GLÚTEO ESQUERDO - MIN",
    key: "estimMinGluE",
    value: 29,
  },
  {
    name: "APLICA ESTÍMULO NO ISQUIOS ESQUERDO - MIN",
    key: "estimMinIsqE",
    value: 30,
  },
  { name: "SOBE_CADEIRA", type: "mover", key: "up", value: 31 },
  { name: "DESCE_CADEIRA", type: "mover", key: "down", value: 32 },
  { name: "INCLINA_CIMA", type: "reclinar", key: "right", value: 33 },
  { name: "INCLINA_BAIXO", type: "reclinar", key: "left", value: 34 },
  { name: "FRENTE", type: "mover", key: "right", value: 35 },
  { name: "ATRAS", type: "mover", key: "left", value: 36 },
  { name: "ENCERRAR SESSÃO", key: "", value: 37 },
];
