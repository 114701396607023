import React, { useEffect, useContext, useState, createContext } from "react";
import { bottomMenuSectionsFake } from "../assets/mock.js";

const BottomMenuSectionContext = createContext();

export default function BottomMenuSectionProvider({ children }) {
  const [bottomMenuSections, setBottomMenuSections] = useState(null);
  const [currentBottomMenu, setCurrentBottomMenu] = useState(null);

  useEffect(() => {
    setCurrentBottomMenu(bottomMenuSectionsFake[bottomMenuSections]);
  }, [bottomMenuSections]);

  useEffect(() => {}, [currentBottomMenu]);

  return (
    <BottomMenuSectionContext.Provider
      value={{
        bottomMenuSections,
        setBottomMenuSections,
        currentBottomMenu,
        setCurrentBottomMenu,
      }}
    >
      {children}
    </BottomMenuSectionContext.Provider>
  );
}

export function useBottomMenuSections() {
  const context = useContext(BottomMenuSectionContext);
  if (!context)
    throw new Error(
      "useBottomMenuSection must be used within a BottomMenuSectionProvider"
    );

  const {
    bottomMenuSections,
    setBottomMenuSections,
    currentBottomMenu,
    setCurrentBottomMenu,
  } = context;

  return {
    bottomMenuSections,
    setBottomMenuSections,
    currentBottomMenu,
    setCurrentBottomMenu,
  };
}
