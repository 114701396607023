import React, { useState, useEffect } from "react";
import _ from "lodash";
import { getJwtToken } from "../../../../../contexts/authentication";
import { usePreload } from "../../../../../contexts/preloading";
import { useAlert } from "../../../../../contexts/alert";
import api from "../../../../../services/Api";

import {
  ConfigItemText,
  GridThumbDados,
  GridThumbDadosAdd,
  ConfigItemSelect,
  ConfigItemSlider,
  ConfigItemSliderRange,
} from "../../../../../ui/General";
import {
  TitleSecao,
  NotasWrapper,
  NotasText,
  NotasBorg,
  BorgTitle,
  BorgButtons,
  BorgButton,
  BorgButtonValue,
  BorgButtonDescription,
  BorgButtonTime,
} from "./styled";

const NotasContainer = ({ pacienteid, sessaoid, sessaoFinalizada, escala }) => {
  const [dados, setDados] = useState(null);
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();

  const loadData = async () => {
    let dadosTemp = _.cloneDeep(dados);

    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .get(`/Schedule/GetScheduleByAccountId/${pacienteid}`, config)
      .then((res) => {
        if (res.status === 200) {
          res.data.map((session, index) => {
            if (session.sessionId === parseInt(sessaoid)) {
              setDados((values) => ({
                note: session.note,
                borgs: session.borgs,
              }));
            }
          });
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao carregar Borg da Sessão!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
        return false;
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {}, [dados]);

  return (
    <>
      <TitleSecao>Sessão</TitleSecao>
      <NotasWrapper>
        <>
          <NotasBorg>
            <BorgTitle>Nível de esforço (BORG)</BorgTitle>
            <BorgButtons>
              {dados &&
                dados.borgs.map((item, index) => {
                  return escala.map(
                    (value, index2) =>
                      parseInt(value.value) === parseInt(item.borgScale) && (
                        <BorgButton key={index2} className={"selected"}>
                          <BorgButtonTime className={"selected"}>
                            {new Date(item.timeOfBorg)
                              .toLocaleString("pt-BR")
                              .replace(" ", "\n")}
                          </BorgButtonTime>
                          <BorgButtonValue className={"selected"}>
                            {value.value}
                          </BorgButtonValue>
                          <BorgButtonDescription className={"selected"}>
                            {value.description}
                          </BorgButtonDescription>
                        </BorgButton>
                      )
                  );
                })}
            </BorgButtons>
          </NotasBorg>
          <NotasText
            placeholder="Notas sobre a sessão..."
            value={(dados && dados.note) || ""}
            readOnly
          ></NotasText>
        </>
      </NotasWrapper>
    </>
  );
};

export default NotasContainer;
