import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { useAuth } from "../contexts/authentication";
import { useLightbox } from "../contexts/lightbox";
import { AppWrapper } from "./styled";
import { createBrowserHistory } from "history";
import Login from "../pages/Login";
import Header from "../shared/Components/Header";
import HeaderTerapeutaHome from "../shared/Components/Header/HeaderComponents/HeaderTerapeutaHome";
import HeaderMedicoHome from "../shared/Components/Header/HeaderComponents/HeaderMedicoHome";
import HeaderPacientes from "../shared/Components/Header/HeaderComponents/HeaderPacientes";
import HeaderPacienteSessao from "../shared/Components/Header/HeaderComponents/HeaderPacienteSessao";
import { PlusSm } from "@styled-icons/heroicons-solid/PlusSm";
// import song from "../assets/audios/MakeupAndVanitySet-Implant.mp3";

// TERAPEUTA
import Terapeuta from "../pages/Terapeuta";
import TerapeutaHome from "../shared/Components/Terapeuta/TerapeutaHome";
//END TERAPEUTA

//MEDICO
import Medico from "../pages/Medico";
import MedicoHome from "../shared/Components/Medico/MedicoHome";
//END MEDICO

//PACIENTE
import Paciente from "../pages/Paciente";
import PacienteInPaciente from "../shared/Components/Pacientes/PacienteInPaciente";
//END PACIENTE

//PACIENTES NO TERAPEUTA E MEDICO
import Pacientes from "../pages/Pacientes";
//END PACIENTES

//PACIENTE NO TERAPEUTA E MEDICO
import PacienteIn from "../shared/Components/Pacientes/PacienteIn";
import PacienteAgenda from "../shared/Components/Pacientes/PacienteAgenda";
import Sessao from "../shared/Components/Pacientes/Sessao";
import PaginasSessao from "../shared/Components/Pacientes/PaginasSessao";
//END PACIENTE

//MANUTENCAO
import Manutencao from "../pages/Manutencao";
import ManutencaoHome from "../shared/Components/Manutencao/ManutencaoHome";
import CiclosManutencao from "../shared/Components/Manutencao/Ciclos";
import CicloManutencao from "../shared/Components/Manutencao/Ciclo";
//END MANUTENCAO

//ADMIN
import Admin from "../pages/Admin";
import AdminHome from "../shared/Components/Admin/AdminHome";
import Account from "../shared/Components/Admin/Account";
import CiclosAdmin from "../shared/Components/Admin/CiclosAdmin";
//END ADMIN

//ADMIN
import Auxiliar from "../pages/Admin";
import AuxiliarHome from "../shared/Components/Auxiliar/AuxiliarHome";
import AccountAuxiliar from "../shared/Components/Auxiliar/Account";
//END ADMIN

import EsqueciSenha from "../pages/EsqueciSenha";
import RedefinirSenha from "../pages/RedefinirSenha";
import VerificarConta from "../pages/VerificarConta";
import { Button, InputText } from "../ui/InputControls";
import { useUser } from "../contexts/user";
import { usePacientes } from "../contexts/pacientes";
import { useAccounts } from "../contexts/accounts";
import { useCiclos } from "../contexts/ciclos";
import Ciclo from "../shared/Components/Admin/Ciclo";
import CodigoDeAcesso from "../pages/CodigoDeAcesso";

const history = createBrowserHistory();

const RouterCicloergometro = () => {
  const { logout, connectMqtt } = useAuth();

  const { user } = useUser();
  const { setLightbox } = useLightbox("");
  const { getAccountsFiltered } = useAccounts();
  const { getCiclosFiltered } = useCiclos();

  const { pacientes } = usePacientes();

  const [pacientesFiltered, setPacientesFiltered] = useState(null);

  const [procuraUsuario, setProcuraUsuario] = useState("");

  const [autoChangeTabIndex, setAutoChangeTabIndex] = useState(null);

  const clone = require("rfdc")();

  const randomSortPacientes = () => {
    let tempList = [...pacientes].sort(() => Math.random() - 0.5);
    setPacientesFiltered(tempList);
  };

  const filterPacientes = (value) => {
    if (pacientes) {
      let pacientesTemp = clone(pacientes);

      pacientesTemp.forEach((obj) => {
        obj.active = false;
      });

      pacientesTemp.forEach((obj) => {
        let regex = "";
        if (value !== "") {
          regex = new RegExp(value, "i");
        }
        let position = obj.nome.search(regex);
        if (position !== -1) {
          obj.active = true;
        }
      });

      setPacientesFiltered(pacientesTemp);
    }
  };

  const voltar = () => {
    history.back();
  };

  useEffect(() => {
    if (user.currentRole === "Paciente" || user.currentRole === "Terapeuta") {
      connectMqtt();
    }
  }, [user, connectMqtt]);

  useEffect(() => {
    // let pacientesTemp = clone(pacientes);
    setPacientesFiltered(pacientes);
  }, [pacientes]);

  return (
    <>
      <AppWrapper>
        <Router history={history} basename="">
          {/****************************** ROUTES FOR HEADER ******************************/}

          <Routes>
            <Route path="/login" element={<></>} />
            <Route path="/" element={<Header logout={logout} />}>
              <>
                <Route
                  index
                  element={
                    <>
                      {user && user.currentRole === "Terapeuta" && (
                        <HeaderTerapeutaHome
                          pacientes={pacientesFiltered}
                          randomSortPacientes={randomSortPacientes}
                        />
                      )}
                      {user && user.currentRole === "Medico" && (
                        <HeaderMedicoHome
                          pacientes={pacientesFiltered}
                          randomSortPacientes={randomSortPacientes}
                        />
                      )}

                      {user && user.currentRole === "Administrador" && (
                        <>
                          <InputText
                            width="200px"
                            id="procurarusuario"
                            value={procuraUsuario}
                            placeholder="Procurar usuário..."
                            onChange={(value) => {
                              getAccountsFiltered(value);
                              setProcuraUsuario(value);
                            }}
                          />
                          <Button
                            width="220px"
                            title="Adicionar Usuário"
                            color="transparent"
                            colorStroke="orange"
                            icon={PlusSm}
                            onClick={() => setLightbox("addUsuario")}
                          />
                        </>
                      )}
                      {user && user.currentRole === "Auxiliar" && (
                        <>
                          <InputText
                            width="200px"
                            id="procurarpaciente"
                            placeholder="Procurar paciente..."
                            value={procuraUsuario}
                            onChange={(value) => {
                              getAccountsFiltered(value);
                              setProcuraUsuario(value);
                            }}
                          />
                          <Button
                            width="220px"
                            title="Adicionar Paciente"
                            color="transparent"
                            colorStroke="orange"
                            icon={PlusSm}
                            onClick={() => setLightbox("addPaciente")}
                          />
                        </>
                      )}
                    </>
                  }
                />
                <Route
                  path="pacientes"
                  element={
                    <>
                      {user && user.currentRole === "Terapeuta" && (
                        <HeaderPacientes
                          filterPacientes={filterPacientes}
                          pacientes={pacientesFiltered}
                          randomSortPacientes={randomSortPacientes}
                        />
                      )}
                      {user && user.currentRole === "Medico" && (
                        <HeaderPacientes
                          filterPacientes={filterPacientes}
                          pacientes={pacientesFiltered}
                          randomSortPacientes={randomSortPacientes}
                        />
                      )}
                    </>
                  }
                />
                <Route
                  path="paciente"
                  element={
                    <>
                      {user && user.currentRole === "Terapeuta" && <Outlet />}
                      {user && user.currentRole === "Medico" && <Outlet />}
                      {user && user.currentRole === "Paciente" && <Outlet />}
                    </>
                  }
                >
                  <Route
                    path=":pacienteid"
                    element={
                      <>
                        {user && user.currentRole === "Terapeuta" && <Outlet />}
                        {user && user.currentRole === "Medico" && <Outlet />}
                        {user && user.currentRole === "Paciente" && <Outlet />}
                      </>
                    }
                  >
                    <Route
                      index
                      element={
                        <>
                          {user && user.currentRole === "Terapeuta" && <></>}
                          {user && user.currentRole === "Medico" && <></>}
                        </>
                      }
                    />
                    <Route path="sessao" element={<Outlet />}>
                      <Route path=":status" element={<Outlet />}>
                        <Route
                          path=":sessaoid"
                          element={
                            <HeaderPacienteSessao
                              voltar={voltar}
                              setAutoChangeTabIndex={setAutoChangeTabIndex}
                            />
                          }
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  path="ciclo"
                  element={
                    <>
                      {user && user.currentRole === "Manutencao" && <Outlet />}
                      {user && user.currentRole === "Administrador" && (
                        <Outlet />
                      )}
                    </>
                  }
                >
                  <Route
                    path=":cicloid"
                    element={
                      <>
                        {user && user.currentRole === "Manutencao" && (
                          <Outlet />
                        )}
                        {user && user.currentRole === "Administrador" && (
                          <Outlet />
                        )}
                      </>
                    }
                  >
                    <Route
                      index
                      element={
                        <>
                          {user && user.currentRole === "Manutencao" && (
                            <Button
                              width="100px"
                              title="Voltar"
                              colorStroke="white"
                              color="transparent"
                              onClick={voltar}
                            />
                          )}
                          {user && user.currentRole === "Administrador" && (
                            <Button
                              width="100px"
                              title="Voltar"
                              colorStroke="white"
                              color="transparent"
                              onClick={voltar}
                            />
                          )}
                        </>
                      }
                    />
                  </Route>
                </Route>
                <Route path="ciclos" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <>
                        {user && user.currentRole === "Manutencao" && <></>}
                        {user && user.currentRole === "Administrador" && (
                          <>
                            <InputText
                              width="250px"
                              id="procurarciclo"
                              placeholder="Procurar cicloergômetro"
                              onChange={getCiclosFiltered}
                            />
                            <Button
                              width="300px"
                              title="Adicionar Cicloergômetro"
                              color="transparent"
                              colorStroke="orange"
                              icon={PlusSm}
                              onClick={() => {
                                setLightbox("addCiclo");
                              }}
                            />
                          </>
                        )}
                      </>
                    }
                  />
                </Route>
                <Route
                  path="account"
                  element={
                    <>
                      {user && user.currentRole === "Administrador" && (
                        <Outlet />
                      )}
                      {user && user.currentRole === "Auxiliar" && <Outlet />}
                    </>
                  }
                >
                  <Route
                    path=":accountid"
                    element={
                      <>
                        {user && user.currentRole === "Administrador" && (
                          <Outlet />
                        )}
                        {user && user.currentRole === "Auxiliar" && <Outlet />}
                      </>
                    }
                  >
                    <Route
                      index
                      element={
                        <>
                          {user && user.currentRole === "Administrador" && (
                            <>
                              <Button
                                width="100px"
                                title="Voltar"
                                colorStroke="white"
                                color="transparent"
                                onClick={voltar}
                              />
                            </>
                          )}
                          {user && user.currentRole === "Auxiliar" && (
                            <>
                              <Button
                                width="100px"
                                title="Voltar"
                                colorStroke="white"
                                color="transparent"
                                onClick={voltar}
                              />
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>
                </Route>
              </>
            </Route>
          </Routes>

          {/****************************** END ROUTES FOR HEADER ******************************/}

          {/****************************** ROUTES FOR CONTENT ******************************/}

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/esquecisenha" element={<EsqueciSenha />} />
            <Route path="/redefinirsenha" element={<RedefinirSenha />} />
            <Route path="/verificarconta" element={<VerificarConta />} />
            <Route path="/codigodeacesso" element={<CodigoDeAcesso />} />
            <>
              <Route
                path="/"
                element={
                  <>
                    {user && user.currentRole === "Administrador" && <Admin />}
                    {user && user.currentRole === "Auxiliar" && <Auxiliar />}
                    {user && user.currentRole === "Terapeuta" && <Terapeuta />}
                    {user && user.currentRole === "Medico" && <Medico />}
                    {user && user.currentRole === "Paciente" && <Paciente />}
                    {user && user.currentRole === "Manutencao" && (
                      <Manutencao />
                    )}
                  </>
                }
              >
                <Route
                  index
                  element={
                    <>
                      {user && user.currentRole === "Administrador" && (
                        <AdminHome />
                      )}
                      {user && user.currentRole === "Auxiliar" && (
                        <AuxiliarHome />
                      )}
                      {user && user.currentRole === "Terapeuta" && (
                        <TerapeutaHome
                          pacientes={pacientesFiltered}
                          randomSortPacientes={randomSortPacientes}
                        />
                      )}
                      {user && user.currentRole === "Medico" && (
                        <MedicoHome
                          pacientes={pacientesFiltered}
                          randomSortPacientes={randomSortPacientes}
                        />
                      )}
                      {user && user.currentRole === "Paciente" && <Outlet />}
                      {user && user.currentRole === "Manutencao" && (
                        <ManutencaoHome />
                      )}
                    </>
                  }
                />
                <Route path="ciclo" element={<Outlet />}>
                  <Route
                    path=":cicloid"
                    element={
                      <>
                        {user && user.currentRole === "Manutencao" && (
                          <CicloManutencao />
                        )}
                        {user && user.currentRole === "Administrador" && (
                          <Ciclo />
                        )}
                      </>
                    }
                  />
                </Route>
                <Route path="ciclos" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <>
                        {user && user.currentRole === "Manutencao" && (
                          <CiclosManutencao />
                        )}
                        {user && user.currentRole === "Administrador" && (
                          <CiclosAdmin />
                        )}
                      </>
                    }
                  />
                </Route>
                <Route path="pacientes">
                  <Route
                    index
                    element={
                      <>
                        {user && user.currentRole === "Terapeuta" && (
                          <Pacientes
                            pacientes={pacientesFiltered}
                            randomSortPacientes={randomSortPacientes}
                          />
                        )}
                        {user && user.currentRole === "Medico" && (
                          <Pacientes
                            pacientes={pacientesFiltered}
                            randomSortPacientes={randomSortPacientes}
                          />
                        )}
                      </>
                    }
                  />
                </Route>
                <Route
                  path="account"
                  element={
                    <>
                      {user && user.currentRole === "Administrador" && (
                        <Outlet />
                      )}
                      {user && user.currentRole === "Auxiliar" && <Outlet />}
                    </>
                  }
                >
                  <Route
                    path=":accountid"
                    element={
                      <>
                        {user && user.currentRole === "Administrador" && (
                          <Account />
                        )}
                        {user && user.currentRole === "Auxiliar" && (
                          <AccountAuxiliar />
                        )}
                      </>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="paciente"
                element={
                  <>
                    {user && user.currentRole === "Terapeuta" && <Outlet />}
                    {user && user.currentRole === "Medico" && <Outlet />}
                    {user && user.currentRole === "Paciente" && <Outlet />}
                  </>
                }
              >
                <Route
                  path=":pacienteid"
                  element={
                    <>
                      {user && user.currentRole === "Terapeuta" && (
                        <PacienteIn pacientes={pacientesFiltered} />
                      )}
                      {user && user.currentRole === "Medico" && (
                        <PacienteIn pacientes={pacientesFiltered} />
                      )}
                      {user && user.currentRole === "Paciente" && (
                        <PacienteInPaciente />
                      )}
                    </>
                  }
                >
                  <Route
                    index
                    element={
                      <>
                        {user && user.currentRole === "Paciente" && (
                          <PacienteAgenda />
                        )}
                        {user && user.currentRole === "Terapeuta" && (
                          <PacienteAgenda />
                        )}
                        {user && user.currentRole === "Medico" && (
                          <PacienteAgenda />
                        )}
                      </>
                    }
                  />
                  <Route path="sessao" element={<Outlet />}>
                    <Route path=":status" element={<PaginasSessao />}>
                      <Route
                        path=":sessaoid"
                        element={
                          <Sessao autoChangeTabIndex={autoChangeTabIndex} />
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </>
          </Routes>
          {/****************************** END ROUTES FOR CONTENT ******************************/}
        </Router>
      </AppWrapper>
    </>
  );
};

export default RouterCicloergometro;
