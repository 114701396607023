import { Config } from "aws-sdk";
import React, { useRef, useState, useEffect } from "react";
import { getJwtToken, useAuth } from "../../../../../contexts/authentication";
import { useCiclos } from "../../../../../contexts/ciclos";
import { useItemCiclo } from "../../../../../contexts/itemCiclo";
import { useSession } from "../../../../../contexts/session";
import { useUser } from "../../../../../contexts/user";
import { usePreload } from "../../../../../contexts/preloading";
import _ from "lodash";
import api from "../../../../../services/Api";
import {
  ConfigItemText,
  GridThumbDados,
  GridThumbDadosAdd,
  ConfigItemSelect,
  ConfigItemSlider,
  ConfigItemButtons,
  ChangeItemSliderRange,
  GridThumbDecrementar,
  GridThumbIncrementar,
  GridThumbFreios,
} from "../../../../../ui/General";
import { Button } from "../../../../../ui/InputControls";
import {
  graficoAcompanhamento,
  graficoInformacoesCorporais,
} from "../../../../../assets/graficos";

import { mqttUpdateData } from "../../../../../assets/mqttData";

import {
  TitleSessaoPendente,
  SubtitleSessaoPendente,
  GridThumbDadosWrapper,
  GridConfigWrapper,
  WrapperEstimulacaoEletrica,
  TestarWrapper,
  TestButton,
  ControlesWrapper,
  ModalGrafico,
} from "./styled";
import GraficoSessao from "./GraficoSessao";
import { useAlert } from "../../../../../contexts/alert";
import { commandosAtuacao } from "../../../../../assets/comandosAtuacao";

const Dados = ({ pacienteid, sessaoid, config, showJustItem }) => {
  const [dados, setDados] = useState({
    pacienteid: pacienteid,
    sessaoid: sessaoid,
    name: "Sessão",
    config: null,
  });
  const [dataTransfer, setDataTransfer] = useState(null);
  const [showPlus, setShowPlus] = useState(false);

  const onDragOverContainer = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDropContainer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let idTarget = e.target.id;
    let idSource = dataTransfer;
    let tempConfig = [...dados.config];
    tempConfig.map((value, index) => {
      if (Number(idSource) > Number(idTarget)) {
        if (Number(value.id) === Number(idSource)) {
          value.id = idTarget;
        } else if (
          Number(value.id) >= Number(idTarget) &&
          Number(value.id) < Number(idSource)
        ) {
          value.id = (Number(value.id) + 1).toString();
        }
      } else if (Number(idSource) < Number(idTarget)) {
        if (Number(value.id) === Number(idSource)) {
          value.id = idTarget;
        } else if (
          Number(value.id) <= Number(idTarget) &&
          Number(value.id) > Number(idSource)
        ) {
          value.id = (Number(value.id) - 1).toString();
        }
      }
    });

    tempConfig.sort((a, b) => {
      return a.id - b.id;
    });

    setDados((values) => ({
      ...values,
      config: tempConfig,
    }));
  };

  const toogleSize = (id) => {
    let tempConfig = [...dados.config];
    tempConfig.map((value, index) => {
      if (value.id === id) {
        if (value.size === "small") {
          value.size = "big";
        } else if (value.size === "big") {
          value.size = "small";
        }
      }
    });
    setDados((values) => ({
      ...values,
      config: tempConfig,
    }));
  };

  const showGraph = (id, grafico) => {
    console.log("grafico", dados.config[id].name, grafico);
    showJustItem(dados.config[id].name, grafico);
  };

  const setConfig = (config) => {
    // console.log(config);
    let configTemp = [
      ...(config["INFORMACOESCORPORAIS"]
        ? config["INFORMACOESCORPORAIS"].map((item) => ({
            ...item,
            grafico: "informacoescorporais",
          }))
        : []),
      ...(config["ACOMPANHAMENTO"]
        ? config["ACOMPANHAMENTO"].map((item) => ({
            ...item,
            grafico: "acompanhamento",
          }))
        : []),
    ];
    setDados((values) => ({
      ...values,
      config: [
        ...values.config,
        values.config.map((item) => {
          if (item.id !== "" && item.id !== null && item.id !== undefined) {
            configTemp.map((itemConfig) => {
              if (itemConfig.key === item.key) {
                item.value = itemConfig.value;
                item.grafico = itemConfig.grafico;
              }
            });
          } else {
            configTemp.map((itemConfig) => {
              if (itemConfig.key === item.key) {
                item = itemConfig;
              }
            });
          }
        }),
      ],
    }));
  };

  useEffect(() => {
    setDados((values) => ({
      ...values,
      pacienteid: pacienteid,
      sessaoid: sessaoid,
    }));
  }, [pacienteid, sessaoid]);

  useEffect(() => {
    if (dados.config === null) {
      let informacoescoporaisArr = mqttUpdateData["INFORMACOESCORPORAIS"]
        ? [...mqttUpdateData["INFORMACOESCORPORAIS"]]
        : [];
      let acompanhamentoArr = mqttUpdateData["ACOMPANHAMENTO"]
        ? [...mqttUpdateData["ACOMPANHAMENTO"]]
        : [];
      informacoescoporaisArr = informacoescoporaisArr.map((item) => ({
        ...item,
        grafico: "informacoescorporais",
      }));
      acompanhamentoArr = acompanhamentoArr.map((item) => ({
        ...item,
        grafico: "acompanhamento",
      }));
      console.log(informacoescoporaisArr, acompanhamentoArr);
      let configTemp = [];
      configTemp = [
        ...(informacoescoporaisArr ? informacoescoporaisArr : []),
        ...(acompanhamentoArr ? acompanhamentoArr : []),
      ];
      configTemp.map((value, index) => {
        if (value.type === "value") {
          value.id = index.toString();
        }
      });
      console.log(configTemp);
      setDados((values) => ({
        ...values,
        pacienteid: pacienteid,
        sessaoid: sessaoid,
        config: configTemp,
      }));
    }
    let count = 0;
    dados.config !== null &&
      dados.config.map(
        (value, index) =>
          value.show === false && value.type === "value" && count++
      );
    if (count === 0) {
      setShowPlus(false);
    } else {
      setShowPlus(true);
    }
  }, [dados]);

  useEffect(() => {
    // console.log(config);
    setConfig(config);
  }, [config]);

  return (
    <GridThumbDadosWrapper
      onDragEnter={(e) => e.preventDefault()}
      onDragOver={(e) => onDragOverContainer(e)}
      onDrop={(e) => onDropContainer(e)}
    >
      {dados.config &&
        dados.config.map(
          (value, index) =>
            value.type === "value" &&
            value.show && (
              <GridThumbDados
                key={index}
                item={value}
                toogleSize={toogleSize}
                dataTransfer={dataTransfer}
                setDataTransfer={setDataTransfer}
                dadosConfig={dados.config}
                setConfig={setConfig}
                showGraph={showGraph}
              />
            )
        )}
      {showPlus && (
        <GridThumbDadosAdd dadosConfig={dados.config} setConfig={setConfig} />
      )}
    </GridThumbDadosWrapper>
  );
};

const DadosCicloContainer = ({
  pacienteid,
  sessaoid,
  freio,
  setFreio,
  toogleFreio,
  decrementarMotor,
  incrementarMotor,
  incrementarCarga,
  decrementarCarga,
  incrementarEstimulo,
  decrementarEstimulo,
}) => {
  const [mqttUpdate, setMqttUpdate] = useState(null);
  const { preloading, setPreloading } = usePreload();
  const { user } = useUser();
  const { session, setSession } = useSession();
  const {
    ciclos,
    setCiclos,
    getCiclos,
    ciclosFiltered,
    getCiclosFiltered,
    getSelectedCiclo,
  } = useCiclos();
  const {
    itemCiclo,
    setItemCiclo,
    loadConfig,
    configMqtt,
    sendConfiguraSessao,
  } = useItemCiclo();
  const { publishMqtt, subscribeMqtt } = useAuth();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const [showGraficoAcompanhamento, setShowGraficoAcompanhamento] =
    useState(false);
  const [showGraficoInformacoesCorporais, setShowGraficoInformacoesCorporais] =
    useState(false);
  const [itemHiddenAcompanhamento, setItemHiddenAcompanhamento] =
    useState(null);
  const [itemHiddenInformacoesCorporais, setItemHiddenInformacoesCorporais] =
    useState(null);
  const clone = require("rfdc")();

  const initialConfig = () => {
    loadConfig(pacienteid, sessaoid)
      .then((response) => {
        setItemCiclo((values) => ({
          ...values,
          pacienteid: pacienteid,
          sessaoid: sessaoid,
        }));
      })
      .catch((err) => {});
  };

  const closeGrafico = () => {
    console.log("closing graph");
    setShowGraficoAcompanhamento(false);
    setShowGraficoInformacoesCorporais(false);
  };

  const showJustItem = (label, grafico) => {
    if (grafico === "acompanhamento") {
      let valuesTemp = _.cloneDeep(itemHiddenAcompanhamento);

      valuesTemp = valuesTemp.map((item, index) =>
        item.label.split("(")[0].trim() === label
          ? { label: item.label, hidden: false }
          : { label: item.label, hidden: true }
      );
      console.log("hide items graph", valuesTemp);
      setItemHiddenAcompanhamento(valuesTemp);
      setTimeout(() => {
        setShowGraficoAcompanhamento(true);
      }, 250);
    } else if (grafico === "informacoescorporais") {
      let valuesTemp = _.cloneDeep(itemHiddenInformacoesCorporais);
      console.log(label);
      valuesTemp = valuesTemp.map((item, index) =>
        item.label.split("(")[0].trim() === label
          ? { label: item.label, hidden: false }
          : { label: item.label, hidden: true }
      );

      setItemHiddenInformacoesCorporais(valuesTemp);
      setTimeout(() => {
        setShowGraficoInformacoesCorporais(true);
      }, 250);
    }
  };

  const changeConfigCiclo = (itemValue, categoryName, itemName) => {
    let tempItemCiclo = clone(itemCiclo);
    tempItemCiclo.config.map((value, index) => {
      if (value.name === categoryName) {
        value.value.map((value2, index2) => {
          if (value2.name === itemName) {
            value2.value = itemValue;
          }
        });
      }
    });
    setItemCiclo(tempItemCiclo);
  };

  const atualizaEstimuloDuranteCorrida = async (e) => {
    console.log("enviando dados comando atualizacao corrida");
    let comando = commandosAtuacao.filter(
      (value) => value.key === "estimMaxQuaD"
    )[0].value;
    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      0,
      false
    )
      .then((result) => {
        console.log(
          "Comando Teste Estímulo enviado. packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeConfigCicloRange = (itemValue, categoryName, itemName) => {
    let tempItemCiclo = clone(itemCiclo);

    tempItemCiclo.config.map((value, index) => {
      if (value.name === categoryName) {
        value.value.map((value2, index2) => {
          if (value2.name === itemName) {
            value2.valuemin = itemValue[0];
            value2.valuemax = itemValue[1];
          }
        });
      }
    });
    setItemCiclo(tempItemCiclo);
    setTimeout(() => {
      atualizaEstimuloDuranteCorrida();
    }, 2000);
  };

  const changeConfigCicloSelect = (item, categoryName, itemName) => {
    // console.log(e.target.getAttribute("value"), categoryName, itemName);
    let tempItemCiclo = clone(itemCiclo);

    tempItemCiclo.config.map((value, index) => {
      if (value.name === categoryName) {
        value.value.map((value2, index2) => {
          if (value2.name === itemName) {
            value2.value = parseInt(item.getAttribute("value"));
            console.log(value2.name, itemName, item.getAttribute("value"));
          }
        });
      }
    });
    setItemCiclo(tempItemCiclo);
  };

  const salvarConfiguracao = async () => {
    let sendConfig = await sendConfiguraSessao();
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    console.log("sendConfig", sendConfig);

    api
      .post(`/MQTT/PostConfiguraSessao`, sendConfig, config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setAlertText("Configurção da sessão salva!");
          setAlertType("success");
          setClasseAlert("on");
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao salvar a configuração da sessão no servidor!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  useEffect(() => {
    console.log("up ", "session.data");
    setMqttUpdate(session.data);
  }, [session]);

  useEffect(() => {
    // console.log(mqttUpdate);
  }, [mqttUpdate]);

  useEffect(() => {
    if (!itemCiclo.config) {
      initialConfig();
    }
  }, []);

  return (
    <>
      {session.state &&
        session.state !== "" &&
        (session.state === "Aquecimento" ||
          session.state === "Resfriamento") && (
          <>
            <TitleSessaoPendente>Controle</TitleSessaoPendente>
            <ControlesWrapper>
              <GridThumbDecrementar
                title={`RPM
Motor`}
                pointerDownFunction={decrementarMotor}
              />
              <GridThumbIncrementar
                pointerDownFunction={incrementarMotor}
                title={`RPM
Motor`}
              />
            </ControlesWrapper>
          </>
        )}
      {session.state && session.state !== "" && session.state === "Corrida" && (
        <>
          <TitleSessaoPendente>Controle</TitleSessaoPendente>
          <ControlesWrapper style={{ paddingBottom: "0px" }}>
            {configMqtt["TipoCarga"] === "0" && (
              <>
                <GridThumbDecrementar
                  pointerDownFunction={decrementarCarga}
                  // pointerUpFunction={stopChangeCarga}
                  title={`Carga`}
                />
                <GridThumbIncrementar
                  pointerDownFunction={incrementarCarga}
                  // pointerUpFunction={stopChangeCarga}
                  title={`Carga`}
                />
              </>
            )}
          </ControlesWrapper>
          <GridConfigWrapper style={{ padding: "0px 0px 0px" }}>
            {itemCiclo.config &&
              session.state &&
              session.state !== "" &&
              (session.state === "Configuração" ||
                session.state === "Aquecimento" ||
                session.state === "Corrida" ||
                session.state === "Resfriamento" ||
                session.state === "Repouso" ||
                session.state === "Trata Resumo/Estatistica" ||
                session.state === "Final de corrida" ||
                session.state === "Pedal freado") &&
              session.sessionid === sessaoid &&
              ciclos.filter((ciclo) => ciclo.selected).length > 0 &&
              itemCiclo.config.map((value, index) => {
                if (value.type === "category" && value.name !== "Disabled") {
                  let valueTipoFibra = null;
                  return (
                    <React.Fragment key={index}>
                      {value.name === "Estimulação Elétrica" && (
                        <SubtitleSessaoPendente>
                          {value.name}
                        </SubtitleSessaoPendente>
                      )}
                      {value.value.map((value2, index2) => {
                        if (value2.type === "slider-range") {
                          if (value.name === "Estimulação Elétrica") {
                            return (
                              <WrapperEstimulacaoEletrica key={index2}>
                                <ChangeItemSliderRange
                                  key={index2}
                                  item={{
                                    name: value2.name,
                                    unit: value2.unit,
                                    min: value2.min,
                                    max: value2.max,
                                    valuemin: value2.valuemin,
                                    valuemax: value2.valuemax,
                                  }}
                                  valueChange={(itemValue) =>
                                    changeConfigCicloRange(
                                      itemValue,
                                      value.name,
                                      value2.name
                                    )
                                  }
                                />
                              </WrapperEstimulacaoEletrica>
                            );
                          }
                        }
                      })}
                    </React.Fragment>
                  );
                }
              })}
            {user.currentRole === "Terapeuta" &&
              session.state &&
              session.state !== "" &&
              session.state === "Corrida" &&
              session.sessionid === sessaoid &&
              ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
                <Button
                  width="200px"
                  title="Salvar Estímulos"
                  color="primary"
                  margin="20px"
                  onClick={(e) => salvarConfiguracao()}
                />
              )}
          </GridConfigWrapper>
        </>
      )}

      {session.state &&
        session.state !== "" &&
        session.sessionid === sessaoid &&
        (session.state === "Pedal freado" ||
          session.state === "Repouso" ||
          session.state === "Final de corrida") &&
        ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
          <>
            <TitleSessaoPendente>Controle</TitleSessaoPendente>
            <ControlesWrapper>
              <GridThumbFreios
                item={freio}
                freado={session.state === "Pedal freado" ? true : false}
                clickFunction={toogleFreio}
              />
            </ControlesWrapper>
          </>
        )}
      <br />
      <TitleSessaoPendente>Sessão</TitleSessaoPendente>
      {mqttUpdate &&
        session.sessionid === sessaoid &&
        session.state &&
        session.state !== "" &&
        session.state !== "Cicloergômetro Disponível" &&
        session.state !== "Configuração" && (
          <>
            <ModalGrafico
              className={!showGraficoAcompanhamento ? "" : "on"}
              onClick={closeGrafico}
            >
              <GraficoSessao
                pacienteid={pacienteid}
                sessaoid={sessaoid}
                config={mqttUpdate}
                objeto={`ACOMPANHAMENTO_RAW`}
                titleGrafico={`Acompanhamento`}
                graficoMask={graficoAcompanhamento}
                itemHidden={itemHiddenAcompanhamento}
                setItemHidden={setItemHiddenAcompanhamento}
              />
            </ModalGrafico>
            <ModalGrafico
              className={!showGraficoInformacoesCorporais ? "" : "on"}
              onClick={closeGrafico}
            >
              <GraficoSessao
                pacienteid={pacienteid}
                sessaoid={sessaoid}
                config={mqttUpdate}
                objeto={`INFORMACOESCORPORAIS_RAW`}
                titleGrafico={`Informações Corporais`}
                graficoMask={graficoInformacoesCorporais}
                itemHidden={itemHiddenInformacoesCorporais}
                setItemHidden={setItemHiddenInformacoesCorporais}
              />
            </ModalGrafico>
            <Dados
              pacienteid={pacienteid}
              sessaoid={sessaoid}
              config={mqttUpdate}
              showJustItem={showJustItem}
            />
          </>
        )}
    </>
  );
};

export default DadosCicloContainer;
