import styled from "styled-components";
export const SectionMenuWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  z-index: 10000;
  width: auto;
  height: 63px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-20px);
  background: #212121 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 32px #000000;
  border-radius: 11px;
  padding: 0px 15px;

  opacity: 1;
`;

export const MenuItem = styled.div`
  cursor: pointer;
  user-select: none;
  height: 20px;
  font: normal normal 600 17px/20px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.greyLight};
  text-transform: capitalize;
  line-height: 17px;
  margin: 20px 15px;
  box-sizing: border-border-box;
  &.selected {
    color: ${({ theme }) => theme.colors.white};
    border-bottom: 2px solid ${({ theme }) => theme.colors.orange};
  }
`;
