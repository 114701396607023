import { Outlet } from "react-router-dom";
import Sidebar from "../../shared/Components/General/Sidebar";
import { ContainerFullwidthLeft, MainContainer } from "../../ui/Containers";
import { SectionMenu } from "../../ui/NavigationComponents";
import { protectedRoute } from "../../contexts/authentication";
import { useUser } from "../../contexts/user";

const Terapeuta = () => {
  const { user, setUser } = useUser();

  return (
    <>
      <ContainerFullwidthLeft>
        <Sidebar user={user} setUser={setUser} />
        <MainContainer>
          <Outlet />
        </MainContainer>
      </ContainerFullwidthLeft>
      <SectionMenu />
    </>
  );
};

export default protectedRoute(Terapeuta);
