import styled from "styled-components";

export const MeusCiclosWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 327px;
  flex-flow: row nowrap;
  background: ${({ theme }) => theme.colors.grey} 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

export const MeusCiclosTitle = styled.div`
  position: absolute;
  font: normal normal 600 22px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 0px 20px;
`;
export const MeusCiclosItems = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CicloItemWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 20px;
  margin: 20px 20px;
  box-sizing: border-box;
`;

export const CicloPhoto = styled.div`
  width: 158px;
  height: 158px;
  background-color: ${({ theme }) => theme.colors.black};
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 80px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CicloTitle = styled.div`
  font: normal normal 400 16px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const CicloName = styled.div`
  font: normal normal 400 12px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  font-weight: light;
  text-align: center;
  margin-top: 5px;
`;

export const CicloValue = styled.div`
  font: normal normal 400 12px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: light;
  text-align: center;
`;
