import { useState, useEffect } from "react";
import { ConfigItemText, GridThumbUserAccount } from "../../../../ui/General";
import { useParams } from "react-router-dom";
import { Toogle } from "../../../../ui/InputControls";
import {
  AccountWrapper,
  GridThumbWrapper,
  SubtitleSessaoPendente,
  ToogleWrapper,
} from "./styled";
import api from "../../../../services/Api";
import { getJwtToken } from "../../../../contexts/authentication";
import { usePreload } from "../../../../contexts/preloading";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import PacienteAgenda from "../../Pacientes/PacienteAgenda";
import { usePaciente } from "../../../../contexts/paciente";

const Account = ({ usuario }) => {
  const { setPreloading } = usePreload();
  const { setPaciente } = usePaciente();
  const { accountid } = useParams();
  const { setBottomMenuSections, currentBottomMenu } = useBottomMenuSections();
  const [currentTabScreen, setCurrentTabScreen] = useState(null);
  const [account, setAccount] = useState(null);
  const [active, setActive] = useState(null);
  const [toogleOn, setToogleOn] = useState(null);

  const loadAccount = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Schedule/GetScheduleByAccountId/${accountid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          let response = {};
          res.data.map(
            (sessao) =>
              (sessao.scheduleAt = new Date(sessao.scheduleAt).toLocaleString(
                "pt-BR"
              ))
          );
          response.agenda = [...res.data];

          api
            .get(`/Application/GetUserBasicByAccountId/${accountid}`, config)
            .then(async (res2) => {
              if (res2.status === 200) {
                let response2 = res2.data;
                if (response2.photo !== null) {
                  api
                    .get(response2.photo, config)
                    .then(async (res2) => {
                      console.log(res2);
                      response2.photo = `data:image/jpeg;base64,${res2.data}`;
                      let responseFinal = { ...response, ...response2 };
                      setAccount(responseFinal);
                      setPaciente(responseFinal);
                      setPreloading(false);
                    })
                    .catch((error) => {
                      console.log(error);

                      setPreloading(false);
                    });
                } else {
                  let responseFinal = { ...response, ...response2 };
                  setAccount(responseFinal);
                  setPaciente(responseFinal);
                  setPreloading(false);
                }
              }
            })
            .catch((error) => {
              console.log(error);
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  const enableAccount = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Account/GetEnableAccount/${accountid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  const disableAccount = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Account/GetDisableAccount/${accountid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  useEffect(() => {
    if (active !== null) {
      setToogleOn(active);
      if (active) {
        enableAccount();
      } else {
        disableAccount();
      }
    }
  }, [active]);

  useEffect(() => {
    if (account) {
      setToogleOn(account.active);
      setActive(account.active);
      console.log(account);
    }
  }, [account]);

  useEffect(() => {
    if (currentBottomMenu) {
      let selectedIndex = currentBottomMenu.findIndex(
        (obj, index) => obj.selected
      );
      setCurrentTabScreen(currentBottomMenu[selectedIndex]);
    }
  }, [currentBottomMenu]);

  useEffect(() => {
    loadAccount();
    setBottomMenuSections("auxiliarAccountDados");
  }, []);

  return (
    <>
      {account && (
        <AccountWrapper>
          {currentTabScreen && currentTabScreen.name === "Dados Gerais" && (
            <>
              <GridThumbWrapper>
                <GridThumbUserAccount item={account} />
                {toogleOn !== null && (
                  <ToogleWrapper>
                    <Toogle
                      checked={toogleOn}
                      onChange={(e) => {
                        setActive(!active);
                      }}
                    />
                  </ToogleWrapper>
                )}
              </GridThumbWrapper>

              <ConfigItemText
                item={{
                  name: "Id Conta",
                  value: account.id,
                }}
              />
              <ConfigItemText
                item={{
                  name: "Nome",
                  value: account.name,
                }}
              />
              <SubtitleSessaoPendente>Permissões</SubtitleSessaoPendente>
              {account.roles.map((value, index) => (
                <ConfigItemText
                  key={index}
                  item={{
                    name: index + 1,
                    value: value.name,
                  }}
                />
              ))}
              <SubtitleSessaoPendente>Dados Gerais</SubtitleSessaoPendente>
              {account.data.map((value, index) => (
                <ConfigItemText
                  key={index}
                  item={{
                    name: value.name,
                    value: value.value,
                  }}
                />
              ))}
              {/* <Button
            title="Excluir usuário"
            // width="200px"
            margin="50px 0px"
            color="primary"
            onClick={deleteUser}
          /> */}
            </>
          )}
          {currentTabScreen && currentTabScreen.name === "Agenda" && (
            <PacienteAgenda />
          )}
        </AccountWrapper>
      )}
    </>
  );
};

export default Account;
