import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { useAuth, getJwtToken } from "../../../../contexts/authentication";
import { useCiclos } from "../../../../contexts/ciclos";
import {
  TitleSecao,
  CicloData,
  CicloDataItem,
  CicloDataItemTitle,
  CicloDataItemText,
  LogsWrapper,
  LogsText,
  GridThumbWrapper,
  ToogleWrapper,
  CalendarWrapper,
} from "./styled";
import { Toogle } from "../../../../ui/InputControls";
import api from "../../../../services/Api";
import { usePreload } from "../../../../contexts/preloading";
import { useAlert } from "../../../../contexts/alert";

const Ciclo = () => {
  let { cicloid } = useParams();
  const { preloading, setPreloading } = usePreload();
  const { ciclos, getCiclos, ciclosFiltered, getCiclosFiltered } = useCiclos();
  const { bottomMenuSections, setBottomMenuSections } = useBottomMenuSections();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const navigate = useNavigate();
  const [ciclo, setCiclo] = useState(null);
  const [cycleErgometerLogs, setCycleErgometerLogs] = useState(null);
  const [isOperating, setIsOperating] = useState(null);
  const [date, setDate] = useState(new Date());

  const clone = require("rfdc")();

  const toogleOperating = async (oldValue) => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    let toogleValue;
    if (oldValue.toLowerCase() === "operando") {
      toogleValue = "Em manutenção";
    } else if (oldValue.toLowerCase() === "em manutenção") {
      toogleValue = "Operando";
    }
    const content = {
      cycleErgometerId: ciclo.cycleErgometerId,
      status: toogleValue,
    };

    api
      .put("/CycleErgometer", content, config)
      .then(async (res) => {
        if (res.status === 200) {
          setCiclo((value) => ({
            ...value,
            status: res.data.status,
          }));
          api
            .get(
              `/CycleErgometer/AddLog/${ciclo.cycleErgometerId}/status alterado para: ${res.data.status}`,
              config
            )
            .then(async (res) => {
              setAlertText("Ciclo atualizado!");
              console.log(res.data);
              setAlertType("success");
              setClasseAlert("on");
              setPreloading(false);
            })
            .catch((error) => {
              console.log(error);
              setAlertText(error.response.data);
              setAlertType("error");
              setClasseAlert("on");
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText(error.response.data);
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  const MainCalendar = ({ date, setDate }) => {
    const changeDate = (date) => {
      setDate(date);
    };

    useEffect(() => {
      console.log(date);
    }, [date]);

    return (
      <CalendarWrapper>
        <Calendar onChange={changeDate} value={date} />
      </CalendarWrapper>
    );
  };

  const getLogs = async () => {
    if (ciclo) {
      let at = await getJwtToken();
      const config = {
        headers: { Authorization: `Bearer ${at}` },
      };

      api
        .get(
          `/CycleErgometer/GetLogsByCycleErgometerIdAndDay/${
            ciclo.cycleErgometerId
          }/${date ? date.toISOString() : new Date().toISOString()}`,
          config
        )
        .then(async (res) => {
          if (res.status === 200) {
            let logs = res.data;
            logs.sort(
              (a, b) => new Date(b.messageDate) - new Date(a.messageDate)
            );
            setCycleErgometerLogs(logs);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getCorridas = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    return api
      .get(`/CycleErgometer/GetRunsDoneById/${cicloid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          return res.data.numberOfRuns;
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (ciclos) {
      setBottomMenuSections("manutencao");
      console.log(cicloid);
      let ciclosTemp = clone(ciclos);
      console.log(ciclosTemp);
      let cicloTemp = ciclosTemp.filter(
        (value) => value.cycleErgometerId === parseInt(cicloid)
      );
      getCorridas()
        .then(async (res) => {
          console.log(res);
          cicloTemp[0].numeroDeCorridas = res;
          setCiclo(cicloTemp[0]);
        })
        .catch((err) => console.log(err));
    }
  }, [ciclos]);

  useEffect(() => {
    if (ciclo && ciclo.status) {
      getLogs();
      if (ciclo.status.toLowerCase() === "operando") {
        setIsOperating(true);
      } else {
        setIsOperating(false);
      }
    }
  }, [ciclo]);

  useEffect(() => {
    getLogs();
  }, [date]);

  useEffect(() => {
    getCiclos();
    setBottomMenuSections("manutencaoCiclos");
  }, []);

  return (
    <>
      <TitleSecao>{ciclo && ciclo.name}</TitleSecao>
      <GridThumbWrapper>
        {isOperating !== null && (
          <ToogleWrapper
            className={
              ciclo.status.toLowerCase() === "operando" ? "" : "manutencao"
            }
          >
            <Toogle
              checked={ciclo.status.toLowerCase() === "operando" ? true : false}
              onChange={(e) => {
                toogleOperating(ciclo.status);
              }}
            />
          </ToogleWrapper>
        )}
      </GridThumbWrapper>
      {ciclo && (
        <CicloData>
          <CicloDataItem>
            <CicloDataItemTitle>Nome: </CicloDataItemTitle>
            <CicloDataItemText>{ciclo.name}</CicloDataItemText>
          </CicloDataItem>
          <CicloDataItem>
            <CicloDataItemTitle>Id: </CicloDataItemTitle>
            <CicloDataItemText>{ciclo.cycleErgometerId}</CicloDataItemText>
          </CicloDataItem>
          <CicloDataItem>
            <CicloDataItemTitle>Status: </CicloDataItemTitle>
            <CicloDataItemText>{ciclo.status}</CicloDataItemText>
          </CicloDataItem>
          <CicloDataItem>
            <CicloDataItemTitle>Estado: </CicloDataItemTitle>
            <CicloDataItemText>{ciclo.state}</CicloDataItemText>
          </CicloDataItem>
          <CicloDataItem>
            <CicloDataItemTitle>
              Quantidade de corridas realizadas:{" "}
            </CicloDataItemTitle>
            <CicloDataItemText>{ciclo.numeroDeCorridas}</CicloDataItemText>
          </CicloDataItem>
        </CicloData>
      )}
      <TitleSecao>Logs</TitleSecao>
      <MainCalendar date={date} setDate={setDate} />
      <LogsWrapper>
        {cycleErgometerLogs && (
          <LogsText
            placeholder="Logs do cicloergômetro"
            value={cycleErgometerLogs
              .map((value, index) => {
                return `Log id: ${value.cycleErgometerLogId} | Ciclo id: ${
                  value.cycleErgometerId
                } | ${new Date(value.messageDate).toLocaleString("pt-BR")} > ${
                  value.message
                } \n`;
              })
              .toString()
              .replace(/,/g, "")}
            readOnly
          />
        )}
      </LogsWrapper>
    </>
  );
};

export default Ciclo;
