export const mqttData = {
  INFORMACOESCORPORAIS: [
    {
      name: "Código da Sessão",
      key: "Sessao_Contagem",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 0,
    },
    {
      name: "Sistólica",
      key: "Sistolica",
      value: null,
      unit: "mmHg",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 1,
    },
    {
      name: "Diastólica",
      key: "Diastolica",
      value: null,
      unit: "mmHg",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 2,
    },
    {
      name: "Saturação Oxigênio",
      key: "SaturaOxi",
      value: null,
      unit: "%",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 3,
    },
    {
      name: "Frequência Cardíaca",
      key: "FreqCardiaca",
      value: null,
      unit: "bpm",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 4,
    },
    {
      name: "Temperatura",
      key: "Temperatura",
      value: null,
      unit: "°C",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 5,
    },
    {
      name: "GPS Lat",
      key: "GPS_LAT",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "hardware",
      order: 6,
    },
    {
      name: "GPS Long",
      key: "GPS_LONG",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "hardware",
      order: 7,
    },
    {
      name: "Data e Hora",
      key: "TimeStamp",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 8,
    },
  ],
  ACOMPANHAMENTO: [
    {
      name: "Código da Sessão",
      key: "Sessao_Contagem",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 9,
    },
    {
      name: "Tempo",
      key: "Tempo",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 10,
    },
    {
      name: "Rotação",
      key: "Rot",
      value: null,
      unit: "rpm",
      type: "value",
      size: "small",
      show: true,
      category: "hardware",
      order: 11,
    },
    {
      name: "Carga",
      key: "Carga",
      value: null,
      unit: "%",
      type: "value",
      size: "big",
      show: true,
      category: "hardware",
      order: 12,
    },
    {
      name: "Estímulo Quadril Direito",
      key: "EstimQuaD",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 13,
    },
    {
      name: "Estímulo Glúteo Direito",
      key: "EstimGluD",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 14,
    },
    {
      name: "Estímulo Ísquio Direito",
      key: "EstimIsqD",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 15,
    },
    {
      name: "Estímulo Quadril Esquerdo",
      key: "EstimQuaE",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 16,
    },
    {
      name: "Estímulo Glúteo Esquerdo",
      key: "EstimGluE",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 17,
    },
    {
      name: "Estímulo Ísquio Esquerdo",
      key: "EstimIsqE",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 18,
    },
    {
      name: "Quantidade Corridas",
      key: "QtdCorridas",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 19,
    },
    {
      name: "Quantidade Corridas Executadas",
      key: "QtdCorridasExe",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 20,
    },
    {
      name: "Fase da Corrida",
      key: "FaseCorrida",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 21,
    },
    {
      name: "Botão Emergência",
      key: "BotaoEmergencia",
      value: null,
      unit: "",
      type: "button",
      size: "small",
      show: true,
      category: "hardware",
      order: 22,
    },
    {
      name: "Data e hora",
      key: "TimeStamp",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 23,
    },
  ],
  INFORMACOESCORPORAIS_RAW: {},
  ACOMPANHAMENTO_RAW: {},
  RESUMOCORRIDA_RAW: {},
  ESTATISTICASESSAO_RAW: {},
};

export const mqttUpdateData = {
  INFORMACOESCORPORAIS: [
    {
      name: "Código da Sessão",
      key: "Sessao_Contagem",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 0,
    },
    {
      name: "Sistólica",
      key: "Sistolica",
      value: null,
      unit: "mmHg",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 1,
    },
    {
      name: "Diastólica",
      key: "Diastolica",
      value: null,
      unit: "mmHg",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 2,
    },
    {
      name: "Saturação Oxigênio",
      key: "SaturaOxi",
      value: null,
      unit: "%",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 3,
    },
    {
      name: "Frequência Cardíaca",
      key: "FreqCardiaca",
      value: null,
      unit: "bpm",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 4,
    },
    {
      name: "Temperatura",
      key: "Temperatura",
      value: null,
      unit: "°C",
      type: "value",
      size: "small",
      show: true,
      category: "corpo",
      order: 5,
    },
    {
      name: "GPS Lat",
      key: "GPS_LAT",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "hardware",
      order: 6,
    },
    {
      name: "GPS Long",
      key: "GPS_LONG",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "hardware",
      order: 7,
    },
    {
      name: "Data e Hora",
      key: "TimeStamp",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 8,
    },
  ],
  ACOMPANHAMENTO: [
    {
      name: "Código da Sessão",
      key: "Sessao_Contagem",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 9,
    },
    {
      name: "Tempo",
      key: "Tempo",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 10,
    },
    {
      name: "Rotação",
      key: "Rot",
      value: null,
      unit: "rpm",
      type: "value",
      size: "small",
      show: true,
      category: "hardware",
      order: 11,
    },
    {
      name: "Carga",
      key: "Carga",
      value: null,
      unit: "%",
      type: "value",
      size: "big",
      show: true,
      category: "hardware",
      order: 12,
    },
    {
      name: "Estímulo Quadril Direito",
      key: "EstimQuaD",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 13,
    },
    {
      name: "Estímulo Glúteo Direito",
      key: "EstimGluD",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 14,
    },
    {
      name: "Estímulo Ísquio Direito",
      key: "EstimIsqD",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 15,
    },
    {
      name: "Estímulo Quadril Esquerdo",
      key: "EstimQuaE",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 16,
    },
    {
      name: "Estímulo Glúteo Esquerdo",
      key: "EstimGluE",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 17,
    },
    {
      name: "Estímulo Ísquio Esquerdo",
      key: "EstimIsqE",
      value: null,
      unit: "mA",
      type: "value",
      size: "small",
      show: true,
      category: "estimulo",
      order: 18,
    },
    {
      name: "Quantidade Corridas",
      key: "QtdCorridas",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 19,
    },
    {
      name: "Quantidade Corridas Executadas",
      key: "QtdCorridasExe",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 20,
    },
    {
      name: "Fase da Corrida",
      key: "FaseCorrida",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 21,
    },
    {
      name: "Botão Emergência",
      key: "BotaoEmergencia",
      value: null,
      unit: "",
      type: "button",
      size: "small",
      show: true,
      category: "hardware",
      order: 22,
    },
    {
      name: "Data e hora",
      key: "TimeStamp",
      value: null,
      unit: "",
      type: "value",
      size: "small",
      show: true,
      category: "sessao",
      order: 23,
    },
  ],
  INFORMACOESCORPORAIS_RAW: [],
  ACOMPANHAMENTO_RAW: [],
  RESUMOCORRIDA_RAW: {},
  ESTATISTICASESSAO_RAW: {},
};
