import { useEffect } from "react";
import { GridThumbCiclos } from "../../../../ui/General";
import { useNavigate } from "react-router-dom";
import { useCiclos } from "../../../../contexts/ciclos";
import { CiclosWrapper } from "./styled";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { TitleBig } from "../../../../ui/Texts";

const CiclosAdmin = () => {
  const { ciclos, getCiclos, ciclosFiltered, getCiclosFiltered } = useCiclos();
  const navigate = useNavigate();

  const { setBottomMenuSections } = useBottomMenuSections();

  const clickThumb = (id) => {
    navigate(`/ciclo/${id}`);
  };

  useEffect(() => {
    getCiclos();
    setBottomMenuSections("adminCiclos");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getCiclosFiltered("");
    }, 1000);
  }, [ciclos]);

  return (
    <>
      <TitleBig>Cicloergômetros</TitleBig>
      <CiclosWrapper>
        {ciclosFiltered &&
          ciclosFiltered.map((item, index) => (
            <GridThumbCiclos
              key={index}
              item={item}
              clickFunction={clickThumb}
            />
          ))}
      </CiclosWrapper>
    </>
  );
};

export default CiclosAdmin;
