import { useEffect, useContext, useState, createContext } from "react";
import api from "../services/Api.js";
import { getJwtToken, useAuth } from "./authentication.jsx";
import { usePreload } from "./preloading.jsx";

const CiclosContext = createContext();

const getStorageWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export default function CiclosProvider({ children }) {
  const { setPreloading } = usePreload();
  const { subscribeMqtt } = useAuth();
  const [ciclos, setCiclos] = useState(null);
  const [ciclosFiltered, setCiclosFiltered] = useState(null);
  const clone = require("rfdc")();

  const getCiclos = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    return api
      .get(`/CycleErgometer/GetAll`, config)
      .then(async (res) => {
        setPreloading(false);
        if (res.status === 200) {
          let tempSession = await getStorageWithExpiry("@session");
          if (tempSession === null) {
            res.data.map(async (ciclo, index) => {
              ciclo.selected = false;
            });
            setCiclos(res.data);
            return res.data;
          } else {
            if (!tempSession.ciclo) {
              res.data.map(async (ciclo, index) => {
                ciclo.selected = false;
              });
              setCiclos(res.data);
              return res.data;
            } else {
              let dados = res.data;
              dados.map(async (ciclo, index) => {
                if (tempSession.ciclo) {
                  if (
                    ciclo &&
                    ciclo.cycleErgometerId ===
                      tempSession.ciclo.cycleErgometerId
                  ) {
                    ciclo.selected = true;
                    if (ciclo.macAddXxx && ciclo.macAddXxx.dbapP_TAGC) {
                      subscribeMqtt(
                        `LucyIO/Ciclo/STATUS/${ciclo.macAddXxx.dbapP_TAGC}/#`,
                        1
                      );
                    }
                  } else {
                    ciclo.selected = false;
                  }
                }
              });
              setCiclos(dados);
              return dados;
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        return false;
      });
  };

  const getCiclosFiltered = (value) => {
    if (ciclos) {
      let ciclosClone = clone(ciclos);
      let ciclosTemp = ciclosClone.filter((obj) => {
        if (value !== "") {
          let regex = "";
          regex = new RegExp(value, "i");
          let position = obj.name.search(regex);
          if (position !== -1) {
            console.log(regex);
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

      setCiclosFiltered(ciclosTemp);
    }
  };

  const getSelectedCiclo = async () => {
    let selectedCiclo = null;
    if (ciclos) {
      selectedCiclo = ciclos.filter((value) => value.selected)[0];
    } else {
      let gettingCiclos = await getCiclos();
      console.log(gettingCiclos);
      selectedCiclo = gettingCiclos.filter((value) => value.selected)[0];
    }

    return selectedCiclo;
  };

  useEffect(() => {}, []);

  useEffect(() => {}, [ciclos]);

  return (
    <CiclosContext.Provider
      value={{
        ciclos,
        getCiclos,
        setCiclos,
        ciclosFiltered,
        getCiclosFiltered,
        getSelectedCiclo,
      }}
    >
      {children}
    </CiclosContext.Provider>
  );
}

export function useCiclos() {
  const context = useContext(CiclosContext);
  if (!context)
    throw new Error("useCiclos must be used within a CiclosProvider");

  const {
    ciclos,
    getCiclos,
    setCiclos,
    ciclosFiltered,
    getCiclosFiltered,
    getSelectedCiclo,
  } = context;

  return {
    ciclos,
    getCiclos,
    setCiclos,
    ciclosFiltered,
    getCiclosFiltered,
    getSelectedCiclo,
  };
}
