import styled from "styled-components";

export const MainSchedulesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  overflow-x: hidden;
  width: 100%;
  height: 514px;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.grey} 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 40%;
  height: 426px;
  margin-top: 20px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 12px;

  /* ~~~ navigation styles ~~~ */
  .react-calendar {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;

    .react-calendar__navigation {
      color: ${({ theme }) => theme.colors.white};

      button {
        color: ${({ theme }) => theme.colors.white};

        &:hover {
          background-color: transparent;
        }
      }

      .react-calendar__navigation__label {
        font: normal normal bold 30px/48px
          ${({ theme }) => theme.fonts.secondary};
      }
    }

    .react-calendar__viewContainer {
      height: 300px;
      margin-top: 50px;
      color: ${({ theme }) => theme.colors.white};

      /* react-calendar__tile--now
        react-calendar__tile--range
        react-calendar__tile--rangeStart
        react-calendar__tile--rangeEnd
        react-calendar__tile--rangeBothEnds
        react-calendar__month-view__days__day */
      .react-calendar__month-view__weekdays {
        font: normal normal bold 10px/15px
          ${({ theme }) => theme.fonts.secondary};
        color: ${({ theme }) => theme.colors.white};
      }

      .react-calendar__month-view {
        height: 100%;
        > div:first-child {
          height: 100%;
          > div:first-child {
            height: 100%;
            .react-calendar__month-view__days {
              height: 100%;
            }
          }
        }
      }

      .react-calendar__tile {
        &.react-calendar__tile--active {
          background: ${({ theme }) => theme.colors.orange};
        }

        &.react-calendar__tile--now {
          background-color: ${({ theme }) => theme.colors.black};
        }

        &:enabled {
          &:hover {
            background-color: ${({ theme }) => theme.colors.blue};
          }
        }

        &.react-calendar__month-view__days__day {
          color: ${({ theme }) => theme.colors.white};

          &.react-calendar__month-view__days__day--weekend {
            color: ${({ theme }) => theme.colors.orange};
          }
          &.react-calendar__month-view__days__day--neighboringMonth {
            color: ${({ theme }) => theme.colors.greyLight};
          }
        }
      }
    }

    .react-calendar__year-view {
      .react-calendar__year-view__months {
        button {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;

export const FirstListWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 507px;
  height: 436px;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 12px;
`;

export const FirstListTitle = styled.div`
  font: normal normal 600 22px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
`;

export const FirstListList = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 85%;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const FirstListListItem = styled.div`
  width: 100%;
  height: 62px;
  margin-bottom: 7px;
  font: normal normal normal 19px/23px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.black} 0% 0% no-repeat padding-box;
  border-radius: 6px;

  &.add {
    background: ${({ theme }) => theme.colors.orange} 0% 0% no-repeat
      padding-box;
  }
`;
