import React, { useEffect, useState } from "react";
import Accounts from "../Accounts";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { useAuth } from "../../../../contexts/authentication";
import { useCiclos } from "../../../../contexts/ciclos";
import { useAccounts } from "../../../../contexts/accounts";

const AuxiliarHome = () => {
  const { accounts } = useAccounts();
  const { ciclos } = useCiclos();
  const { bottomMenuSections, setBottomMenuSections } = useBottomMenuSections();

  const { user, logout } = useAuth();

  useEffect(() => {
    setBottomMenuSections("auxiliar");
  }, []);

  return (
    <>
      <Accounts accounts={accounts} />
    </>
  );
};

export default AuxiliarHome;
