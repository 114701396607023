import React, { useState, useEffect } from "react";
import { usePaciente } from "../../../../../contexts/paciente";
import {
  ConfigItemText,
  GridThumbDadosDone,
  GridThumbDadosDoneMin,
  GridThumbDadosDoneMax,
  GridThumbDadosAdd,
  ConfigItemSelect,
  ConfigItemSlider,
  ConfigItemSliderRange,
} from "../../../../../ui/General";
import { TitleSecao, Wrapper } from "./styled";
import { resumoEstatistica } from "../../../../../assets/resumoEstatistica";
import { useParams } from "react-router-dom";

const clone = require("rfdc")();

const MediaParametrica = ({ session }) => {
  const { sessaoid } = useParams();
  const [dados, setDados] = useState({
    resumoCorrida: clone(resumoEstatistica),
    estatisticaSessao: clone(resumoEstatistica),
  });

  useEffect(() => {
    if (session.data) {
      let dadosTemp = clone(dados);
      let sessionTemp = { ...session.data };

      if (sessionTemp["RESUMOCORRIDA_RAW"]) {
        dadosTemp.resumoCorrida.map((dado) => {
          dado.value = sessionTemp["RESUMOCORRIDA_RAW"][dado.id];
        });
        setDados((values) => ({
          ...values,
          resumoCorrida: dadosTemp.resumoCorrida,
        }));
      }
      if (sessionTemp["ESTATISTICASESSAO_RAW"]) {
        dadosTemp.estatisticaSessao.map((dado) => {
          dado.value = sessionTemp["ESTATISTICASESSAO_RAW"][dado.id];
        });
        setDados((values) => ({
          ...values,
          estatisticaSessao: dadosTemp.estatisticaSessao,
        }));
      }
    }
  }, [session]);

  useEffect(() => {}, [dados]);

  return (
    <>
      <TitleSecao>Resumo Corrida</TitleSecao>
      {sessaoid &&
        session.sessionid === sessaoid &&
        session.state &&
        session.state !== "" && (
          <>
            <Wrapper>
              {dados.resumoCorrida.map((corrida, index) => {
                return <GridThumbDadosDone key={index} item={corrida} />;
              })}
            </Wrapper>
            <TitleSecao>Estatística Sessão</TitleSecao>
            <Wrapper>
              {dados.estatisticaSessao.map((sessao, index) => {
                return <GridThumbDadosDone key={index} item={sessao} />;
              })}
            </Wrapper>
          </>
        )}
    </>
  );
};

export default MediaParametrica;
