import React, { useEffect, useState } from "react";
import { useUser } from "../../../../contexts/user";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import NotasContainer from "./NotasContainer";
import MediaParametrica from "./MediaParametrica";
import GraficoSessao from "./GraficoSessao";
import { escala } from "../../../../assets/mock";
import {
  graficoAcompanhamento,
  graficoInformacoesCorporais,
} from "../../../../assets/graficos";
const clone = require("rfdc")();

const SessaoFeita = ({ pacienteid, sessaoid }) => {
  const { user } = useUser();
  let { bottomMenuSections, setBottomMenuSections, currentBottomMenu } =
    useBottomMenuSections();
  const [currentTabScreen, setCurrentTabScreen] = useState(null);
  const [graficosMerged, setGraficosMerged] = useState(null);

  useEffect(() => {
    if (user && user.currentRole === "Terapeuta") {
      setBottomMenuSections("terapeuta");
    } else if (user && user.currentRole === "Paciente") {
      setBottomMenuSections("paciente");
    }
  }, [user]);

  useEffect(() => {
    if (currentBottomMenu) {
      let selectedIndex = currentBottomMenu.findIndex(
        (obj, index) => obj.selected
      );
      setCurrentTabScreen(currentBottomMenu[selectedIndex]);
    }
  }, [currentBottomMenu]);

  useEffect(() => {
    let graficoTemp = [
      ...clone(graficoAcompanhamento),
      ...clone(graficoInformacoesCorporais),
    ];
    setGraficosMerged(graficoTemp);
  }, []);
  return (
    <>
      <NotasContainer
        pacienteid={pacienteid}
        sessaoid={sessaoid}
        escala={escala}
      />
      <MediaParametrica pacienteid={pacienteid} sessaoid={sessaoid} />
      {graficosMerged && (
        <GraficoSessao
          pacienteid={pacienteid}
          sessaoid={sessaoid}
          endpoint={[
            `/MQTT/GetAcompanhamentosBySessionId/`,
            `/MQTT/GetInformacoesCorporaisBySessionId/`,
          ]}
          titleGrafico={`Geral`}
          graficoMask={graficosMerged}
        />
      )}
    </>
  );
};

export default SessaoFeita;
