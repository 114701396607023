import React, { useEffect, useContext, useState, createContext } from "react";
import api from "../services/Api.js";
import { getJwtToken, useAuth } from "./authentication.jsx";
import { usePreload } from "./preloading.jsx";
import { sessaoConfig } from "../assets/sessaoConfig";
import { useCiclos } from "./ciclos.jsx";
import { useSession } from "./session.jsx";
import { useAlert } from "./alert.jsx";

const ItemCicloContext = createContext();

export default function ItemCicloProvider({ children }) {
  const { setPreloading } = usePreload();
  const { publishMqtt, connectMqtt } = useAuth();
  const { getSelectedCiclo } = useCiclos();
  const { session } = useSession();
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const [itemCiclo, setItemCiclo] = useState({
    pacienteid: null,
    sessaoid: null,
    name: "Cicloergômetro",
    config: null,
  });
  const [configMqtt, setConfigMqtt] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const loadConfig = async (pacienteid, sessaoid) => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    return api
      .get(`/MQTT/GetConfiguraSessaoByAccountId/${pacienteid}`, config)
      .then((res) => {
        if (res.status === 200) {
          const response = res.data;
          Object.keys(response).forEach((k) => {
            sessaoConfig.forEach((category) => {
              category.value.forEach((item) => {
                if (item.key) {
                  if (typeof item.key === "object") {
                    if (item.key[0] === k) {
                      item.valuemin = response[k];
                    } else if (item.key[1] === k) {
                      item.valuemax = response[k];
                    }
                  } else {
                    if (item.key === k) {
                      if (item.type === "text") {
                        if (item.key !== "sessao_Contagem") {
                          item.value = response[k];
                        } else if (item.key === "sessao_Contagem") {
                          item.value = sessaoid;
                        }
                      } else if (item.type === "select") {
                        item.value = parseFloat(response[k]);
                      } else if (item.type === "slider") {
                        item.value = parseFloat(response[k]);
                      }
                    }
                  }
                }
              });
            });
          });
          setItemCiclo((values) => ({
            ...values,
            config: sessaoConfig,
          }));
          setPreloading(false);
          return true;
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        return false;
      });
  };

  const sendConfiguraSessao = async () => {
    let selectedCiclo = await getSelectedCiclo();
    if (selectedCiclo) {
      let sendConfig = {};
      itemCiclo.config.forEach((category) => {
        category.value.forEach((item) => {
          if (item.key) {
            if (typeof item.key === "object") {
              sendConfig[item.key[0]] = parseInt(item.valuemin);
              sendConfig[item.key[1]] = parseInt(item.valuemax);
            } else {
              if (item.type === "text") {
                if (item.key === "qtdCorridasExe") {
                  sendConfig[item.key] = parseInt(
                    !session.currentRun ? 0 : session.currentRun
                  );
                } else {
                  sendConfig[item.key] = parseInt(item.value);
                }
              } else if (item.type === "select") {
                sendConfig[item.key] = parseInt(item.value);
              } else if (item.type === "slider") {
                sendConfig[item.key] = parseInt(item.value);
              }
            }
          }
        });
      });
      let configMqttTemp = { ...sendConfig };
      delete configMqttTemp.accountId;
      delete configMqttTemp.configuraSessaoId;
      delete configMqttTemp.sessionId;
      delete configMqttTemp.runSessionId;
      const output = Object.entries(configMqttTemp).map(([key, value]) => [
        key[0].toUpperCase() + key.slice(1),
        value.toString(),
      ]);
      let finalConfigMqtt = Object.fromEntries(output);
      setConfigMqtt(finalConfigMqtt);
      return publishMqtt(
        `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/CONFIGURASESSAO`,
        finalConfigMqtt,
        1,
        true
      )
        .then((result) => {
          return sendConfig;
        })
        .catch((err) => {
          console.log(err);
          return new Error("Falha ao enviar configuração da sessão", {
            cause: err,
          });
        });
    }
  };

  useEffect(() => {
    if (itemCiclo.config) {
      if (firstLoad) {
        connectMqtt()
          .then((response) => {
            sendConfiguraSessao();
            setFirstLoad(false);
          })
          .catch((err) => {
            setAlertText("Erro ao conectar no Mqtt!");
            setAlertType("error");
            setClasseAlert("on");
            setFirstLoad(false);
          });
      }
    }
  }, [itemCiclo]);

  return (
    <ItemCicloContext.Provider
      value={{
        itemCiclo,
        setItemCiclo,
        loadConfig,
        sendConfiguraSessao,
        configMqtt,
        setConfigMqtt,
      }}
    >
      {children}
    </ItemCicloContext.Provider>
  );
}

export function useItemCiclo() {
  const context = useContext(ItemCicloContext);
  if (!context)
    throw new Error("useItemCiclo must be used within a ItemCicloProvider");

  const {
    itemCiclo,
    setItemCiclo,
    loadConfig,
    sendConfiguraSessao,
    configMqtt,
    setConfigMqtt,
  } = context;

  return {
    itemCiclo,
    setItemCiclo,
    loadConfig,
    sendConfiguraSessao,
    configMqtt,
    setConfigMqtt,
  };
}
