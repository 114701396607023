import styled from "styled-components";

export const AddCicloWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  width: 50%;

  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  background: #212121 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 22px #0000006e;
  border-radius: 14px;
  margin-bottom: 5px;
`;
