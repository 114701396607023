import { useEffect } from "react";
import { GridThumbUsers } from "../../../../ui/General";
import { useNavigate } from "react-router-dom";
import { useAccounts } from "../../../../contexts/accounts";
import { AccountsWrapper } from "./styled";
import { TitleBig } from "../../../../ui/Texts";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";

const Accounts = () => {
  const { accounts, getAccounts, accountsFiltered, getAccountsFiltered } =
    useAccounts();
  const navigate = useNavigate();
  const { setBottomMenuSections } = useBottomMenuSections();

  useEffect(() => {
    setBottomMenuSections("admin");
  }, []);

  const clickThumb = (id) => {
    navigate(`/account/${id}`);
  };

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (accounts) {
      setTimeout(() => {
        getAccountsFiltered("");
      }, 1000);
    }
  }, [accounts]);

  return (
    <>
      <TitleBig>Usuários</TitleBig>
      <AccountsWrapper>
        {accounts &&
          accountsFiltered &&
          accountsFiltered.map((item, index) => (
            <GridThumbUsers
              key={index}
              item={item}
              clickFunction={clickThumb}
            />
          ))}
      </AccountsWrapper>
    </>
  );
};

export default Accounts;
