import styled from "styled-components";
import { theme } from "../../theme";

export const InputWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  background: #101010 0% 0% no-repeat padding-box;
  border: 2px solid #333333;
  border-radius: 6px;
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : "10px")};
`;

export const InputTextElement = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  padding: 10px;
  box-sizing: border-box;
  font: normal normal 600 15px ${({ theme }) => theme.fonts.primary};
  line-height: ${(props) => (props.height ? props.height : "35px")};
  color: ${({ theme }) => theme.colors.white};
  &:focus {
    outline: none;
  }
`;

export const ProfileField = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
  background: ${({ theme }) => theme.colors.black} 0% 0% no-repeat padding-box;
  border-radius: 6px;
  box-sizing: border-box;
  margin-bottom: 5px;

  label {
    /* position: absolute;
    top: 50%;
    left: 0; */
    width: 40% !important;
    padding: 10px 0px;
    font: normal normal 600 14px/18px ${({ theme }) => theme.fonts.primary};
    text-align: left;
    color: ${({ theme }) => theme.colors.greyLight};
  }

  input {
    /* position: absolute;
    top: 0;
    right: 0; */
    width: 60% !important;
    height: 100%;
    background: transparent;
    border: none;
    padding: 10px 0px;
    font: normal normal 600 14px/18px ${({ theme }) => theme.fonts.primary};
    box-sizing: border-box;
    text-align: right;
    color: ${({ theme }) => theme.colors.white};

    &:focus {
      outline: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  background: 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : "10px")};
  font: normal normal 600 15px ${({ theme }) => theme.fonts.primary};
  text-align: center;
  line-height: ${(props) => (props.height ? props.height : "35px")};
  user-select: none;
  cursor: pointer;
  border: ${(props) =>
    props.colorStroke
      ? "2px solid " + props.theme.colors[props.colorStroke]
      : "none"};

  &.primary {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
  }

  &.secondary {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
  }

  &.danger {
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
  }

  &.danger-stroke {
    background-color: transparent};
    color: ${({ theme }) => theme.colors.red};
  }

  &.transparent {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    filter: brightness(80%);
  }
`;

export const ButtonActions = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;

  width: 30px;
  height: 30px;
  margin: 0 20px;
`;

export const AnchorWrapper = styled.div`
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.colors.white};
  margin: ${(props) => (props.margin ? props.margin : "10px")};
  text-align: center;
  white-spacing: pre-wrap;
  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export const Icon = styled.div`
  display: flex;
  width: 25px;
  height: 100%;
`;

export const Selector = styled.select`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  background: 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : "10px")};
  font: normal normal 600 15px ${({ theme }) => theme.fonts.primary};
  text-align: center;
  line-height: ${(props) => (props.height ? props.height : "35px")};
  user-select: none;
  cursor: pointer;
  border: ${(props) =>
    props.colorStroke
      ? "2px solid " + props.theme.colors[props.colorStroke]
      : "none"};

  &.primary {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
  }

  &.secondary {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
  }

  &.grey {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }

  &.transparent {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    outline: none;
  }

  ::selection {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const ToogleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

export const ToogleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 34px;

  &:checked + .slider {
    background-color: ${({ theme }) => theme.colors.orange};
    &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  &:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
`;

export const ToogleSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.greyLight};
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;

export const ToogleLabelRoles = styled.label`
  pointer-events: all;
  position: relative;
  display: inline-block;
  width: 85px;
  height: 34px;
`;

export const ToogleLabelRolesSearch = styled.label`
  pointer-events: all;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

export const ToogleInputRoles = styled.input`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 34px;

  &:checked + .slider {
    background-color: ${({ theme }) => theme.colors.orange};
    &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  &:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
`;

export const ToogleSpanRoles = styled.span`
  position: absolute;
  pointer-events: none;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.greyLight};
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;
