import styled from "styled-components";

export const TitleSecao = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const NotasWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.grey};
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};

  overflow: hidden;
`;

export const NotasText = styled.textarea`
  width: 98%;
  height: 400px;
  border-radius: 24px;
  font: normal normal 400 22px/30px ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 25px;
  margin-bottom: 25px;
  resize: none;
`;

export const NotasBorg = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 98%;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.black};
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const BorgTitle = styled.div`
  box-sizing: border-box;
  padding: 25px;
  font: normal normal 800 25px/30px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
`;
export const BorgButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 60%;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const BorgButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grey};
  margin: 10px 10px;
  padding: 15px 10px 0px;
  border-radius: 15px;
`;

export const BorgButtonTime = styled.div`
  user-select: none;
  width: 100%;
  height: 30px;
  text-align: center;
  letter-spacing: 1px;
  font: normal normal 400 14px/16px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  &.selected {
    color: ${({ theme }) => theme.colors.white};
  }
  margin-bottom: 15px;
  white-space: pre-wrap;
  line-height: 18px;
`;

export const BorgButtonValue = styled.div`
  user-select: none;
  width: 100%;
  height: 30px;
  text-align: center;
  font: normal normal 800 24px/25px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  &.selected {
    color: ${({ theme }) => theme.colors.pink};
  }
  margin-bottom: 10px;
`;

export const BorgButtonDescription = styled.div`
  user-select: none;
  width: 100%;
  height: 30px;
  text-align: center;
  font: normal normal 400 14px/14px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  &.selected {
    color: ${({ theme }) => theme.colors.pink};
  }
  margin-bottom: 10px;
`;
