import styled from "styled-components";

export const TitlesAgenda = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const InfosGeraisWrapper = styled.div`
  width: 100%;
  padding-top: 0px;
  padding-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const InfosGeraisThumbWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 20px;
  margin: 20px 20px;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const InfosGeraisThumbWrapperTitle = styled.div`
  top: 30px;
  width: 90%;
  font: normal normal 600 16px/22px ${({ theme }) => theme.fonts.primary};
  text-align: center;
  letter-spacing: 0px;
  // color: ${({ theme }) => theme.colors.white};
  text-shadow: 0px 0px 26px ${({ theme }) => theme.colors.black};
  white-space: pre-wrap;
`;

export const InfosGeraisThumbWrapperValue = styled.div`
  margin-top: 5px;
  overflow: hidden;
  font: normal normal 600 28px/32px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: center;

  span {
    font: normal normal 600 21px/24px ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.pink};
    letter-spacing: 2px;
  }
`;
