import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Wrapper } from "./styled";
import { ContainerFullwidthCenterCenter } from "../../ui/Containers";
import { TitleBig } from "../../ui/Texts";
import { InputText, Button, Anchor } from "../../ui/InputControls";
import { useAlert } from "../../contexts/alert";
import { useEffect } from "react";

const EsqueciSenha = () => {
  let navigate = useNavigate();
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const [valueEmail, setValueEmail] = useState("");

  const codigoDeAcesso = () => {
    navigate("/codigodeacesso");
  };

  const redefinir = () => {
    Auth.forgotPassword(valueEmail)
      .then((data) => {
        console.log(data);
        setAlertText(
          "Um e-mail para redefinição foi mandado para seu e-mail cadastrado."
        );
        setAlertType("success");
        setClasseAlert("on");
        setTimeout(() => {
          codigoDeAcesso();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setAlertText(
          "Ocorreu algum erro! Verifique seu e-mail e tente novamente."
        );
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const fazerLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    console.log(valueEmail);
  }, [valueEmail]);
  return (
    <ContainerFullwidthCenterCenter>
      <Wrapper>
        <TitleBig margin="20px">LUCY.IO</TitleBig>
        <InputText
          width="200px"
          id="email"
          placeholder="Digite seu e-mail..."
          value={valueEmail}
          onChange={setValueEmail}
        />
        <Button
          width="200px"
          title="Redefinir Senha"
          color="primary"
          onClick={redefinir}
        />
        <Anchor onClick={fazerLogin}>Fazer login</Anchor>
      </Wrapper>
    </ContainerFullwidthCenterCenter>
  );
};

export default EsqueciSenha;
