import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Wrapper } from "./styled";
import { ContainerFullwidthCenterCenter } from "../../ui/Containers";
import { TitleBig } from "../../ui/Texts";
import { InputText, Button, Anchor, Select } from "../../ui/InputControls";
import { useAlert } from "../../contexts/alert";
import { useEffect } from "react";

const CodigoDeAcesso = () => {
  let navigate = useNavigate();
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const [valueEmail, setValueEmail] = useState("");
  const [valueCode, setValueCode] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [selectOptions, setSelectOptions] = useState([
    {
      id: "novoCadastro",
      name: "Validar Novo Cadastro",
      selected: true,
    },
    {
      id: "trocarSenha",
      name: "Trocar a Senha",
      selected: false,
    },
  ]);

  const clone = require("rfdc")();

  const redefinirSenha = () => {
    Auth.forgotPasswordSubmit(valueEmail, valueCode, valuePassword)
      .then((data) => {
        console.log(data);
        setAlertText("Senha da conta redefinida com sucesso!");
        setAlertType("success");
        setClasseAlert("on");
        setTimeout(() => {
          fazerLogin();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        let index = String(err).indexOf(":");
        let cleanError = String(err).substring(0, index);
        if (cleanError === "LimitExceededException") {
          setAlertText(
            "Muitas tentativas foram feitas. Tente novamente mais tarde."
          );
        } else if (cleanError === "ExpiredCodeException") {
          setAlertText("Código de verificação expirado. Solicite novamente.");
        } else if (cleanError === "CodeMismatchException") {
          setAlertText("Código inválido. Tente novamente.");
        } else if (cleanError === "InvalidPasswordException") {
          setAlertText(
            "Senha fraca. Mínimo de 8 dígitos, usando ao menos 1 letra maiúscula, 1 minúscula, 1 caracter especial e 1 número."
          );
        }
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const validarCadastro = () => {
    Auth.confirmSignUp(valueEmail, valueCode)
      .then((data) => {
        console.log(data);
        setAlertText("Conta validada com sucesso!");
        setAlertType("success");
        setClasseAlert("on");
        setTimeout(() => {
          fazerLogin();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        let index = String(err).indexOf(":");
        let cleanError = String(err).substring(0, index);
        if (cleanError === "LimitExceededException") {
          setAlertText(
            "Muitas tentativas foram feitas. Tente novamente mais tarde."
          );
        } else if (cleanError === "ExpiredCodeException") {
          setAlertText("Código de verificação expirado. Solicite novamente.");
        } else if (cleanError === "CodeMismatchException") {
          setAlertText("Código inválido. Tente novamente.");
        }
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const reenviar = () => {
    Auth.resendSignUp(valueEmail)
      .then((data) => {
        console.log(data);
        setAlertText("Código reenviado com sucesso");
        setAlertType("success");
        setClasseAlert("on");
      })
      .catch((err) => {
        console.log(err);
        setAlertText("Ococrreu algum problema. Tente novamente.");
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const fazerLogin = () => {
    navigate("/login");
  };

  const changeSelectOptions = (e) => {
    let tempOptions = clone(selectOptions);
    tempOptions.map((value) =>
      value.id === e.target.value
        ? (value.selected = true)
        : (value.selected = false)
    );
    setSelectOptions(tempOptions);
  };

  const esqueciSenha = () => {
    navigate("/esquecisenha");
  };

  useEffect(() => {
    console.log(valueEmail);
  }, [valueEmail]);
  return (
    <ContainerFullwidthCenterCenter>
      <Wrapper>
        <TitleBig margin="20px">LUCY.IO</TitleBig>
        <Select
          id="submit-type"
          name="submit-type"
          options={selectOptions}
          current={
            selectOptions &&
            selectOptions.filter((value) => value.selected)[0].id
          }
          width="100%"
          height="40px"
          title="Sair"
          color="grey"
          colorStroke="orange"
          margin="10px 0px"
          onChange={changeSelectOptions}
        />
        <InputText
          width="250px"
          id="email"
          placeholder="Digite seu e-mail..."
          value={valueEmail}
          onChange={setValueEmail}
        />
        <InputText
          width="250px"
          id="code"
          placeholder="Código de verificação..."
          value={valueCode}
          onChange={setValueCode}
        />
        {selectOptions.filter((value) => value.selected)[0].id ===
          "trocarSenha" && (
          <InputText
            width="250px"
            id="password"
            placeholder="Senha desejada..."
            value={valuePassword}
            onChange={setValuePassword}
            type="password"
          />
        )}
        <Button
          width="200px"
          title={
            selectOptions.filter((value) => value.selected)[0].id ===
            "novoCadastro"
              ? "Validar Cadastro"
              : "Redefinir Senha"
          }
          color="primary"
          onClick={
            selectOptions.filter((value) => value.selected)[0].id ===
            "novoCadastro"
              ? validarCadastro
              : redefinirSenha
          }
        />
        {selectOptions.filter((value) => value.selected)[0].id ===
          "novoCadastro" && (
          <Button
            width="200px"
            title="Reenviar código"
            color="primary"
            onClick={reenviar}
          />
        )}
        <Anchor onClick={fazerLogin}>Fazer login</Anchor>
        {selectOptions.filter((value) => value.selected)[0].id ===
          "trocarSenha" && (
          <Anchor onClick={esqueciSenha}>
            Código para redefinir senha?
            <br />
            Solicite aqui.
          </Anchor>
        )}
      </Wrapper>
    </ContainerFullwidthCenterCenter>
  );
};

export default CodigoDeAcesso;
