import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Wrapper } from "./styled";
import { ContainerFullwidthCenterCenter } from "../../ui/Containers";
import { TitleBig } from "../../ui/Texts";
import { InputText, Button, Anchor } from "../../ui/InputControls";
import { useAlert } from "../../contexts/alert";
import { useEffect } from "react";

const RedefinirSenha = () => {
  let navigate = useNavigate();
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const [valueEmail, setValueEmail] = useState("");
  const [valueCode, setValueCode] = useState("");
  const [valuePassword, setValuePassword] = useState("");

  const verificar = () => {
    Auth.forgotPasswordSubmit(valueEmail, valueCode, valuePassword)
      .then((data) => {
        console.log(data);
        setAlertText("Senha da conta redefinida com sucesso!");
        setAlertType("success");
        setClasseAlert("on");
      })
      .catch((err) => {
        console.log(err);
        let index = String(err).indexOf(":");
        let cleanError = String(err).substring(0, index);
        if (cleanError === "LimitExceededException") {
          setAlertText(
            "Muitas tentativas foram feitas. Tente novamente mais tarde."
          );
        } else if (cleanError === "ExpiredCodeException") {
          setAlertText("Código de verificação expirado. Solicite novamente.");
        } else if (cleanError === "CodeMismatchException") {
          setAlertText("Código inválido. Tente novamente.");
        } else if (cleanError === "InvalidPasswordException") {
          setAlertText(
            "Senha fraca. Mínimo de 8 dígitos, usando ao menos 1 letra maiúscula, 1 minúscula, 1 caracter especial e 1 número."
          );
        }
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const fazerLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    console.log(valueEmail);
  }, [valueEmail]);
  return (
    <ContainerFullwidthCenterCenter>
      <Wrapper>
        <TitleBig margin="20px">LUCY.IO</TitleBig>
        <InputText
          width="250px"
          id="email"
          placeholder="Digite seu e-mail..."
          value={valueEmail}
          onChange={setValueEmail}
        />
        <InputText
          width="250px"
          id="code"
          placeholder="Código de verificação..."
          value={valueCode}
          onChange={setValueCode}
        />
        <InputText
          width="250px"
          id="password"
          placeholder="Senha desejada..."
          value={valuePassword}
          onChange={setValuePassword}
        />
        <Button
          width="200px"
          title="Redefinir Senha"
          color="primary"
          onClick={verificar}
        />
        <Anchor onClick={fazerLogin}>Fazer login</Anchor>
      </Wrapper>
    </ContainerFullwidthCenterCenter>
  );
};

export default RedefinirSenha;
