import React, { useState, useEffect, useRef } from "react";
import JoyStick from "react-joystick";
import { useTheme } from "styled-components";
import { GridThumbConfiguracoesHome } from "../../../../../ui/General";
// import { RightArrow } from "@styled-icons/boxicons-regular";
import { RightArrowCircle as RightArrow } from "@styled-icons/boxicons-solid/RightArrowCircle";
import {
  TitleSessaoPendente,
  SubtitleSessaoPendente,
  GridThumbWrapper,
  GridConfigWrapper,
  CadeiraControls,
  MoverContainer,
  MoverVerticalContainer,
  ReclinarContainer,
  JoystickContainer,
  JoystickWrapper,
  Circle,
  LeftText,
  RightText,
  TopText,
  BottomText,
  ToogleWrapper,
} from "./styled";
import { Toogle } from "../../../../../ui/InputControls";
import { commandosAtuacao } from "../../../../../assets/comandosAtuacao";
import { useCiclos } from "../../../../../contexts/ciclos";
import { useAuth } from "../../../../../contexts/authentication";

const joyOptions = {
  zone: document.getElementById("static"),
  mode: "static",
  position: { left: "50%", top: "50%" },
  color: "white",
  multitouch: false,
};

const joyOptionsReclinar = {
  zone: document.getElementById("static-reciclar"),
  mode: "static",
  position: { left: "50%", top: "50%" },
  color: "white",
  multitouch: false,
};

const containerStyle = {
  position: "relative",
  height: "300px",
  width: "460px",
  background: "transparent",
};

const ConfigCicloCadeira = ({
  pacienteid,
  sessaoid,
  configCiclo,
  configCadeira,
  activeConfigCadeira,
  setActiveConfigCadeira,
}) => {
  const theme = useTheme();
  const { getSelectedCiclo } = useCiclos();
  const { publishMqtt } = useAuth();
  const [itemCiclo, setItemCiclo] = useState({
    pacienteid: null,
    sessaoid: null,
    name: "Cicloergômetro",
  });
  const [itemCadeira, setItemCadeira] = useState({
    pacienteid: null,
    sessaoid: null,
    name: "Cadeira",
  });
  const [direction, setDirection] = useState(null);
  const [directionReclinar, setDirectionReclinar] = useState(null);
  const [joyManager, setJoyManager] = useState(null);
  const [joyManagerReclinar, setJoyManagerReclinar] = useState(null);
  const timer = useRef(null);
  const clone = require("rfdc")();

  useEffect(() => {
    setItemCiclo((values) => ({
      ...values,
      pacienteid: pacienteid,
      sessaoid: sessaoid,
    }));
    setItemCadeira((values) => ({
      ...values,
      pacienteid: pacienteid,
      sessaoid: sessaoid,
    }));
  }, [pacienteid, sessaoid]);

  const managerListener = (manager) => {
    setJoyManager(manager);
    manager.on("move", (e, stick) => {
      if (stick.distance > 20) {
        if (direction !== stick.direction.angle) {
          setDirection(stick.direction.angle);
        }
      } else {
        if (direction !== null) {
          setDirection(null);
        }
      }
    });
    manager.on("end", () => {
      setDirection(null);
      setDirectionReclinar(null);
    });
  };

  const managerListenerReclinar = (manager) => {
    setJoyManagerReclinar(manager);
    manager.on("move", (e, stick) => {
      // console.log(stick);

      if (stick.distance > 20) {
        if (directionReclinar !== stick.direction.angle) {
          setDirectionReclinar(stick.direction.angle);
        }
      } else {
        if (directionReclinar !== null) {
          setDirectionReclinar(null);
        }
      }
    });
    manager.on("end", () => {
      setDirection(null);
      setDirectionReclinar(null);
    });
  };

  const setTimer = async (type, value) => {
    let comandosTemp = clone(commandosAtuacao);
    let command = comandosTemp.filter(
      (comando) => comando.type === type && comando.key === value
    )[0];
    if (command) {
      let atuacao = {
        Sessao_Contagem: sessaoid,
        SobeCadeira: 0,
        DesceCadeira: 0,
        InclinaCima: 0,
        InclinaBaixa: 0,
        Frente: 0,
        Atras: 0,
        Inicia: command.value,
        Pausa: 0,
        Encerra: 0,
      };

      let selectedCiclo = await getSelectedCiclo();

      try {
        clearInterval(timer.current);
      } catch (err) {}

      timer.current = setInterval(() => {
        publishMqtt(
          `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
          atuacao,
          1,
          false
        );
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      joyManager && joyManager.destroy();
      joyManagerReclinar && joyManagerReclinar.destroy();
    };
  }, [joyManager, joyManagerReclinar]);

  useEffect(() => {
    if (direction) {
      if (
        direction === "left" ||
        direction === "right" ||
        direction === "up" ||
        direction === "down"
      ) {
        setTimer("mover", direction);
      } else {
        try {
          clearInterval(timer.current);
        } catch (err) {}
      }
    } else {
      try {
        clearInterval(timer.current);
      } catch (err) {}
    }
  }, [direction]);

  useEffect(() => {
    if (directionReclinar) {
      if (
        directionReclinar === "left" ||
        directionReclinar === "right" ||
        directionReclinar === "up" ||
        directionReclinar === "down"
      ) {
        setTimer("reclinar", directionReclinar);
      } else {
        try {
          clearInterval(timer.current);
        } catch (err) {}
      }
    } else {
      try {
        clearInterval(timer.current);
      } catch (err) {}
    }
  }, [directionReclinar]);

  return (
    <>
      <>
        <TitleSessaoPendente>Configurações</TitleSessaoPendente>
        <GridThumbWrapper>
          {activeConfigCadeira && (
            <GridThumbConfiguracoesHome
              item={itemCadeira}
              clickFunction={configCadeira}
            />
          )}
        </GridThumbWrapper>
        <GridConfigWrapper>
          <CadeiraControls>
            <MoverContainer>
              {direction === "right" && (
                <RightArrow
                  style={{
                    height: "50px",
                    marginTop: "5px",
                    transform: "rotate(0deg)",
                    flexShrink: "0",
                    backgroundColor: theme.colors.grey,
                    borderRadius: "50px",
                    color: theme.colors.orange,
                  }}
                />
              )}
              {direction === "left" && (
                <RightArrow
                  style={{
                    height: "50px",
                    marginTop: "5px",
                    transform: "rotate(180deg)",
                    flexShrink: "0",
                    backgroundColor: theme.colors.grey,
                    borderRadius: "50px",
                    color: theme.colors.orange,
                  }}
                />
              )}
            </MoverContainer>
            <MoverVerticalContainer>
              {direction === "down" && (
                <RightArrow
                  style={{
                    height: "50px",
                    marginTop: "5px",
                    transform: "rotate(0deg)",
                    flexShrink: "0",
                    backgroundColor: theme.colors.grey,
                    borderRadius: "50px",
                    color: theme.colors.orange,
                  }}
                />
              )}
              {direction === "up" && (
                <RightArrow
                  style={{
                    height: "50px",
                    marginTop: "5px",
                    transform: "rotate(180deg)",
                    flexShrink: "0",
                    backgroundColor: theme.colors.grey,
                    borderRadius: "50px",
                    color: theme.colors.orange,
                  }}
                />
              )}
            </MoverVerticalContainer>
            <ReclinarContainer>
              {directionReclinar === "left" && (
                <RightArrow
                  style={{
                    height: "50px",
                    marginTop: "5px",
                    transform: "rotate(-90deg)",
                    flexShrink: "0",
                    backgroundColor: theme.colors.grey,
                    borderRadius: "50px",
                    color: theme.colors.orange,
                  }}
                />
              )}
              {directionReclinar === "right" && (
                <RightArrow
                  style={{
                    height: "50px",
                    marginTop: "5px",
                    transform: "rotate(-90deg) scaleX(-100%)",
                    flexShrink: "0",
                    backgroundColor: theme.colors.grey,
                    borderRadius: "50px",
                    color: theme.colors.orange,
                  }}
                />
              )}
            </ReclinarContainer>
          </CadeiraControls>
          <JoystickContainer>
            <JoystickWrapper>
              <Circle />
              <div id="static">
                <JoyStick
                  options={joyOptions}
                  containerStyle={containerStyle}
                  managerListener={managerListener}
                />
              </div>

              <LeftText>
                Mover
                <br />
                p/ trás
                <RightArrow
                  style={{
                    height: "40px",
                    marginLeft: "10px",
                    transform: "rotate(180deg)",
                    flexShrink: "0",
                    color:
                      direction === "left"
                        ? theme.colors.orange
                        : theme.colors.white,
                  }}
                />
              </LeftText>
              <RightText>
                <RightArrow
                  style={{
                    height: "40px",
                    marginRight: "10px",
                    transform: "rotate(0deg)",
                    flexShrink: "0",
                    color:
                      direction === "right"
                        ? theme.colors.orange
                        : theme.colors.white,
                  }}
                />
                Mover
                <br />
                p/ frente
              </RightText>
              <TopText>
                Mover
                <br />
                p/ cima
                <RightArrow
                  style={{
                    height: "40px",
                    marginTop: "5px",
                    transform: "rotate(270deg)",
                    flexShrink: "0",
                    color:
                      direction === "up"
                        ? theme.colors.orange
                        : theme.colors.white,
                  }}
                />
              </TopText>
              <BottomText>
                <RightArrow
                  style={{
                    height: "40px",
                    marginBottom: "5px",
                    transform: "rotate(90deg)",
                    flexShrink: "0",
                    color:
                      direction === "down"
                        ? theme.colors.orange
                        : theme.colors.white,
                  }}
                />
                Mover
                <br />
                p/ baixo
              </BottomText>
            </JoystickWrapper>

            <JoystickWrapper>
              <Circle />
              <div id="static-reciclar">
                <JoyStick
                  options={joyOptionsReclinar}
                  containerStyle={containerStyle}
                  managerListener={managerListenerReclinar}
                />
              </div>

              <LeftText>
                Reclinar
                <br />
                p/ trás
                <RightArrow
                  style={{
                    height: "40px",
                    marginLeft: "10px",
                    transform: "rotate(180deg)",
                    flexShrink: "0",
                    color:
                      directionReclinar === "left"
                        ? theme.colors.orange
                        : theme.colors.white,
                  }}
                />
              </LeftText>
              <RightText>
                <RightArrow
                  style={{
                    height: "40px",
                    marginRight: "10px",
                    transform: "rotate(0deg)",
                    flexShrink: "0",
                    color:
                      directionReclinar === "right"
                        ? theme.colors.orange
                        : theme.colors.white,
                  }}
                />
                Reclinar
                <br />
                p/ frente
              </RightText>
            </JoystickWrapper>
          </JoystickContainer>
        </GridConfigWrapper>
      </>
    </>
  );
};

export default ConfigCicloCadeira;
