import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import SessaoCancelada from "../SessaoCancelada";
import SessaoPendente from "../SessaoPendente";
import SessaoFazendo from "../SessaoFazendo";
import SessaoFeita from "../SessaoFeita";
import SessaoAcompanhar from "../SessaoAcompanhar";

const Sessao = ({ autoChangeTabIndex }) => {
  const { pacienteid, status, sessaoid } = useParams();
  const clone = require("rfdc")();

  const navigate = useNavigate();

  useEffect(() => {}, [pacienteid, status, sessaoid]);

  return status === "canceled" ? (
    <SessaoCancelada pacienteid={pacienteid} sessaoid={sessaoid} />
  ) : status === "failed" ? (
    <SessaoCancelada pacienteid={pacienteid} sessaoid={sessaoid} />
  ) : status === "pending" ? (
    <SessaoPendente pacienteid={pacienteid} sessaoid={sessaoid} />
  ) : status === "doing" ? (
    <SessaoFazendo
      pacienteid={pacienteid}
      sessaoid={sessaoid}
      autoChangeTabIndex={autoChangeTabIndex}
    />
  ) : status === "done" ? (
    <SessaoFeita pacienteid={pacienteid} sessaoid={sessaoid} />
  ) : (
    <></>
  );
};

export default Sessao;
