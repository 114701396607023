import React, { useState, useEffect } from "react";
import NotasContainer from "./NotasContainer";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";

const SessaoCancelada = ({ pacienteid, sessaoid }) => {
  let { bottomMenuSections, setBottomMenuSections, currentBottomMenu } =
    useBottomMenuSections();
  const [currentTabScreen, setCurrentTabScreen] = useState(null);

  useEffect(() => {
    setBottomMenuSections("terapeuta");
    console.log(sessaoid, pacienteid);
  }, []);

  return (
    <>
      <NotasContainer pacienteid={pacienteid} sessaoid={sessaoid} />
    </>
  );
};

export default SessaoCancelada;
