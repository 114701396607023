import styled from "styled-components";

export const TitleSessaoPendente = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const SubtitleSessaoPendente = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 18px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 20px 0px 0px 20px;
  box-sizing: border-box;
`;

export const GridThumbWrapper = styled.div`
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const GridThumbDadosWrapper = styled.div`
  /* touch-action: none; */
  width: 100%;
  padding-top: 0px;
  padding-bottom: 100px;
`;

export const GridConfigWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0px 100px;
`;

export const ControlesWrapper = styled.div`
  width: 100%;
  padding-top: 0px;
  padding-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const WrapperEstimulacaoEletrica = styled.div`
  position: relative;
  width: 100%;
`;
export const TestarWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  left: -140px;
  width: 120px;
  height: 100%;
`;

export const TestButton = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export const ModalGrafico = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: none;
  flex-flow: row wrap;
  z-index: 100000;
  box-sizing: border-box;
  padding: 50px 50px 200px;
  background-color: rgba(0, 0, 0, 0.9);

  &.on {
    display: flex;
  }
`;
