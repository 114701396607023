import React, { useEffect, useContext, useState, createContext } from "react";

const RolesContext = createContext();

export default function RolesProvider({ children }) {
  const [rolesValues, setRolesValues] = useState([
    { id: "Administrador", name: "Administrador", value: 1, selected: false },
    { id: "Manutencao", name: "Manutencao", value: 2, selected: false },
    { id: "Medico", name: "Medico", value: 4, selected: false },
    { id: "Terapeuta", name: "Terapeuta", value: 8, selected: false },
    { id: "Paciente", name: "Paciente", value: 16, selected: false },
    { id: "Auxiliar", name: "Auxiliar", value: 32, selected: false },
  ]);
  const [roles, setRoles] = useState([
    { id: "Administrador", name: "Adminstrador", value: 1, selected: false },
    { id: "Manutencao", name: "Manutencao", value: 2, selected: false },
    { id: "Medico", name: "Medico", value: 4, selected: false },
    { id: "Terapeuta", name: "Terapeuta", value: 8, selected: false },
    { id: "Paciente", name: "Paciente", value: 16, selected: false },
    { id: "Auxiliar", name: "Auxiliar", value: 32, selected: false },
  ]);

  useEffect(() => {}, [roles]);

  return (
    <RolesContext.Provider
      value={{ roles, setRoles, rolesValues, setRolesValues }}
    >
      {children}
    </RolesContext.Provider>
  );
}

export function useRoles() {
  const context = useContext(RolesContext);
  if (!context) throw new Error("useRoles must be used within a RolesProvider");

  const { roles, setRoles, rolesValues, setRolesValues } = context;

  return { roles, setRoles, rolesValues, setRolesValues };
}
