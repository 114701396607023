import React, { useEffect, useState } from "react";
import {
  getJwtToken,
  loadUserBasic,
  useAuth,
} from "../../../../contexts/authentication";
import {
  InputText,
  InputPassword,
  InputProfile,
  ButtonShowHide,
  ButtonEdit,
} from "../../../../ui/InputControls";
import {
  SidebarWrapper,
  ProfileHeader,
  ProfilePhoto,
  MessageChangePhoto,
  InputFile,
  ProfileName,
  ProfileBody,
  ActionsWrapper,
  ProfileFields,
  Initials,
} from "./styled";
import woman from "../../../../assets/images/woman.jpg";
import { getInitials } from "../../../../ui/General";
import { useAlert } from "../../../../contexts/alert";
import api from "../../../../services/Api";
import { usePreload } from "../../../../contexts/preloading";

const Sidebar = ({ user, setUser }) => {
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const { updateUserStorage } = useAuth();
  const [fieldsVisible, setFieldsVisible] = useState(false);
  const [fieldsEditable, setFieldsEditable] = useState(true);
  const [showChangePhoto, setShowChangePhoto] = useState(false);

  const changeValue = (e) => {
    const newValues = user.data.map((obj) => {
      if (obj.key === e.target.id) {
        return { ...obj, value: e.target.value };
      }
      return obj;
    });

    setUser({ ...user, data: newValues });
    updateUserStorage({ ...user, data: newValues });
  };

  const changePhoto = async (e) => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: {
        Authorization: `Bearer ${at}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      let foto = reader.result;
      console.log(foto);
      const formData = new FormData();
      formData.append("fileImage", e.target.files[0]);
      formData.append("AccountId", user.id);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      api
        .post("/Account/PostProfileImageRaw", formData, config)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            setAlertText("Foto atualizada!");
            setAlertType("success");
            setClasseAlert("on");
            loadUserBasic()
              .then((res2) => {
                setUser({
                  ...res,
                  ...res2,
                });
                setPreloading(false);
              })
              .catch((err) => {
                console.log(err);
                setAlertText(
                  "Problema ao carregar dados do usuário! Tente novamente."
                );
                setAlertType("error");
                setClasseAlert("on");
                setPreloading(false);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setAlertText("Problema ao atualizar a foto! Tente novamente.");
          setAlertType("error");
          setClasseAlert("on");
          setPreloading(false);
        });
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <SidebarWrapper>
      {user !== null && (
        <>
          <ProfileHeader>
            <ProfilePhoto>
              {user.photo ? (
                <img src={user.photo} />
              ) : (
                <Initials>{getInitials(user.name)}</Initials>
              )}
              <InputFile
                type="file"
                id="fileImage"
                name="fileImage"
                title="File Image"
                alt="File Image"
                onChange={changePhoto}
                onPointerOver={() => setShowChangePhoto(true)}
                onPointerOut={() => setShowChangePhoto(false)}
              />
              <MessageChangePhoto className={showChangePhoto ? "on" : ""} />
            </ProfilePhoto>

            <ProfileName>{user !== null && user.name}</ProfileName>
          </ProfileHeader>
          <ProfileBody>
            <ActionsWrapper>
              <ButtonShowHide
                fieldsVisible={fieldsVisible}
                setFieldsVisible={setFieldsVisible}
              />
            </ActionsWrapper>
            <ProfileFields>
              {user.data !== undefined &&
                user.data.map(
                  (value, index) =>
                    value.key !== "id" &&
                    value.key !== "AccountId" && (
                      <InputProfile
                        key={index}
                        value={value}
                        changeValue={changeValue}
                        fieldsVisible={fieldsVisible}
                        fieldsEditable={fieldsEditable}
                      />
                    )
                )}
            </ProfileFields>
          </ProfileBody>
        </>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
