import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 85px;
  align-items: center;
  justify-content: end;
  padding-right: 30px;
  padding-bottom: auto;
`;

export const ContentHeadLeft = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-right: auto;
`;
export const LogoHeader = styled.div`
<<<<<<< HEAD
=======
  pointer-events: all;
>>>>>>> development
  margin-left: 20px;
  width: 134px;
  height: 40px;
  font: normal normal 600 32px/32px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
`;

export const GreetingsHeader = styled.div`
  line-height: 40px;
  margin-left: 20px;
<<<<<<< HEAD
=======
  margin-right: 20px;
>>>>>>> development
  color: ${({ theme }) => theme.colors.white};
`;
