import React, { useState, useEffect } from "react";
import DadosCicloContainer from "./DadosCicloContainer";
import { usePaciente } from "../../../../contexts/paciente";

const DadosCiclo = ({
  pacienteid,
  sessaoid,
  freio,
  setFreio,
  toogleFreio,
  decrementarMotor,
  incrementarMotor,
  incrementarCarga,
  decrementarCarga,
  incrementarEstimulo,
  decrementarEstimulo,
}) => {
  const { paciente } = usePaciente();

  useEffect(() => {
    // console.log(paciente);
  }, [paciente]);

  return paciente ? (
    <DadosCicloContainer
      pacienteid={pacienteid}
      sessaoid={sessaoid}
      freio={freio}
      setFreio={setFreio}
      toogleFreio={toogleFreio}
      decrementarMotor={decrementarMotor}
      incrementarMotor={incrementarMotor}
      incrementarCarga={incrementarCarga}
      decrementarCarga={decrementarCarga}
      incrementarEstimulo={incrementarEstimulo}
      decrementarEstimulo={decrementarEstimulo}
    />
  ) : (
    <></>
  );
};

export default DadosCiclo;
