import styled from "styled-components";

export const TitleSecao = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin: 40px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 100px;
  padding: 10px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.grey};
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  overflow: hidden;
`;

export const Block = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const BlockSidebar = styled.div`
  width: 0%;
  height: 680px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.black};
  border-top-left-radius: 24px;
  transition: all 1s ease-in-out;
  box-sizing: border-box;

  &.on {
    width: 35%;
    height: 480px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.orange};
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.grey};
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: ${({ theme }) => theme.colors.orange};
    scrollbar-track-color: ${({ theme }) => theme.colors.grey};
  }
`;

export const BlockGraphic = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  border-top-right-radius: 24px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const ButtonToogleSize = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 90px;
  left: 0px;
  top: 45%;
  background-color: ${({ theme }) => theme.colors.pink};
  z-index: 10000000;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  &::before {
    content: "►";
    font: normal normal 800 14px/14px ${({ theme }) => theme.fonts.secondary};
    color: ${({ theme }) => theme.colors.black};
  }

  &.on {
    &::before {
      content: "◄";
      font: normal normal 800 14px/14px ${({ theme }) => theme.fonts.secondary};
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const BlockTable = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  padding: 0px 0px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black};

  .table-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 51px;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};

    .table-row-title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 22%;
      font: normal normal 800 14px/14px ${({ theme }) => theme.fonts.primary};
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
      box-sizing: border-box;
      padding-left: 10px;
      white-space: pre-wrap;

      &.titulo {
        justify-content: center;
        // color: ${({ theme }) => theme.colors.orange};
      }
    }
    .table-row-values {
      width: 78%;
      height: 51px;
    }
    .table-row-icon {
      width: 9%;
      margin-right: 15px;
    }

    .table-row-value {
      float: left;
      width: calc((100% / 16) - 2px);
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: 1px solid ${({ theme }) => theme.colors.grey};
      font: normal normal 800 14px/14px ${({ theme }) => theme.fonts.primary};
      color: ${({ theme }) => theme.colors.white};

      &.titulo {
        // color: ${({ theme }) => theme.colors.pink};
      }
    }
  }
`;
