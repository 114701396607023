import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import RouterCicloergometro from "./Routes";
import PreloadProvider from "./contexts/preloading";
import AuthProvider from "./contexts/authentication";
import Preloader from "./shared/Components/General/Preloader";
import AccountsProvider from "./contexts/accounts";
import UserProvider from "./contexts/user";
import PacientesProvider from "./contexts/pacientes";
import PacienteProvider from "./contexts/paciente";
import CiclosProvider from "./contexts/ciclos";
import LightboxProvider from "./contexts/lightbox";
import BottomMenuSectionProvider from "./contexts/bottomMenuSections";
import { Lightbox } from "./ui/General";
import RolesProvider from "./contexts/roles";
import AlertProvider from "./contexts/alert";
import { Alert } from "./ui/InformationComponents";
import ItemCicloProvider from "./contexts/itemCiclo";
import SessionProvider from "./contexts/session";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <PreloadProvider>
        <Preloader />
        <AlertProvider>
          <Alert />
          <AccountsProvider>
            <RolesProvider>
              <UserProvider>
                <AuthProvider>
                  <CiclosProvider>
                    <LightboxProvider>
                      <Lightbox />
                      <PacientesProvider>
                        <PacienteProvider>
                          <SessionProvider>
                            <ItemCicloProvider>
                              <BottomMenuSectionProvider>
                                <RouterCicloergometro />
                              </BottomMenuSectionProvider>
                            </ItemCicloProvider>
                          </SessionProvider>
                        </PacienteProvider>
                      </PacientesProvider>
                    </LightboxProvider>
                  </CiclosProvider>
                </AuthProvider>
              </UserProvider>
            </RolesProvider>
          </AccountsProvider>
        </AlertProvider>
      </PreloadProvider>
    </ThemeProvider>
  );
}

export default App;


