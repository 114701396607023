import { useEffect, useContext, useState, createContext } from "react";
import { getJwtToken } from "./authentication.jsx";
import { usePreload } from "./preloading.jsx";
import api from "../services/Api.js";

const PacientesContext = createContext();

export default function PacientesProvider({ children }) {
  const [pacientes, setPacientes] = useState(false);
  const { setPreloading } = usePreload();

  const clone = require("rfdc")();

  const getPacientes = async () => {
    let at = await getJwtToken();
    console.log("at", at);
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .get(`/CadastroPessoa/GetMosaicoFinalizado`, config)
      .then((res) => {
        if (res.status === 200) {
          let accountsTemp = clone(res.data);

          accountsTemp.filter((account) => {
            let isPaciente = false;
            for (let i = 0; i < account.roles.length; i++) {
              if (account.roles[i] === "Paciente") {
                isPaciente = true;
              }
            }
            return isPaciente;
          });

          let loadCount = 0;
          accountsTemp.forEach((account) => {
            account.active = true;

            if (account.photo !== null) {
              setPreloading(true);
              api
                .get(account.photo, config)
                .then((res2) => {
                  setPreloading(true);
                  account.photo = `data:image/jpeg;base64,${res2.data}`;
                  loadCount++;
                  if (accountsTemp.length === loadCount) {
                    setPacientes(accountsTemp);
                    setPreloading(false);
                  }
                })
                .catch((error) => {
                  setPreloading(false);
                });
            } else {
              loadCount++;
              if (accountsTemp.length === loadCount) {
                setPacientes(accountsTemp);
                setPreloading(false);
              }
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  useEffect(() => {
    getPacientes()
      .then((response) => {})
      .catch((err) => {
        console.log(err);
        setPreloading(false);
      });
  }, []);

  return (
    <PacientesContext.Provider value={{ pacientes, setPacientes }}>
      {children}
    </PacientesContext.Provider>
  );
}

export function usePacientes() {
  const context = useContext(PacientesContext);
  if (!context)
    throw new Error("usePacientes must be used within a PacientesProvider");

  const { pacientes, setPacientes } = context;

  return { pacientes, setPacientes };
}
