import React, { useState, useEffect } from "react";
import _ from "lodash";
import { getJwtToken } from "../../../../../../contexts/authentication";
import { usePaciente } from "../../../../../../contexts/paciente";
import { usePreload } from "../../../../../../contexts/preloading";
import { useAlert } from "../../../../../../contexts/alert";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useTheme } from "styled-components";
import { Button } from "../../../../../../ui/InputControls";
import { BlockSidebarItem } from "../../../../../../ui/General";
import {
  TitleSecao,
  Wrapper,
  Block,
  BlockSidebar,
  BlockGraphic,
  ButtonToogleSize,
  BlockTable,
  BlockSidebarTitle,
  GraficoWrapper,
} from "./styled";
import { Heart } from "@styled-icons/evaicons-solid";
import { Timer } from "@styled-icons/ionicons-solid";
import { HardwareChip } from "@styled-icons/ionicons-outline";
import { Power } from "@styled-icons/icomoon";
import api from "../../../../../../services/Api";
import {
  getStorageWithExpiry,
  setStorageWithExpiry,
} from "../../../../../../shared/utils/functions";

Chart.register(zoomPlugin); // REGISTER PLUGIN
Chart.register({
  id: "customSpacingLegend",
  beforeInit(chart) {
    const originalFit = chart.legend.fit;

    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 30;
    };
  },
});

function generateRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const GraficoSessao = ({
  pacienteid,
  sessaoid,
  config,
  objeto,
  titleGrafico,
  graficoMask,
  itemHidden,
  setItemHidden,
}) => {
  const { paciente } = usePaciente();
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const [datasetGrafico, setDatasetGrafico] = useState(
    _.cloneDeep(graficoMask)
  );
  const theme = useTheme();
  const [classBlockSidebar, setClassBlockSidebar] = useState("");
  const [dados, setDados] = useState({
    dadosGrafico: [],
  });

  Chart.defaults.font.size = 15;
  Chart.defaults.color = "#ffffff";

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          padding: 20,
        },
        onClick: (e, item) => {
          console.log(item.text);
          toogleShowItem(item.text);
        },
      },
      title: {
        display: false,
        text: "Gráfico Sessão",
      },

      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     pinch: {
      //       enabled: true,
      //     },
      //     mode: "xy",
      //   },
      //   pan: {
      //     enabled: true,
      //     mode: "xy",
      //     speed: 100,
      //   },
      //   limits: {
      //     y: { min: "original", max: "original" },
      //   },
      // },
    },
    interaction: {},
    layout: {
      padding: 5,
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: theme.colors.grey,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: theme.colors.grey,
        },
      },
    },
  };

  const [data, setData] = useState(null);

  const toogleSize = () => {
    if (classBlockSidebar === "") {
      setClassBlockSidebar("on");
    } else {
      setClassBlockSidebar("");
    }
  };

  const toogleShowAll = (e) => {
    e.stopPropagation();
    console.log(itemHidden);
    let tempItems = _.cloneDeep(itemHidden);
    let items = tempItems.filter((value) => value.hidden === true);
    if (items.length > 0) {
      setItemHidden((values) =>
        values.map((item, index) => ({ label: item.label, hidden: false }))
      );
    } else {
      setItemHidden((values) =>
        values.map((item, index) => ({ label: item.label, hidden: true }))
      );
    }
  };

  const toogleShowItem = (label) => {
    let valuesTemp = _.cloneDeep(itemHidden);

    valuesTemp = valuesTemp.map((item, index) =>
      item.label === label
        ? item.hidden
          ? { label: item.label, hidden: false }
          : { label: item.label, hidden: true }
        : { label: item.label, hidden: item.hidden }
    );

    setItemHidden(valuesTemp);
  };

  const sidebarItemClick = () => {};
  useEffect(() => {
    if (itemHidden !== null) {
      if (dados) {
        let dadosObject = new Object();
        dados.dadosGrafico.map((values, index) => {
          Object.entries(values).map(([key, value]) => {
            if (!dadosObject[key]) {
              dadosObject[key] = [];
            }
            dadosObject[key].push(value);
          });
        });
        let dataTemp = _.cloneDeep(data.datasets);

        dataTemp.map((value, index) => {
          value.hidden = itemHidden[index].hidden;
        });
        if (dadosObject.timeStamp) {
          let dataTime = dadosObject.timeStamp.map((value, index) => {
            return new Date(value).toLocaleString("pt-BR");
          });
          setData({
            labels: dataTime,
            datasets: dataTemp,
          });
        }
      }
    }
  }, [itemHidden]);

  useEffect(() => {
    if (dados) {
      let dadosObject = new Object();
      dados.dadosGrafico.map((values, index) => {
        Object.entries(values).map(([key, value]) => {
          if (!dadosObject[key]) {
            dadosObject[key] = [];
          }
          dadosObject[key].push(value);
        });
      });

      let dataTemp;

      if (data === null || data.datasets === null) {
        dataTemp = _.cloneDeep(datasetGrafico);
      } else {
        dataTemp = _.cloneDeep(data.datasets);
      }

      dataTemp.map((value, index) => {
        value.data = dadosObject[value.id];
      });

      if (itemHidden === null) {
        let hiddenTemp = dataTemp.map((value, index) => {
          return { label: value.label, hidden: value.hidden };
        });
        setItemHidden(hiddenTemp);
      }
      // console.log(dadosObject.timeStamp);
      setData({
        labels:
          dadosObject.timeStamp &&
          dadosObject.timeStamp.map((hora) => new Date(hora).toLocaleString()),
        datasets: dataTemp,
      });
    }
  }, [dados]);

  // useEffect(() => {
  //   if (paciente !== null && paciente.agenda !== undefined) {
  //     let filtered = paciente.agenda.filter(
  //       (value) => value.sessionId === parseInt(sessaoid)
  //     );
  //     setSessaoAtual(filtered);
  //   }
  // }, [paciente]);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  useEffect(() => {
    // console.log(dados);
  }, [dados]);

  function lowerCaseFirstLetterObject(arr) {
    return arr.map((obj, index) =>
      Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.charAt(0).toLowerCase() + key.slice(1)] =
          key === "Temperatura" ? obj[key] / 10 : obj[key];
        return accumulator;
      }, {})
    );
  }

  const loadData = async () => {
    let tempSession = await getStorageWithExpiry("@session");
    if (tempSession.data[objeto]) {
      setDados((values) => ({
        dadosGrafico: lowerCaseFirstLetterObject(tempSession.data[objeto]),
      }));
    }
  };

  useEffect(() => {
    loadData();
  }, [config]);

  const stopAll = (e) => {
    console.log("stop all");
    e.stopPropagation();
  };

  return data ? (
    <GraficoWrapper onClick={(e) => stopAll(e)}>
      <TitleSecao>
        Gráfico {titleGrafico}{" "}
        <Button
          title={"Mostrar / Esconder Todos"}
          color={"primary"}
          width={"250px"}
          margin={"0px 30px"}
          onClick={(e) => toogleShowAll(e)}
        />
      </TitleSecao>
      <Wrapper>
        <Block>
          <BlockSidebar className={classBlockSidebar}>
            <BlockSidebarTitle>Médias {titleGrafico} </BlockSidebarTitle>
            {data.datasets.map(
              (value, index) =>
                value.id !== "tempo" &&
                value.id !== "faseCorrida" && (
                  <BlockSidebarItem
                    key={index}
                    item={value}
                    clickFunction={sidebarItemClick}
                  />
                )
            )}
          </BlockSidebar>
          <BlockGraphic>
            <ButtonToogleSize
              onClick={toogleSize}
              className={classBlockSidebar}
            />
            {data !== null && (
              <Line
                options={options}
                data={data}
                // {...props}
              />
            )}
          </BlockGraphic>
        </Block>
        {/*  <Block>
          <BlockTable>
            {sessaoAtual[0].sessao.sessionSummary
              .filter((value) => {
                return value.graphic !== null;
              })
              .map(
                (value, index) =>
                  index === 0 && (
                    <div key={index} className="table-row">
                      <div className="table-row-title titulo">Parâmetros</div>
                      <div className="table-row-values">
                        {value.graphic.map(
                          (value2, index2) =>
                            index2 % 8 === 0 && (
                              <div
                                key={index2}
                                className="table-row-value titulo"
                              >
                                {value2.time}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  )
              )}
            {sessaoAtual[0].sessao.sessionSummary
              .filter((value) => {
                return value.graphic !== null;
              })
              .map((value, index) => (
                <div key={index} className="table-row">
                  <div className="table-row-title">
                    <div className="table-row-icon">
                      {value.category === "corpo" ? (
                        <Heart color={theme.colors.pink} />
                      ) : value.category === "sessao" ? (
                        <Timer color={theme.colors.white} />
                      ) : value.category === "hardware" ? (
                        <HardwareChip color={theme.colors.blue} />
                      ) : value.category === "estimulo" ? (
                        <Power color={theme.colors.green} />
                      ) : (
                        <></>
                      )}
                    </div>
                    {value.name}{" "}
                    <span style={{ color: theme.colors.orange }}>
                      ({value.unit})
                    </span>
                  </div>
                  <div className="table-row-values">
                    {value.graphic.map(
                      (value2, index2) =>
                        index2 % 8 === 0 && (
                          <div key={index2} className="table-row-value">
                            <span style={{ color: value.borderColor }}>
                              {value2.value}
                            </span>
                          </div>
                        )
                    )}
                  </div>
                </div>
              ))} 
          </BlockTable>
        </Block>*/}
      </Wrapper>
    </GraficoWrapper>
  ) : (
    <></>
  );
};

export default GraficoSessao;
