import { useEffect } from "react";
import { GridThumb } from "../../ui/General";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/user";
import { useBottomMenuSections } from "../../contexts/bottomMenuSections";

const Pacientes = ({ pacientes }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { setBottomMenuSections } = useBottomMenuSections();
  const clickThumb = (id) => {
    navigate(`/paciente/${id}`);
  };

  useEffect(() => {
    if (user && user.currentRole === "Terapeuta") {
      setBottomMenuSections("terapeutaPacientes");
    } else if (user && user.currentRole === "Medico") {
      setBottomMenuSections("medicoPacientes");
    }
  }, [user]);

  return (
    <>
      {pacientes &&
        pacientes.map(
          (item, index) =>
            item.active && (
              <GridThumb key={index} item={item} clickFunction={clickThumb} />
            )
        )}
    </>
  );
};

export default Pacientes;
