import React, { useEffect, useState } from "react";
import MeusPacientes from "../../General/MeusPacientes";
import MainSchedules from "../../General/MainSchedules";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { useAuth } from "../../../../contexts/authentication";

const TerapeutaHome = ({ pacientes, randomSortPacientes }) => {
  const { bottomMenuSections, setBottomMenuSections } = useBottomMenuSections();

  const { user, logout } = useAuth();

  useEffect(() => {
    setBottomMenuSections("terapeuta");
  }, []);

  return (
    <>
      <MeusPacientes pacientes={pacientes} />
      <MainSchedules
        randomSortPacientes={randomSortPacientes}
        pacientes={pacientes}
      />
    </>
  );
};

export default TerapeutaHome;
