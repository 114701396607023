import styled from "styled-components";

export const FullwidthCenterCenter = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const FullwidthLeft = styled.div`
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const MainContainerLeft = styled.div`
  width: 71vw;
  margin: 1vw;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`;
