import styled from "styled-components";
import { InputText, InputPassword } from "../../../../ui/InputControls";

export const SidebarWrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: 23vw;
  min-height: 200px;
  background: ${({ theme }) => theme.colors.grey} 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 24px;
  margin: 1vw;
  padding: 20px;
  box-sizing: border-box;
`;

export const ProfileHeader = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
`;

export const ProfilePhoto = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 214px;
  height: 214px;
  margin: 14px auto 24px;
  border-radius: 107px;
  background-color: ${({ theme }) => theme.colors.greyDarker};
  overflow: hidden;

  img {
    position: absolute;
    width: 214px;
    height: 214px;
    object-fit: cover;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const ProfileName = styled.div`
  width: 100%;
  height: 33px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font: normal normal 400 27px/27px ${({ theme }) => theme.fonts.primary};
`;

export const ProfileBody = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ProfileFields = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
`;

export const Initials = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 80px;
  margin-bottom: 15px;
  border-radius: 90px;
  overflow: hidden;
  font: normal normal 600 66px/70px ${({ theme }) => theme.fonts.primary};
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.orange};
`;
