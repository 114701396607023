import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  ConfigItemText,
  GridThumbDadosDone,
  GridThumbDadosDoneMin,
  GridThumbDadosDoneMax,
  GridThumbDadosAdd,
  ConfigItemSelect,
  ConfigItemSlider,
  ConfigItemSliderRange,
} from "../../../../../ui/General";
import { getJwtToken } from "../../../../../contexts/authentication";
import { usePaciente } from "../../../../../contexts/paciente";
import { usePreload } from "../../../../../contexts/preloading";
import { useAlert } from "../../../../../contexts/alert";
import api from "../../../../../services/Api";

import { TitleSecao, Wrapper } from "./styled";

import { resumoEstatistica } from "../../../../../assets/resumoEstatistica";

const MediaParametrica = ({ pacienteid, sessaoid, escala }) => {
  const { paciente } = usePaciente();
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();

  const [dados, setDados] = useState({
    resumoCorrida: [],
    estatisticaSessao: _.cloneDeep(resumoEstatistica),
  });

  function upperCaseFirstLetterObject(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
      return accumulator;
    }, {});
  }

  const loadData = async () => {
    let dadosTemp = _.cloneDeep(dados);

    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .get(`/MQTT/GetResumoCorridaBySessionId/${sessaoid}`, config)
      .then((res) => {
        if (res.status === 200) {
          console.log("resumo corrida", res.data);

          let arrayTemp = [];

          res.data.map((dado, index) => {
            arrayTemp.push(_.cloneDeep(resumoEstatistica));
          });

          arrayTemp.map((arr, index) => {
            let dataTemp = upperCaseFirstLetterObject(res.data[index]);
            arr.map((dado) => {
              dado.value =
                dado.id === "TempCorpMax" || dado.id === "TempCorpMin"
                  ? dataTemp[dado.id] / 10
                  : dataTemp[dado.id];
            });
          });
          setDados((values) => ({
            ...values,
            resumoCorrida: arrayTemp,
          }));

          setPreloading(false);
        }
        api
          .get(`/MQTT/GetEstatisticaSessaoBySessionId/${sessaoid}`, config)
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);

              dadosTemp.estatisticaSessao.map((dado) => {
                let dataTemp = upperCaseFirstLetterObject(res.data);
                dado.value =
                  dado.id === "TempCorpMax" || dado.id === "TempCorpMin"
                    ? dataTemp[dado.id] / 10
                    : dataTemp[dado.id];
              });

              setDados((values) => ({
                ...values,
                estatisticaSessao: dadosTemp.estatisticaSessao,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
            setAlertText("Erro ao carregar Estatísticas da Sessão!");
            setAlertType("error");
            setClasseAlert("on");
            setPreloading(false);
            return false;
          });
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao carregar Resumo da corrida!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
        return false;
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    console.log(dados);
  }, [dados]);

  return dados ? (
    <>
      <TitleSecao>Estatística Sessão</TitleSecao>
      <Wrapper>
        {dados.estatisticaSessao &&
          dados.estatisticaSessao.map((sessao, index) => {
            return <GridThumbDadosDone key={index} item={sessao} />;
          })}
      </Wrapper>
      {dados.resumoCorrida &&
        dados.resumoCorrida.map((corrida, index) => {
          return (
            <React.Fragment key={index}>
              <TitleSecao>Resumo Corrida {index + 1}</TitleSecao>
              <Wrapper>
                {corrida.map((dado, index) => {
                  return <GridThumbDadosDone key={index} item={dado} />;
                })}
              </Wrapper>
            </React.Fragment>
          );
        })}
    </>
  ) : (
    <></>
  );
};

export default MediaParametrica;
