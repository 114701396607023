import React, { useEffect, useState } from "react";
import { useUser } from "../../../../contexts/user";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import NotasContainer from "./NotasContainer";
import MediaParametrica from "./MediaParametrica";
import GraficoSessao from "./GraficoSessao";
import { escala } from "../../../../assets/mock";
import { useSession } from "../../../../contexts/session";

const ResumoSessao = () => {
  const { user } = useUser();
  const { session } = useSession();
  let { bottomMenuSections, setBottomMenuSections, currentBottomMenu } =
    useBottomMenuSections();
  const [currentTabScreen, setCurrentTabScreen] = useState(null);

  useEffect(() => {
    if (user && user.currentRole === "Terapeuta") {
      setBottomMenuSections("terapeutaSessao");
    } else if (user && user.currentRole === "Paciente") {
      setBottomMenuSections("terapeutaSessao");
    }
  }, [user]);

  useEffect(() => {
    if (currentBottomMenu) {
      let selectedIndex = currentBottomMenu.findIndex(
        (obj, index) => obj.selected
      );
      setCurrentTabScreen(currentBottomMenu[selectedIndex]);
    }
  }, [currentBottomMenu]);
  return (
    <>
      <MediaParametrica session={session} />
    </>
  );
};

export default ResumoSessao;
