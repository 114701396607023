import styled, { keyframes } from "styled-components";

const rotate = keyframes`
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  `;

const dash = keyframes`
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  `;

export const PreloadWrapper = styled.div`
  position: fixed;
  /* background: #ffffff; */
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: -1;
  transition: z-index 0s linear 0.7s, opacity 0.7s ease-in-out 0s;

  &.on {
    opacity: 0.9;
    z-index: 9999999999;
    transition: z-index 0s linear 0s, opacity 0.7s ease-in-out 0s;
  }
`;

export const ProfileMainLoader = styled.div`
  left: 50% !important;
  margin-left: -100px;
  position: fixed !important;
  top: 50% !important;
  margin-top: -125px;
  width: 45px;
  z-index: 9000 !important;

  .loader {
    position: relative;
    margin: 0px auto;
    width: 200px;
    height: 200px;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
`;

export const CircularLoader = styled.svg`
  animation: ${rotate} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
`;

export const LoaderPath = styled.circle`
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  stroke-width: 1px;
  animation: ${dash} 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
`;
