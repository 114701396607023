import { useRef, useEffect, useState, useMemo } from "react";
import { useThree, useLoader, useFrame, extend } from "@react-three/fiber";
import * as THREE from "three";
import { OrbitControls } from "@react-three/drei";
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Noise,
  Vignette,
} from "@react-three/postprocessing";
import _ from "lodash";
import Random from "canvas-sketch-util/random";

const clone = require("rfdc")();

let compareTwoArrayOfObjects = (
  first_array_of_objects,
  second_array_of_objects
) => {
  return (
    first_array_of_objects.length === second_array_of_objects.length &&
    first_array_of_objects.every((element_1) =>
      second_array_of_objects.some((element_2) =>
        Object.keys(element_1).every((key) => element_1[key] === element_2[key])
      )
    )
  );
};

THREE.Vector3.prototype.round = function (digits) {
  var e = Math.pow(10, digits || 0);

  this.x = Math.round(this.x * e) / e;
  this.y = Math.round(this.y * e) / e;
  this.z = Math.round(this.z * e) / e;

  return this;
};

const makeCurve = (carga, points, setPoints, cargaLateral, metaverso) => {
  {
    // console.log("making curve");
    // Create an empty array to stores the points

    let pointsTemp = _.cloneDeep(points);

    // Define points along Z axis
    if (pointsTemp.length === 0) {
      for (var i = 0; i < 6; i += 1)
        pointsTemp.push(new THREE.Vector3(0, 0, -2.5 * (i / 4)).round(5));
    }

    // console.log("pointsTemp y", pointsTemp[5].y);

    pointsTemp[0].y = 0.0001;
    pointsTemp[1].y =
      pointsTemp[1].y === 0.005 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        ? 0.005 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        : pointsTemp[1].y < 0.005 * (carga - 0.5 > 0 ? carga - 0.5 : 0) - 0.0005
        ? (pointsTemp[1].y += 0.0005)
        : pointsTemp[1].y > 0.005 * (carga - 0.5 > 0 ? carga - 0.5 : 0) + 0.0005
        ? (pointsTemp[1].y -= 0.0005)
        : 0.005 * (carga - 0.5 > 0 ? carga - 0.5 : 0);
    pointsTemp[2].y =
      pointsTemp[2].y === 0.018 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        ? 0.018 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        : pointsTemp[2].y < 0.018 * (carga - 0.5 > 0 ? carga - 0.5 : 0) - 0.0005
        ? (pointsTemp[2].y += 0.0005)
        : pointsTemp[2].y > 0.018 * (carga - 0.5 > 0 ? carga - 0.5 : 0) + 0.0005
        ? (pointsTemp[2].y -= 0.0005)
        : 0.018 * (carga - 0.5 > 0 ? carga - 0.5 : 0);
    pointsTemp[3].y =
      pointsTemp[3].y === 0.04 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        ? 0.04 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        : pointsTemp[3].y < 0.04 * (carga - 0.5 > 0 ? carga - 0.5 : 0) - 0.0005
        ? (pointsTemp[3].y += 0.0005)
        : pointsTemp[3].y > 0.04 * (carga - 0.5 > 0 ? carga - 0.5 : 0) + 0.0005
        ? (pointsTemp[3].y -= 0.0005)
        : 0.04 * (carga - 0.5 > 0 ? carga - 0.5 : 0);
    pointsTemp[4].y =
      pointsTemp[4].y === 0.07 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        ? 0.07 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        : pointsTemp[4].y < 0.07 * (carga - 0.5 > 0 ? carga - 0.5 : 0) - 0.0005
        ? (pointsTemp[4].y += 0.0005)
        : pointsTemp[4].y > 0.07 * (carga - 0.5 > 0 ? carga - 0.5 : 0) + 0.0005
        ? (pointsTemp[4].y -= 0.0005)
        : 0.07 * (carga - 0.5 > 0 ? carga - 0.5 : 0);
    pointsTemp[5].y =
      pointsTemp[5].y === 0.1 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        ? 0.1 * (carga - 0.5 > 0 ? carga - 0.5 : 0)
        : pointsTemp[5].y < 0.1 * (carga - 0.5 > 0 ? carga - 0.5 : 0) - 0.0005
        ? (pointsTemp[5].y += 0.0005)
        : pointsTemp[5].y > 0.1 * (carga - 0.5 > 0 ? carga - 0.5 : 0) + 0.0005
        ? (pointsTemp[5].y -= 0.0005)
        : 0.1 * (carga - 0.5 > 0 ? carga - 0.5 : 0);

    if (metaverso) {
      pointsTemp[0].x = 0;
      pointsTemp[1].x = 0;
      pointsTemp[2].x =
        pointsTemp[2].x === 0.015 * cargaLateral
          ? 0.015 * cargaLateral
          : pointsTemp[2].x < 0.015 * cargaLateral - 0.0005
          ? (pointsTemp[2].x += 0.0005)
          : pointsTemp[2].x > 0.015 * cargaLateral + 0.0005
          ? (pointsTemp[2].x -= 0.0005)
          : 0.015 * cargaLateral;
      pointsTemp[3].x =
        pointsTemp[3].x === 0.04 * cargaLateral
          ? 0.04 * cargaLateral
          : pointsTemp[3].x < 0.04 * cargaLateral - 0.0005
          ? (pointsTemp[3].x += 0.0005)
          : pointsTemp[3].x > 0.04 * cargaLateral + 0.0005
          ? (pointsTemp[3].x -= 0.0005)
          : 0.04 * cargaLateral;
      pointsTemp[4].x =
        pointsTemp[4].x === 0.07 * cargaLateral
          ? 0.07 * cargaLateral
          : pointsTemp[4].x < 0.07 * cargaLateral - 0.0005
          ? (pointsTemp[4].x += 0.0005)
          : pointsTemp[4].x > 0.07 * cargaLateral + 0.0005
          ? (pointsTemp[4].x -= 0.0005)
          : 0.07 * cargaLateral;
      pointsTemp[5].x =
        pointsTemp[5].x === 0.1 * cargaLateral
          ? 0.1 * cargaLateral
          : pointsTemp[5].x < 0.1 * cargaLateral - 0.0005
          ? (pointsTemp[5].x += 0.0005)
          : pointsTemp[5].x > 0.1 * cargaLateral + 0.0005
          ? (pointsTemp[5].x -= 0.0005)
          : 0.1 * cargaLateral;
    } else {
      pointsTemp[0].x = 0;
      pointsTemp[1].x = 0;
      pointsTemp[2].x = 0;
      pointsTemp[3].x = 0;
      pointsTemp[4].x = 0;
      pointsTemp[5].x = 0;
    }

    pointsTemp[0] = pointsTemp[0].round(5);
    pointsTemp[1] = pointsTemp[1].round(5);
    pointsTemp[2] = pointsTemp[2].round(5);
    pointsTemp[3] = pointsTemp[3].round(5);
    pointsTemp[4] = pointsTemp[4].round(5);
    pointsTemp[5] = pointsTemp[5].round(5);
    setPoints(pointsTemp);
    // Create a curve based on the points
    let curve = new THREE.CatmullRomCurve3(pointsTemp);
    curve.verticesNeedUpdate = true;
    return curve;
  }
};

const makeTexture = (playbackRate, metaverso) => ({
  offsetX:
    (metaverso ? 0.005 : 0.003) * (playbackRate > 0.0 ? playbackRate : 0.0),
  offsetY: 0,
  repeatX: 10,
  repeatY: 4,
});

const RandomMeshes = ({ playbackRate }) => {
  const meshRef = useRef(null);
  const raysMeshRef = useRef(null);
  const [objectsParticles, setObjectsParticles] = useState([]);
  const [trianglesLength, setTrianglesLength] = useState(40);
  const [raysLength, setRaysLength] = useState(100);
  const [scale, setScale] = useState(1000);
  // let vertices = [-0.005, 0, 0, 0.005, 0, 0, 0, 0.01, 0];//triangle
  let vertices = [0, 0, 1, 0, 0.5, 0, 0, -0.5, 0]; //triangle
  vertices = vertices.map((vertice, index) => vertice * 0.005);
  vertices = new Float32Array(vertices);

  let verticesRay = [
    0, 0.2, 0, 0, 0.2, -800, 0, -0.2, 0, 0, -0.2, 0, 0, -0.2, -800, 0, 0.2,
    -800,
  ]; //reactangle
  verticesRay = verticesRay.map((vertice, index) => vertice * 0.005);
  verticesRay = new Float32Array(verticesRay);

  function getRandomFloatPositive(min, max, decimals) {
    const str = (Math.random() * (max - min + min)).toFixed(decimals);

    let num = parseFloat(str);

    return num;
  }

  function getRandomFloat(absoluteValue, decimals, negDiff, posDiff) {
    const str = (
      Math.random() *
      absoluteValue *
      (Math.round(Math.random()) ? 1 : -1)
    ).toFixed(decimals);

    let num = parseFloat(str);
    // console.log(num);

    if (negDiff !== 0) {
      // console.log(num);

      if (num >= 0) {
        if (num < posDiff) {
          let diff = posDiff - num;
          num += diff;
        }
      } else {
        if (num > negDiff) {
          let diff = num - negDiff;
          num -= diff;
        }
      }
    }

    return num;
  }

  useEffect(() => {
    if (meshRef === null) return;
    if (meshRef.current === null) return;

    const mesh = meshRef.current;

    let objectsArr = [];
    for (let i = 0; i < trianglesLength; i++) {
      let tempObject = new THREE.Object3D();
      let tempColor = new THREE.Color();
      //position
      tempObject.position.set(
        0.06 * getRandomFloat(1000, 5, -1000, 1000),
        0.06 * getRandomFloat(1000, 5, 0, 0),
        -getRandomFloatPositive(0, 4000, 5)
      );
      tempObject.rotation.set(
        -getRandomFloatPositive(0, Math.PI * 2, 5),
        -getRandomFloatPositive(0, Math.PI * 2, 5),
        -getRandomFloatPositive(0, Math.PI * 2, 5)
      );
      tempObject.scale.set(
        -getRandomFloatPositive(scale / 100, scale * 2, 5),
        -getRandomFloatPositive(scale / 100, scale * 2, 5),
        -getRandomFloatPositive(scale / 100, scale * 2, 5)
      );
      tempObject.updateMatrix();
      mesh.setMatrixAt(i, tempObject.matrix);
      mesh.setColorAt(i, tempColor.setRGB(1, 1, 1));
      objectsArr.push(tempObject);
    }
    setObjectsParticles(objectsArr);
    mesh.instanceMatrix.needsUpdate = true;
  }, [meshRef]);

  useEffect(() => {
    if (raysMeshRef === null) return;
    if (raysMeshRef.current === null) return;

    const mesh2 = raysMeshRef.current;

    for (let i = 0; i < raysLength; i++) {
      let tempObject = new THREE.Object3D();
      let tempColor = new THREE.Color();
      //position
      tempObject.position.set(
        0.06 * getRandomFloat(3000, 5, -100, 100),
        0.06 * getRandomFloat(3000, 5, -2000, 2000),
        -getRandomFloatPositive(0, 20000, 5)
      );
      tempObject.rotation.set(0, 0, 0);
      tempObject.scale.set(
        -getRandomFloatPositive(scale / 100, scale, 5),
        -getRandomFloatPositive(scale / 100, scale, 5),
        -getRandomFloatPositive(scale / 100, scale, 5)
      );
      tempObject.updateMatrix();
      mesh2.setMatrixAt(i, tempObject.matrix);

      // color
      // mesh.setColorAt(
      //   i,
      //   tempColor.setRGB(Math.random(), Math.random(), Math.random())
      // );
    }
    mesh2.instanceMatrix.needsUpdate = true;
  }, [raysMeshRef]);

  useFrame(() => {
    if (meshRef === null) return;
    if (meshRef.current === null) return;

    const mesh = meshRef.current;

    let velocidade = parseFloat(playbackRate);
    // console.log(velocidade);

    for (let i = 0; i < trianglesLength; i++) {
      let tempObject = new THREE.Object3D();
      let tempColor = new THREE.Color();
      let matrix = new THREE.Matrix4();

      mesh.getMatrixAt(i, matrix);
      matrix.decompose(
        tempObject.position,
        tempObject.quaternion,
        tempObject.scale
      );
      //position
      if (tempObject.position.z < 0) {
        tempObject.position.set(
          tempObject.position.x,
          tempObject.position.y,
          tempObject.position.z + velocidade
        );
        tempObject.rotation.set(
          tempObject.rotation.x,
          tempObject.rotation.y,
          tempObject.rotation.z + 0.008 * velocidade
        );
      } else {
        tempObject.position.set(
          tempObject.position.x,
          tempObject.position.y,
          -4000
        );
      }
      if (tempObject.position.z < -1700) {
        tempObject.position.y = 50000;
      } else {
        if (objectsParticles[i]) {
          tempObject.position.y = objectsParticles[i].position.y;
        }
      }
      tempObject.updateMatrix();
      mesh.setMatrixAt(i, tempObject.matrix);
      mesh.setColorAt(i, tempColor.setRGB(1, 1, 1));
    }

    mesh.instanceMatrix.needsUpdate = true;

    if (raysMeshRef === null) return;
    if (raysMeshRef.current === null) return;

    const meshRays = raysMeshRef.current;

    for (let i = 0; i < raysLength; i++) {
      let tempObject = new THREE.Object3D();
      let tempColor = new THREE.Color();
      let matrix = new THREE.Matrix4();

      meshRays.getMatrixAt(i, matrix);
      matrix.decompose(
        tempObject.position,
        tempObject.quaternion,
        tempObject.scale
      );
      //position
      if (tempObject.position.z < 0) {
        tempObject.position.set(
          tempObject.position.x,
          tempObject.position.y,
          tempObject.position.z + 25 * velocidade
        );
        tempObject.rotation.set(
          tempObject.rotation.x,
          tempObject.rotation.y,
          tempObject.rotation.z
        );
      } else {
        tempObject.position.set(
          tempObject.position.x,
          tempObject.position.y,
          -20000
        );
      }
      tempObject.updateMatrix();
      meshRays.setMatrixAt(i, tempObject.matrix);

      // color
      // meshRays.setColorAt(
      //   i,
      //   tempColor.setRGB(Math.random(), Math.random(), Math.random())
      // );
    }
    meshRays.instanceMatrix.needsUpdate = true;
  });

  return (
    <>
      <instancedMesh ref={meshRef} args={[null, null, trianglesLength]}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position" //attribute parameter yang akan dikontrol
            args={[vertices, 3]}
          />
        </bufferGeometry>
        <meshBasicMaterial
          attach="material"
          color={"white"}
          wireframe
          side={THREE.DoubleSide}
        />
      </instancedMesh>
      <instancedMesh ref={raysMeshRef} args={[null, null, raysLength]}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position" //attribute parameter yang akan dikontrol
            args={[verticesRay, 3]}
          />
        </bufferGeometry>
        <meshBasicMaterial
          attach="material"
          color={"white"}
          side={THREE.DoubleSide}
        />
      </instancedMesh>
    </>
  );
};

const Tunnel = ({ playbackRate, carga, metaverso }) => {
  const mat = useRef();
  const tube = useRef();
  const [points, setPoints] = useState([]);
  const [pointsOld, setPointsOld] = useState([]);
  const [cargaLateral, setCargaLateral] = useState(0);
  const [curve, setCurve] = useState(() =>
    makeCurve(
      (carga / 100) * 3.0,
      points,
      setPoints,
      (cargaLateral / 100) * 3.0,
      metaverso
    )
  );
  const texture = useLoader(THREE.TextureLoader, "/pattern.png");
  const textureMeta = useLoader(THREE.TextureLoader, "/pattern2.png");
  const { camera, mouse } = useThree();
  const [textureParams, setTextureParams] = useState(() =>
    makeTexture(playbackRate, metaverso)
  );
  const light = useRef(null);
  const mesh = useRef(null);
  const [count, setCount] = useState(1000);
  const [timer, setTimer] = useState({
    lastTime: 0,
    interval: 10,
  });
  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      const time = Random.range(0, 100);
      const factor = Random.range(20, 120);
      const speed = Random.range(0.01, 0.015) / 2;
      const x = Random.range(-50, 50);
      const y = Random.range(-50, 50);
      const z = Random.range(-500, 20);

      temp.push({ time, factor, speed, x, y, z });
    }
    return temp;
  }, [count]);
  const dummy = useMemo(() => new THREE.Object3D(), []);

  useEffect(() => {
    setCurve(() =>
      makeCurve(
        (carga / 100) * 3.0,
        points,
        setPoints,
        (cargaLateral / 100) * 3.0,
        metaverso
      )
    );
  }, [carga, cargaLateral, metaverso]);

  useEffect(() => {
    console.log("playbackRate", playbackRate);
    setTextureParams(() => makeTexture(playbackRate, metaverso));
  }, [playbackRate, metaverso]);

  useEffect(() => {
    // console.log(compareTwoArrayOfObjects(points, pointsOld));
    if (compareTwoArrayOfObjects(points, pointsOld) === false) {
      setTimeout(() => {
        let pointsTemp = _.cloneDeep(points);
        setPointsOld(pointsTemp);
        setCurve(() =>
          makeCurve(
            (carga / 100) * 3.0,
            points,
            setPoints,
            (cargaLateral / 100) * 3.0,
            metaverso
          )
        );
      }, 1);
    }
  }, [points]);

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    if (a > timer.lastTime + timer.interval) {
      console.log("timer done");
      setTimer({
        lastTime: a,
        interval: Math.random() * 10 + 5,
      });
      setCargaLateral(Math.random() * 100 * (Math.random() < 0.5 ? -1 : 1));
    }
    if (!metaverso) {
      texture.offset.x += textureParams.offsetX;
      // texture.offset.y += 0.001;
      texture.repeat.set(textureParams.repeatX, textureParams.repeatY);
    } else {
      textureMeta.offset.x += textureParams.offsetX;
      textureMeta.repeat.set(textureParams.repeatX, textureParams.repeatY);
    }

    tube.current.verticesNeedUpdate = true;
    tube.current.normalsNeedUpdate = true;
    // camera.rotation.z = mouse.x * 0.08;
    if (metaverso) {
      particles.forEach((particle, index) => {
        let { factor, speed, x, y, z } = particle;

        // Update the particle time
        const t = (particle.time += speed);

        // Update the particle position based on the time
        // This is mostly random trigonometry functions to oscillate around the (x, y, z) point
        dummy.position.set(
          x + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
          y + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
          z + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
        );

        // Derive an oscillating value which will be used
        // for the particle size and rotation
        const s = Math.cos(t);
        dummy.scale.set(s, s, s);
        dummy.rotation.set(s * 5, s * 5, s * 5);
        dummy.updateMatrix();

        // And apply the matrix to the instanced item
        mesh.current.setMatrixAt(index, dummy.matrix);
      });
      mesh.current.instanceMatrix.needsUpdate = true;
    }
  });

  return (
    <>
      <pointLight ref={light} distance={10} intensity={100} color="white" />
      {metaverso && (
        <instancedMesh ref={mesh} args={[null, null, count]}>
          <dodecahedronGeometry args={[0.05, 0]} />
          <meshPhongMaterial color="#66aaff" />
        </instancedMesh>
      )}
      <mesh>
        <tubeGeometry
          dynamic={false}
          args={[curve, 140, 0.03, 10, true]}
          ref={tube}
        />
        {!metaverso && (
          <meshStandardMaterial
            ref={mat}
            map={texture}
            map-wrapS={THREE.MirroredRepeatWrapping}
            map-wrapT={THREE.MirroredRepeatWrapping}
            map-repeat={[5, 1]}
            side={THREE.BackSide}
            transparent={true}
          />
        )}
        {metaverso && (
          <meshStandardMaterial
            ref={mat}
            map={textureMeta}
            map-wrapS={THREE.RepeatWrapping}
            map-wrapT={THREE.MirroredRepeatWrapping}
            map-repeat={[5, 1]}
            side={THREE.BackSide}
            transparent={true}
          />
        )}
      </mesh>
      {/* <OrbitControls /> */}
      {metaverso && <RandomMeshes playbackRate={playbackRate} />}
      {/* {metaverso && (
        <EffectComposer>
          <DepthOfField
            focusDistance={0}
            focalLength={0.02}
            bokehScale={2}
            height={480}
          />
          <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} />
          <Noise opacity={0.05} />
          <Vignette eskil={false} offset={0.1} darkness={1.1} />
        </EffectComposer>
      )} */}
    </>
  );
};

export default Tunnel;
