import React, { useState, useEffect } from "react";
import { usePaciente } from "../../../../../contexts/paciente";
import {
  ConfigItemText,
  GridThumbDados,
  GridThumbDadosAdd,
  ConfigItemSelect,
  ConfigItemSlider,
  ConfigItemSliderRange,
} from "../../../../../ui/General";
import {
  TitleSecao,
  NotasWrapper,
  NotasText,
  NotasBorg,
  BorgTitle,
  BorgButtons,
  BorgButton,
  BorgButtonValue,
  BorgButtonDescription,
} from "./styled";

const NotasContainer = ({ pacienteid, sessaoid }) => {
  const { paciente } = usePaciente();
  const [sessaoAtual, setSessaoAtual] = useState(null);

  useEffect(() => {
    if (paciente !== null && paciente.agenda !== undefined) {
      let filtered = paciente.agenda.filter(
        (value) => value.sessionId === parseInt(sessaoid)
      );
      setSessaoAtual(filtered);
    }
  }, [paciente]);

  return sessaoAtual ? (
    <>
      <TitleSecao>Sessão Cancelada</TitleSecao>
    </>
  ) : (
    <></>
  );
};

export default NotasContainer;
