import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authentication";
import { Wrapper } from "./styled";
import { ContainerFullwidthCenterCenter } from "../../ui/Containers";
import { TitleBig } from "../../ui/Texts";
import {
  InputText,
  InputPassword,
  Button,
  Anchor,
} from "../../ui/InputControls";
import { useEffect } from "react";
import { usePreload } from "../../contexts/preloading";

const Login = () => {
  const auth = useAuth();
  let navigate = useNavigate();

  const { setPreloading } = usePreload(false);
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);

  const entrar = () => {
    auth.login(user, password);
  };

  const esqueciSenha = () => {
    navigate("/esquecisenha");
  };

  const verificarCodigo = () => {
    navigate("/codigodeacesso");
  };

  useEffect(() => {
    setPreloading(false);
  }, []);

  return (
    <ContainerFullwidthCenterCenter>
      <Wrapper>
        <TitleBig margin="20px">LUCY.IO</TitleBig>
        <InputText
          width="200px"
          id="usuario"
          placeholder="Usuário"
          onChange={setUser}
          value={user}
        />
        <InputPassword
          width="200px"
          id="senha"
          placeholder="Senha"
          onChange={setPassword}
        />
        <Button width="200px" title="Entrar" color="primary" onClick={entrar} />
        <Anchor onClick={esqueciSenha}>Esqueci minha senha</Anchor>
        <Anchor onClick={verificarCodigo}>
          <div className="span">Tem um código de verificação?</div> Verifique
          aqui sua conta
        </Anchor>
      </Wrapper>
    </ContainerFullwidthCenterCenter>
  );
};

export default Login;
