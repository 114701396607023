import React, { useEffect, useState } from "react";
import {
  InputText,
  InputPassword,
  InputProfile,
  ButtonShowHide,
  ButtonEdit,
} from "../../../../ui/InputControls";
import {
  SidebarWrapper,
  ProfileHeader,
  ProfilePhoto,
  ProfileName,
  ProfileBody,
  ActionsWrapper,
  ProfileFields,
  Initials,
} from "./styled";
import woman from "../../../../assets/images/woman.jpg";
import { useUser } from "../../../../contexts/user";
import { getInitials } from "../../../../ui/General";

const SidebarPaciente = ({ paciente, setPaciente }) => {
  const { user, setUser } = useUser();
  const [fieldsVisible, setFieldsVisible] = useState(false);
  const [fieldsEditable, setFieldsEditable] = useState(true);

  const changeValue = (e) => {
    const newValues = paciente.data.map((obj) => {
      if (obj.key === e.target.id) {
        return { ...obj, value: e.target.value };
      }
      return obj;
    });

    setPaciente({ ...paciente, data: newValues });
  };

  useEffect(() => {}, [paciente]);

  return (
    <SidebarWrapper>
      {paciente !== null && (
        <>
          <ProfileHeader>
            <ProfilePhoto>
              {paciente.photo ? (
                <img src={paciente.photo} />
              ) : (
                <Initials>
                  {getInitials(paciente.name ? paciente.name : paciente.nome)}
                </Initials>
              )}
            </ProfilePhoto>
            <ProfileName>
              {paciente !== null && paciente.name
                ? paciente.name
                : paciente.nome}
            </ProfileName>
          </ProfileHeader>
          <ProfileBody>
            <ActionsWrapper>
              <ButtonShowHide
                fieldsVisible={fieldsVisible}
                setFieldsVisible={setFieldsVisible}
              />
              {/* <ButtonEdit
                fieldsEditable={fieldsEditable}
                setFieldsEditable={setFieldsEditable}
              /> */}
            </ActionsWrapper>
            <ProfileFields>
              {paciente.data !== undefined &&
                paciente.data.map(
                  (value, index) =>
                    value.key !== "id" &&
                    value.key !== "AccountId" && (
                      <InputProfile
                        key={index}
                        value={value}
                        changeValue={changeValue}
                        fieldsVisible={fieldsVisible}
                        fieldsEditable={fieldsEditable}
                      />
                    )
                )}
            </ProfileFields>
          </ProfileBody>
        </>
      )}
    </SidebarWrapper>
  );
};

export default SidebarPaciente;
