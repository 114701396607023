import { useEffect, useContext, useState, createContext } from "react";
import _ from "lodash";
import { matchPath } from "react-router";
import api from "../services/Api";
import {
  getStorageWithExpiry,
  setStorageWithExpiry,
} from "../shared/utils/functions";
import { useCiclos } from "./ciclos";
import { estadosCiclo } from "../assets/estadosCiclo";
import { useAuth, getJwtToken } from "./authentication";
const SessionContext = createContext();
const clone = require("clone");

export default function SessionProvider({ children }) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [timerInterval] = useState(15);
  const [session, setSession] = useState({
    sessionid: null,
    pacienteid: null,
    ciclo: null,
    state: null, // values: os valores do estado do ciclo vindo do mqtt
    currentRun: 0,
    runEnd: false,
    sessionEnd: false,
    data: null, //array of objects. Parameters by timestamp
    owner: null,
  });
  const [sessionGraph, setSessionGraph] = useState({
    sessionid: null,
    pacienteid: null,
    ciclo: null,
    state: null, // values: os valores do estado do ciclo vindo do mqtt
    currentRun: 0,
    runEnd: false,
    sessionEnd: false,
    data: null, //array of objects. Parameters by timestamp
    owner: null,
  });
  const [lastSessionGraph, setLastSessionGraph] = useState({
    acompanhamento: {
      last: 0,
      next: 0,
      needSend: false,
    },
    informacoescorporais: {
      last: 0,
      next: 0,
      needSend: false,
    },
  });
  const { setCiclos, getCiclos } = useCiclos();
  const { mqttUpdate } = useAuth();

  const compileSession = async () => {
    let sessionTemp = clone(session);
    sessionTemp.currentRun = sessionTemp.currentRun + 1;
    sessionTemp.runEnd = true;
    sessionTemp.sessionEnd = true;

    let sessionGraphTemp = clone(sessionGraph);
    sessionGraphTemp.currentRun = sessionGraphTemp.currentRun + 1;
    sessionGraphTemp.runEnd = true;
    sessionGraphTemp.sessionEnd = true;

    setSession((values) => ({
      ...values,
      currentRun: sessionTemp.currentRun,
      runEnd: true,
      sessionEnd: true,
    }));
    setSessionGraph((values) => ({
      ...values,
      currentRun: sessionGraphTemp.currentRun,
      runEnd: true,
      sessionEnd: true,
    }));
    setStorageWithExpiry("@session", sessionGraphTemp, 3600000 * 24);
    return sessionTemp.currentRun;
  };

  const endSession = async () => {
    resetSession().then((response) => {
      return "session ended";
    });
  };

  const resetSession = async () => {
    let sessionResetValues = {
      sessionid: null,
      pacienteid: null,
      ciclo: null,
      state: null, // values: os valores do estado do ciclo vindo do mqtt
      currentRun: 0,
      runEnd: false,
      sessionEnd: false,
      data: null, //array of objects. Parameters by timestamp
      owner: null,
    };
    setSession(sessionResetValues);
    setSessionGraph(sessionResetValues);
    setStorageWithExpiry("@session", sessionResetValues, 3600000 * 24);
    return true;
  };

  function getBaseUrl() {
    let hostName = window.location.hostname;
    let baseURL = "";
    if (hostName === "localhost") {
      baseURL = "https://" + hostName + ":" + window.location.port;
    } else {
      baseURL = "https://" + hostName;
    }

    return baseURL;
  }

  const resolveSession = async () => {
    const match = matchPath(
      {
        path: "/paciente/:pacienteid/sessao/:status/:sessaoid",
      },
      window.location.pathname
    );

    await getCiclos()
      .then(async (response) => {
        let tempSession = await getStorageWithExpiry("@session");
        if (tempSession && tempSession.owner) {
          console.log(tempSession, tempSession.sessionid);
          if (
            window.location.href !==
            `${getBaseUrl()}/paciente/${tempSession.pacienteid}/sessao/doing/${
              tempSession.sessionid
            }`
          ) {
            window.location.href = `${getBaseUrl()}/paciente/${
              tempSession.pacienteid
            }/sessao/doing/${tempSession.sessionid}`;
          } else {
            if (response) {
              console.log("res", response);
              let ciclosTemp = clone(response);
              ciclosTemp.forEach((value) => {
                value.cycleErgometerId ===
                parseInt(tempSession.ciclo.cycleErgometerId)
                  ? (value.selected = true)
                  : (value.selected = false);
              });
              console.log("ciclos", ciclosTemp);
              setCiclos(ciclosTemp);
            } else {
              console.log("not ciclos");
            }
            setSession(tempSession);
            setSessionGraph(tempSession);
          }
        } else {
          if (match) {
            let at = await getJwtToken();
            const config = {
              headers: { Authorization: `Bearer ${at}` },
            };

            api
              .get(`Session/GetSessionById/${match.params.sessaoid}`, config)
              .then((res2) => {
                if (res2.status === 200) {
                  let cicloSessao = {};
                  let ciclosTemp = clone(response);
                  ciclosTemp.forEach((value) => {
                    if (
                      value.cycleErgometerId ===
                      parseInt(res2.data.cycleErgometerId)
                    ) {
                      value.selected = true;
                      cicloSessao = value;
                    } else {
                      value.selected = false;
                    }
                  });
                  setCiclos(ciclosTemp);

                  let tempSession = {
                    sessionid: match.params.sessaoid,
                    pacienteid: match.params.pacienteid,
                    ciclo: cicloSessao,
                    state: null, // values: os valores do estado do ciclo vindo do mqtt
                    currentRun: null,
                    runEnd: false,
                    sessionEnd: false,
                    data: null, //array of objects. Parameters by timestamp
                    owner: null,
                  };

                  setSession(tempSession);
                  setSessionGraph(tempSession);
                } else {
                  console.log(`get session`, res2.data);
                  console.log("You are not the owner of this session");
                  return "You are not the owner of this session";
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            return "You are not the owner of this session";
          }
        }
      })
      .catch((err) => {});
  };

  function replaceToNum(key, value) {
    if (typeof value === "string") {
      const valueNumber = Number(value);
      if (!Number.isNaN(valueNumber)) {
        return valueNumber;
      }
    }
    return value;
  }

  function lowerCaseFirstLetterObject(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key];
      return accumulator;
    }, {});
  }

  const sendData = async () => {
    if (sessionGraph.pacienteid && sessionGraph.sessionid) {
      let tempAcompanhamento = null;
      let tempInformacoesCorporais = null;

      if (lastSessionGraph.acompanhamento.needSend === true) {
        tempAcompanhamento =
          sessionGraph.data.ACOMPANHAMENTO_RAW[
            sessionGraph.data.ACOMPANHAMENTO_RAW.length - 1
          ];

        setLastSessionGraph((values) => ({
          ...values,
          acompanhamento: { ...values.acompanhamento, needSend: false },
        }));
      }

      if (lastSessionGraph.informacoescorporais.needSend === true) {
        tempInformacoesCorporais =
          sessionGraph.data.INFORMACOESCORPORAIS_RAW[
            sessionGraph.data.INFORMACOESCORPORAIS_RAW.length - 1
          ];

        setLastSessionGraph((values) => ({
          ...values,
          informacoescorporais: {
            ...values.informacoescorporais,
            needSend: false,
          },
        }));
      }

      let dataSend = {
        borg: [...(sessionGraph.borg ? sessionGraph.borg : [])],
        nota: sessionGraph.nota ? sessionGraph.nota : "Sem nota",
        acompanhamento:
          tempAcompanhamento !== null ? [tempAcompanhamento] : null,
        informacoescorporais:
          tempInformacoesCorporais !== null ? [tempInformacoesCorporais] : null,
      };

      dataSend.borg = dataSend.borg.map((value, index) => {
        value.sessionId = sessionGraph.sessionid;
        return lowerCaseFirstLetterObject(value);
      });

      dataSend.acompanhamento =
        dataSend.acompanhamento !== null
          ? dataSend.acompanhamento.map((value, index) => {
              value.accountId = sessionGraph.pacienteid;
              value.sessionId = sessionGraph.sessionid;
              value.runSessionId = 0;
              return lowerCaseFirstLetterObject(value);
            })
          : null;

      dataSend.informacoescorporais =
        dataSend.informacoescorporais !== null
          ? dataSend.informacoescorporais.map((value, index) => {
              value.accountId = sessionGraph.pacienteid;
              value.sessionId = sessionGraph.sessionid;
              value.runSessionId = 0;
              return lowerCaseFirstLetterObject(value);
            })
          : null;

      dataSend.borg.forEach((value, index) => {
        let json = JSON.stringify(value);
        let newJson = JSON.parse(json, replaceToNum);
        dataSend.borg[index] = newJson;
      });

      dataSend.acompanhamento !== null &&
        dataSend.acompanhamento.forEach((value, index) => {
          let json = JSON.stringify(value);
          let newJson = JSON.parse(json, replaceToNum);
          dataSend.acompanhamento[index] = newJson;
        });

      dataSend.informacoescorporais !== null &&
        dataSend.informacoescorporais.forEach((value, index) => {
          let json = JSON.stringify(value);
          let newJson = JSON.parse(json, replaceToNum);
          dataSend.informacoescorporais[index] = newJson;
        });

      let jsonNote = JSON.stringify({
        sessionId: sessionGraph.sessionid,
        note: dataSend.nota,
      });

      dataSend.nota = JSON.parse(jsonNote, replaceToNum);

      let at = await getJwtToken();
      const config = {
        headers: { Authorization: `Bearer ${at}` },
      };

      if (dataSend.acompanhamento && dataSend.acompanhamento.length > 0) {
        api
          .post(`/MQTT/PostAcompanhamentos`, dataSend.acompanhamento, config)
          .then((res) => {
            if (res.status === 200) {
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (
        dataSend.informacoescorporais &&
        dataSend.informacoescorporais.length > 0
      ) {
        api
          .post(
            `/MQTT/PostInformacoesCorporais`,
            dataSend.informacoescorporais,
            config
          )
          .then((res) => {
            if (res.status === 200) {
              console.log("informacoes corporais postado!");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (dataSend.borg.length > 0) {
        api
          .post(`/Session/PostSessionBorg`, dataSend.borg, config)
          .then((res) => {
            if (res.status === 200) {
              console.log("borg postado!");
              setSession((values) => ({
                ...values,
                borg: null,
              }));
              setSessionGraph((values) => ({
                ...values,
                borg: null,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (dataSend.nota !== "Sem nota" && dataSend.nota !== "") {
        api
          .post(`/Session/PostSessionNote`, dataSend.nota, config)
          .then((res) => {
            if (res.status === 200) {
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("problema ao postar notas");
          });
      }
    }
  };

  useEffect(() => {
    if (sessionGraph.sessionid) {
      setStorageWithExpiry("@session", sessionGraph, 3600000 * 24);
      sendData();
    }
  }, [sessionGraph]);

  useEffect(() => {}, [session]);

  useEffect(() => {
    if (firstLoad === false) {
      let mqttTemp = _.cloneDeep(mqttUpdate);
      if (mqttTemp["ACOMPANHAMENTO"]) {
        let mqttAcompanhamentoTemp = mqttTemp["ACOMPANHAMENTO"];
        let mqttTempFilter = mqttAcompanhamentoTemp.filter(
          (item) => item.key === "FaseCorrida"
        );
        let botaoEmergencia = mqttAcompanhamentoTemp.filter(
          (item) => item.key === "BotaoEmergencia"
        );
        let estadoAtual = "";
        if (mqttTempFilter[0].value && mqttTempFilter[0].value !== "") {
          estadoAtual = estadosCiclo.filter(
            (estado) => estado.value === parseInt(mqttTempFilter[0].value)
          )[0].name;
        }

        setSession((values) => ({
          ...values,
          state: estadoAtual,
          data: {
            ...values.data,
            ACOMPANHAMENTO_RAW: [
              ...(values.data && values.data.ACOMPANHAMENTO_RAW
                ? values.data.ACOMPANHAMENTO_RAW
                : []),
              mqttTemp["ACOMPANHAMENTO_RAW"] && mqttTemp["ACOMPANHAMENTO_RAW"],
            ],
            ACOMPANHAMENTO: [...mqttTemp["ACOMPANHAMENTO"]],
          },
          botaoEmergencia: Number(botaoEmergencia),
        }));

        if (
          lastSessionGraph.acompanhamento.last === 0 ||
          new Date(mqttTemp["ACOMPANHAMENTO_RAW"].TimeStamp) >
            lastSessionGraph.acompanhamento.next
        ) {
          setSessionGraph((values) => ({
            ...values,
            state: estadoAtual,
            data: {
              ...values.data,
              ACOMPANHAMENTO_RAW: [
                ...(values.data && values.data.ACOMPANHAMENTO_RAW
                  ? values.data.ACOMPANHAMENTO_RAW
                  : []),
                mqttTemp["ACOMPANHAMENTO_RAW"] &&
                  mqttTemp["ACOMPANHAMENTO_RAW"],
              ],
              ACOMPANHAMENTO: [...mqttTemp["ACOMPANHAMENTO"]],
            },
            botaoEmergencia: Number(botaoEmergencia),
          }));

          let time = new Date(mqttTemp["ACOMPANHAMENTO_RAW"].TimeStamp);
          let timeNext = new Date(time.getTime());
          timeNext.setSeconds(timeNext.getSeconds() + timerInterval);
          setLastSessionGraph((values) => ({
            ...values,
            acompanhamento: { last: time, next: timeNext, needSend: true },
          }));
        } else {
          setSessionGraph((values) => ({
            ...values,
            state: estadoAtual,
            data: {
              ...values.data,
              ACOMPANHAMENTO: [...mqttTemp["ACOMPANHAMENTO"]],
            },
            botaoEmergencia: Number(botaoEmergencia),
          }));
        }
      } else if (mqttTemp["INFORMACOESCORPORAIS"]) {
        console.log("mqtt informacoescorporais");
        setSession((values) => ({
          ...values,
          data: {
            ...values.data,
            INFORMACOESCORPORAIS_RAW: [
              ...(values.data && values.data.INFORMACOESCORPORAIS_RAW
                ? values.data.INFORMACOESCORPORAIS_RAW
                : []),
              mqttTemp["INFORMACOESCORPORAIS_RAW"] &&
                mqttTemp["INFORMACOESCORPORAIS_RAW"],
            ],
            INFORMACOESCORPORAIS: [...mqttTemp["INFORMACOESCORPORAIS"]],
          },
        }));

        if (
          lastSessionGraph.informacoescorporais.last === 0 ||
          new Date(mqttTemp["INFORMACOESCORPORAIS_RAW"].TimeStamp) >
            lastSessionGraph.informacoescorporais.next
        ) {
          setSessionGraph((values) => ({
            ...values,
            data: {
              ...values.data,
              INFORMACOESCORPORAIS_RAW: [
                ...(values.data && values.data.INFORMACOESCORPORAIS_RAW
                  ? values.data.INFORMACOESCORPORAIS_RAW
                  : []),
                mqttTemp["INFORMACOESCORPORAIS_RAW"] &&
                  mqttTemp["INFORMACOESCORPORAIS_RAW"],
              ],
              INFORMACOESCORPORAIS: [...mqttTemp["INFORMACOESCORPORAIS"]],
            },
          }));

          let time = new Date(mqttTemp["INFORMACOESCORPORAIS_RAW"].TimeStamp);
          let timeNext = new Date(time.getTime());
          timeNext.setSeconds(timeNext.getSeconds() + timerInterval);
          setLastSessionGraph((values) => ({
            ...values,
            informacoescorporais: {
              last: time,
              next: timeNext,
              needSend: true,
            },
          }));
          console.log(
            `sessiongraph informacoescorporais atualizado! last: ${time}, next: ${timeNext}`
          );
        }
      } else if (mqttTemp["RESUMOCORRIDA_RAW"]) {
        setSession((values) => ({
          ...values,
          data: {
            ...values.data,
            RESUMOCORRIDA_RAW: {
              ...(mqttTemp["RESUMOCORRIDA_RAW"]
                ? mqttTemp["RESUMOCORRIDA_RAW"]
                : ""),
            },
          },
        }));

        setSessionGraph((values) => ({
          ...values,
          data: {
            ...values.data,
            RESUMOCORRIDA_RAW: {
              ...(mqttTemp["RESUMOCORRIDA_RAW"]
                ? mqttTemp["RESUMOCORRIDA_RAW"]
                : ""),
            },
          },
        }));
      } else if (mqttTemp["ESTATISTICASESSAO_RAW"]) {
        setSession((values) => ({
          ...values,
          data: {
            ...values.data,
            ESTATISTICASESSAO_RAW: {
              ...(mqttTemp["ESTATISTICASESSAO_RAW"]
                ? mqttTemp["ESTATISTICASESSAO_RAW"]
                : ""),
            },
          },
        }));

        setSessionGraph((values) => ({
          ...values,
          data: {
            ...values.data,
            ESTATISTICASESSAO_RAW: {
              ...(mqttTemp["ESTATISTICASESSAO_RAW"]
                ? mqttTemp["ESTATISTICASESSAO_RAW"]
                : ""),
            },
          },
        }));
      }
    } else {
      setFirstLoad(false);
    }
  }, [mqttUpdate]);

  useEffect(() => {
    resolveSession();
  }, []);

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        sessionGraph,
        setSessionGraph,
        compileSession,
        endSession,
        resetSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);
  if (!context)
    throw new Error("useSession must be used within a SessionProvider");

  const {
    session,
    setSession,
    sessionGraph,
    setSessionGraph,
    compileSession,
    endSession,
    resetSession,
  } = context;

  return {
    session,
    setSession,
    sessionGraph,
    setSessionGraph,
    compileSession,
    endSession,
    resetSession,
  };
}
