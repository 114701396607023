import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { getJwtToken } from "../../../../contexts/authentication";
import { useAuth } from "../../../../contexts/authentication";
import { usePaciente } from "../../../../contexts/paciente";
import { usePreload } from "../../../../contexts/preloading";
import { useUser } from "../../../../contexts/user";
import api from "../../../../services/Api";
import Header from "../../Header";
import SidebarPaciente from "../../General/SidebarPaciente";
import MeusPacientes from "../../General/MeusPacientes";
import MainSchedules from "../../General/MainSchedules";
import {
  ContainerFullwidthLeft,
  MainContainer,
} from "../../../../ui/Containers";
import { SectionMenu } from "../../../../ui/NavigationComponents";
import { useCiclos } from "../../../../contexts/ciclos";

import {
  sessaoPendingDoing,
  sessaoCanceled,
  sessaoFailed,
  sessaoDone,
} from "../../../../assets/mock.js";

const PacienteInPaciente = ({}) => {
  const { preloading, setPreloading } = usePreload();
  const { user, setUser } = useUser();
  const { paciente, setPaciente } = usePaciente();
  const { logout } = useAuth();
  const [pacienteIndex, setPacienteIndex] = useState(null);

  const clone = require("rfdc")();

  const loadPaciente = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Schedule/GetScheduleByAccountId/${user.id}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          let tempUser = clone(user);
          let data = clone(res.data);
          data.map(
            (value) =>
              (value.scheduleAt = new Date(value.scheduleAt).toLocaleString())
          );
          data.map((schedule, index) => {
            if (schedule.status === "Pending" || schedule.status === "Doing") {
              schedule.sessao = sessaoPendingDoing;
            } else if (schedule.status === "Canceled") {
              schedule.sessao = sessaoCanceled;
            } else if (schedule.status === "Failed") {
              schedule.sessao = sessaoFailed;
            } else if (schedule.status === "Done") {
              schedule.sessao = sessaoDone;
            }
          });
          // console.log(data);
          tempUser.agenda = data;

          setPaciente(tempUser);
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  useEffect(() => {
    if (user) {
      loadPaciente();
    }
  }, [user]);

  useEffect(() => {}, [paciente]);

  return (
    paciente && (
      <React.Fragment>
        <ContainerFullwidthLeft>
          <SidebarPaciente paciente={paciente} setPaciente={setPaciente} />
          <MainContainer>
            <Outlet />
          </MainContainer>
        </ContainerFullwidthLeft>
        <SectionMenu />
      </React.Fragment>
    )
  );
};

export default PacienteInPaciente;
