import React, { useEffect, useState } from "react";
import MeusPacientes from "../../General/MeusPacientes";
import MainSchedules from "../../General/MainSchedules";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { useAuth } from "../../../../contexts/authentication";
import {
  TitlesAgenda,
  InfosGeraisWrapper,
  InfosGeraisThumbWrapper,
  InfosGeraisThumbWrapperTitle,
  InfosGeraisThumbWrapperValue,
} from "./styled";
import { getJwtToken } from "../../../../contexts/authentication";
import { usePreload } from "../../../../contexts/preloading";
import { useAlert } from "../../../../contexts/alert";
import api from "../../../../services/Api";

const MedicoHome = ({ pacientes, randomSortPacientes }) => {
  const { bottomMenuSections, setBottomMenuSections } = useBottomMenuSections();
  const { user, logout } = useAuth();
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const [estatisticas, setEstatisticas] = useState(null);

  const getStatistics = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .get(
        `/Session/GetStatisticsByDoctorAccountId/${parseInt(user.id)}`,
        config
      )
      .then((res) => {
        if (res.status === 200) {
          setEstatisticas(res.data);
          setPreloading(false);
        } else {
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao carregar estatísticas do paciente!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  useEffect(() => {
    getStatistics();
    setBottomMenuSections("medico");
    console.log(user);
  }, []);

  useEffect(() => {
    console.log(estatisticas);
  }, [estatisticas]);

  return (
    <>
      <TitlesAgenda>Informações Gerais</TitlesAgenda>
      <InfosGeraisWrapper>
        {estatisticas && (
          <>
            <InfosGeraisThumbWrapper>
              <InfosGeraisThumbWrapperTitle>
                Quantidade Pacientes
              </InfosGeraisThumbWrapperTitle>
              <InfosGeraisThumbWrapperValue>
                {estatisticas.numberOfPatients}
              </InfosGeraisThumbWrapperValue>
            </InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapper>
              <InfosGeraisThumbWrapperTitle>
                Pacientes Sessões Feitas
              </InfosGeraisThumbWrapperTitle>
              <InfosGeraisThumbWrapperValue>
                {estatisticas.countSessionsDone}
                <br />
                <span>({estatisticas.countSessionsDone}%)</span>
              </InfosGeraisThumbWrapperValue>
            </InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapper>
              <InfosGeraisThumbWrapperTitle>
                Pacientes Sessões em Andamento
              </InfosGeraisThumbWrapperTitle>
              <InfosGeraisThumbWrapperValue>
                {estatisticas.countSessionsDoing}
                <br />
                <span>({estatisticas.percentSessionsDoing}%)</span>
              </InfosGeraisThumbWrapperValue>
            </InfosGeraisThumbWrapper>
          </>
        )}
      </InfosGeraisWrapper>
      <MeusPacientes pacientes={pacientes} />
    </>
  );
};

export default MedicoHome;
