import React, { useState, useEffect } from "react";
import { usePaciente } from "../../../../../contexts/paciente";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useTheme } from "styled-components";
import { Button } from "../../../../../ui/InputControls";
import { BlockSidebarItem } from "../../../../../ui/General";
import {
  TitleSecao,
  Wrapper,
  Block,
  BlockSidebar,
  BlockGraphic,
  ButtonToogleSize,
  BlockTable,
} from "./styled";
import { Heart } from "@styled-icons/evaicons-solid";
import { Timer } from "@styled-icons/ionicons-solid";
import { HardwareChip } from "@styled-icons/ionicons-outline";
import { Power } from "@styled-icons/icomoon";

Chart.register(zoomPlugin); // REGISTER PLUGIN
Chart.register({
  id: "customSpacingLegend",
  beforeInit(chart) {
    const originalFit = chart.legend.fit;

    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 30;
    };
  },
});

function generateRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const GraficoSessao = ({ pacienteid, sessaoid, escala }) => {
  const { paciente } = usePaciente();
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const theme = useTheme();
  const [classBlockSidebar, setClassBlockSidebar] = useState("");
  const [itemHidden, setItemHidden] = useState(null);

  Chart.defaults.font.size = 15;
  Chart.defaults.color = "#ffffff";

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          padding: 20,
        },
        onClick: (e, legendItem) => {
          toogleShowItem(legendItem.datasetIndex);
        },
      },
      title: {
        display: false,
        text: "Gráfico Sessão",
      },
    },
    interaction: {},
    layout: {
      padding: 5,
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: theme.colors.grey,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: theme.colors.grey,
        },
      },
    },
  };

  const [data, setData] = useState(null);

  const toogleSize = () => {
    if (classBlockSidebar === "") {
      setClassBlockSidebar("on");
    } else {
      setClassBlockSidebar("");
    }
  };

  const toogleShowAll = () => {
    let tempItems = [...itemHidden];
    let finalItems;
    let items = tempItems.filter((value) => value === true);
    if (items.length > 0) {
      finalItems = tempItems.map((value, index) => {
        return false;
      });
    } else {
      finalItems = tempItems.map((value, index) => {
        return true;
      });
    }
    console.log(finalItems);
    setItemHidden(finalItems);
  };

  const toogleShowItem = (id) => {
    let tempItems = [...itemHidden];
    if (tempItems[id]) {
      tempItems[id] = false;
    } else {
      tempItems[id] = true;
    }
    setItemHidden(tempItems);
  };

  const sidebarItemClick = () => {};

  useEffect(() => {
    if (sessaoAtual) {
      let hiddenTemp = sessaoAtual[0].sessao.sessionSummary
        .filter((value) => {
          return value.graphic !== null;
        })
        .map((value, index) => {
          return value.graphicHidden;
        });
      setItemHidden(hiddenTemp);
    }
  }, [sessaoAtual]);

  useEffect(() => {
    itemHidden !== null &&
      setData({
        labels: sessaoAtual[0].sessao.sessionSummary[1].graphic.map(
          (value, index) => value.time
        ),
        datasets: sessaoAtual[0].sessao.sessionSummary
          .filter((value) => {
            return value.graphic !== null;
          })
          .map((value, index) => {
            return {
              label: value.name,
              data: value.graphic.map((value2, index) => value2.value),
              hitRadius: 10,
              borderWidth: 2,
              borderColor: value.borderColor,
              backgroundColor: value.borderColor,
              pointRadius: 2,
              pointHoverRadius: 3,
              tension: 0.3,
              hidden: itemHidden[index],
            };
          }),
      });
  }, [itemHidden]);

  useEffect(() => {
    if (paciente !== null && paciente.agenda !== undefined) {
      let filtered = paciente.agenda.filter(
        (value) => value.sessionId === parseInt(sessaoid)
      );
      setSessaoAtual(filtered);
    }
  }, [paciente]);

  return sessaoAtual ? (
    <>
      <TitleSecao>
        Gráfico Sessão{" "}
        <Button
          title={"Mostrar / Esconder Todos"}
          color={"primary"}
          width={"250px"}
          margin={"0px 30px"}
          onClick={toogleShowAll}
        />
      </TitleSecao>
      <Wrapper>
        <Block>
          <BlockSidebar className={classBlockSidebar}>
            {sessaoAtual[0].sessao.sessionSummary
              .filter((value) => {
                return value.graphic !== null;
              })
              .map((value, index) => (
                <BlockSidebarItem
                  key={index}
                  item={value}
                  clickFunction={sidebarItemClick}
                />
              ))}
          </BlockSidebar>
          <BlockGraphic>
            <ButtonToogleSize
              onClick={toogleSize}
              className={classBlockSidebar}
            />
            {data !== null && (
              <Line
                options={options}
                data={data}
                // {...props}
              />
            )}
          </BlockGraphic>
        </Block>
        <Block>
          <BlockTable>
            {sessaoAtual[0].sessao.sessionSummary
              .filter((value) => {
                return value.graphic !== null;
              })
              .map(
                (value, index) =>
                  index === 0 && (
                    <div key={index} className="table-row">
                      <div className="table-row-title titulo">Parâmetros</div>
                      <div className="table-row-values">
                        {value.graphic.map(
                          (value2, index2) =>
                            index2 % 8 === 0 && (
                              <div
                                key={index2}
                                className="table-row-value titulo"
                              >
                                {value2.time}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  )
              )}
            {sessaoAtual[0].sessao.sessionSummary
              .filter((value) => {
                return value.graphic !== null;
              })
              .map((value, index) => (
                <div key={index} className="table-row">
                  <div className="table-row-title">
                    <div className="table-row-icon">
                      {value.category === "corpo" ? (
                        <Heart color={theme.colors.pink} />
                      ) : value.category === "sessao" ? (
                        <Timer color={theme.colors.white} />
                      ) : value.category === "hardware" ? (
                        <HardwareChip color={theme.colors.blue} />
                      ) : value.category === "estimulo" ? (
                        <Power color={theme.colors.green} />
                      ) : (
                        <></>
                      )}
                    </div>
                    {value.name}{" "}
                    <span style={{ color: theme.colors.orange }}>
                      ({value.unit})
                    </span>
                  </div>
                  <div className="table-row-values">
                    {value.graphic.map(
                      (value2, index2) =>
                        index2 % 8 === 0 && (
                          <div key={index2} className="table-row-value">
                            <span style={{ color: value.borderColor }}>
                              {value2.value}
                            </span>
                          </div>
                        )
                    )}
                  </div>
                </div>
              ))}
          </BlockTable>
        </Block>
      </Wrapper>
    </>
  ) : (
    <></>
  );
};

export default GraficoSessao;
