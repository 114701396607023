import React, { useEffect, useState } from "react";
import MeusCiclos from "../../General/MeusCiclos";
import MainSchedulesManutencao from "../../General/MainSchedulesManutencao";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { useAuth } from "../../../../contexts/authentication";
import { useCiclos } from "../../../../contexts/ciclos";

const ManutencaoHome = () => {
  const { ciclos, getCiclos, ciclosFiltered, getCiclosFiltered } = useCiclos();
  const { bottomMenuSections, setBottomMenuSections } = useBottomMenuSections();

  const { user, logout } = useAuth();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    getCiclos();
    setBottomMenuSections("manutencao");
  }, []);

  return (
    <>
      <MeusCiclos ciclos={ciclos} />
      <MainSchedulesManutencao ciclos={ciclos} date={date} setDate={setDate} />
    </>
  );
};

export default ManutencaoHome;
