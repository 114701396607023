import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { GridThumbSchedules } from "../../../../ui/General";
import { useNavigate, useParams } from "react-router-dom";
import { getJwtToken } from "../../../../contexts/authentication";
import { usePreload } from "../../../../contexts/preloading";
import { useAlert } from "../../../../contexts/alert";
import api from "../../../../services/Api";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { usePaciente } from "../../../../contexts/paciente";
import { useUser } from "../../../../contexts/user";
import { useCiclos } from "../../../../contexts/ciclos";

import {
  TitlesAgenda,
  InfosGeraisWrapper,
  InfosGeraisThumbWrapper,
  InfosGeraisThumbWrapperTitle,
  InfosGeraisThumbWrapperValue,
} from "./styled";
import { graficoEstatisticas } from "../../../../assets/graficos";
import GraficoSessao from "./GraficoSessao";
import { useSession } from "../../../../contexts/session";

const PacienteAgenda = () => {
  const { user } = useUser();
  const { paciente } = usePaciente();
  const { session } = useSession();
  const { pacienteid } = useParams();
  const { bottomMenuSections, setBottomMenuSections } = useBottomMenuSections();
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const navigate = useNavigate();
  const [sortedAgenda, setSortedAgenda] = useState(null);
  const [estatisticas, setEstatisticas] = useState(null);
  const clone = require("rfdc")();

  const clickThumb = (id, status) => {
    if (user && user.currentRole === "Terapeuta") {
      // let statusTemp =
      //   status === "doing"
      //     ? session.sessionid === null || session.sessionid === undefined
      //       ? "acompanhar"
      //       : status
      //     : status;
      navigate(`sessao/${status}/${id}`);
    }
    if (user && user.currentRole === "Paciente") {
      let statusTemp =
        // status === "doing"
        //   ? session.sessionid === null || session.sessionid === undefined
        //     ? "acompanhar"
        //     : status
        //   : status;
        navigate(`sessao/${status}/${id}`);
    } else if (user && user.currentRole === "Medico") {
      if (status !== "pending" && status !== "doing") {
        // let statusTemp =
        //   status === "doing"
        //     ? session.sessionid === null || session.sessionid === undefined
        //       ? "acompanhar"
        //       : status
        //     : status;
        navigate(`sessao/${status}/${id}`);
      }
    }
  };

  const getStatistics = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .get(
        `/Session/GetStatisticsByAccountId/${
          pacienteid ? pacienteid : paciente.id
        }`,
        config
      )
      .then((res) => {
        if (res.status === 200) {
          let dados = res.data;
          dados.estatisticaSessaos.map((sessao, index) => {
            sessao.tempCorpMax = sessao.tempCorpMax / 10;
            sessao.tempCorpMin = sessao.tempCorpMin / 10;
          });
          setEstatisticas(dados);
          setPreloading(false);
        } else {
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao carregar estatísticas do paciente!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  useEffect(() => {
    // console.log(paciente);
    if (paciente.agenda) {
      let tempAgenda = clone(paciente.agenda);
      if (tempAgenda !== null) {
        tempAgenda.sort((a, b) => {
          const newA = a.scheduleAt.replaceAll("/", "-");
          const newB = b.scheduleAt.replaceAll("/", "-");
          return newB - newA;
        });
        setSortedAgenda(tempAgenda.reverse());
      }
    }
  }, [paciente]);

  useEffect(() => {
    if (user && user.currentRole === "Terapeuta") {
      setBottomMenuSections("terapeuta");
    } else if (user && user.currentRole === "Paciente") {
      setBottomMenuSections("paciente");
    }
  }, [user]);

  useEffect(() => {
    // console.log(sortedAgenda);
  }, [sortedAgenda]);

  useEffect(() => {
    // console.log(estatisticas);
  }, [estatisticas]);

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <>
      <TitlesAgenda>Informações Gerais</TitlesAgenda>
      <InfosGeraisWrapper>
        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Sessões Planejadas
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.sessionsPlanned}
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}
        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Sessões Canceladas
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.countSessionsCanceled}
              <br />
              {/* <span>({estatisticas.percentSessionsCanceled}%)</span> */}
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}
        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Sessões Realizadas
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.countSessionsDone +
                estatisticas.countSessionsFailed}
              <br />
              <span>
                (
                {estatisticas.percentSessionsDone +
                  estatisticas.percentSessionsFailed}
                %)
              </span>
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}
        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Sessões não Realizadas
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.countSessionsPending}
              <br />
              <span>({estatisticas.percentSessionsPending}%)</span>
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}
        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Sessões em Andamento
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.countSessionsDoing}
              <br />
              <span>({estatisticas.percentSessionsDoing}%)</span>
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}
        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Sessões Concluídas (Êxito)
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.countSessionsDone}
              <br />
              <span>({estatisticas.percentSessionsDone}%)</span>
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}
        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Sessões Concluídas (Falha)
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.countSessionsFailed}
              <br />
              <span>
                ({estatisticas.percentSessionsFailed}
                %)
              </span>
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}

        {estatisticas && (
          <InfosGeraisThumbWrapper>
            <InfosGeraisThumbWrapperTitle>
              Corridas Realizadas
            </InfosGeraisThumbWrapperTitle>
            <InfosGeraisThumbWrapperValue>
              {estatisticas.countRunsDoneSuccess +
                estatisticas.countRunsDoneFail}
              <br />
              <span>
                (
                {estatisticas.percentRunsDoneSuccess +
                  estatisticas.percentRunsDoneFail}
                %)
              </span>
            </InfosGeraisThumbWrapperValue>
          </InfosGeraisThumbWrapper>
        )}
        {estatisticas !== null && estatisticas.estatisticaSessaos !== null && (
          <GraficoSessao
            pacienteid={pacienteid}
            estatisticas={estatisticas.estatisticaSessaos}
            titleGrafico={`Estatísticas Sessões`}
            graficoMask={graficoEstatisticas}
          />
        )}
      </InfosGeraisWrapper>

      <TitlesAgenda>Agendamentos</TitlesAgenda>
      {sortedAgenda !== undefined &&
        sortedAgenda !== null &&
        sortedAgenda.map((item, index) => (
          <GridThumbSchedules
            key={index}
            item={item}
            clickFunction={clickThumb}
          />
        ))}
    </>
  );
};

export default PacienteAgenda;
