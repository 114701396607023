import styled from "styled-components";

export const AccountWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 100px;
`;

export const GridThumbWrapper = styled.div`
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const SubtitleSessaoPendente = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 18px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 20px 0px 0px 20px;
  box-sizing: border-box;
`;

export const ToogleWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 250px;
  height: 250px;
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.orange};
  background-color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  white-space: pre-wrap;

  &:before {
    content: "Ativar / Desativar \\A Usuário";
    white-space: pre-wrap;
  }
`;
