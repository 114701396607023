import React, { useState, useEffect } from "react";
import NotasContainer from "./NotasContainer";
import { escala } from "../../../../assets/mock";

const Notas = ({ pacienteid, sessaoid }) => {
  return (
    <NotasContainer
      pacienteid={pacienteid}
      sessaoid={sessaoid}
      escala={escala}
    />
  );
};

export default Notas;
