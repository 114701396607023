export const theme = {
  colors: {
    greyDarker: "#0f0f0f",
    greyDark: "#1C1C1C",
    grey: "#212121",
    greySemiLight: "#525252",
    greyLight: "#97979C",
    black: "#000000",
    white: "#ffffff",
    orange: "#FA6D0F",
    purpleDark: "#3F0066",
    purple: "#AF00FF",
    red: "#FB000F",
    green: "#A9FA0F",
    pink: "#FB0F5B",
    blue: "#0F8EFA",
    shadow: "rgba(0,0,0, 0.7)",
    orangeShadow: "#FA6D0F54",
  },
  fonts: {
    primary: "Montserrat, sans-serif",
    secondary: "Noto Sans, sans-serif",
  },
};
