import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import {
  ListSectionsWrapper,
  ListSectionsTitle,
  ListSectionsList,
  ListSectionsListItem,
  ListSectionsCiclosListItem,
  ItemTime,
  ItemStatus,
  ItemDivisor,
  ThumbWrapper,
  ThumbWrapperUserAccount,
  AvatarThumb,
  AvatarThumbUsers,
  AvatarThumbCiclos,
  AvatarThumbUserAccount,
  AvatarThumbCiclo,
  Initials,
  TitleThumb,
  SubtitlesThumb,
  SubtitleThumb,
  TitleThumbSchedules,
  DataThumbSchedules,
  ThumbWrapperDados,
  ThumbWrapperDadosTitle,
  ThumbWrapperDadosTitleDone,
  ThumbWrapperDadosIcon,
  ThumbWrapperDadosIconMaxMin,
  ThumbWrapperDadosIconTrash,
  ThumbWrapperDadosIconGraph,
  ThumbWrapperDadosValue,
  ThumbWrapperConfiguracoesHome,
  ThumbWrapperConfiguracoesHomeTitle,
  ThumbWrapperConfiguracoesHomeImage,
  ConfigItemTextWrapper,
  ConfigItemContentWrapper,
  ConfigItemTextTitle,
  ConfigItemTextValue,
  ConfigItemTextValuesWrapper,
  ConfigItemTextValueMinMax,
  ConfigItemTextUnit,
  ConfigItemTextValueStatic,
  ConfigItemTextValuesSelectorWrapper,
  ConfigItemButtonsWrapper,
  ConfigItemButton,
  ConfigItemSelector,
  BlockSidebarItemWrapper,
  BlockSidebarItemIcon,
  BlockSidebarItemTitle,
  BlockSidebarItemValue,
  LightboxWrapper,
} from "./styled";
import { useTheme } from "styled-components";
import cicloIcon from "../../assets/images/ciclo-icon.svg";
import cadeiraIcon from "../../assets/images/cadeira-icon.svg";
import { Heart } from "@styled-icons/evaicons-solid";
import { Timer } from "@styled-icons/ionicons-solid";
import { HardwareChip } from "@styled-icons/ionicons-outline";
import { Power } from "@styled-icons/icomoon";
import { ArrowMinimize } from "@styled-icons/fluentui-system-filled";
import { ArrowMaximize } from "@styled-icons/fluentui-system-filled";
import { Trash } from "@styled-icons/ionicons-outline";
import { GraphUp } from "@styled-icons/bootstrap/GraphUp";
import { Plus } from "@styled-icons/icomoon";
import { useLightbox } from "../../contexts/lightbox";

import LightboxAddUser from "../../shared/Components/Admin/LighboxAddUser";
import LightboxAddCiclo from "../../shared/Components/Admin/LighboxAddCiclo";
import LightboxAddPaciente from "../../shared/Components/Auxiliar/LighboxAddPaciente";
import { ToogleInput, ToogleInputRoles } from "../InputControls/styled";
import { ToogleRoles } from "../InputControls";
import { useAlert } from "../../contexts/alert";

export const getInitials = (string) => {
  if (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
};

function getBaseUrl() {
  // Nome do host
  let hostName = window.location.hostname;
  let baseURL = "";
  if (hostName === "localhost") {
    baseURL = "https://" + hostName + ":" + window.location.port;
  } else {
    baseURL = "https://" + hostName;
  }

  return baseURL;
}

export const ListSections = ({ title, list, width, height }) => {
  const [orderedList, setOrderedList] = useState(null);
  useEffect(() => {
    console.log(list);
    let listTemp = _.cloneDeep(list);
    listTemp.sort((a, b) => new Date(a.scheduleAt) - new Date(b.scheduleAt));
    setOrderedList(listTemp);
  }, [list]);
  return (
    <ListSectionsWrapper width={width} height={height}>
      <ListSectionsTitle>{title}</ListSectionsTitle>
      <ListSectionsList>
        {orderedList &&
          orderedList.map((value, index) => (
            <ListSectionsListItem
              key={index}
              className={value.status}
              onClick={() => {
                window.location.href = `${getBaseUrl()}/paciente/${
                  value.accountId
                }/sessao/${
                  value.status !== "Failed"
                    ? value.status.toLowerCase()
                    : "done"
                }/${value.sessionId}`;
              }}
            >
              {value.name}
              {value.status === "Pending"
                ? " - [pendente]"
                : value.status === "Doing"
                ? " - [em andamento]"
                : value.status === "Done"
                ? " - [concluída]"
                : value.status === "Failed"
                ? " - [abortada]"
                : " - [cancelada]"}
              <ItemTime>
                {new Date(value.scheduleAt).toLocaleString("pt-BR")}
              </ItemTime>
            </ListSectionsListItem>
          ))}
      </ListSectionsList>
    </ListSectionsWrapper>
  );
};

export const ListCiclos = ({ title, list, width, height }) => {
  useEffect(() => {}, []);
  return (
    <ListSectionsWrapper width={width} height={height}>
      <ListSectionsTitle>{title}</ListSectionsTitle>
      <ListSectionsList>
        {list &&
          list.map((value, index) => (
            <ListSectionsListItem key={index}>
              {value.name} - {value.id}
              <ItemTime>{value.horario}</ItemTime>
            </ListSectionsListItem>
          ))}
      </ListSectionsList>
    </ListSectionsWrapper>
  );
};

export const ListCilosLastActivities = ({
  title,
  cycleErgometerLogs,
  ciclos,
  width,
  height,
  clickFunction,
}) => {
  useEffect(() => {
    console.log(cycleErgometerLogs, ciclos);
  }, [cycleErgometerLogs]);
  return (
    <ListSectionsWrapper width={width} height={height}>
      <ListSectionsTitle>{title}</ListSectionsTitle>
      <ListSectionsList>
        {cycleErgometerLogs &&
          cycleErgometerLogs.map((value, index) => (
            <ListSectionsCiclosListItem
              key={index}
              onClick={(e) => clickFunction(value.cycleErgometerId)}
            >
              {ciclos.map((ciclo, index2) => {
                if (ciclo.cycleErgometerId === value.cycleErgometerId) {
                  return ciclo.name;
                }
              })}
              <ItemDivisor>|</ItemDivisor>
              <ItemStatus>
                {new Date(value.messageDate).getHours() +
                  ":" +
                  (new Date(value.messageDate).getMinutes() < 10 ? "0" : "") +
                  new Date(value.messageDate).getMinutes() +
                  ":" +
                  new Date(value.messageDate).getSeconds()}
              </ItemStatus>
              <ItemDivisor>|</ItemDivisor>
              <ItemStatus>{value.message}</ItemStatus>
            </ListSectionsCiclosListItem>
          ))}
      </ListSectionsList>
    </ListSectionsWrapper>
  );
};

export const GridThumb = ({ item, clickFunction }) => {
  return (
    <ThumbWrapper onClick={() => clickFunction(item.accountId)}>
      <AvatarThumb>
        {item.photo ? (
          <img src={item.photo} />
        ) : (
          <Initials>{getInitials(item.nome)}</Initials>
        )}
      </AvatarThumb>
      <TitleThumb>{item.nome}</TitleThumb>
    </ThumbWrapper>
  );
};

export const GridThumbUsers = ({ item, clickFunction }) => {
  return (
    item.roles && (
      <ThumbWrapper onClick={() => clickFunction(item.accountId)}>
        <AvatarThumbUsers>
          {item.photo ? (
            <img src={item.photo} />
          ) : (
            <Initials>{getInitials(item.nome)}</Initials>
          )}
        </AvatarThumbUsers>
        <TitleThumb>{item.nome}</TitleThumb>
        <SubtitlesThumb>
          {item.roles.map((value, index) => (
            <SubtitleThumb key={index}>{value.substring(0, 2)}</SubtitleThumb>
          ))}
        </SubtitlesThumb>
      </ThumbWrapper>
    )
  );
};

export const GridThumbPacientes = ({ item, clickFunction }) => {
  return (
    item.roles && (
      <ThumbWrapper onClick={() => clickFunction(item.accountId)}>
        <AvatarThumbUsers>
          {item.photo ? (
            <img src={item.photo} />
          ) : (
            <Initials>{getInitials(item.nome)}</Initials>
          )}
        </AvatarThumbUsers>
        <TitleThumb>{item.nome}</TitleThumb>
        <SubtitlesThumb>
          {item.roles.map((value, index) => (
            <SubtitleThumb key={index}>{value.substring(0, 2)}</SubtitleThumb>
          ))}
        </SubtitlesThumb>
      </ThumbWrapper>
    )
  );
};

export const GridThumbCiclos = ({ item, clickFunction }) => {
  return (
    item && (
      <ThumbWrapper onClick={() => clickFunction(item.cycleErgometerId)}>
        <AvatarThumbCiclos>
          <Initials>
            {item.name.substring(0, 1)} {item.name.split(" ")[1]}
          </Initials>
        </AvatarThumbCiclos>
        <TitleThumb>{item.name}</TitleThumb>
        <SubtitlesThumb>
          <TitleThumb>{item.macAddress}</TitleThumb>
        </SubtitlesThumb>
      </ThumbWrapper>
    )
  );
};

export const GridThumbUserAccount = ({ item, clickFunction }) => {
  return (
    <ThumbWrapperUserAccount
      onClick={() =>
        typeof clickFunction === "function" && clickFunction(item.id)
      }
    >
      <AvatarThumbUserAccount>
        {item.photo ? (
          <img src={item.photo} />
        ) : (
          <Initials>{getInitials(item.name)}</Initials>
        )}
      </AvatarThumbUserAccount>
    </ThumbWrapperUserAccount>
  );
};

export const GridThumbUserCiclo = ({ item, clickFunction }) => {
  return (
    <ThumbWrapperUserAccount onClick={() => clickFunction(item.id)}>
      <AvatarThumbCiclo>
        <Initials>
          {item.name.substring(0, 1)} {item.name.split(" ")[1]}
        </Initials>
      </AvatarThumbCiclo>
    </ThumbWrapperUserAccount>
  );
};

export const GridThumbSchedules = ({ item, clickFunction }) => {
  // console.log(item);
  function FormataStringData(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];

    return ano + "-" + mes + "-" + dia;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  return (
    <ThumbWrapper
      className={`${
        item.status === "Canceled"
          ? "past canceled"
          : item.status === "Failed"
          ? "past failed"
          : item.status === "Pending"
          ? "pending"
          : item.status === "Done"
          ? "past done"
          : item.status === "Doing"
          ? "doing"
          : ""
      }`}
      onClick={() =>
        clickFunction(
          item.sessionId,
          item.status === "Canceled"
            ? "canceled"
            : item.status === "Failed"
            ? "done"
            : item.status === "Pending"
            ? "pending"
            : item.status === "Done"
            ? "done"
            : item.status === "Doing"
            ? "doing"
            : ""
        )
      }
    >
      <TitleThumbSchedules
        className={`${
          item.status === "Canceled"
            ? "past canceled"
            : item.status === "Failed"
            ? "past failed"
            : item.status === "Pending"
            ? "pending"
            : item.status === "Done"
            ? "past done"
            : item.status === "Doing"
            ? "doing"
            : ""
        }`}
      >
        Sessão Id: {item.sessionId}
      </TitleThumbSchedules>
      <DataThumbSchedules
        className={`${
          item.status === "Canceled"
            ? "past canceled"
            : item.status === "Failed"
            ? "past failed"
            : item.status === "Pending"
            ? "pending"
            : item.status === "Done"
            ? "past done"
            : item.status === "Doing"
            ? "doing"
            : ""
        }`}
      >
        {item.scheduleAt.replace(" ", "\n")}
      </DataThumbSchedules>
    </ThumbWrapper>
  );
};

export const GridThumbDados = ({
  item,
  toogleSize,
  dataTransfer,
  setDataTransfer,
  dadosConfig,
  setConfig,
  showGraph,
}) => {
  const [tempoTratado, setTempoTratado] = useState("00:00:00");
  const theme = useTheme();
  const dragStart = (e) => {
    setDataTransfer(e.target.id);
  };

  const dragEnd = (e) => {
    e.preventDefault();
  };

  const swapItem = (e, box) => {
    let configTemp = [...dadosConfig];
    let targetId = e.target.value;
    let boxId = box.id;
    let boxSize = box.size;
    configTemp.map((value, index) => {
      if (value.id === targetId) {
        value.show = true;
        value.id = boxId;
        value.size = boxSize;
      } else if (value.id === boxId) {
        value.show = false;
        value.id = targetId;
      }
    });
    configTemp.sort((a, b) => {
      return a.id - b.id;
    });
    configTemp.map((value, index) => {
      if (value.type === "value") {
        value.id = index.toString();
      }
    });
    setConfig(configTemp);
  };

  const deleteItem = (id) => {
    let configTemp = [...dadosConfig];
    configTemp.map((value, index) => {
      if (value.id === id) {
        value.show = false;
      }
    });
    configTemp.sort((a, b) => {
      return a.id - b.id;
    });
    setConfig(configTemp);
  };

  useEffect(() => {}, [dadosConfig]);

  useEffect(() => {
    if (item.key === "Tempo") {
      let horaTratatoTemp = parseInt(item.value / 3600).toString();
      if (horaTratatoTemp < 10) {
        horaTratatoTemp = "0" + horaTratatoTemp;
      }
      let minutoTratatoTemp = parseInt(item.value / 60).toString();
      if (minutoTratatoTemp < 10) {
        minutoTratatoTemp = "0" + minutoTratatoTemp;
      }
      let segundoTratatoTemp = parseInt(item.value % 60).toString();
      if (segundoTratatoTemp < 10) {
        segundoTratatoTemp = "0" + segundoTratatoTemp;
      }
      setTempoTratado(
        horaTratatoTemp + ":" + minutoTratatoTemp + ":" + segundoTratatoTemp
      );
    }
  }, [item.value]);
  return (
    <ThumbWrapperDados
      // onClick={() => clickFunction(item.id)}
      id={item.id}
      className={item.size}
      draggable
      onDragStart={dragStart}
      onDragEnd={dragEnd}
    >
      <ThumbWrapperDadosTitle
        index={item.id}
        onChange={(e) => {
          swapItem(e, item);
          e.target.blur();
          e.target.parentElement.setAttribute("draggable", true);
        }}
        value={item.id}
        onBlur={(e) => {
          e.target.parentElement.setAttribute("draggable", true);
        }}
        onAbort={(e) => {
          e.target.parentElement.setAttribute("draggable", true);
        }}
        onClick={(e) => {
          e.target.parentElement.setAttribute("draggable", false);
        }}
      >
        <option value={item.id}>
          {item.name} {item.unit !== "" ? `(${item.unit})` : ""}
        </option>
        {dadosConfig.map(
          (value, index) =>
            !value.show &&
            value.type === "value" && (
              <option key={index * 3000} value={value.id}>
                {value.name} {value.unit !== "" ? `(${value.unit})` : ""}
              </option>
            )
        )}
      </ThumbWrapperDadosTitle>
      <ThumbWrapperDadosIcon>
        {item.category === "corpo" ? (
          <Heart color={theme.colors.pink} />
        ) : item.category === "sessao" ? (
          <Timer color={theme.colors.white} />
        ) : item.category === "hardware" ? (
          <HardwareChip color={theme.colors.blue} />
        ) : item.category === "estimulo" ? (
          <Power color={theme.colors.green} />
        ) : (
          <></>
        )}
      </ThumbWrapperDadosIcon>
      <ThumbWrapperDadosValue>
        {item.key === "TimeStamp"
          ? new Date(item.value).toLocaleString("pt-BR")
          : item.key === "TempCorpMax" || item.key === "TempCorpMin"
          ? item.value / 10
          : item.key === "Tempo"
          ? tempoTratado
          : item.value}
      </ThumbWrapperDadosValue>
      <ThumbWrapperDadosIconTrash onClick={() => deleteItem(item.id)}>
        <Trash color={theme.colors.white} />
      </ThumbWrapperDadosIconTrash>
      {item.key !== "FaseCorrida" &&
        item.key !== "GPS_LAT" &&
        item.key !== "GPS_LONG" &&
        item.key !== "TimeStamp" &&
        item.key !== "Sessao_Contagem" &&
        item.key !== "QtdCorridasExe" && (
          <ThumbWrapperDadosIconGraph
            onClick={() => showGraph(item.id, item.grafico)}
          >
            <GraphUp color={theme.colors.white} />
          </ThumbWrapperDadosIconGraph>
        )}
      <ThumbWrapperDadosIconMaxMin onClick={() => toogleSize(item.id)}>
        {item.size === "small" ? (
          <ArrowMaximize color={theme.colors.white} />
        ) : item.size === "big" ? (
          <ArrowMinimize color={theme.colors.white} />
        ) : (
          <></>
        )}
      </ThumbWrapperDadosIconMaxMin>
    </ThumbWrapperDados>
  );
};

export const GridThumbDadosDone = ({ item }) => {
  const theme = useTheme();

  return (
    <ThumbWrapperDados id={item.id} className={"small"}>
      <ThumbWrapperDadosTitleDone index={item.id} value={item.id}>
        {item.name} {item.unit !== "" ? `(${item.unit})` : ""}
      </ThumbWrapperDadosTitleDone>
      <ThumbWrapperDadosIcon>
        {item.category === "corpo" ? (
          <Heart color={theme.colors.pink} />
        ) : item.category === "sessao" ? (
          <Timer color={theme.colors.white} />
        ) : item.category === "hardware" ? (
          <HardwareChip color={theme.colors.blue} />
        ) : item.category === "estimulo" ? (
          <Power color={theme.colors.green} />
        ) : (
          <></>
        )}
      </ThumbWrapperDadosIcon>

      <ThumbWrapperDadosValue>
        {item.id === "TimeStamp"
          ? new Date(item.value).toLocaleString("pt-BR")
          : item.id === "TempCorpMax" || item.id === "TempCorpMin"
          ? item.value / 10
          : item.value}
      </ThumbWrapperDadosValue>
    </ThumbWrapperDados>
  );
};

export const GridThumbDadosDoneMin = ({ item }) => {
  const theme = useTheme();

  return (
    <ThumbWrapperDados
      // onClick={() => clickFunction(item.id)}
      id={item.id}
      className={"small"}
    >
      <ThumbWrapperDadosTitleDone index={item.id} value={item.id}>
        {item.name} mín {item.unit !== "" ? `(${item.unit})` : ""}
      </ThumbWrapperDadosTitleDone>
      <ThumbWrapperDadosIcon>
        {item.category === "corpo" ? (
          <Heart color={theme.colors.pink} />
        ) : item.category === "sessao" ? (
          <Timer color={theme.colors.white} />
        ) : item.category === "hardware" ? (
          <HardwareChip color={theme.colors.blue} />
        ) : item.category === "estimulo" ? (
          <Power color={theme.colors.green} />
        ) : (
          <></>
        )}
      </ThumbWrapperDadosIcon>
      <ThumbWrapperDadosValue>{item.value[0]}</ThumbWrapperDadosValue>
    </ThumbWrapperDados>
  );
};

export const GridThumbDadosDoneMax = ({ item }) => {
  const theme = useTheme();

  return (
    <ThumbWrapperDados id={item.id} className={"small"}>
      <ThumbWrapperDadosTitleDone index={item.id} value={item.id}>
        {item.name} máx {item.unit !== "" ? `(${item.unit})` : ""}
      </ThumbWrapperDadosTitleDone>
      <ThumbWrapperDadosIcon>
        {item.category === "corpo" ? (
          <Heart color={theme.colors.pink} />
        ) : item.category === "sessao" ? (
          <Timer color={theme.colors.white} />
        ) : item.category === "hardware" ? (
          <HardwareChip color={theme.colors.blue} />
        ) : item.category === "estimulo" ? (
          <Power color={theme.colors.green} />
        ) : (
          <></>
        )}
      </ThumbWrapperDadosIcon>
      <ThumbWrapperDadosValue>{item.value[1]}</ThumbWrapperDadosValue>
    </ThumbWrapperDados>
  );
};

export const GridThumbDadosAdd = ({ dadosConfig, setConfig }) => {
  const theme = useTheme();

  const addBox = () => {
    let changed = false;
    let higherIndex = 0;
    let tempConfig = [...dadosConfig];
    tempConfig.map((value, index) => {
      if (value.type === "value") {
        if (value.show === true) {
          let id = Number(value.id);
          if (id > higherIndex) {
            higherIndex = Number(value.id);
          }
        }
      }
    });
    tempConfig.map((value, index) => {
      if (value.type === "value") {
        if (value.show === false && !changed) {
          changed = true;
          value.show = true;
          value.id = (higherIndex + 1).toString();
        }
      }
    });
    tempConfig.sort((a, b) => {
      return a.id - b.id;
    });
    tempConfig.map((value, index) => {
      if (value.type === "value") {
        value.id = index.toString();
      }
    });
    setConfig(tempConfig);
  };
  return (
    <ThumbWrapperDados onClick={addBox} className={"small"}>
      <ThumbWrapperDadosValue>
        <Plus color={theme.colors.orange} size={50} />
      </ThumbWrapperDadosValue>
    </ThumbWrapperDados>
  );
};

export const BlockSidebarItem = ({ item, clickFunction }) => {
  const theme = useTheme();

  useEffect(() => {
    // console.log(item);
  }, [item]);
  return (
    <BlockSidebarItemWrapper>
      <BlockSidebarItemIcon>
        {item.category === "corpo" ? (
          <Heart color={theme.colors.pink} />
        ) : item.category === "sessao" ? (
          <Timer color={theme.colors.white} />
        ) : item.category === "hardware" ? (
          <HardwareChip color={theme.colors.blue} />
        ) : item.category === "estimulo" ? (
          <Power color={theme.colors.green} />
        ) : (
          <></>
        )}
      </BlockSidebarItemIcon>
      <BlockSidebarItemTitle>
        {item.label}{" "}
        {/* <span style={{ color: theme.colors.orange }}>({item.unit})</span> */}
      </BlockSidebarItemTitle>
      <BlockSidebarItemValue style={{ color: item.borderColor }}>
        {item.data &&
          Math.round(
            item.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
              item.data.length
          )}
      </BlockSidebarItemValue>
    </BlockSidebarItemWrapper>
  );
};

export const GridThumbConfiguracoesHome = ({ item, clickFunction }) => {
  return (
    <ThumbWrapperConfiguracoesHome onClick={() => clickFunction(item.sessaoid)}>
      <ThumbWrapperConfiguracoesHomeTitle>
        {item.name}
      </ThumbWrapperConfiguracoesHomeTitle>
      <ThumbWrapperConfiguracoesHomeImage>
        <img
          src={item.name === "Cicloergômetro" ? cicloIcon : cadeiraIcon}
          className={item.name === "Cadeira" ? "flip" : ""}
        />
      </ThumbWrapperConfiguracoesHomeImage>
    </ThumbWrapperConfiguracoesHome>
  );
};

export const GridThumbFreios = ({ item, clickFunction, freado }) => {
  return (
    <ThumbWrapperConfiguracoesHome onClick={() => clickFunction()}>
      <ThumbWrapperConfiguracoesHomeTitle className={freado ? "freado" : ""}>
        {!freado
          ? `Travar
Pedal`
          : `Destravar
Pedal`}
      </ThumbWrapperConfiguracoesHomeTitle>
    </ThumbWrapperConfiguracoesHome>
  );
};

export const GridThumbIncrementar = ({
  pointerDownFunction,
  // pointerUpFunction,
  title,
}) => {
  return (
    <ThumbWrapperConfiguracoesHome
      onClick={() => pointerDownFunction()}
      // onPointerUp={() => pointerUpFunction()}
    >
      <ThumbWrapperConfiguracoesHomeTitle>
        <span>+</span>
        <br />
        {title}
      </ThumbWrapperConfiguracoesHomeTitle>
    </ThumbWrapperConfiguracoesHome>
  );
};

export const GridThumbDecrementar = ({
  pointerDownFunction,
  // pointerUpFunction,
  title,
}) => {
  return (
    <ThumbWrapperConfiguracoesHome
      onClick={() => pointerDownFunction()}
      // onPointerUp={() => pointerUpFunction()}
    >
      <ThumbWrapperConfiguracoesHomeTitle>
        <span>-</span>
        <br />
        {title}
      </ThumbWrapperConfiguracoesHomeTitle>
    </ThumbWrapperConfiguracoesHome>
  );
};

export const ConfigItemText = ({
  item,
  valueChange,
  classe,
  selected = false,
  toogle = false,
  toogleChange = null,
  readOnly = false,
}) => {
  useEffect(() => {
    // console.log(item);
  }, []);
  return (
    <ConfigItemTextWrapper
      className={classe}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ConfigItemTextTitle className={classe}>{item.name}</ConfigItemTextTitle>
      <ConfigItemTextValue
        className={classe}
        value={
          item.value !== undefined && item.value !== null ? item.value : ""
        }
        onChange={(e) => valueChange(e.target.value)}
        readOnly={readOnly ? readOnly : false}
      />
      {toogle ? (
        <ToogleRoles
          checked={selected}
          value={item.value}
          name={item.name}
          id={item.id}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toogleChange(e);
          }}
        />
      ) : (
        <></>
      )}
    </ConfigItemTextWrapper>
  );
};

export const ConfigItemTextInput = ({
  item,
  valueChange,
  classe,
  selected = false,
  toogle = false,
  toogleChange = null,
}) => {
  useEffect(() => {
    // console.log(item);
  }, []);
  return (
    <ConfigItemTextWrapper
      className={classe}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ConfigItemTextTitle className={classe}>{item.name}</ConfigItemTextTitle>
      <ConfigItemTextValue
        className={classe}
        value={item.value ? item.value : ""}
        id={item.id}
        onChange={(e) => valueChange(item.id, e.target.value)}
      />
      {toogle ? (
        <ToogleRoles
          checked={selected}
          value={item.value}
          name={item.name}
          id={item.id}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toogleChange(e);
          }}
        />
      ) : (
        <></>
      )}
    </ConfigItemTextWrapper>
  );
};

export const ConfigItemSelect = ({ item, valueChange }) => {
  const [value, setValue] = useState(item.value);

  const handleChange = (event) => {
    valueChange(event.target.value);
    setValue(event.target.value);
  };
  useEffect(() => {}, []);
  return (
    <ConfigItemTextWrapper>
      <ConfigItemTextTitle>{item.name}</ConfigItemTextTitle>
      <ConfigItemTextValuesSelectorWrapper>
        <ConfigItemSelector
          value={value}
          className={"grey"}
          onChange={handleChange}
          colorStroke={"white"}
        >
          {item.values &&
            item.values.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
        </ConfigItemSelector>
      </ConfigItemTextValuesSelectorWrapper>
    </ConfigItemTextWrapper>
  );
};

export const ConfigItemButtons = ({ item, valueChange }) => {
  const [value, setValue] = useState(item.value);

  useEffect(() => {}, []);
  return (
    <ConfigItemTextWrapper>
      <ConfigItemTextTitle>{item.name}</ConfigItemTextTitle>
      <ConfigItemButtonsWrapper>
        {item.values &&
          item.values.map((value, index) => (
            <ConfigItemButton
              key={index}
              value={value.value}
              className={value.value === item.value ? "selected" : ""}
              onClick={(e) => valueChange(e)}
            >
              {value.name}
            </ConfigItemButton>
          ))}
      </ConfigItemButtonsWrapper>
    </ConfigItemTextWrapper>
  );
};

export const ConfigItemSlider = ({ item, valueChange, classe }) => {
  const [value, setValue] = useState(item.value);

  const change = (event) => {
    setValue(event.target.value);
  };

  const changeCommited = (event, newValue) => {
    valueChange(newValue);
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value} ${item.unit}`;
  }

  function calculateValue(value) {
    return value * 1;
  }

  useEffect(() => {
    setValue(item.value);
  }, [item]);

  return (
    <ConfigItemTextWrapper className={classe}>
      <ConfigItemTextTitle>{item.name}</ConfigItemTextTitle>
      <ConfigItemContentWrapper>
        <Box sx={{ width: "100%" }}>
          <Slider
            value={value}
            min={item.min}
            max={item.max}
            step={1}
            scale={calculateValue}
            onChange={change}
            onChangeCommitted={changeCommited}
            valueLabelDisplay="auto"
            valueLabelFormat={valuetext}
            getAriaValueText={valuetext}
          />
        </Box>
      </ConfigItemContentWrapper>
      <ConfigItemTextValuesWrapper>
        <ConfigItemTextValueMinMax
          type="text"
          value={value}
          onChange={change}
        />
        <ConfigItemTextUnit>{item.unit}</ConfigItemTextUnit>
      </ConfigItemTextValuesWrapper>
    </ConfigItemTextWrapper>
  );
};

export const ConfigItemSliderRange = ({ item, valueChange }) => {
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const [valueFinal, setValueFinal] = useState([item.valuemin, item.valuemax]);

  const change = (event, newValue) => {
    setValueFinal(newValue);
  };

  const changeCommited = (event, newValue) => {
    setValueFinal(newValue);
    valueChange(newValue);
  };

  const changeMin = (event) => {
    let valueTemp = [...valueFinal];
    valueTemp[0] = event.target.value;
    setValueFinal(valueTemp);
  };

  const changeMax = (event, newValue) => {
    let valueTemp = [...valueFinal];
    valueTemp[1] = event.target.value;
    setValueFinal(valueTemp);
  };

  function valuetext(value) {
    let isMin = value === valueFinal[0];
    return `${value} ${item.unit} ${isMin ? "mín" : "máx"}`;
  }

  function calculateValue(value) {
    return value * 1;
  }

  useEffect(() => {
    setValueFinal([item.valuemin, item.valuemax]);
  }, [item]);

  return (
    <ConfigItemTextWrapper>
      <ConfigItemTextTitle>{item.name}</ConfigItemTextTitle>
      <ConfigItemContentWrapper>
        <Box sx={{ width: "100%" }}>
          <Slider
            value={valueFinal}
            min={item.min}
            max={item.max}
            step={1}
            scale={calculateValue}
            onChangeCommitted={changeCommited}
            onChange={change}
            valueLabelDisplay="auto"
            valueLabelFormat={valuetext}
            getAriaValueText={valuetext}
          />
        </Box>
      </ConfigItemContentWrapper>
      <ConfigItemTextValuesWrapper>
        <ConfigItemTextValueMinMax
          type="text"
          value={valueFinal[0]}
          onChange={changeMin}
        />
        <ConfigItemTextValueStatic>/</ConfigItemTextValueStatic>
        <ConfigItemTextValueMinMax
          type="text"
          value={valueFinal[1]}
          onChange={changeMax}
        />
        <ConfigItemTextUnit>{item.unit}</ConfigItemTextUnit>
      </ConfigItemTextValuesWrapper>
    </ConfigItemTextWrapper>
  );
};

export const ChangeItemSliderRange = ({ item, valueChange }) => {
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const [valueFinal, setValueFinal] = useState([item.valuemin, item.valuemax]);
  const [lastValue, setLastValue] = useState([item.valuemin, item.valuemax]);

  const change = (event, newValue) => {
    setValueFinal(newValue);
  };

  const changeCommited = (event, newValue) => {
    console.log("values range", newValue[1], lastValue[1]);
    if (valueFinal[1] > lastValue[1] + 10) {
      setValueFinal(lastValue);
      setAlertText(`Você só pode incrementar até 10 unidades por vez!`);
      setAlertType("autoCancel");
      setClasseAlert("on");
    } else {
      setValueFinal(newValue);
      valueChange(newValue);
      setLastValue(newValue);
    }
  };

  const changeMin = (event) => {
    let valueTemp = [...valueFinal];
    valueTemp[0] = event.target.value;
    setValueFinal(valueTemp);
  };

  const changeMax = (event, newValue) => {
    let valueTemp = [...valueFinal];
    valueTemp[1] = event.target.value;
    setValueFinal(valueTemp);
  };

  function valuetext(value) {
    let isMin = value === valueFinal[0];
    return `${value} ${item.unit} ${isMin ? "mín" : "máx"}`;
  }

  function calculateValue(value) {
    return value * 1;
  }

  useEffect(() => {
    setValueFinal([item.valuemin, item.valuemax]);
  }, [item]);

  return (
    <ConfigItemTextWrapper>
      <ConfigItemTextTitle>{item.name}</ConfigItemTextTitle>
      <ConfigItemContentWrapper>
        <Box sx={{ width: "100%" }}>
          <Slider
            value={valueFinal}
            min={item.min}
            max={item.max}
            step={1}
            scale={calculateValue}
            onChangeCommitted={changeCommited}
            onChange={change}
            valueLabelDisplay="auto"
            valueLabelFormat={valuetext}
            getAriaValueText={valuetext}
          />
        </Box>
      </ConfigItemContentWrapper>
      <ConfigItemTextValuesWrapper>
        <ConfigItemTextValueMinMax
          type="text"
          value={valueFinal[0]}
          onChange={changeMin}
        />
        <ConfigItemTextValueStatic>/</ConfigItemTextValueStatic>
        <ConfigItemTextValueMinMax
          type="text"
          value={valueFinal[1]}
          onChange={changeMax}
        />
        <ConfigItemTextUnit>{item.unit}</ConfigItemTextUnit>
      </ConfigItemTextValuesWrapper>
    </ConfigItemTextWrapper>
  );
};

export const Lightbox = () => {
  const clone = require("rfdc")();
  const { lightbox, setLightbox } = useLightbox("");
  const closeLightbox = () => {
    setLightbox("");
  };

  return (
    <LightboxWrapper
      className={lightbox !== "" ? "on" : ""}
      onClick={closeLightbox}
    >
      {lightbox === "addUsuario" && <LightboxAddUser />}
      {lightbox === "addCiclo" && <LightboxAddCiclo />}
      {lightbox === "addPaciente" && <LightboxAddPaciente />}
    </LightboxWrapper>
  );
};
