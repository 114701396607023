import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 250px;
  text-align: center;

  .span {
    color: ${({ theme }) => theme.colors.orange};
  }
`;
