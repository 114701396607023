import styled from "styled-components";

export const TitleSecao = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const NotasWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 730px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.grey};
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};

  overflow: hidden;
`;

export const NotasText = styled.textarea`
  width: 98%;
  height: 60%;
  border-radius: 24px;
  font: normal normal 400 25px/30px ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 25px;
  resize: none;
`;

export const NotasBorg = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 98%;
  height: 34%;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const BorgTitle = styled.div`
  box-sizing: border-box;
  padding: 25px;
  font: normal normal 800 25px/30px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
`;
export const BorgButtons = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 60%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const BorgButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  height: 98%;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const BorgButtonValue = styled.div`
  user-select: none;
  width: 100%;
  text-align: center;
  font: normal normal 800 70px/75px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  &.selected {
    color: ${({ theme }) => theme.colors.pink};
  }
`;

export const BorgButtonDescription = styled.div`
  user-select: none;
  width: 100%;
  text-align: center;
  font: normal normal 400 20px/25px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  &.selected {
    color: ${({ theme }) => theme.colors.pink};
  }
`;
