import axios from "axios";

const api = axios.create({
  baseURL: "https://app.plataformalucyio.com.br/api",
  // baseURL: "http://localhost:5000/api",
});

export const apiGeneral = axios.create({
  baseURL: "https://plataformalucyio.com.br",
  // baseURL: "http://localhost:5000",
});

export default api;
