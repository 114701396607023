import React, { useEffect, useContext, useState, createContext } from "react";

const PacienteContext = createContext();

export default function PacienteProvider({ children }) {
  const [paciente, setPaciente] = useState(false);

  useEffect(() => {}, [paciente]);

  return (
    <PacienteContext.Provider value={{ paciente, setPaciente }}>
      {children}
    </PacienteContext.Provider>
  );
}

export function usePaciente() {
  const context = useContext(PacienteContext);
  if (!context)
    throw new Error("usePaciente must be used within a PacienteProvider");

  const { paciente, setPaciente } = context;

  return { paciente, setPaciente };
}
