import React, { useEffect, useState } from "react";
import {
  SearchWrapper,
  AddUserResultWrapper,
  TooglesWrapper,
  ToogleWrapper,
  ConfigItemTextWrapper,
  ConfigItemTextTitle,
  ConfigItemTextValue,
} from "./styled";
import api from "../../../../services/Api";
import {
  Button,
  InputText,
  Select,
  Toogle,
  ToogleRoles,
  ToogleRolesSearch,
} from "../../../../ui/InputControls";
import { getJwtToken } from "../../../../contexts/authentication";
import { Auth } from "aws-amplify";
import { useAlert } from "../../../../contexts/alert";
import { TitleThumb } from "../../../../ui/General/styled";
import { TitleBig } from "../../../../ui/Texts";
import { usePreload } from "../../../../contexts/preloading";

const LightboxAddPaciente = () => {
  const { preloading, setPreloading } = usePreload();
  const [pesquisaPessoa, setPesquisaPessoa] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [searchOptions, setSearchOptions] = useState([
    {
      id: "",
      name: "Pesquisar por...",
      selected: true,
    },
    {
      id: "Nome",
      name: "Nome da pessoa",
      selected: false,
    },
    {
      id: "CPF",
      name: "CPF (somente números)",
      selected: false,
    },
  ]);
  const clone = require("rfdc")();

  const changeSearchOptions = (e) => {
    let tempOptions = clone(searchOptions);
    tempOptions.map((value) =>
      value.id === e.target.value
        ? (value.selected = true)
        : (value.selected = false)
    );
    setSearchOptions(tempOptions);
  };

  const searchFor = async (value) => {
    setPreloading(true);
    let tempOptions = clone(searchOptions);
    let searchKey = tempOptions.filter((value) => value.selected);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(
        `/CadastroPessoa/GetFiltroCadastro?${searchKey[0].id}=${value}`,
        config
      )
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setSearchResult(res.data);
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  return (
    <SearchWrapper>
      <TitleBig margin="20px">Cadastrar usuário</TitleBig>
      <Select
        id="search-type"
        name="search-type"
        options={searchOptions}
        current={
          searchOptions && searchOptions.filter((value) => value.selected)[0].id
        }
        width="80%"
        height="40px"
        title="Sair"
        color="grey"
        colorStroke="orange"
        margin="0px 20px"
        onChange={changeSearchOptions}
      />
      <InputText
        width="80%"
        id="adicionarusuario"
        value={pesquisaPessoa}
        placeholder="Digite para pesquisar..."
        onChange={(value) => {
          searchFor(value);
          setPesquisaPessoa(value);
        }}
      />
      <AddUserResultWrapper>
        {searchResult &&
          searchResult.map((value, index) => (
            <AddUserForm key={index} item={value} />
          ))}
      </AddUserResultWrapper>
    </SearchWrapper>
  );
};

const AddUserForm = ({ item, valueChange }) => {
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const { preloading, setPreloading } = usePreload();
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState([
    { id: "Paciente", name: "Paciente", value: 16, selected: true },
  ]);
  const [roleFinal, setRoleFinal] = useState(null);

  const clone = require("rfdc")();

  const changeEmail = (value) => {
    setEmail(value);
  };

  const changeRole = async (e) => {
    let tempRole = clone(role);
    const isChecked = e.target.checked;

    tempRole.map((value) => {
      if (value.id === e.target.id) {
        value.selected = isChecked;
      }
    });
    console.log(tempRole);
    setRole(tempRole);
  };

  const cadastrarUsuario = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let userName =
      item.nome.split(" ")[0].toLowerCase() +
      "." +
      email.split("@")[0] +
      "." +
      Math.floor(Math.random() * 100000);
    let cadastrarRoles = role.filter((value) => value.selected);

    ///PRECISA VERIFICAR SE O EMAIL JA ESTA CADASTRADO NO COGNITO ADRIANO

    setPreloading(true);
    Auth.signUp({
      username: userName,
      password: "$enhA%123",
      attributes: {
        email,
        nickname: item.nome,
        // phone_number: phoneNumber
        name: item.nome,
      },
    })
      .then(async (res) => {
        console.log(res.data);
        let rolesSend = [];
        cadastrarRoles.map((value) => rolesSend.push(value.id));
        console.log(item.accountId, email, rolesSend);

        let content = {
          accountId: item.accountId,
          email: email,
          roles: rolesSend,
        };

        let at = await getJwtToken();
        const config = {
          headers: { Authorization: `Bearer ${at}` },
        };

        return api
          .put(`/Account/PutAccountUpdate`, content, config)
          .then(async (res) => {
            if (res.status === 200) {
              console.log(res.data);
              setAlertText(
                "Usuário Cadastrato! Ativar a conta pelo e-mail cadastrado!"
              );
              setAlertType("success");
              setClasseAlert("on");
              setPreloading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setAlertText(error.response.data);
            setAlertType("error");
            setClasseAlert("on");
            setPreloading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setPreloading(false);
      });
  };

  const cpfMask = (value) => {
    const cpfAtual = value;

    let cpfAtualizado;

    cpfAtualizado = cpfAtual.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      function (regex, argumento1, argumento2, argumento3, argumento4) {
        return (
          argumento1 + "." + argumento2 + "." + argumento3 + "-" + argumento4
        );
      }
    );
    return cpfAtualizado;
  };

  useEffect(() => {
    // console.log(email);
  }, [email]);

  useEffect(() => {
    // console.log(item);
    setRoleFinal(role);
  }, [role]);
  return (
    <ConfigItemTextWrapper className={"roles"}>
      <ConfigItemTextTitle className={"roles"}>
        Id: {item.accountId}
        <br />
        Nome: {item.nome}
        <br />
        Cpf: {cpfMask(item.cpf)}
        <br />
        Data de nascimento:{" "}
        {new Date(item.dataDeNascimento).getDate() +
          "/" +
          (new Date(item.dataDeNascimento).getMonth() + parseInt(1)) +
          "/" +
          new Date(item.dataDeNascimento).getFullYear()}
        <br />
        {item.prontuario && `Prontuário: ${item.prontuario}`}
        <br />
        {item.macroprocesso && `Macroprocesso: ${item.macroprocesso}`}
        <br />
        {item.cpfMedicoResponsavel &&
          `CPF Médico: ${cpfMask(item.cpfMedicoResponsavel)}`}
        <br />
      </ConfigItemTextTitle>
      <ConfigItemTextValue className={"roles"}>
        <InputText
          width="50%"
          margin="auto 0px"
          placeholder="E-mail"
          defaultValue={email}
          onChange={changeEmail}
        />
        <TooglesWrapper>
          {roleFinal &&
            roleFinal.map((value, index) => (
              <ToogleWrapper key={index}>
                {value.name}
                <ToogleRolesSearch
                  key={index}
                  checked={value.selected}
                  value={value.value}
                  name={value.name}
                  id={value.id}
                  onClick={() => {
                    return false;
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </ToogleWrapper>
            ))}
        </TooglesWrapper>
      </ConfigItemTextValue>
      <Button
        width="10%"
        title="Cadastrar"
        color="primary"
        margin="0px"
        onClick={cadastrarUsuario}
      />
    </ConfigItemTextWrapper>
  );
};

export default LightboxAddPaciente;
