import { useContext, useState, createContext } from "react";

const AlertContext = createContext();

export default function AlertProvider({ children }) {
  const [classeAlert, setClasseAlert] = useState("");
  const [alertText, setAlertText] = useState("Dados inválidos!");
  const [alertType, setAlertType] = useState("error");

  return (
    <AlertContext.Provider
      value={{
        classeAlert,
        setClasseAlert,
        alertText,
        setAlertText,
        alertType,
        setAlertType,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}

export function useAlert() {
  const context = useContext(AlertContext);
  if (!context) throw new Error("useAlert must be used within a AlertProvider");

  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = context;

  return {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  };
}
