import { useState, useEffect } from "react";
import { ConfigItemText, GridThumbUserAccount } from "../../../../ui/General";
import { useParams } from "react-router-dom";
import { Toogle } from "../../../../ui/InputControls";
import {
  AccountWrapper,
  GridThumbWrapper,
  SubtitleSessaoPendente,
  ToogleWrapper,
} from "./styled";
import api from "../../../../services/Api";
import { getJwtToken } from "../../../../contexts/authentication";
import { usePreload } from "../../../../contexts/preloading";
import { useAlert } from "../../../../contexts/alert";
import { TitleBig } from "../../../../ui/Texts";
import { useRoles } from "../../../../contexts/roles";

const Account = ({ usuario }) => {
  const { setPreloading } = usePreload();
  const { accountid } = useParams();
  // const { accounts } = useAccounts();
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const [account, setAccount] = useState(null);
  const [active, setActive] = useState(null);
  const [toogleOn, setToogleOn] = useState(null);
  const [firstAccess, setFirstAccess] = useState(true);
  const { rolesValues } = useRoles();
  const [rolesFinal, setRolesFinal] = useState(null);
  const [rolesFinalRender, setRolesFinalRender] = useState(null);

  const loadAccount = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Application/GetUserBasicByAccountId/${accountid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          api
            .get(`/Account/GetEnableAccount/${accountid}`, config)
            .then(async (res2) => {
              let response = { ...res.data };
              let response2 = { ...res2.data };
              if (response.photo !== null) {
                api
                  .get(response.photo, config)
                  .then(async (res2) => {
                    console.log(res2);
                    response.photo = `data:image/jpeg;base64,${res2.data}`;
                    response.email = response2.email;
                    setAccount(response);
                    setPreloading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                    setPreloading(false);
                  });
              } else {
                response.email = response2.email;
                setAccount(response);
                setPreloading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  const enableAccount = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Account/GetEnableAccount/${accountid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPreloading(false);

          if (!firstAccess) {
            setAlertText("Usuário ativado!");
            setAlertType("success");
            setClasseAlert("on");
          } else {
            setFirstAccess(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        if (!firstAccess) {
          setAlertText("Ocorreu algum problema!");
          setAlertType("error");
          setClasseAlert("on");
        } else {
          setFirstAccess(false);
        }
      });
  };

  const disableAccount = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/Account/GetDisableAccount/${accountid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPreloading(false);
          if (!firstAccess) {
            setAlertText("Usuário desativado!");
            setAlertType("success");
            setClasseAlert("on");
          } else {
            setFirstAccess(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        if (!firstAccess) {
          setAlertText("Ocorreu algum problema!");
          setAlertType("error");
          setClasseAlert("on");
        } else {
          setFirstAccess(false);
        }
      });
  };

  const toogleRoles = async (e) => {
    setPreloading(true);

    const clone = require("rfdc")();
    let rolesTemp = clone(rolesFinal);
    rolesTemp.forEach((obj) => {
      if (obj.id === e.target.name) {
        console.log(obj.id, e.target.name);
        return (obj.selected = e.target.checked === true ? true : false);
      }
    });
    setRolesFinal(rolesTemp);

    let activeRoles = rolesTemp.filter((value) => value.selected);

    let content = {
      accountId: parseInt(account.id),
      email: account.email,
      roles: activeRoles.map((value) => value.id),
    };
    console.log(content);

    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .put(`/Account/PutAccountUpdate/`, content, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPreloading(false);
          setAlertText("Permissões atualizadas");
          setAlertType("success");
          setClasseAlert("on");
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        setAlertText("Ocorreu algum problema ao atualizar as permissões");
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  useEffect(() => {
    if (active !== null) {
      setToogleOn(active);
      if (active) {
        enableAccount();
      } else {
        disableAccount();
      }
    }
  }, [active]);

  useEffect(() => {
    if (account) {
      setToogleOn(account.active);
      setActive(account.active);
      rolesValues.forEach((value, index) => {
        value.selected = false;
        for (let i = 0; i < account.roles.length; i++) {
          if (account.roles[i].name === value.name) {
            value.selected = true;
          }
        }
      });
      setRolesFinal(rolesValues);
    }
  }, [account]);

  useEffect(() => {
    loadAccount();
  }, []);

  useEffect(() => {
    setRolesFinalRender(rolesFinal);
  }, [rolesFinal]);

  return (
    <>
      {account && (
        <>
          <TitleBig>{account.name}</TitleBig>
          <AccountWrapper>
            <GridThumbWrapper>
              <GridThumbUserAccount item={account} />
              {toogleOn !== null && (
                <ToogleWrapper>
                  <Toogle
                    checked={toogleOn}
                    onChange={(e) => {
                      setActive(!active);
                    }}
                  />
                </ToogleWrapper>
              )}
            </GridThumbWrapper>

            <ConfigItemText
              item={{
                name: "Id Conta",
                value: account.id,
              }}
              valueChange={() => {}}
            />
            <ConfigItemText
              item={{
                name: "Nome",
                value: account.name,
              }}
              valueChange={() => {}}
            />
            <SubtitleSessaoPendente>Permissões</SubtitleSessaoPendente>
            {rolesFinalRender &&
              rolesFinalRender.map((value, index) => (
                <ConfigItemText
                  key={index}
                  item={{
                    name: value.name,
                    value: value.name,
                  }}
                  toogle={true}
                  selected={value.selected}
                  toogleChange={toogleRoles}
                  valueChange={() => {}}
                />
              ))}
            <SubtitleSessaoPendente>Dados Gerais</SubtitleSessaoPendente>
            {account.data.map((value, index) => (
              <ConfigItemText
                key={index}
                item={{
                  name: value.name,
                  value: value.value,
                }}
                valueChange={() => {}}
              />
            ))}
          </AccountWrapper>
        </>
      )}
    </>
  );
};

export default Account;
