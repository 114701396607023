import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getJwtToken } from "../../../../contexts/authentication";
import { usePreload } from "../../../../contexts/preloading";
import { useAlert } from "../../../../contexts/alert";
import api from "../../../../services/Api";
import { MainSchedulesWrapper, CalendarWrapper } from "./styled";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ListSections, ListCiclos } from "../../../../ui/General";

const MainCalendar = ({ calendarDate, setCalendarDate }) => {
  const changeDate = (date) => {
    setCalendarDate(date);
  };

  return (
    <CalendarWrapper>
      <Calendar onChange={changeDate} value={calendarDate} />
    </CalendarWrapper>
  );
};

const CalendarFirstList = (props) => {
  return <ListSections {...props} />;
};

const CalendarSecondList = (props) => {
  return <ListCiclos {...props} />;
};

const MainSchedules = () => {
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [list, setList] = useState([]);

  const loadData = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .get(
        `/Schedule/GetScheduleByPeriod/${calendarDate.getFullYear()}-${
          calendarDate.getMonth() + 1
        }-${calendarDate.getDate()}/${calendarDate.getFullYear()}-${
          calendarDate.getMonth() + 1
        }-${calendarDate.getDate()}`,
        config
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            res.data.map((sessao) => {
              api
                .get(
                  `/Application/GetUserBasicByAccountId/${sessao.accountId}`,
                  config
                )
                .then((res2) => {
                  if (res2.status === 200) {
                    let tempObject = {
                      ...sessao,
                      ...res2.data,
                    };
                    setList((values) => [...values, tempObject]);
                  }
                  setPreloading(false);
                })
                .catch((error) => {
                  console.log(error);

                  setPreloading(false);
                });
            });
          } else {
            setPreloading(false);
          }
        } else {
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        setPreloading(false);
      });
  };

  useEffect(() => {
    setList([]);
    loadData();
  }, [calendarDate]);

  return (
    <MainSchedulesWrapper>
      <MainCalendar
        calendarDate={calendarDate}
        setCalendarDate={setCalendarDate}
      />
      <CalendarFirstList width="100%" title="Sessões" list={list} />
    </MainSchedulesWrapper>
  );
};

export default MainSchedules;
