export const estadosCiclo = [
  { name: "Cicloergômetro Disponível", value: 0 },
  { name: "Configuração", value: 3 },
  { name: "Aquecimento", value: 4 },
  { name: "Corrida", value: 5 },
  { name: "Resfriamento", value: 6 },
  { name: "Repouso", value: 7 },
  { name: "Trata Resumo/Estatistica", value: 8 },
  { name: "Apresenta Resumo", value: 9 },
  { name: "Apresenta Estatística", value: 10 },
  { name: "Alarme sonora da Falha", value: 11 },
  { name: "Falha sem alarme", value: 12 },
  { name: "Alarme sonoro da emergência", value: 13 },
  { name: "Emergência sem alarme", value: 14 },
  { name: "Pedal freado", value: 15 },
  { name: "Alarme sonoro espasmo", value: 16 },
  { name: "Deteção de espasmo sem alarme com freio acionado", value: 17 },
  { name: "Deteção de espasmo sem alarne pedal liberado", value: 18 },
  { name: "Final de corrida", value: 19 },
  { name: "Falha Eletrodo Quadríceps Direito", value: 20 },
  { name: "Falha Eletrodo Glúteo Direito", value: 21 },
  { name: "Falha Eletrodo Ísquio Direito", value: 22 },
  { name: "Falha Eletrodo Quadríceps Esquerdo", value: 23 },
  { name: "Falha Eletrodo Glúteo Esquerdo", value: 24 },
  { name: "Falha Eletrodo Ísquio Esquerdo", value: 25 },
];
