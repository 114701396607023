import React, { useState, useEffect } from "react";
import _ from "lodash";
import { getJwtToken } from "../../../../../contexts/authentication";
import { usePaciente } from "../../../../../contexts/paciente";
import { usePreload } from "../../../../../contexts/preloading";
import { useAlert } from "../../../../../contexts/alert";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useTheme } from "styled-components";
import { Button } from "../../../../../ui/InputControls";
import { BlockSidebarItem } from "../../../../../ui/General";
import {
  TitleSecao,
  Wrapper,
  Block,
  BlockSidebar,
  BlockGraphic,
  ButtonToogleSize,
  BlockTable,
  BlockSidebarTitle,
} from "./styled";
import { Heart } from "@styled-icons/evaicons-solid";
import { Timer } from "@styled-icons/ionicons-solid";
import { HardwareChip } from "@styled-icons/ionicons-outline";
import { Power } from "@styled-icons/icomoon";
import api from "../../../../../services/Api";

Chart.register(zoomPlugin); // REGISTER PLUGIN
Chart.register({
  id: "customSpacingLegend",
  beforeInit(chart) {
    const originalFit = chart.legend.fit;

    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 30;
    };
  },
});

function generateRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const GraficoSessao = ({
  pacienteid,
  estatisticas,
  titleGrafico,
  graficoMask,
}) => {
  const { paciente } = usePaciente();
  const { preloading, setPreloading } = usePreload();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const [datasetGrafico, setDatasetGrafico] = useState(
    _.cloneDeep(graficoMask)
  );
  const theme = useTheme();
  const [classBlockSidebar, setClassBlockSidebar] = useState("");
  const [itemHidden, setItemHidden] = useState(null);
  const [dados, setDados] = useState({
    dadosGrafico: [],
    informacoescorporais: [],
  });

  Chart.defaults.font.size = 15;
  Chart.defaults.color = "#ffffff";

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          padding: 20,
        },
        onClick: (e, legendItem) => {
          toogleShowItem(legendItem.datasetIndex);
        },
      },
      title: {
        display: false,
        text: "Gráfico Sessão",
      },
    },
    interaction: {},
    layout: {
      padding: 5,
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: theme.colors.grey,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: theme.colors.grey,
        },
      },
    },
  };

  const [data, setData] = useState(null);

  const toogleSize = () => {
    if (classBlockSidebar === "") {
      setClassBlockSidebar("on");
    } else {
      setClassBlockSidebar("");
    }
  };

  const toogleShowAll = () => {
    let tempItems = [...itemHidden];
    let finalItems;
    let items = tempItems.filter((value) => value === true);
    if (items.length > 0) {
      finalItems = tempItems.map((value, index) => {
        return false;
      });
    } else {
      finalItems = tempItems.map((value, index) => {
        return true;
      });
    }
    console.log(finalItems);
    setItemHidden(finalItems);
  };

  const toogleShowItem = (id) => {
    let tempItems = [...itemHidden];
    if (tempItems[id]) {
      tempItems[id] = false;
    } else {
      tempItems[id] = true;
    }
    setItemHidden(tempItems);
  };

  const sidebarItemClick = () => {};
  useEffect(() => {
    if (itemHidden !== null) {
      if (dados) {
        let dadosObject = new Object();
        dados.dadosGrafico.map((values, index) => {
          Object.entries(values).map(([key, value]) => {
            if (!dadosObject[key]) {
              dadosObject[key] = [];
            }
            dadosObject[key].push(value);
          });
        });
        let dataTemp = _.cloneDeep(data.datasets);

        dataTemp.map((value, index) => {
          value.hidden = itemHidden[index];
        });
        if (dadosObject.timeStamp) {
          let dataTime = dadosObject.timeStamp.map((value, index) => {
            return new Date(value).toLocaleString("pt-BR");
          });
          setData({
            labels: dataTime,
            datasets: dataTemp,
          });
        }
      }
    }
  }, [itemHidden]);

  useEffect(() => {
    if (dados) {
      let dadosObject = new Object();
      dados.dadosGrafico.map((values, index) => {
        Object.entries(values).map(([key, value]) => {
          if (!dadosObject[key]) {
            dadosObject[key] = [];
          }
          dadosObject[key].push(value);
        });
      });
      let dataTemp = _.cloneDeep(datasetGrafico);

      dataTemp.map((value, index) => {
        value.data = dadosObject[value.id];
      });

      let hiddenTemp = dataTemp.map((value, index) => {
        return value.hidden;
      });
      setItemHidden(hiddenTemp);

      setData({
        labels: dadosObject.timeStamp,
        datasets: dataTemp,
      });
    }
  }, [dados]);

  useEffect(() => {}, [data]);

  useEffect(() => {}, [dados]);

  useEffect(() => {
    setDados((values) => ({
      ...values,
      dadosGrafico: estatisticas,
    }));
  }, []);

  return data ? (
    <>
      <TitleSecao>
        Gráfico {titleGrafico}{" "}
        <Button
          title={"Mostrar / Esconder Todos"}
          color={"primary"}
          width={"250px"}
          margin={"0px 30px"}
          onClick={toogleShowAll}
        />
      </TitleSecao>
      <Wrapper>
        <Block>
          <BlockSidebar className={classBlockSidebar}>
            <BlockSidebarTitle>Médias {titleGrafico} </BlockSidebarTitle>
            {data.datasets.map(
              (value, index) =>
                value.id !== "tempo" &&
                value.id !== "faseCorrida" && (
                  <BlockSidebarItem
                    key={index}
                    item={value}
                    clickFunction={sidebarItemClick}
                  />
                )
            )}
          </BlockSidebar>
          <BlockGraphic>
            <ButtonToogleSize
              onClick={toogleSize}
              className={classBlockSidebar}
            />
            {data !== null && (
              <Line
                options={options}
                data={data}
                // {...props}
              />
            )}
          </BlockGraphic>
        </Block>
      </Wrapper>
    </>
  ) : (
    <></>
  );
};

export default GraficoSessao;
