import styled from "styled-components";

export const ListSectionsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: ${(props) => (props.width ? props.width : "300px")};
  height: ${(props) => (props.height ? props.height : "436px")};
  margin-top: 30px;
  box-sizing: border-box;
  padding: 12px;
`;

export const ListSectionsTitle = styled.div`
  font: normal normal 600 20px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
`;

export const ListSectionsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  justify-content: center;
  width: 100%;
  max-height: 85%;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ListSectionsListItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 62px;
  padding: 0px 20px;
  margin-bottom: 6px;
  flex-shrink: 0;
  box-sizing: border-box;
  font: normal normal normal 16px/20px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
    padding-box;
  border-radius: 6px;

  &.add {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal bold 42px/53px ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.orange} 0% 0% no-repeat
      padding-box;
  }

  &.Doing {
    background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.orange};
    text-decoration: none;
  }
  &.Done {
    background: ${({ theme }) => theme.colors.black} 0% 0% no-repeat padding-box;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
  &.Failed {
    background: ${({ theme }) => theme.colors.black} 0% 0% no-repeat padding-box;
    color: ${({ theme }) => theme.colors.pink};
    text-decoration: none;
  }
  &.Canceled {
    background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.red};
    text-decoration: line-through;
  }
`;

export const ListSectionsCiclosListItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 62px;
  padding: 0px 20px;
  margin-bottom: 6px;
  flex-shrink: 0;
  box-sizing: border-box;
  font: normal normal normal 16px/20px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.black} 0% 0% no-repeat padding-box;
  border-radius: 6px;

  &.add {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal bold 42px/53px ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.orange} 0% 0% no-repeat
      padding-box;
  }
`;

export const ItemTime = styled.div`
  margin-left: auto;
`;

export const ItemStatus = styled.div``;
export const ItemDivisor = styled.div`
  margin: 0px 10px;
  color: ${({ theme }) => theme.colors.orange};
`;

export const ThumbWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 226px;
  height: 226px;
  background: ${({ theme }) => theme.colors.purpleDark} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 24px;
  margin: 20px 20px;

  &.past {
    background: ${({ theme }) => theme.colors.black} 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 12px ${({ theme }) => theme.colors.shadow};
  }
`;

export const ThumbWrapperUserAccount = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 255px;
  height: 255px;
  background: ${({ theme }) => theme.colors.grey} 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 24px;
  margin: 20px 0px;

  &.past {
    background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
      padding-box;
    box-shadow: inset 0px 0px 12px ${({ theme }) => theme.colors.shadow};
  }
`;

export const AvatarThumb = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 166px;
  margin-bottom: 20px;
  border-radius: 90px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AvatarThumbUsers = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  border-radius: 90px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AvatarThumbCiclos = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  margin-bottom: 15px;
  overflow: hidden;
`;

export const Initials = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  margin-bottom: 15px;
  border-radius: 90px;
  overflow: hidden;
  font: normal normal 600 63px/70px ${({ theme }) => theme.fonts.primary};
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.orange};
`;

export const AvatarThumbUserAccount = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AvatarThumbCiclo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  overflow: hidden;
`;

export const TitleThumb = styled.div`
  max-width: 90%;
  text-align: center;
  font: normal normal 600 13px/13px ${({ theme }) => theme.fonts.primary};
  letter-spacing: 0px;
  text-transform: lowercase;
  white-space: pre-wrap;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.white};
  &::first-line {
    text-transform: capitalize;
  }
`;

export const SubtitlesThumb = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`;

export const SubtitleThumb = styled.div`
  text-align: center;
  font: normal normal bold 13px/17px ${({ theme }) => theme.fonts.primary};
  line-height: 24px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.black};
  width: 24px;
  height: 24px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.orange};
  margin: 0px 3px;
`;

export const TitleThumbSchedules = styled.div`
  position: absolute;
  width: 80%;
  top: 20px;
  text-align: left;
  font: normal normal 600 13px/8px ${({ theme }) => theme.fonts.primary};
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.orange};

  &.past {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const DataThumbSchedules = styled.div`
  text-align: center;
  letter-spacing: 0px;
  font: normal normal normal 22px/33px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  white-space: break-spaces;
  letter-spacing: 1px;

  &:after {
    display: inline-block;
    content: " (Pendente)";
    text-decoration: none;
    color: ${({ theme }) => theme.colors.orange};
  }

  &.past {
    &.canceled {
      text-decoration: line-through;
      color: ${({ theme }) => theme.colors.white};
      &:after {
        display: inline-block;
        content: " (Cancelada)";
        text-decoration: none;
        color: ${({ theme }) => theme.colors.red};
      }
    }
    &.failed {
      color: ${({ theme }) => theme.colors.white};
      &:after {
        display: inline-block;
        content: " (Incompleta)";
        text-decoration: none;
        color: ${({ theme }) => theme.colors.purple};
      }
    }
    &.done {
      color: ${({ theme }) => theme.colors.white};
      &:after {
        display: inline-block;
        content: " (Concluída)";
        text-decoration: none;
        color: ${({ theme }) => theme.colors.orange};
      }
    }
  }

  &.doing {
    color: ${({ theme }) => theme.colors.orange};
    &:after {
      display: inline-block;
      content: " (Em andamento)";
      text-decoration: none;
    }
  }
`;

export const ThumbWrapperDados = styled.div`
  /* touch-action: none; */
  float: left;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 20px;
  margin: 20px 20px;

  transition: all 0.5s ease-in-out;

  &.small {
    width: 226px;
    height: 226px;
  }

  &.big {
    width: 492px;
    height: 492px;
  }

  &.highlighted {
    border: 2px solid ${({ theme }) => theme.colors.orange};
    box-shadow: 0px 8px 30px ${({ theme }) => theme.colors.orangeShadow};
  }
`;

export const ThumbWrapperDadosTitle = styled.select`
  pointer-events: all;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 72%;
  z-index: 10000;
  font: normal normal normal 16px/20px ${({ theme }) => theme.fonts.primary};
  text-align: left;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greyDarker};
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  white-space: pre-wrap;
`;

export const ThumbWrapperDadosTitleDone = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  width: 72%;
  height: auto;
  font: normal normal normal 16px/20px ${({ theme }) => theme.fonts.primary};
  text-align: left;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greyDarker};
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  white-space: pre-wrap;
`;

export const ThumbWrapperDadosIcon = styled.div`
  pointer-events: none;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 15px;
`;

export const ThumbWrapperDadosIconMaxMin = styled.div`
  pointer-events: all;
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 20px;
  right: 15px;
`;

export const ThumbWrapperDadosIconTrash = styled.div`
  pointer-events: all;
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 15px;
  left: 15px;
`;

export const ThumbWrapperDadosIconGraph = styled.div`
  pointer-events: all;
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%);
`;

export const ThumbWrapperDadosValue = styled.div`
  pointer-events: none;
  width: 100%;
  font: normal normal normal 33px/33px ${({ theme }) => theme.fonts.primary};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.6;

  &.highlighted {
    opacity: 1;
  }
`;

export const ThumbWrapperConfiguracoesHome = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 20px;
  margin: 20px 20px;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ThumbWrapperConfiguracoesHomeTitle = styled.div`
  top: 30px;
  width: 90%;
  font: normal normal 600 14px/17px ${({ theme }) => theme.fonts.primary};
  text-align: center;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 0px 0px 26px ${({ theme }) => theme.colors.black};
  white-space: pre-wrap;

  span {
    font: normal normal 600 60px/45px ${({ theme }) => theme.fonts.primary};
  }

  &.freado {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const ThumbWrapperConfiguracoesHomeImage = styled.div`
  width: 80px;
  height: 70px;
  margin-top: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.flip {
      transform: scaleX(-1);
    }
  }
`;

export const ConfigItemTextWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #212121 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 22px #0000006e;
  border-radius: 14px;
  margin-bottom: 5px;

  &.roles {
    height: 350px;
  }

  &.disable {
    pointer-events: none;
  }
`;

export const ConfigItemTextTitle = styled.div`
  width: 30%;
  text-align: left;
  font: normal normal 200 16px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};

  &.roles {
    width: 30%;
  }
`;

export const ConfigItemTextValue = styled.input`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  text-align: right;
  font: normal normal 600 22px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  -webkit-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  margin-right: 20px;

  &.roles {
    justify-content: space-evenly;
    width: 60%;
    height: 100%;
  }
`;

export const ConfigItemTextValuesWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  max-width: 15%;
  margin-top: -25px;
`;

export const ConfigItemTextValueMinMax = styled.input`
  max-width: 40%;
  text-align: center;
  font: normal normal 600 22px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  -webkit-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
`;

export const ConfigItemTextValueStatic = styled.div`
  text-align: center;
  font: normal normal 600 22px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.greyLight};
`;

export const ConfigItemContentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  width: 50%;
  height: 75px;
`;

export const ConfigItemTextUnit = styled.div`
  position: absolute;
  text-align: center;
  font: normal normal 600 19px/19px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  bottom: -25px;
`;

export const ConfigItemTextValuesSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  width: 15%;
`;

export const ConfigItemSelector = styled.select`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  background: 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : "10px")};
  font: normal normal 600 15px ${({ theme }) => theme.fonts.primary};
  text-align: center;
  line-height: ${(props) => (props.height ? props.height : "35px")};
  user-select: none;
  cursor: pointer;
  border: ${(props) =>
    props.colorStroke
      ? "2px solid " + props.theme.colors[props.colorStroke]
      : "none"};

  &.primary {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
  }

  &.secondary {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
  }

  &.grey {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }

  &.transparent {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    outline: none;
  }

  ::selection {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const ConfigItemButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  width: 100%;
`;

export const ConfigItemButton = styled.div`
  padding: 10px 20px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.colors.orange};
  background-color: transparent;
  margin: 0 10px;
  box-shadow: 0px 8px 16px ${({ theme }) => theme.colors.shadow};

  &.selected {
    border: none;
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const BlockSidebarItemWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
`;
export const BlockSidebarItemIcon = styled.div`
  width: 10%;
`;
export const BlockSidebarItemTitle = styled.div`
  width: 85%;
  padding: 20px 0px 20px 10px;
  font: normal normal 600 14px/14px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
`;
export const BlockSidebarItemValue = styled.div`
  font: normal normal 600 14px/14px ${({ theme }) => theme.fonts.primary};
  text-align: right;
  width: 15%;
`;

export const LightboxWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 40px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s linear 0s, top 0.5s ease-in-out 0s,
    z-index 0s linear 0.5s;

  &.on {
    opacity: 1;
    z-index: 100000;
    transition: opacity 0.5s linear 0s, top 0.5s ease-in-out 0s,
      z-index 0s linear 0s;
  }
`;
