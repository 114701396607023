import styled from "styled-components";

export const TitleSessaoPendente = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const SubtitleSessaoPendente = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 18px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 20px 0px 0px 20px;
  box-sizing: border-box;
`;

export const GridThumbWrapper = styled.div`
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const GridConfigWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0px 100px;

  &.disabled {
    pointer-events: none;
  }
`;

export const WrapperEstimulacaoEletrica = styled.div`
  position: relative;
  width: 100%;
`;
export const TestarWrapper = styled.div`
  pointer-events: all;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  left: -180px;
  width: 160px;
  height: 100%;
`;

export const TestButton = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export const ToogleWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 150px;
  height: 150px;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.orange};
  background-color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  white-space: pre-wrap;

  &:before {
    content: "Ativar Config \\A Cadeira";
    white-space: pre-wrap;
  }
`;
