import {
  InputWrapper,
  InputTextElement,
  ProfileField,
  ButtonWrapper,
  ButtonActions,
  AnchorWrapper,
  Icon,
  Selector,
  ToogleLabel,
  ToogleInput,
  ToogleSpan,
  ToogleSpanRoles,
  ToogleLabelRoles,
  ToogleLabelRolesSearch,
  ToogleInputRoles,
} from "./styled";
import { Show } from "@styled-icons/boxicons-solid/Show";
import { Hide } from "@styled-icons/boxicons-solid/Hide";
import { Edit } from "@styled-icons/material-rounded/Edit";
import { EditOff } from "@styled-icons/material-rounded/EditOff";
import { useEffect } from "react";

export const InputText = (props) => {
  return (
    <InputWrapper
      width={props.width}
      height={props.height}
      margin={props.margin}
    >
      <InputTextElement
        type={props.type ? props.type : "text"}
        id={props.id}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        value={props.value ? props.value : ""}
        readOnly={props.readOnly ? true : false}
        style={{ fontSize: props.fontSize && props.fontSize }}
      />
    </InputWrapper>
  );
};

export const InputPassword = (props) => {
  return (
    <InputWrapper
      width={props.width}
      height={props.height}
      margin={props.margin}
    >
      <InputTextElement
        type="password"
        id={props.id}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </InputWrapper>
  );
};

export const InputProfile = ({
  value,
  changeValue,
  fieldsVisible,
  fieldsEditable,
}) => {
  return (
    <ProfileField>
      <label htmlFor={value.key}>{value.name}</label>
      <input
        id={value.key}
        name={value.key}
        type={fieldsVisible ? "text" : "password"}
        value={value.value === null ? undefined : value.value}
        onChange={(e) => changeValue(e)}
        readOnly={!fieldsEditable}
      />
    </ProfileField>
  );
};

export const Button = (props) => {
  return (
    <ButtonWrapper
      width={props.width}
      height={props.height}
      margin={props.margin}
      className={props.color}
      onClick={props.onClick}
      colorStroke={props.colorStroke}
    >
      {props.title}
      {props.icon ? (
        <Icon>
          <props.icon />
        </Icon>
      ) : (
        <></>
      )}
    </ButtonWrapper>
  );
};

export const ButtonShowHide = ({ fieldsVisible, setFieldsVisible }) => {
  return (
    <ButtonActions
      onClick={() => {
        setFieldsVisible(!fieldsVisible);
      }}
    >
      {!fieldsVisible ? (
        <Show style={{ color: "#fff" }} />
      ) : (
        <Hide style={{ color: "#fff" }} />
      )}
    </ButtonActions>
  );
};

export const ButtonEdit = ({ fieldsEditable, setFieldsEditable }) => {
  return (
    <ButtonActions
      onClick={() => {
        setFieldsEditable(!fieldsEditable);
      }}
    >
      {!fieldsEditable ? (
        <Edit style={{ color: "#fff" }} />
      ) : (
        <EditOff style={{ color: "#fff" }} />
      )}
    </ButtonActions>
  );
};

export const Anchor = (props) => {
  return (
    <AnchorWrapper onClick={props.onClick} margin={props.margin}>
      {props.children}
    </AnchorWrapper>
  );
};

export const Select = (props) => {
  useEffect(() => {}, []);
  return (
    <Selector
      id={props.id}
      name={props.name}
      value={props.current}
      width={props.width}
      height={props.height}
      margin={props.margin}
      className={props.color}
      onChange={props.onChange}
      colorStroke={props.colorStroke}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.options &&
        props.options.map((value, index) => (
          <option key={index} value={value.id}>
            {value.name}
          </option>
        ))}
    </Selector>
  );
};

export const SelectCycleErgometer = (props) => {
  useEffect(() => {}, []);
  return (
    <Selector
      id={props.id}
      name={props.name}
      value={props.current}
      width={props.width}
      height={props.height}
      margin={props.margin}
      className={props.color}
      onChange={props.onChange}
      colorStroke={props.colorStroke}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <option value={null}>Cicloergômetro...</option>
      {props.options &&
        props.options.map((value, index) => (
          <option key={index} value={value.cycleErgometerId}>
            {value.name}
          </option>
        ))}
    </Selector>
  );
};

export const Toogle = (props) => {
  useEffect(() => {}, []);
  return (
    <ToogleLabel
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ToogleInput type="checkbox" {...props} />
      <ToogleSpan
        className="slider"
        onClick={(e) => {
          e.stopPropagation();
        }}
      ></ToogleSpan>
    </ToogleLabel>
  );
};

export const ToogleRoles = (props) => {
  useEffect(() => {}, []);
  return (
    <ToogleLabelRoles
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ToogleInputRoles type="checkbox" {...props} />
      <ToogleSpanRoles
        className="slider"
        onClick={(e) => {
          e.stopPropagation();
        }}
      ></ToogleSpanRoles>
    </ToogleLabelRoles>
  );
};

export const ToogleRolesSearch = (props) => {
  useEffect(() => {}, []);
  return (
    <ToogleLabelRolesSearch
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ToogleInputRoles type="checkbox" {...props} />
      <ToogleSpanRoles
        className="slider"
        onClick={(e) => {
          e.stopPropagation();
        }}
      ></ToogleSpanRoles>
    </ToogleLabelRolesSearch>
  );
};
