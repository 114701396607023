import { useEffect, useState } from "react";
import {
  SearchWrapper,
  AddUserResultWrapper,
  TooglesWrapper,
  ToogleWrapper,
  ConfigItemTextWrapper,
  ConfigItemTextTitle,
  ConfigItemTextValue,
} from "./styled";
import api from "../../../../services/Api";
import {
  Button,
  InputText,
  Select,
  ToogleRolesSearch,
} from "../../../../ui/InputControls";
import { getJwtToken } from "../../../../contexts/authentication";
import { Auth } from "aws-amplify";
import { useAlert } from "../../../../contexts/alert";
import { TitleBig } from "../../../../ui/Texts";
import { usePreload } from "../../../../contexts/preloading";
import { useRoles } from "../../../../contexts/roles";

const LightboxAddUser = () => {
  const { setPreloading } = usePreload();
  const [pesquisaPessoa, setPesquisaPessoa] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [searchOptions, setSearchOptions] = useState([
    {
      id: "",
      name: "Pesquisar por...",
      selected: true,
    },
    {
      id: "Nome",
      name: "Nome da pessoa",
      selected: false,
    },
    {
      id: "CPF",
      name: "CPF (somente números)",
      selected: false,
    },
    {
      id: "Prontuario",
      name: "Prontuário (somente letras e números)",
      selected: false,
    },
    {
      id: "Macroprocesso",
      name: "Macroprocesso",
      selected: false,
    },
  ]);

  const clone = require("rfdc")();

  const changeSearchOptions = (e) => {
    let tempOptions = clone(searchOptions);
    tempOptions.map((value) =>
      value.id === e.target.value
        ? (value.selected = true)
        : (value.selected = false)
    );
    setSearchOptions(tempOptions);
  };

  const searchFor = async (value) => {
    setPreloading(true);
    let tempOptions = clone(searchOptions);
    let searchKey = tempOptions.filter((value) => value.selected);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(
        `/CadastroPessoa/GetFiltroCadastro?${searchKey[0].id}=${value}`,
        config
      )
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setSearchResult(res.data);
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  return (
    <SearchWrapper>
      <TitleBig margin="20px">Cadastrar usuário</TitleBig>
      <Select
        id="search-type"
        name="search-type"
        options={searchOptions}
        current={
          searchOptions && searchOptions.filter((value) => value.selected)[0].id
        }
        width="80%"
        height="40px"
        title="Sair"
        color="grey"
        colorStroke="orange"
        margin="0px 20px"
        onChange={changeSearchOptions}
      />
      <InputText
        value={pesquisaPessoa}
        placeholder="Digite para pesquisar..."
        onChange={(value) => {
          searchFor(value);
          setPesquisaPessoa(value);
        }}
      />
      <AddUserResultWrapper>
        {searchResult &&
          searchResult.map((value, index) => (
            <AddUserForm key={index} item={value} />
          ))}
      </AddUserResultWrapper>
    </SearchWrapper>
  );
};

const AddUserForm = ({ item, valueChange }) => {
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const { setPreloading } = usePreload();
  const [email, setEmail] = useState(null);
  const { rolesValues } = useRoles();
  const [role, setRole] = useState(null);
  const [roleFinal, setRoleFinal] = useState(null);

  const clone = require("rfdc")();

  const changeEmail = (value) => {
    setEmail(value);
  };

  const changeRole = async (e) => {
    let tempRole = clone(role);
    const isChecked = e.target.checked;

    tempRole.forEach((value) => {
      if (value.id === e.target.id) {
        value.selected = isChecked;
      }
    });
    console.log(tempRole);
    setRole(tempRole);
  };

  const cadastrarUsuario = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let cadastrarRoles = role.filter((value) => value.selected);

    setPreloading(true);
    Auth.signUp({
      username: email,
      password: "Abcd@1234",
      attributes: {
        email,
      },
    })
      .then(async (res) => {
        console.log(res.data);
        let rolesSend = [];
        cadastrarRoles.map((value) => rolesSend.push(value.id));
        console.log(item.accountId, email, rolesSend);

        let content = {
          accountId: item.accountId,
          email: email,
          roles: rolesSend,
        };

        let at = await getJwtToken();
        const config = {
          headers: { Authorization: `Bearer ${at}` },
        };

        return api
          .put(`/Account/PutAccountUpdate`, content, config)
          .then(async (res) => {
            if (res.status === 200) {
              console.log(res.data);
              setAlertText(
                "Usuário Cadastrato! Ativar a conta pelo e-mail cadastrado!"
              );
              setAlertType("success");
              setClasseAlert("on");
              setPreloading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setAlertText(error.response.data);
            setAlertType("error");
            setClasseAlert("on");
            setPreloading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setPreloading(false);
      });
  };

  const cpfMask = (value) => {
    const cpfAtual = value;

    let cpfAtualizado;

    cpfAtualizado = cpfAtual.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      function (regex, argumento1, argumento2, argumento3, argumento4) {
        return (
          argumento1 + "." + argumento2 + "." + argumento3 + "-" + argumento4
        );
      }
    );
    return cpfAtualizado;
  };

  useEffect(() => {}, [email]);

  useEffect(() => {
    setRole(rolesValues);
  }, [rolesValues]);

  useEffect(() => {
    setRoleFinal(role);
  }, [role]);

  return (
    <ConfigItemTextWrapper className={"roles"}>
      <ConfigItemTextTitle className={"roles"}>
        Id: {item.accountId}
        <br />
        Nome: {item.nome}
        <br />
        Cpf: {cpfMask(item.cpf)}
        <br />
        Data de nascimento:{" "}
        {new Date(item.dataDeNascimento).getDate() +
          "/" +
          (new Date(item.dataDeNascimento).getMonth() + parseInt(1)) +
          "/" +
          new Date(item.dataDeNascimento).getFullYear()}
        <br />
        {item.prontuario && `Prontuário: ${item.prontuario}`}
        <br />
        {item.macroprocesso && `Macroprocesso: ${item.macroprocesso}`}
        <br />
        {item.cpfMedicoResponsavel &&
          `CPF Médico: ${cpfMask(item.cpfMedicoResponsavel)}`}
        <br />
      </ConfigItemTextTitle>
      <ConfigItemTextValue className={"roles"}>
        <InputText
          width="50%"
          margin="auto 0px"
          placeholder="E-mail"
          value={email}
          onChange={changeEmail}
        />
        <TooglesWrapper>
          {roleFinal &&
            roleFinal.map((value, index) => (
              <ToogleWrapper key={index}>
                {value.name}
                <ToogleRolesSearch
                  key={index}
                  checked={value.selected}
                  value={value.value}
                  name={value.name}
                  id={value.id}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeRole(e);
                  }}
                />
              </ToogleWrapper>
            ))}
        </TooglesWrapper>
      </ConfigItemTextValue>
      <Button
        width="10%"
        title="Cadastrar"
        color="primary"
        margin="0px"
        onClick={cadastrarUsuario}
      />
    </ConfigItemTextWrapper>
  );
};

export default LightboxAddUser;
