import { useEffect, useContext, useState, createContext } from "react";
import api from "../services/Api.js";

import { getJwtToken } from "./authentication.jsx";
import { usePreload } from "./preloading.jsx";

const AccountsContext = createContext();

export default function AccountsProvider({ children }) {
  const { setPreloading } = usePreload();
  const [accounts, setAccounts] = useState(null);
  const [accountsFiltered, setAccountsFiltered] = useState(null);
  const clone = require("rfdc")();

  const getAccounts = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    api
      .get(`/CadastroPessoa/GetMosaicoFinalizado`, config)
      .then((res) => {
        if (res.status === 200) {
          let accountsTemp = clone(res.data);
          let loadCount = 0;
          accountsTemp.forEach((account) => {
            if (account.photo !== null) {
              setPreloading(true);
              api
                .get(account.photo, config)
                .then((res2) => {
                  setPreloading(true);
                  account.photo = `data:image/jpeg;base64,${res2.data}`;
                  loadCount++;
                  if (accountsTemp.length === loadCount) {
                    setAccounts(accountsTemp);
                    setPreloading(false);
                  }
                })
                .catch((error) => {
                  setPreloading(false);
                });
            } else {
              loadCount++;
              if (accountsTemp.length === loadCount) {
                setAccounts(accountsTemp);
                setPreloading(false);
              }
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  const getAccountsFiltered = (value) => {
    if (accounts) {
      let accountsClone = clone(accounts);
      let accountsTemp = accountsClone.filter((obj) => {
        if (value !== "") {
          let regex = "";
          regex = new RegExp(value, "i");
          let position = obj.nome.search(regex);
          if (position !== -1) {
            console.log(regex);
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

      setAccountsFiltered(accountsTemp);
    }
  };

  useEffect(() => {}, [accounts]);

  return (
    <AccountsContext.Provider
      value={{
        accounts,
        getAccounts,
        accountsFiltered,
        setAccountsFiltered,
        getAccountsFiltered,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
}

export function useAccounts() {
  const context = useContext(AccountsContext);
  if (!context)
    throw new Error("useAccounts must be used within a AccountsProvider");

  const {
    accounts,
    getAccounts,
    accountsFiltered,
    setAccountsFiltered,
    getAccountsFiltered,
  } = context;

  return {
    accounts,
    getAccounts,
    accountsFiltered,
    setAccountsFiltered,
    getAccountsFiltered,
  };
}
