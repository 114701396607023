import React, { useState, useEffect } from "react";
import { GridThumbConfiguracoesHome } from "../../../../ui/General";
import ConfigCicloCadeira from "./ConfigCicloCadeira";
import ConfigCicloCiclo from "./ConfigCicloCiclo";
import { TitleSessaoPendente, GridThumbWrapper } from "./styled";
import { usePaciente } from "../../../../contexts/paciente";

const ConfigCiclo = ({ pacienteid, sessaoid }) => {
  const { paciente } = usePaciente();
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [configScreen, setConfigScreen] = useState("ciclo");
  const [activeConfigCadeira, setActiveConfigCadeira] = useState(false);

  const configCiclo = (id) => {
    setConfigScreen("ciclo");
  };

  const configCadeira = (id) => {
    setConfigScreen("cadeira");
  };

  useEffect(() => {
    if (!activeConfigCadeira) {
      setConfigScreen("ciclo");
    }
  }, [activeConfigCadeira]);

  useEffect(() => {
    if (paciente !== null && paciente.agenda !== undefined) {
      let filtered = paciente.agenda.filter(
        (value) => value.sessionId === parseInt(sessaoid)
      );
      setSessaoAtual(filtered);
    }
  }, [paciente]);

  return configScreen === "ciclo" && sessaoAtual ? (
    <ConfigCicloCiclo
      pacienteid={pacienteid}
      sessaoid={sessaoid}
      configCiclo={configCiclo}
      configCadeira={configCadeira}
      activeConfigCadeira={activeConfigCadeira}
      setActiveConfigCadeira={setActiveConfigCadeira}
    />
  ) : configScreen === "cadeira" && sessaoAtual ? (
    <ConfigCicloCadeira
      pacienteid={pacienteid}
      sessaoid={sessaoid}
      configCiclo={configCiclo}
      configCadeira={configCadeira}
      activeConfigCadeira={activeConfigCadeira}
      setActiveConfigCadeira={setActiveConfigCadeira}
    />
  ) : (
    <></>
  );
};

export default ConfigCiclo;
