import styled from "styled-components";

export const CicloItemWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.colors.greyDarker} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 16px 32px ${({ theme }) => theme.colors.shadow};
  border-radius: 20px;
  margin: 20px 20px;
  box-sizing: border-box;
`;

export const CicloTitle = styled.div`
  font: normal normal 400 16px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const CicloName = styled.div`
  font: normal normal 400 12px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  font-weight: light;
  text-align: center;
  margin-top: 5px;
`;

export const CicloValue = styled.div`
  font: normal normal 400 12px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: light;
  text-align: center;
`;
