import styled from "styled-components";

export const ARCicloContainerWrapper = styled.div`
  overflow: hidden;

  &.hide {
    width: 0px;
    height: 0px;
  }
`;

export const TitleSecao = styled.div`
  width: 100%;
  margin: 0px auto 20px;
  font: normal normal 800 25px/22px ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.orange};
  text-align: left;
  padding: 0px 20px;
  box-sizing: border-box;
`;
