import React, { useEffect, useState } from "react";
import {
  InputText,
  InputPassword,
  Button,
  Anchor,
} from "../../../../../ui/InputControls";
import { PlusSm } from "@styled-icons/heroicons-solid/PlusSm";

const HeaderPacientes = ({ filterPacientes }) => {
  const [procuraUsuario, setProcuraUsuario] = useState("");
  const procurarPaciente = (valor) => {
    filterPacientes(valor);
  };
  const adicionarPaciente = (valor) => {
    console.log(valor);
  };

  useEffect(() => {
    filterPacientes("");
  }, []);
  return (
    <>
      <InputText
        width="200px"
        id="procurarpaciente"
        placeholder="Procurar paciente..."
        value={procuraUsuario}
        onChange={(value) => {
          procurarPaciente(value);
          setProcuraUsuario(value);
        }}
      />
      <Button
        width="214px"
        title="Adicionar Paciente"
        color="transparent"
        colorStroke="orange"
        onClick={adicionarPaciente}
        icon={PlusSm}
      />
    </>
  );
};

export default HeaderPacientes;
