import React, { useState, useEffect } from "react";
import {
  ConfigItemText,
  ConfigItemTextInput,
  GridThumbUserCiclo,
} from "../../../../ui/General";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Toogle } from "../../../../ui/InputControls";
import {
  CicloWrapper,
  GridThumbWrapper,
  SubtitleSessaoPendente,
  ToogleWrapper,
} from "./styled";
import api from "../../../../services/Api";
import { getJwtToken, useAuth } from "../../../../contexts/authentication";
import { usePreload } from "../../../../contexts/preloading";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { TitleBig } from "../../../../ui/Texts";
import { useAlert } from "../../../../contexts/alert";

const Ciclo = ({ usuario }) => {
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const { setPreloading } = usePreload();
  const { publishMqtt } = useAuth();
  const { cicloid } = useParams();
  const { setBottomMenuSections } = useBottomMenuSections();
  const navigate = useNavigate();
  // const { ciclos } = useCiclos();
  const [ciclo, setCiclo] = useState(null);
  const [active, setActive] = useState(null);
  const [toogleOn, setToogleOn] = useState(null);
  const [firstAccess, setFirstAccess] = useState(true);

  const clone = require("rfdc")();

  const loadCiclo = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/CycleErgometer/GetByCycleErgometerId/${cicloid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          let response = { ...res.data };
          // console.log(response);
          setCiclo(response);
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        // preload.setPreloading(false);
      });
  };

  const enableCiclo = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/CycleErgometer/GetEnableCiclo/${cicloid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          api
            .get(
              `/CycleErgometer/AddLog/${cicloid}/Cicloergômetro id: ${cicloid} ativado.`,
              config
            )
            .then(async (res3) => {
              setPreloading(false);
              if (!firstAccess) {
                setAlertText("Ciclo ativado!");
                setAlertType("success");
                setClasseAlert("on");
              } else {
                setFirstAccess(false);
              }
              console.log(
                "Log ciclo",
                `Cicloergômetro id: ${cicloid} ativado.`
              );
            })
            .catch((error) => {
              console.log(error);
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        if (!firstAccess) {
          setAlertText("Ocorreu algum problema ao ativar o ciclo!");
          setAlertType("error");
          setClasseAlert("on");
        } else {
          setFirstAccess(false);
        }
      });
  };

  const disableCiclo = async () => {
    setPreloading(true);
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .get(`/CycleErgometer/GetDisableCiclo/${cicloid}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          api
            .get(
              `/CycleErgometer/AddLog/${cicloid}/Cicloergômetro id: ${cicloid} desativado.`,
              config
            )
            .then(async (res3) => {
              setPreloading(false);
              if (!firstAccess) {
                setAlertText("Ciclo desativado!");
                setAlertType("success");
                setClasseAlert("on");
              } else {
                setFirstAccess(false);
              }
              console.log(
                "Log ciclo",
                `Cicloergômetro id: ${cicloid} desativado.`
              );
            })
            .catch((error) => {
              console.log(error);
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        if (!firstAccess) {
          setAlertText("Ocorreu algum problema ao desativar o ciclo!");
          setAlertType("error");
          setClasseAlert("on");
        } else {
          setFirstAccess(false);
        }
      });
  };

  const changeAttribute = (name, value) => {
    setCiclo((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const changeSetupAttribute = (name, value) => {
    let cicloTemp = clone(ciclo);

    let macAddXxxTemp = cicloTemp.macAddXxx;

    macAddXxxTemp[name] = value;

    setCiclo(cicloTemp);
  };

  function toUppercaseKeys(obj) {
    const entries = Object.entries(obj);

    return Object.fromEntries(
      entries.map(([key, value]) => {
        return [key.toUpperCase().replaceAll("_", "."), value];
      })
    );
  }

  const atualizarCiclo = async () => {
    const clone = require("rfdc")();
    let content = clone(ciclo);

    console.log(content);

    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .put(`/CycleErgometer/`, content, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPreloading(false);
          setAlertText("Ciclo atualizado!");
          setAlertType("success");
          setClasseAlert("on");
        }
        let configMqtt = { ...ciclo.macAddXxx };
        delete configMqtt.macAddXxxId;
        delete configMqtt.cycleErgometerId;
        let finalConfigMqtt = toUppercaseKeys(configMqtt);
        console.log(finalConfigMqtt);

        publishMqtt(
          `LucyIO/Ciclo/CONFIG/${content.macAddress}`,
          finalConfigMqtt,
          1,
          true
        );
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        setAlertText("Ocorreu algum problema ao atualizar o cicloergômetro");
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const excluirCiclo = async (id) => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    api
      .delete(`/CycleErgometer?CycleErgometerId=${id}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPreloading(false);
          setAlertText("Ciclo excluído!");
          setAlertType("success");
          setClasseAlert("on");
          setTimeout(() => {
            navigate(`/ciclos`);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
        setAlertText("Ocorreu algum problema ao excluir o cicloergômetro");
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  useEffect(() => {
    if (active !== null) {
      setToogleOn(active);
      if (active) {
        enableCiclo();
      } else {
        disableCiclo();
      }
    }
  }, [active]);

  useEffect(() => {
    if (ciclo) {
      setToogleOn(ciclo.active);
      setActive(ciclo.active);
      console.log(ciclo);
    }
  }, [ciclo]);

  useEffect(() => {
    loadCiclo();
    setBottomMenuSections("adminCiclos");
  }, []);

  return (
    <>
      {ciclo && (
        <>
          {" "}
          <TitleBig>{ciclo.name}</TitleBig>
          <CicloWrapper>
            <GridThumbWrapper>
              <GridThumbUserCiclo item={ciclo} />
              {toogleOn !== null && (
                <ToogleWrapper>
                  <Toogle
                    checked={toogleOn}
                    onChange={(e) => {
                      setActive(!active);
                    }}
                  />
                </ToogleWrapper>
              )}
            </GridThumbWrapper>

            <ConfigItemText
              item={{
                name: "Id Cicloergômetro",
                value: ciclo.cycleErgometerId,
              }}
            />
            <ConfigItemTextInput
              item={{
                name: "Nome",
                id: "name",
                value: ciclo.name,
              }}
              valueChange={changeAttribute}
            />
            <ConfigItemTextInput
              item={{
                name: "MAC Address",
                id: "macAddress",
                value: ciclo.macAddress,
              }}
              valueChange={changeAttribute}
            />

            <SubtitleSessaoPendente>Status Geral</SubtitleSessaoPendente>
            <ConfigItemText
              item={{
                name: "Número de sessões realizadas",
                value: 259,
              }}
            />
            <ConfigItemText
              item={{
                name: "Criado em",
                value: new Date(ciclo.createdAt).toLocaleString("pt-BR"),
              }}
            />
            <ConfigItemTextInput
              item={{
                name: "Status",
                id: "status",
                value: ciclo.status,
              }}
              valueChange={changeAttribute}
            />
            <ConfigItemTextInput
              item={{
                name: "Estado Atual",
                id: "state",
                value: ciclo.state,
              }}
              valueChange={changeAttribute}
            />

            {ciclo.macAddXxx && (
              <SubtitleSessaoPendente>
                Setup Cicloergômetro
              </SubtitleSessaoPendente>
            )}
            {ciclo.macAddXxx &&
              Object.keys(ciclo.macAddXxx).map((key, index) => {
                return key !== "macAddXxxId" && key !== "cycleErgometerId" ? (
                  <ConfigItemTextInput
                    key={index}
                    item={{
                      name: key,
                      id: key,
                      value: ciclo.macAddXxx[key],
                    }}
                    valueChange={changeSetupAttribute}
                  />
                ) : (
                  <React.Fragment key={index}></React.Fragment>
                );
              })}
            <Button
              width="230px"
              title="Excluir Cicloergômetro"
              color="danger"
              onClick={() => excluirCiclo(ciclo.cycleErgometerId)}
            />
            <Button
              width="250px"
              title="Atualizar Cicloergômetro"
              color="primary"
              onClick={atualizarCiclo}
            />
          </CicloWrapper>
        </>
      )}
    </>
  );
};

export default Ciclo;
