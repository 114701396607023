import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../../shared/Components/General/Sidebar";
import { ContainerFullwidthLeft, MainContainer } from "../../ui/Containers";
import { SectionMenu } from "../../ui/NavigationComponents";
import { protectedRoute } from "../../contexts/authentication";
import { useUser } from "../../contexts/user";

const Paciente = () => {
  let navigate = useNavigate();
  const { user, setUser } = useUser();

  useEffect(() => {
    if (user) {
      navigate(`/paciente/${user.id}`);
      console.log(user);
    }
  }, [user]);

  useEffect(() => {
    console.log(typeof autoPlayAudio);
  }, []);

  return (
    <>
      <ContainerFullwidthLeft>
        <Sidebar user={user} setUser={setUser} />
        <MainContainer>
          <Outlet />
        </MainContainer>
      </ContainerFullwidthLeft>
      <SectionMenu />
    </>
  );
};

export default protectedRoute(Paciente);
