import React, { useState, useEffect } from "react";
import Player from "./Player";
import { ARCicloContainerWrapper, TitleSecao } from "./styled";

const ARCicloContainer = ({
  pacienteid,
  sessaoid,
  config,
  classe,
  enablePip,
  setEnablePip,
  freio,
  setFreio,
  toogleFreio,
  decrementarMotor,
  incrementarMotor,
  incrementarCarga,
  decrementarCarga,
  incrementarEstimulo,
  decrementarEstimulo,
}) => {
  return (
    <ARCicloContainerWrapper className={classe}>
      <TitleSecao>AR</TitleSecao>
      <Player
        classe={classe}
        enablePip={enablePip}
        setEnablePip={setEnablePip}
        freio={freio}
        setFreio={setFreio}
        toogleFreio={toogleFreio}
        decrementarMotor={decrementarMotor}
        incrementarMotor={incrementarMotor}
        incrementarCarga={incrementarCarga}
        decrementarCarga={decrementarCarga}
        incrementarEstimulo={incrementarEstimulo}
        decrementarEstimulo={decrementarEstimulo}
      />
    </ARCicloContainerWrapper>
  );
};

export default ARCicloContainer;
