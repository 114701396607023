import React, { useEffect, useState } from "react";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import ConfigCiclo from "../ConfigCiclo";
import DadosCiclo from "../DadosCiclo";
import ARCiclo from "../ARCiclo";
import Notas from "../Notas";
import { useItemCiclo } from "../../../../contexts/itemCiclo";
import { useUser } from "../../../../contexts/user";
import ResumoSessao from "../ResumoSessao";
import { useCiclos } from "../../../../contexts/ciclos";
import { useAuth } from "../../../../contexts/authentication";

const clone = require("rfdc")();

const SessaoFazendo = ({ pacienteid, sessaoid, autoChangeTabIndex }) => {
  const { itemCiclo, setItemCiclo, loadConfig, sendConfiguraSessao } =
    useItemCiclo();
  const { user } = useUser();
  const [enablePip, setEnablePip] = useState(true);
  let {
    bottomMenuSections,
    setBottomMenuSections,
    currentBottomMenu,
    setCurrentBottomMenu,
  } = useBottomMenuSections();
  const {
    ciclos,
    setCiclos,
    getCiclos,
    ciclosFiltered,
    getCiclosFiltered,
    getSelectedCiclo,
  } = useCiclos();
  const { publishMqtt, subscribeMqtt } = useAuth();
  const [currentTabScreen, setCurrentTabScreen] = useState(null);
  const [freio, setFreio] = useState({
    name: "Frear / Liberar pedal",
    active: false,
  });

  const toogleFreio = async () => {
    let newEstado = !freio.active;

    let comando = 11; //comando encerra corrida

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando liberar/frear pedal enviado. packet id: " + result.packet_id
        );
        setFreio((values) => ({
          ...values,
          active: newEstado,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const decrementarMotor = async () => {
    let comando = 18; //comando decrementa motor

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando decrementar velocidade motor. packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const incrementarMotor = async () => {
    let comando = 17; //comando incrementar motor

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando decrementar velocidade motor. packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const incrementarCarga = async () => {
    let comando = 13; //comando incrementar

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando decrementar carga . packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const decrementarCarga = async () => {
    let comando = 14; //comando decrementa

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando decrementar carga . packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const incrementarEstimulo = async () => {
    let comando = 15; //comando incrementar

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando decrementar estimulo . packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const decrementarEstimulo = async () => {
    let comando = 16; //comando decrementa

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result) => {
        console.log(
          "Comando decrementar estimulo . packet id: " + result.packet_id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialConfig = () => {
    loadConfig(pacienteid, sessaoid)
      .then((response) => {
        setItemCiclo((values) => ({
          ...values,
          pacienteid: pacienteid,
          sessaoid: sessaoid,
        }));
        setBottomMenuSections("terapeutaSessao");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setBottomMenuSections("terapeutaSessao");
  }, [user]);

  useEffect(() => {
    if (currentBottomMenu) {
      let selectedIndex = currentBottomMenu.findIndex(
        (obj, index) => obj.selected
      );
      setCurrentTabScreen(currentBottomMenu[selectedIndex]);
    }
  }, [currentBottomMenu]);

  useEffect(() => {
    if (itemCiclo.config) {
      // sendConfiguraSessao();
    }
  }, [itemCiclo]);

  useEffect(() => {
    if (!itemCiclo.config) {
      initialConfig();
    }
    setBottomMenuSections("terapeutaSessao");
  }, []);

  useEffect(() => {
    if (autoChangeTabIndex) {
      setCurrentTabScreen(currentBottomMenu[autoChangeTabIndex]);
      if (currentBottomMenu) {
        let currentBottomMenuTemp = clone(currentBottomMenu);

        currentBottomMenuTemp.map((obj) => {
          obj.selected = false;
        });

        currentBottomMenuTemp.map((obj, index) => {
          if (index === autoChangeTabIndex) {
            obj.selected = true;
          }
        });
        setCurrentBottomMenu(currentBottomMenuTemp);
      }
    }
  }, [autoChangeTabIndex]);

  useEffect(() => {
    if (currentTabScreen) {
      if (
        currentTabScreen.name === "Borg" ||
        currentTabScreen.name === "Sessão"
      ) {
        setEnablePip(true);
      } else {
        setEnablePip(false);
      }
    }
  }, [currentTabScreen]);

  return (
    <>
      {currentTabScreen && currentTabScreen.name === "Borg" && (
        <Notas pacienteid={pacienteid} sessaoid={sessaoid} />
      )}
      {currentTabScreen && currentTabScreen.name === "Configurações" && (
        <ConfigCiclo pacienteid={pacienteid} sessaoid={sessaoid} />
      )}
      {currentTabScreen && currentTabScreen.name === "Sessão" && (
        <DadosCiclo
          pacienteid={pacienteid}
          sessaoid={sessaoid}
          freio={freio}
          setFreio={setFreio}
          toogleFreio={toogleFreio}
          decrementarMotor={decrementarMotor}
          incrementarMotor={incrementarMotor}
          incrementarCarga={incrementarCarga}
          decrementarCarga={decrementarCarga}
          incrementarEstimulo={incrementarEstimulo}
          decrementarEstimulo={decrementarEstimulo}
        />
      )}
      <ARCiclo
        pacienteid={pacienteid}
        sessaoid={sessaoid}
        enablePip={enablePip}
        setEnablePip={setEnablePip}
        freio={freio}
        setFreio={setFreio}
        toogleFreio={toogleFreio}
        decrementarMotor={decrementarMotor}
        incrementarMotor={incrementarMotor}
        incrementarCarga={incrementarCarga}
        decrementarCarga={decrementarCarga}
        incrementarEstimulo={incrementarEstimulo}
        decrementarEstimulo={decrementarEstimulo}
        classe={
          currentTabScreen && currentTabScreen.name === "AR" ? "" : "hide"
        }
      />
      {currentTabScreen && currentTabScreen.name === "Resumo" && (
        <ResumoSessao />
      )}
    </>
  );
};

export default SessaoFazendo;
