import { useState } from "react";
import { AddCicloWrapper } from "./styled";
import api from "../../../../services/Api";
import { Button, InputText } from "../../../../ui/InputControls";
import { getJwtToken } from "../../../../contexts/authentication";
import { useAlert } from "../../../../contexts/alert";
import { TitleBig } from "../../../../ui/Texts";
import { useLightbox } from "../../../../contexts/lightbox";
import { useCiclos } from "../../../../contexts/ciclos";
import { usePreload } from "../../../../contexts/preloading";

const LightboxAddCiclo = () => {
  const { setPreloading } = usePreload();
  const { getCiclos } = useCiclos();
  const { setLightbox } = useLightbox();
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const [nomeCiclo, setNomeCiclo] = useState(null);
  const [macAddress, setMacAddress] = useState(null);

  const cadastrarCiclo = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let content = {
      name: nomeCiclo,
      macAddress: macAddress,
      status: "Em manutenção",
      currentStatus: "Desligado",
      active: true,
      macAddXxx: {
        swvr: "",
        dbapP_TAGC: "",
        ssid: "",
        pass: "",
        actv: "",
        modowifi: "",
        ipmultiparametrico: "",
        macmultiparametrico: "",
      },
    };

    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };

    setPreloading(true);
    api
      .delete(`/CycleErgometer?CycleErgometerId=3`, config)
      .then(async (res) => {})
      .catch((error) => {});
    api
      .delete(`/CycleErgometer?CycleErgometerId=4`, config)
      .then(async (res) => {})
      .catch((error) => {});
    api
      .delete(`/CycleErgometer?CycleErgometerId=5`, config)
      .then(async (res) => {})
      .catch((error) => {});
    api
      .delete(`/CycleErgometer?CycleErgometerId=7`, config)
      .then(async (res) => {})
      .catch((error) => {});
    api
      .post(`/CycleErgometer`, content, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          setAlertText("Ciclo cadastrado com sucesso!");
          setAlertType("success");
          setClasseAlert("on");
          setLightbox("");
          getCiclos();
          setPreloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Problema ao criar Cicloergômetro");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  return (
    <AddCicloWrapper>
      <TitleBig margin="20px">Cadastrar Cicloergômetro</TitleBig>
      <InputText
        width="80%"
        id="name"
        placeholder="Nome do Cicloergômetro..."
        value={nomeCiclo}
        onChange={setNomeCiclo}
      />
      <InputText
        width="80%"
        id="macAddress"
        placeholder="MAC Address..."
        value={macAddress}
        onChange={setMacAddress}
      />
      <Button
        width="150px"
        title="Cadastrar"
        color="primary"
        margin="20px"
        onClick={cadastrarCiclo}
      />
    </AddCicloWrapper>
  );
};

export default LightboxAddCiclo;
