import React, { useState, useEffect } from "react";
import { useSession } from "../../../../../contexts/session";
import {
  ConfigItemText,
  GridThumbDados,
  GridThumbDadosAdd,
  ConfigItemSelect,
  ConfigItemSlider,
  ConfigItemSliderRange,
} from "../../../../../ui/General";
import {
  TitleSecao,
  NotasWrapper,
  NotasText,
  NotasBorg,
  BorgTitle,
  BorgButtons,
  BorgButton,
  BorgButtonValue,
  BorgButtonDescription,
} from "./styled";

const NotasContainer = ({ pacienteid, sessaoid, escala }) => {
  const { session, setSession } = useSession();

  const selectBorg = (value) => {
    setSession((values) => ({
      ...values,
      borg: session.borg
        ? [
            ...session.borg,
            {
              time: new Date().toISOString(),
              borg: parseInt(value),
            },
          ]
        : [
            {
              time: new Date().toISOString(),
              borg: parseInt(value),
            },
          ],
    }));
  };

  const updateNota = (e) => {
    setSession((values) => ({
      ...values,
      nota: e.target.value,
    }));
  };

  useEffect(() => {
    console.log(
      session.sessionid === sessaoid &&
        session.state &&
        session.state !== "" &&
        session.state !== "Cicloergômetro Disponível"
    );
  }, []);
  return (
    <>
      <TitleSecao>Borg</TitleSecao>
      {session.sessionid === sessaoid &&
        session.state &&
        session.state !== "" &&
        session.state !== "Cicloergômetro Disponível" && (
          <NotasWrapper>
            <>
              <NotasBorg>
                <BorgTitle>Nível de esforço (BORG)</BorgTitle>
                <BorgButtons>
                  {escala.map((value, index) => (
                    <BorgButton
                      key={index}
                      onClick={() => selectBorg(value.value)}
                      className={
                        session.borg &&
                        value.value ===
                          session.borg[session.borg.length - 1].value
                          ? "selected"
                          : ""
                      }
                    >
                      <BorgButtonValue
                        className={
                          session.borg &&
                          parseInt(value.value) ===
                            session.borg[session.borg.length - 1].borg
                            ? "selected"
                            : ""
                        }
                      >
                        {value.value}
                      </BorgButtonValue>
                      <BorgButtonDescription
                        className={
                          session.borg &&
                          parseInt(value.value) ===
                            session.borg[session.borg.length - 1].borg
                            ? "selected"
                            : ""
                        }
                      >
                        {value.description}
                      </BorgButtonDescription>
                    </BorgButton>
                  ))}
                </BorgButtons>
              </NotasBorg>
              <NotasText
                placeholder="Escreva notas sobre a sessão aqui..."
                onChange={updateNota}
                value={session.nota || ""}
              ></NotasText>
            </>
          </NotasWrapper>
        )}
    </>
  );
};

export default NotasContainer;
