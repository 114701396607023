import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import "./style.scss?cache=1234";
import { useSession } from "../../../../../../contexts/session";
import { escala } from "../../../../../../assets/mock";
import { useItemCiclo } from "../../../../../../contexts/itemCiclo";
import { useCiclos } from "../../../../../../contexts/ciclos";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Torus, Tube, useTexture } from "@react-three/drei";
import * as THREE from "three";
import background from "../../../../../../assets/images/goegap_4k.png";
import Tunnel from "./Tunnel";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";

function Box(props) {
  const mesh = useRef();
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  useFrame((state, delta) => (mesh.current.rotation.x += delta));
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? "hotpink" : "orange"} />
    </mesh>
  );
}

const Player = ({
  classe,
  enablePip,
  setEnablePip,
  freio,
  setFreio,
  toogleFreio,
  decrementarMotor,
  incrementarMotor,
  incrementarCarga,
  decrementarCarga,
  incrementarEstimulo,
  decrementarEstimulo,
  // audioOn,
  // toogleAudio,
}) => {
  const playerRef = useRef(null);
  const playerHudRef = useRef(null);
  const [playbackRate, setPlaybackRate] = useState(null);
  const [metaverso, setMetaverso] = useState(true);
  const [carga, setCarga] = useState(0);
  const [rotacoesMinMax, setRotacoesMinMax] = useState([0, 80]);
  const [showBorg, setShowBorg] = useState(false);
  const { session, setSession } = useSession();
  const { itemCiclo, configMqtt } = useItemCiclo();
  const [tempoTratado, setTempoTratado] = useState("00:00:00");
  const {
    ciclos,
    setCiclos,
    getCiclos,
    ciclosFiltered,
    getCiclosFiltered,
    getSelectedCiclo,
  } = useCiclos();
  const borgTimer = useRef(null);

  const selectBorg = (value) => {
    setShowBorg(false);
    setSession((values) => ({
      ...values,
      borg: session.borg
        ? [
            ...session.borg,
            { time: new Date().toISOString(), borg: parseInt(value) },
          ]
        : [{ time: new Date().toISOString(), borg: parseInt(value) }],
    }));
  };

  useEffect(() => {
    if (playerRef.current) {
      let player = playerRef.current.getInternalPlayer();
      if (player) {
        player.id = "video-ar";
      }
    }
  }, [playerRef.current]);

  useEffect(() => {
    if (session.data && session.data.ACOMPANHAMENTO_RAW) {
      if (
        session.data.ACOMPANHAMENTO_RAW[
          session.data.ACOMPANHAMENTO_RAW.length - 1
        ]
      ) {
        let velocidade =
          (parseInt(
            session.data.ACOMPANHAMENTO_RAW[
              session.data.ACOMPANHAMENTO_RAW.length - 1
            ].Rot
          ) /
            rotacoesMinMax[1]) *
          6;
        if (playerRef.current) {
          setPlaybackRate(velocidade);
        }
        let cargaTemp =
          session.data &&
          session.data.ACOMPANHAMENTO_RAW &&
          session.data.ACOMPANHAMENTO_RAW.length > 0 &&
          session.data.ACOMPANHAMENTO_RAW[
            session.data.ACOMPANHAMENTO_RAW.length - 1
          ].Carga;
        setCarga(parseInt(cargaTemp));
      }
    }
    if (
      session.data &&
      session.data.ACOMPANHAMENTO_RAW &&
      session.data.ACOMPANHAMENTO_RAW.length > 0 &&
      session.data.ACOMPANHAMENTO_RAW[
        session.data.ACOMPANHAMENTO_RAW.length - 1
      ].Tempo
    ) {
      let horaTratatoTemp = parseInt(
        session.data.ACOMPANHAMENTO_RAW[
          session.data.ACOMPANHAMENTO_RAW.length - 1
        ].Tempo / 3600
      ).toString();
      if (horaTratatoTemp < 10) {
        horaTratatoTemp = "0" + horaTratatoTemp;
      }
      let minutoTratatoTemp = parseInt(
        session.data.ACOMPANHAMENTO_RAW[
          session.data.ACOMPANHAMENTO_RAW.length - 1
        ].Tempo / 60
      ).toString();
      if (minutoTratatoTemp < 10) {
        minutoTratatoTemp = "0" + minutoTratatoTemp;
      }
      let segundoTratatoTemp = parseInt(
        session.data.ACOMPANHAMENTO_RAW[
          session.data.ACOMPANHAMENTO_RAW.length - 1
        ].Tempo % 60
      ).toString();
      if (segundoTratatoTemp < 10) {
        segundoTratatoTemp = "0" + segundoTratatoTemp;
      }
      setTempoTratado(
        horaTratatoTemp + ":" + minutoTratatoTemp + ":" + segundoTratatoTemp
      );
    }
  }, [session]);
  useEffect(() => {}, [carga]);

  useEffect(() => {
    if (
      enablePip &&
      session.data &&
      session.data.ACOMPANHAMENTO_RAW &&
      session.data.ACOMPANHAMENTO_RAW[
        session.data.ACOMPANHAMENTO_RAW.length - 1
      ]
    ) {
      let velocidade =
        (parseInt(
          session.data.ACOMPANHAMENTO_RAW[
            session.data.ACOMPANHAMENTO_RAW.length - 1
          ].Rot
        ) /
          rotacoesMinMax[1]) *
        6;
      if (playerRef.current) {
        setPlaybackRate(velocidade / 1.2);
      }
    } else {
      if (session.data && session.data.ACOMPANHAMENTO_RAW) {
        if (
          session.data.ACOMPANHAMENTO_RAW[
            session.data.ACOMPANHAMENTO_RAW.length - 1
          ]
        ) {
          let velocidade =
            (parseInt(
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].Rot
            ) /
              rotacoesMinMax[1]) *
            6;
          if (playerRef.current) {
            setPlaybackRate(velocidade);
          }
        }
      }
    }
  }, [enablePip]);

  useEffect(() => {
    borgTimer.current = setInterval(() => {
      setShowBorg(true);
    }, 600000);

    return () => {
      try {
        clearInterval(borgTimer.current);
      } catch (err) {}
    };
  }, []);

  return (
    <>
      <div className={`borg ${showBorg ? "on" : ""} ${classe}`}>
        <div className="borg-title">
          Qual o seu nível de esforço neste momento?
        </div>
        <div className="borg-values">
          {escala.map((value, index) => (
            <div
              key={index}
              className={`borg-value ${
                session.borg &&
                parseInt(value.value) ===
                  session.borg[session.borg.length - 1].borg
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                selectBorg(value.value);
              }}
            >
              <div className="borg-value-number">{value.value}</div>
              <div
                className={`borg-value-description ${
                  session.borg &&
                  parseInt(value.value) ===
                    session.borg[session.borg.length - 1].borg
                    ? "selected"
                    : ""
                }`}
              >
                {value.description}
              </div>
            </div>
          ))}
        </div>
        <div className="borg-warning">
          {`Terapeuta: Aferir pressão e temperatura do paciente!`}
        </div>
      </div>
      <div className={`odometer ${classe}`}>
        <div className="rpm">
          <div className="child">
            {session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              `${
                session.data.ACOMPANHAMENTO_RAW[
                  session.data.ACOMPANHAMENTO_RAW.length - 1
                ].Rot
              } RPM`}
          </div>
        </div>
        <div className="general-top">
          <div>
            <div className="estimulo-title">Comandos</div>
            <div className="estimulo-title-top">Comandos</div>
          </div>
          {session.state &&
            session.state !== "" &&
            (session.state === "Aquecimento" ||
              session.state === "Resfriamento") && (
              <>
                <div
                  className="general-data-top "
                  onClick={() => decrementarMotor()}
                >
                  <div className="title estimulo">-</div>
                  <div className="value">RPM Motor</div>
                </div>
                <div
                  className="general-data-top "
                  onClick={() => incrementarMotor()}
                >
                  <div className="title estimulo">+</div>
                  <div className="value">RPM Motor</div>
                </div>
              </>
            )}
          {session.state &&
            session.state !== "" &&
            session.state === "Corrida" && (
              <>
                {configMqtt["TipoCarga"] === "0" && (
                  <>
                    <div
                      className="general-data-top "
                      onClick={decrementarCarga}
                    >
                      <div className="title estimulo">-</div>
                      <div className="value">Carga</div>
                    </div>
                    <div
                      className="general-data-top "
                      onClick={incrementarCarga}
                    >
                      <div className="title estimulo">+</div>
                      <div className="value">Carga</div>
                    </div>
                  </>
                )}
              </>
            )}
          {(session.state === "Pedal freado" ||
            session.state === "Repouso" ||
            session.state === "Final de corrida") &&
            ciclos.filter((ciclo) => ciclo.selected).length > 0 && (
              <>
                <div className="general-data-top" onClick={toogleFreio}>
                  <div
                    className={`value ${
                      session.state === "Pedal freado" ? "freado" : ""
                    }`}
                  >
                    {session.state === "Pedal freado"
                      ? "Destravar Pedal"
                      : "Travar Pedal"}
                  </div>
                </div>
              </>
            )}
        </div>
        <div className="general-left-top">
          <div className="general-data" onClick={() => setMetaverso(false)}>
            <div className="title">Realverso</div>
          </div>
          <div className="general-data" onClick={() => setMetaverso(true)}>
            <div className="title">Metaverso</div>
          </div>
        </div>
        <div className="general-left">
          <div className="general-data Sistolica">
            <div className="title">Sistólica</div>
            <div className="value">{`${
              session.data &&
              session.data.INFORMACOESCORPORAIS_RAW &&
              session.data.INFORMACOESCORPORAIS_RAW.length > 0 &&
              session.data.INFORMACOESCORPORAIS_RAW[
                session.data.INFORMACOESCORPORAIS_RAW.length - 1
              ].Sistolica
            } mmHg`}</div>
          </div>
          <div className="general-data Diastolica">
            <div className="title">Diastólica</div>
            <div className="value">{`${
              session.data &&
              session.data.INFORMACOESCORPORAIS_RAW &&
              session.data.INFORMACOESCORPORAIS_RAW.length > 0 &&
              session.data.INFORMACOESCORPORAIS_RAW[
                session.data.INFORMACOESCORPORAIS_RAW.length - 1
              ].Diastolica
            } mmHg`}</div>
          </div>
          <div className="general-data SaturaOxi">
            <div className="title">Oxiênio</div>
            <div className="value">{`${
              session.data &&
              session.data.INFORMACOESCORPORAIS_RAW &&
              session.data.INFORMACOESCORPORAIS_RAW.length > 0 &&
              session.data.INFORMACOESCORPORAIS_RAW[
                session.data.INFORMACOESCORPORAIS_RAW.length - 1
              ].SaturaOxi
            } %`}</div>
          </div>
          <div className="general-data FreqCardiaca">
            <div className="title">Frequência Cardíaca</div>
            <div className="value">{`${
              session.data &&
              session.data.INFORMACOESCORPORAIS_RAW &&
              session.data.INFORMACOESCORPORAIS_RAW.length > 0 &&
              session.data.INFORMACOESCORPORAIS_RAW[
                session.data.INFORMACOESCORPORAIS_RAW.length - 1
              ].FreqCardiaca
            } BPM`}</div>
          </div>
          <div className="general-data Temperatura">
            <div className="title">Temperatura</div>
            <div className="value">{`${
              session.data &&
              session.data.INFORMACOESCORPORAIS_RAW &&
              session.data.INFORMACOESCORPORAIS_RAW.length > 0 &&
              session.data.INFORMACOESCORPORAIS_RAW[
                session.data.INFORMACOESCORPORAIS_RAW.length - 1
              ].Temperatura / 10
            } °C`}</div>
          </div>
          <div className="general-data GPS_LAT">
            <div className="title">GPS Lat.</div>
            <div className="value">
              {session.data &&
                session.data.INFORMACOESCORPORAIS_RAW &&
                session.data.INFORMACOESCORPORAIS_RAW.length > 0 &&
                session.data.INFORMACOESCORPORAIS_RAW[
                  session.data.INFORMACOESCORPORAIS_RAW.length - 1
                ].GPS_LAT}
            </div>
          </div>
          <div className="general-data GPS_LONG">
            <div className="title">GPF Long.</div>
            <div className="value">
              {session.data &&
                session.data.INFORMACOESCORPORAIS_RAW &&
                session.data.INFORMACOESCORPORAIS_RAW.length > 0 &&
                session.data.INFORMACOESCORPORAIS_RAW[
                  session.data.INFORMACOESCORPORAIS_RAW.length - 1
                ].GPS_LONG}
            </div>
          </div>
        </div>
        <div className="general-right-top">
          <div className="general-data ciclo">
            <div className="title ciclo">
              <>
                Estado do Cicloergômetro:{" "}
                <span
                  style={{
                    color: "#00aaff",
                    textShadow: "0px 0px 1px #78eefd",
                    WebkitTextStroke: "1px #01e1ff",
                    letterSpacing: "2px",
                  }}
                >
                  {session.state}
                </span>
              </>
            </div>
          </div>
        </div>
        <div className="general-right">
          <div className="general-data-right Tempo">
            <div className="title">Tempo</div>
            <div className="value">{`${tempoTratado}`}</div>
          </div>
          <div className="general-data-right Carga">
            <div className="title">Carga</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].Carga
            } %`}</div>
          </div>
          <div className="general-data-right QtdCorridas">
            <div className="title">Quantidade Corridas</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].QtdCorridas
            }`}</div>
          </div>
          <div className="general-data-right QtdCorridasExe">
            <div className="title">Quantidade Corridas Executadas</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].QtdCorridasExe
            }`}</div>
          </div>
          <div className="general-data-right TimeStamp">
            <div className="title">Ultima atualização</div>
            <div className="value time">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              new Date(
                session.data.ACOMPANHAMENTO_RAW[
                  session.data.ACOMPANHAMENTO_RAW.length - 1
                ].TimeStamp
              ).toLocaleString("pt-BR")
            }`}</div>
          </div>
        </div>
        <div className="general-bottom">
          <div>
            <div className="estimulo-title">Estímulos</div>
            <div className="estimulo-title-top">Estímulos</div>
          </div>
          <div className="general-data-bottom EstimQuaD">
            <div className="title estimulo">Quadril Direito</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].EstimQuaD
            } mA`}</div>
          </div>
          <div className="general-data-bottom EstimQuaE">
            <div className="title estimulo">Quadril Esquerdo</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].EstimQuaE
            } mA`}</div>
          </div>
          <div className="general-data-bottom EstimGluD">
            <div className="title estimulo">Glúteo Direito</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].EstimGluD
            } mA`}</div>
          </div>
          <div className="general-data-bottom EstimGluE">
            <div className="title estimulo">Glúteo Esquerdo</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].EstimGluE
            } mA`}</div>
          </div>
          <div className="general-data-bottom EstimIsqD">
            <div className="title estimulo">Ísqueo Direito</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].EstimIsqD
            } mA`}</div>
          </div>
          <div className="general-data-bottom EstimIsqE">
            <div className="title estimulo">Ísqueo Esquerdo</div>
            <div className="value">{`${
              session.data &&
              session.data.ACOMPANHAMENTO_RAW &&
              session.data.ACOMPANHAMENTO_RAW.length > 0 &&
              session.data.ACOMPANHAMENTO_RAW[
                session.data.ACOMPANHAMENTO_RAW.length - 1
              ].EstimIsqE
            } mA`}</div>
          </div>
        </div>
      </div>

      <ReactPlayer
        ref={playerRef}
        // url="https://dev-umantech.com.br/assets/ar.mp4"
        // url="https://dev-umantech.com.br/assets/alpes.mp4"
        // url="https://youtu.be/l1cWBKqPafU"
        url="https://youtu.be/NZwcKkuU3F4"
        // url={pinheiros}
        className={`react-player`}
        playing
        loop
        volume={0}
        muted={true}
        width="1920px"
        height="1080px"
        playbackRate={playbackRate}
        // pip={enablePip}
        stopOnUnmount={false}
        // onEnablePIP={() => setEnablePip(true)}
        // onDisablePIP={() => setEnablePip(false)}
        onPause={() => {
          playerRef.current.getInternalPlayer().play();
        }}
        style={{
          position: "fixed",
          overflow: "hidden",
          left: "50%",
          top: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          zIndex: classe === "hide" ? -1 : 9,
        }}
      />

      {metaverso && <div className={`bg  ${classe}`}></div>}

      <Canvas
        className={`canvas  ${classe}`}
        camera={{
          fov: 15,
          near: 0.01,
          far: 4000,
          position: [0, 0, 0.05],
        }}
      >
        {/* <Back /> */}
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Tunnel
          playbackRate={playbackRate}
          carga={carga}
          metaverso={metaverso}
        />
      </Canvas>
    </>
  );
};

const Back = () => {
  const bgTexture = useTexture(background);
  return <primitive attach="background" object={bgTexture} />;
};

export default Player;
