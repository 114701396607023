import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth, protectedRoute } from "../../../contexts/authentication";
import { setStorageWithExpiry } from "../../utils/functions";
import {
  HeaderWrapper,
  ContentHeadLeft,
  LogoHeader,
  GreetingsHeader,
} from "./styled";
import {
  InputText,
  InputPassword,
  Button,
  Select,
  Anchor,
} from "../../../ui/InputControls";
import { useUser } from "../../../contexts/user";

const Header = ({ logout }) => {
  const { user, setUser } = useUser();
  let navigate = useNavigate();
  const clone = require("rfdc")();
  const version = "1.0.24";

  const sair = () => {
    logout();
  };

  const changeRole = (e) => {
    let tempUser = clone(user);
    tempUser.currentRole = e.target.value;
    setUser(tempUser);
    setStorageWithExpiry("@currentRole", e.target.value, 86400000);
    navigate("/");
  };

  const goHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (user) {
      if (user.currentRole === undefined || user.currentRole === null) {
        console.log(user);
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    }
  }, [user]);

  return (
    <HeaderWrapper>
      <ContentHeadLeft>
        <LogoHeader onClick={() => goHome()}>LUCY.IO</LogoHeader>
        <GreetingsHeader>
          Olá, {user !== null && user.name}! v{version}
        </GreetingsHeader>
        <Select
          id="select-role"
          name="select-role"
          options={user && user.roles}
          current={user && user.currentRole}
          width="150px"
          height="40px"
          title="Sair"
          color="grey"
          colorStroke="orange"
          margin="auto 20px auto 0px"
          onChange={changeRole}
        />
        <Button
          width="60px"
          height="25px"
          title="Sair"
          color="primary"
          margin="auto 0px"
          onClick={sair}
        />
      </ContentHeadLeft>
      <Outlet />
    </HeaderWrapper>
  );
};

export default protectedRoute(Header);
