import React from "react";
import {
  InputText,
  InputPassword,
  Button,
  Anchor,
} from "../../../../../ui/InputControls";
import { PlusSm } from "@styled-icons/heroicons-solid/PlusSm";

const HeaderTerapeutaHome = () => {
  const procurarPaciente = (valor) => {
    console.log(valor);
  };
  const adicionarPaciente = (valor) => {
    console.log(valor);
  };
  return <></>;
};

export default HeaderTerapeutaHome;
