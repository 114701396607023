import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getInitials } from "../../../../ui/General";
import { Initials } from "../../../../ui/General/styled";
import {
  MeusPacientesWrapper,
  MeusPacientesTitle,
  MeusPacientesItems,
  PacienteItemWrapper,
  PacientePhoto,
  PacienteName,
} from "./styled";

const MeusPacientes = ({ pacientes }) => {
  const navigate = useNavigate();
  const clickThumb = (id) => {
    navigate(`/paciente/${id}`);
  };

  return (
    <MeusPacientesWrapper>
      <MeusPacientesTitle>Pacientes</MeusPacientesTitle>
      <MeusPacientesItems>
        {pacientes &&
          pacientes.map((value, index) => (
            <PacienteItem
              clickFunction={clickThumb}
              key={index}
              id={value.accountId}
              name={value.nome}
              photo={value.photo}
            />
          ))}
      </MeusPacientesItems>
    </MeusPacientesWrapper>
  );
};

const PacienteItem = ({ id, name, photo, clickFunction }) => {
  return (
    <PacienteItemWrapper
      onClick={(e) => {
        e.preventDefault();
        clickFunction(id);
      }}
    >
      <PacientePhoto>
        {photo ? <img src={photo} /> : <Initials>{getInitials(name)}</Initials>}
      </PacientePhoto>
      <PacienteName>{name}</PacienteName>
    </PacienteItemWrapper>
  );
};

export default MeusPacientes;
