import React, { useRef, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../services/Api";
import { useUser } from "../../../../../contexts/user";
import { useCiclos } from "../../../../../contexts/ciclos";
import {
  Button,
  InputText,
  Select,
  SelectCycleErgometer,
} from "../../../../../ui/InputControls";
import { useAuth, getJwtToken } from "../../../../../contexts/authentication";
import { commandosAtuacao } from "../../../../../assets/comandosAtuacao";
import { useItemCiclo } from "../../../../../contexts/itemCiclo";
import { estadosCiclo } from "../../../../../assets/estadosCiclo";
import { useSession } from "../../../../../contexts/session";
import { usePreload } from "../../../../../contexts/preloading";
import { useAlert } from "../../../../../contexts/alert";
import { useBottomMenuSections } from "../../../../../contexts/bottomMenuSections";
import {
  MenuLines,
  MenuFirstLine,
  MenuSecondLine,
  ButtonSettings,
} from "./styled";
import alarme from "../../../../../assets/audios/alarm.mp3";
import Dropdown from "react-bootstrap/Dropdown";
import "./style.scss";

const HeaderPacienteSessao = ({ voltar, setAutoChangeTabIndex }) => {
  const { user, setUser } = useUser();
  const { preloading, setPreloading } = usePreload();
  const {
    ciclos,
    setCiclos,
    getCiclos,
    ciclosFiltered,
    getCiclosFiltered,
    getSelectedCiclo,
  } = useCiclos();
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const {
    publishMqtt,
    subscribeMqtt,
    unsubscribeMqtt,
    myConnection,
    mqttFailed,
    setMqttFailed,
  } = useAuth();
  const {
    itemCiclo,
    setItemCiclo,
    sendConfiguraSessao,
    configMqtt,
    setConfigMqtt,
  } = useItemCiclo();
  const { pacienteid, status, sessaoid } = useParams();
  const { session, setSession, setSessionGraph, compileSession, endSession } =
    useSession();
  let {
    bottomMenuSections,
    setBottomMenuSections,
    currentBottomMenu,
    setCurrentBottomMenu,
  } = useBottomMenuSections();
  const [lastEstadoCiclo, setLastEstadoCiclo] = useState(null);
  const [estadoCiclo, setEstadoCiclo] = useState(null);
  const [proximaEtapaClicked, setProximaEtapaClicked] = useState(false);
  const [botaoEmergenciaPressed, setBotaoEmergenciaPressed] = useState(null);
  const proximaEtapaClickedTimer = useRef(null);
  const [sessaoCancelada, setSessaoCancelada] = useState(false);
  const [sessaoFinalizada, setSessaoFinalizada] = useState(false);
  const timerTryFinishSession = useRef(null);
  const [canceled, setCanceled] = useState(false);
  const [lastDataSent, setLastDataSent] = useState({
    acompanhamento: {
      last: 0,
      next: 0,
    },
    informacoescorporais: {
      last: 0,
      next: 0,
    },
  });
  let audio = new Audio(alarme);

  const playAlarm = () => {
    console.log("alarmeeee");
    audio.play();
  };

  const navigate = useNavigate();
  const clone = require("rfdc")();

  function getBaseUrl() {
    let hostName = window.location.hostname;
    let baseURL = "";
    if (hostName === "localhost") {
      baseURL = "https://" + hostName + ":" + window.location.port;
    } else {
      baseURL = "https://" + hostName;
    }

    return baseURL;
  }

  const goAgenda = () => {
    window.location.href = `${getBaseUrl()}/paciente/${pacienteid}`;
  };

  const refazerSessao = () => {
    window.location.href = `${getBaseUrl()}/paciente/${pacienteid}/sessao/pending/${sessaoid}`;
  };

  const setCancelSchedule = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);

    return api
      .get(`/Schedule/GetScheduleByAccountId/${pacienteid}`, config)
      .then((res) => {
        if (res.status === 200) {
          let sessionToCancel = res.data.filter(
            (sessao) => sessao.sessionId === parseInt(sessaoid)
          );
          sessionToCancel[0].status = "Canceled";
          console.log(res.data, sessionToCancel);
          api
            .put(`/Schedule/PutScheduleStatus`, sessionToCancel[0], config)
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data);
                setAlertText("Sessão cancelada!");
                setAlertType("success");
                setClasseAlert("on");
                setPreloading(false);
                setTimeout(() => {
                  window.location.href = `${getBaseUrl()}/paciente/${pacienteid}`;
                }, 3000);
              }
            })
            .catch((error) => {
              console.log(error);
              setAlertText("Erro ao cancelar a sessão!");
              setAlertType("error");
              setClasseAlert("on");
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao cancelar a sessão!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  const setFailed = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);

    return api
      .get(`/Schedule/GetScheduleByAccountId/${pacienteid}`, config)
      .then(async (res) => {
        console.log("resposta", res.data);
        if (res.status === 200) {
          let sessionToFail = res.data.filter(
            (sessao) => sessao.sessionId === parseInt(sessaoid)
          );
          sessionToFail[0].status = "Failed";
          console.log(res.data, sessionToFail);
          return api
            .put(`/Schedule/PutScheduleStatus`, sessionToFail[0], config)
            .then(async (res2) => {
              if (res2.status === 200) {
                setAlertText("Sessão cancelada!");
                setAlertType("error");
                setClasseAlert("on");
                setPreloading(false);
                return res2.data;
              }
            })
            .catch((error) => {
              console.log(error);
              setAlertText("Erro ao cancelar a sessão!");
              setAlertType("error");
              setClasseAlert("on");
              setPreloading(false);
              return error;
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao cancelar a sessão!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
        return error;
      });
  };

  const setFailedEmergencia = async (emergencia, estado) => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);

    return api
      .get(`/Schedule/GetScheduleByAccountId/${pacienteid}`, config)
      .then(async (res) => {
        console.log("resposta", res.data);
        if (res.status === 200) {
          let sessionToFail = res.data.filter(
            (sessao) => sessao.sessionId === parseInt(sessaoid)
          );
          sessionToFail[0].status = "Failed";
          // console.log(res.data, sessionToFail);
          return api
            .put(`/Schedule/PutScheduleStatus`, sessionToFail[0], config)
            .then(async (res2) => {
              if (res2.status === 200) {
                if (emergencia) {
                  setAlertText(`Sessão cancelada!
Estado do ciclo: ${estado}
Verificar a necessidade de desligar e ligar o cicloergômetro`);
                } else {
                  setAlertText(`Sessão cancelada!`);
                }
                if (emergencia) {
                  setAlertType("autoCancel");
                } else {
                  setAlertType("error");
                }
                setClasseAlert("on");
                setPreloading(false);
                return res2.data;
              }
            })
            .catch((error) => {
              console.log(error);
              setAlertText("Erro ao cancelar a sessão!");
              setAlertType("error");
              setClasseAlert("on");
              setPreloading(false);
              return error;
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao cancelar a sessão!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
        return error;
      });
  };

  const setDoing = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);

    return api
      .get(`/Schedule/GetScheduleByAccountId/${pacienteid}`, config)
      .then((res) => {
        if (res.status === 200) {
          let sessionDoing = res.data.filter(
            (sessao) => sessao.sessionId === parseInt(sessaoid)
          );
          sessionDoing[0].status = "Doing";
          console.log(res.data, sessionDoing);
          api
            .put(`/Schedule/PutScheduleStatus`, sessionDoing[0], config)
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data);
                setAlertText("Sessão em andamento");
                setAlertType("success");
                setClasseAlert("on");
                setPreloading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setAlertText("Erro ao coloar a sessão em andamento!");
              setAlertType("error");
              setClasseAlert("on");
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao coloar a sessão em andamento!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  const setDone = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);

    return api
      .get(`/Schedule/GetScheduleByAccountId/${pacienteid}`, config)
      .then((res) => {
        if (res.status === 200) {
          let sessionDone = res.data.filter(
            (sessao) => sessao.sessionId === parseInt(sessaoid)
          );
          sessionDone[0].status = "Done";
          console.log(res.data, sessionDone);
          api
            .put(`/Schedule/PutScheduleStatus`, sessionDone[0], config)
            .then(async (res) => {
              if (res.status === 200) {
                let selectedCiclo = await getSelectedCiclo();

                let at = await getJwtToken();

                const config = {
                  headers: { Authorization: `Bearer ${at}` },
                };

                const content = {
                  cycleErgometerId: selectedCiclo.cycleErgometerId,
                  state: `Pronto para uso`,
                };

                api
                  .put(`/CycleErgometer/`, content, config)
                  .then(async (res2) => {
                    api
                      .get(
                        `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`,
                        config
                      )
                      .then(async (res3) => {
                        setAlertText("Sessão finalizada!");
                        setAlertType("success");
                        setClasseAlert("on");
                        setPreloading(false);

                        console.log(
                          "Log ciclo",
                          `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
                        );
                      })
                      .catch((error) => {
                        console.log(error);
                        setPreloading(false);
                      });
                    console.log(
                      "Estado Ciclo",
                      `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                    setPreloading(false);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              setAlertText("Erro ao finalizar a sessão!");
              setAlertType("error");
              setClasseAlert("on");
              setPreloading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao finalizar a sessão!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
      });
  };

  const resetCiclo = async (e) => {
    let lastEstado = estadoCiclo;
    setLastEstadoCiclo(lastEstado);
    setEstadoCiclo("Aguardando estado cicloergômetro...");
    let ciclosTemp = clone(ciclos);
    ciclosTemp.map(async (ciclo) => {
      // console.log(ciclo);
      return await unsubscribeMqtt(
        `LucyIO/Ciclo/STATUS/${ciclo.macAddXxx.dbapP_TAGC}/#`
      );
    });
    ciclosTemp.map(async (value) => {
      value.selected = false;
    });
    setCiclos(ciclosTemp);
  };

  const changeCiclo = async (e) => {
    let lastEstado = estadoCiclo;
    setLastEstadoCiclo(lastEstado);
    setEstadoCiclo("Aguardando estado cicloergômetro...");

    let ciclosTemp = clone(ciclos);
    ciclosTemp.map(async (ciclo) => {
      // console.log(ciclo);
      return await unsubscribeMqtt(
        `LucyIO/Ciclo/STATUS/${ciclo.macAddXxx.dbapP_TAGC}/#`
      );
    });
    ciclosTemp.map(async (value) => {
      console.log(value, e.target.value);
      if (value.cycleErgometerId === parseInt(e.target.value)) {
        value.selected = true;
        subscribeMqtt(`LucyIO/Ciclo/STATUS/${value.macAddXxx.dbapP_TAGC}/#`, 1)
          .then(async (response) => {
            console.log(`ciclo ${value.macAddXxx.dbapP_TAGC} selecionado`);
          })
          .catch((err) => {
            return "problema ao subscrever no Ciclo.";
          });
      } else {
        value.selected = false;
      }
    });
    setCiclos(ciclosTemp);
  };

  const goPending = () => {
    endSession()
      .then(async (response) => {
        resetCiclo()
          .then(async (response2) => {
            setTimeout(() => {
              window.location.href = `${getBaseUrl()}/paciente/${pacienteid}/sessao/pending/${sessaoid}`;
            }, 3000);
          })
          .catch((err) => {
            console.log("end session error");
          });
      })
      .catch((err) => {
        console.log("end session error");
      });
  };

  const configurarSessao = async () => {
    setProximaEtapaClicked(true);
    try {
      clearTimeout(proximaEtapaClickedTimer.current);
    } catch (err) {}
    proximaEtapaClickedTimer.current = setTimeout(() => {
      setSession((values) => ({
        ...values,
        state: "Falha iniciar sessão",
      }));
      setSessionGraph((values) => ({
        ...values,
        state: "Falha iniciar sessão",
      }));
      setProximaEtapaClicked(false);
      setAlertText(
        "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
      );
      setAlertType("error");
      setClasseAlert("on");
    }, 30000);
    let comando = 9; //comando próxima etapa

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    let at = await getJwtToken();
    setPreloading(true);

    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };

    const content = {
      cycleErgometerId: selectedCiclo.cycleErgometerId,
      state: `Em uso sessão id ${sessaoid}`,
    };

    api
      .put(`/CycleErgometer/`, content, config)
      .then(async (res) => {
        api
          .get(
            `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Em uso na sessão id: ${sessaoid}`,
            config
          )
          .then(async (res2) => {
            console.log(
              "Log ciclo",
              `Estado alterado para: Em uso na sessão id: ${sessaoid}`
            );
            setPreloading(false);
          })
          .catch((error) => {
            console.log(error);
            setPreloading(false);
          });
        console.log(
          "Estado Ciclo",
          `Estado alterado para: Em uso na sessão id: ${sessaoid}`
        );
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then((result2) => {
        // console.log(result);
        console.log(
          "Comando configurar sessão enviado. packet id: " + result2.packet_id
        );
        const content2 = {
          sessionId: parseInt(sessaoid),
          cycleErgometerId: selectedCiclo.cycleErgometerId,
          startAt: new Date().toISOString(),
        };
        api
          .post(`/Session/PostStartSession`, content2, config)
          .then(async (res) => {
            console.log(res.data);
          })
          .catch((err) => console.log(err));
        setSession((values) => ({
          ...values,
          sessionid: sessaoid,
          pacienteid: pacienteid,
          ciclo: selectedCiclo,
          runEnd: false,
          sessionEnd: false,
          owner: user.id,
        }));
        setSessionGraph((values) => ({
          ...values,
          sessionid: sessaoid,
          pacienteid: pacienteid,
          ciclo: selectedCiclo,
          runEnd: false,
          sessionEnd: false,
          owner: user.id,
        }));
        setTimeout(() => {
          navigate(`/paciente/${pacienteid}/sessao/doing/${sessaoid}`);
        }, 3000);
      })
      .catch((err) => {
        try {
          clearTimeout(proximaEtapaClickedTimer.current);
        } catch (err) {}
        setProximaEtapaClicked(false);
        setAlertText(
          "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
        );
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const iniciarProximaCorrida = async () => {
    setProximaEtapaClicked(true);
    try {
      clearTimeout(proximaEtapaClickedTimer.current);
    } catch (err) {}
    proximaEtapaClickedTimer.current = setTimeout(() => {
      setProximaEtapaClicked(false);
      setAlertText(
        "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
      );
      setAlertType("error");
      setClasseAlert("on");
    }, 30000);
    salvarConfiguracao()
      .then(async (result) => {
        console.log(result);
        let comando = 9; //comando inicia corrida

        let atuacao = {
          Sessao_Contagem: sessaoid,
          SobeCadeira: 0,
          DesceCadeira: 0,
          InclinaCima: 0,
          InclinaBaixa: 0,
          Frente: 0,
          Atras: 0,
          Inicia: comando,
          Pausa: 0,
          Encerra: 0,
        };

        let selectedCiclo = await getSelectedCiclo();

        publishMqtt(
          `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
          atuacao,
          1,
          false
        )
          .then((result2) => {
            // console.log(result);
            console.log(
              "Comando iniciar sessão enviado. packet id: " + result2.packet_id
            );
            setSession((values) => ({
              ...values,
              sessionid: sessaoid,
              pacienteid: pacienteid,
              ciclo: selectedCiclo,
              runEnd: false,
              owner: user.id,
            }));
            setSessionGraph((values) => ({
              ...values,
              sessionid: sessaoid,
              pacienteid: pacienteid,
              ciclo: selectedCiclo,
              runEnd: false,
              owner: user.id,
            }));
            setTimeout(() => {
              navigate(`/paciente/${pacienteid}/sessao/doing/${sessaoid}`);
            }, 3000);
          })
          .catch((err) => {
            try {
              clearTimeout(proximaEtapaClickedTimer.current);
            } catch (err) {}
            setProximaEtapaClicked(false);
            setAlertText(
              "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
            );
            setAlertType("error");
            setClasseAlert("on");
          });
      })
      .catch((err) => {
        try {
          clearTimeout(proximaEtapaClickedTimer.current);
        } catch (err) {}
        setProximaEtapaClicked(false);
        setAlertText(
          "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
        );
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const proximaEtapa = async () => {
    setProximaEtapaClicked(true);
    try {
      clearTimeout(proximaEtapaClickedTimer.current);
    } catch (err) {}
    proximaEtapaClickedTimer.current = setTimeout(() => {
      setProximaEtapaClicked(false);
      setAlertText(
        "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
      );
      setAlertType("error");
      setClasseAlert("on");
    }, 30000);

    let comando = 9; //comando pular etapa
    window.aliveTime = window.aliveTime + 1;
    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: window.aliveTime,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    return publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then(async (response) => {
        console.log("resposta mqtt botao proxima etapa", response);
        if (estadoCiclo === "Final de corrida") {
          if (!sessaoCancelada && !sessaoFinalizada) {
            return finalizarCorrida()
              .then(async (response2) => {
                // console.log(response);
                return response2;
              })
              .catch((err) => {
                console.log(err);
                return err;
              });
          } else {
            return response;
          }
        } else {
          return response;
        }
      })
      .catch((err) => {
        try {
          clearTimeout(proximaEtapaClickedTimer.current);
        } catch (err) {}
        setProximaEtapaClicked(false);
        setAlertText(
          "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
        );
        setAlertType("error");
        setClasseAlert("on");
        return err;
      });
  };

  function replaceToNum(key, value) {
    if (typeof value === "string") {
      const valueNumber = Number(value);
      if (!Number.isNaN(valueNumber)) {
        return valueNumber;
      }
    }
    return value;
  }

  function lowerCaseFirstLetterObject(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key];
      return accumulator;
    }, {});
  }

  const finalizarCorrida = async () => {
    let dataSend = {
      resumocorrida: { ...session.data.RESUMOCORRIDA_RAW },
      estatisticasessao: { ...session.data.ESTATISTICASESSAO_RAW },
    };

    dataSend.resumocorrida.accountId = pacienteid;
    dataSend.resumocorrida.sessionId = sessaoid;
    dataSend.resumocorrida.cycleErgometerId = session.data.cycleErgometerId;
    dataSend.resumocorrida.runSessionId = 0;
    dataSend.resumocorrida = lowerCaseFirstLetterObject(dataSend.resumocorrida);

    dataSend.estatisticasessao.accountId = pacienteid;
    dataSend.estatisticasessao.sessionId = sessaoid;
    dataSend.estatisticasessao.runSessionId = 0;
    dataSend.estatisticasessao = lowerCaseFirstLetterObject(
      dataSend.estatisticasessao
    );

    let jsonResumoCorrida = JSON.stringify(dataSend.resumocorrida);
    dataSend.resumocorrida = JSON.parse(jsonResumoCorrida, replaceToNum);

    let jsonEstatisticaSessao = JSON.stringify(dataSend.estatisticasessao);
    dataSend.estatisticasessao = JSON.parse(
      jsonEstatisticaSessao,
      replaceToNum
    );

    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);

    let selectedCiclo = await getSelectedCiclo();

    let sessionTemp = clone(session);

    if (session.currentRun < configMqtt["QtdCorridas"] - 1) {
      console.log(dataSend);

      return api
        .post(`/MQTT/PostResumoCorrida`, dataSend.resumocorrida, config)
        .then((res) => {
          if (res.status === 200) {
            let currentRun = sessionTemp.currentRun + 1;
            setSession((values) => ({
              ...values,
              sessionid: sessaoid,
              pacienteid: pacienteid,
              ciclo: selectedCiclo,
              currentRun: currentRun,
              data: { ...values.data, RESUMOCORRIDA_RAW: null },
              runEnd: true,
              owner: user.id,
            }));
            setSessionGraph((values) => ({
              ...values,
              sessionid: sessaoid,
              pacienteid: pacienteid,
              ciclo: selectedCiclo,
              currentRun: currentRun,
              data: { ...values.data, RESUMOCORRIDA_RAW: null },
              runEnd: true,
              owner: user.id,
            }));
            setAlertText("Corrida finalizada!");
            setAlertType("success");
            setClasseAlert("on");
            setPreloading(false);
            return `corrida ${sessionTemp.currentRun + 1} finalizada`;
          }
        })
        .catch((error) => {
          let currentRun = sessionTemp.currentRun + 1;
          console.log(error);
          setAlertText("Erro ao tentar salvar resumo da corrida!");
          setAlertType("error");
          setClasseAlert("on");
          setPreloading(false);
          setSession((values) => ({
            ...values,
            sessionid: sessaoid,
            pacienteid: pacienteid,
            ciclo: selectedCiclo,
            currentRun: currentRun,
            data: { ...values.data, RESUMOCORRIDA_RAW: null },
            runEnd: true,
            owner: user.id,
          }));
          setSessionGraph((values) => ({
            ...values,
            sessionid: sessaoid,
            pacienteid: pacienteid,
            ciclo: selectedCiclo,
            currentRun: currentRun,
            data: { ...values.data, RESUMOCORRIDA_RAW: null },
            runEnd: true,
          }));
          setAlertText("Corrida finalizada!");
          setAlertType("success");
          setClasseAlert("on");
          setPreloading(false);
          return `corrida ${sessionTemp.currentRun + 1} finalizada`;
        });
    } else {
      compileSession()
        .then(async (response) => {
          return api
            .post(`/MQTT/PostResumoCorrida`, dataSend.resumocorrida, config)
            .then(async (res) => {
              if (res.status === 200) {
                return api
                  .post(
                    `/MQTT/PostEstatisticaSessao`,
                    dataSend.estatisticasessao,
                    config
                  )
                  .then(async (res) => {
                    if (res.status === 200) {
                      setAlertText("Corrida finalizada!");
                      setAlertType("success");
                      setClasseAlert("on");
                      setPreloading(false);
                      return setDone();
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setAlertText("Erro ao tentar salvar estatística sessão!");
                    setAlertType("error");
                    setClasseAlert("on");
                    setPreloading(false);
                    return false;
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              setAlertText("Erro ao tentar salvar resumo da corrida!");
              setAlertType("error");
              setClasseAlert("on");
              setPreloading(false);
              return false;
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const finalizarCorridaFinalizada = async () => {
    let dataSend = {
      resumocorrida: { ...session.data.RESUMOCORRIDA_RAW },
      estatisticasessao: { ...session.data.ESTATISTICASESSAO_RAW },
    };

    dataSend.resumocorrida.accountId = pacienteid;
    dataSend.resumocorrida.sessionId = sessaoid;
    dataSend.resumocorrida.cycleErgometerId = session.data.cycleErgometerId;
    dataSend.resumocorrida.runSessionId = 0;
    dataSend.resumocorrida = lowerCaseFirstLetterObject(dataSend.resumocorrida);

    dataSend.estatisticasessao.accountId = pacienteid;
    dataSend.estatisticasessao.sessionId = sessaoid;
    dataSend.estatisticasessao.runSessionId = 0;
    dataSend.estatisticasessao = lowerCaseFirstLetterObject(
      dataSend.estatisticasessao
    );

    let jsonResumoCorrida = JSON.stringify(dataSend.resumocorrida);
    dataSend.resumocorrida = JSON.parse(jsonResumoCorrida, replaceToNum);

    let jsonEstatisticaSessao = JSON.stringify(dataSend.estatisticasessao);
    dataSend.estatisticasessao = JSON.parse(
      jsonEstatisticaSessao,
      replaceToNum
    );

    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);

    compileSession()
      .then(async (response) => {
        return api
          .post(`/MQTT/PostResumoCorrida`, dataSend.resumocorrida, config)
          .then(async (res) => {
            if (res.status === 200) {
              return api
                .post(
                  `/MQTT/PostEstatisticaSessao`,
                  dataSend.estatisticasessao,
                  config
                )
                .then(async (res) => {
                  if (res.status === 200) {
                    setAlertText("Corrida finalizada!");
                    setAlertType("success");
                    setClasseAlert("on");
                    setPreloading(false);
                    return setDone();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setAlertText("Erro ao tentar salvar estatística sessão!");
                  setAlertType("error");
                  setClasseAlert("on");
                  setPreloading(false);
                  return false;
                });
            }
          })
          .catch((error) => {
            console.log(error);
            setAlertText("Erro ao tentar salvar resumo da corrida!");
            setAlertType("error");
            setClasseAlert("on");
            setPreloading(false);
            return false;
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const finalizarCorridaCancelada = async () => {
    let at = await getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };

    compileSession()
      .then(async (response) => {
        setAlertText("Corrida cancelada!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
        return setFailed();
      })
      .catch((err) => {
        setPreloading(false);
        console.log(err);
      });
  };

  const finalizarCorridaEmergencia = async (emergencia = true, estado = "") => {
    compileSession()
      .then(async (response) => {
        setAlertText("Corrida cancelada!");
        if (emergencia) {
          setAlertType("autoCancel");
        } else {
          setAlertType("error");
        }
        setClasseAlert("on");
        setPreloading(false);
        return setFailedEmergencia(emergencia, estado)
          .then(async (response2) => {
            return response2;
          })
          .catch((error) => {
            console.log(error);
            console.log("problema ao cancelar sessao");
          });
      })
      .catch((error) => {
        console.log(error);
        console.log("problema ao cancelar sessao");
      });
  };

  const finalizarSessao = async () => {
    setProximaEtapaClicked(true);
    try {
      clearTimeout(proximaEtapaClickedTimer.current);
    } catch (err) {}
    proximaEtapaClickedTimer.current = setTimeout(() => {
      setProximaEtapaClicked(false);
      setAlertText(
        "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
      );
      setSessaoFinalizada(false);
      setAlertType("error");
      setClasseAlert("on");
    }, 30000);

    let comando = 37; //comando encerrar sessão

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    )
      .then(async (res) => {
        setSessaoFinalizada(true);
        let at = await getJwtToken();

        const config = {
          headers: { Authorization: `Bearer ${at}` },
        };

        const content = {
          cycleErgometerId: selectedCiclo.cycleErgometerId,
          state: `Pronto para uso`,
        };

        setPreloading(true);

        api
          .put(`/CycleErgometer/`, content, config)
          .then(async (res) => {
            api
              .get(
                `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`,
                config
              )
              .then(async (res2) => {
                console.log(
                  "Log ciclo",
                  `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
                );
                setPreloading(false);
              })
              .catch((error) => {
                console.log(error);
                setPreloading(false);
              });
            console.log(
              "Estado Ciclo",
              `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
            );
          })
          .catch((error) => {
            console.log(error);
            setPreloading(false);
          });
      })
      .catch((err) => {
        try {
          clearTimeout(proximaEtapaClickedTimer.current);
        } catch (err) {}
        setProximaEtapaClicked(false);
        setAlertText(
          "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
        );
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const finalizarSessaoFinalizada = async () => {
    setSessaoFinalizada(false);
    finalizarCorridaFinalizada()
      .then(async (response) => {
        let comando = 9;

        let atuacao = {
          Sessao_Contagem: sessaoid,
          SobeCadeira: 0,
          DesceCadeira: 0,
          InclinaCima: 0,
          InclinaBaixa: 0,
          Frente: 0,
          Atras: 0,
          Inicia: comando,
          Pausa: 0,
          Encerra: 0,
        };

        let selectedCiclo = await getSelectedCiclo();

        publishMqtt(
          `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
          atuacao,
          1,
          false
        )
          .then((res) => {
            console.log("sessao finalizadaaaaaaaaaa");
          })
          .catch((err) => console.log("erro ao finalizar  a sessão", err));

        let at = await getJwtToken();

        const config = {
          headers: { Authorization: `Bearer ${at}` },
        };

        const content = {
          cycleErgometerId: selectedCiclo.cycleErgometerId,
          state: `Pronto para uso`,
        };

        setPreloading(true);
        api
          .put(`/CycleErgometer/`, content, config)
          .then(async (res) => {
            api
              .get(
                `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`,
                config
              )
              .then(async (res2) => {
                console.log(
                  "Log ciclo",
                  `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
                );

                setPreloading(false);
              })
              .catch((error) => {
                console.log(error);
                setPreloading(false);
              });
            console.log(
              "Estado Ciclo",
              `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
            );
          })
          .catch((error) => {
            console.log(error);
            setPreloading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setPreloading(false);
      });
  };

  const cancelarSessao = async () => {
    setProximaEtapaClicked(true);
    setSessaoCancelada(true);
    try {
      clearTimeout(proximaEtapaClickedTimer.current);
    } catch (err) {}
    proximaEtapaClickedTimer.current = setTimeout(() => {
      setProximaEtapaClicked(false);
      setAlertText(
        "Cicloergômetro não respondeu à ação do botão. Verifique a conexão e tente novamente."
      );
      setSessaoCancelada(false);
      setAlertType("error");
      setClasseAlert("on");
    }, 30000);

    let comando = 37; //comando encerrar sessão

    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: comando,
      Pausa: 0,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    publishMqtt(
      `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
      atuacao,
      1,
      false
    );

    let at = await getJwtToken();

    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };

    const content = {
      cycleErgometerId: selectedCiclo.cycleErgometerId,
      state: `Pronto para uso`,
    };

    setPreloading(true);

    api
      .put(`/CycleErgometer/`, content, config)
      .then(async (res) => {
        api
          .get(
            `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`,
            config
          )
          .then(async (res2) => {
            console.log(
              "Log ciclo",
              `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
            );
            setPreloading(false);
          })
          .catch((error) => {
            console.log(error);
            setPreloading(false);
          });
        console.log(
          "Estado Ciclo",
          `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
        );
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  const cancelarSessaoCancelada = async () => {
    setSessaoCancelada(false);
    finalizarCorridaCancelada()
      .then(async (response) => {
        let comando = 9;

        let atuacao = {
          Sessao_Contagem: sessaoid,
          SobeCadeira: 0,
          DesceCadeira: 0,
          InclinaCima: 0,
          InclinaBaixa: 0,
          Frente: 0,
          Atras: 0,
          Inicia: comando,
          Pausa: 0,
          Encerra: 0,
        };

        let selectedCiclo = await getSelectedCiclo();

        publishMqtt(
          `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
          atuacao,
          1,
          false
        );

        let at = await getJwtToken();

        const config = {
          headers: { Authorization: `Bearer ${at}` },
        };

        const content = {
          cycleErgometerId: selectedCiclo.cycleErgometerId,
          state: `Pronto para uso`,
        };

        setPreloading(true);
        api
          .put(`/CycleErgometer/`, content, config)
          .then(async (res) => {
            api
              .get(
                `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`,
                config
              )
              .then(async (res2) => {
                console.log(
                  "Log ciclo",
                  `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
                );

                setPreloading(false);
              })
              .catch((error) => {
                console.log(error);
                setPreloading(false);
              });
            console.log(
              "Estado Ciclo",
              `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
            );
          })
          .catch((error) => {
            console.log(error);
            setPreloading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setPreloading(false);
      });
  };

  const cancelarSessaoEmergencia = async (estado) => {
    setCanceled(true);
    finalizarCorridaEmergencia(true, estado)
      .then(async (response) => {
        setSession((values) => ({
          ...values,
          state: "Final de corrida",
        }));
        let selectedCiclo = await getSelectedCiclo();
        let at = await getJwtToken();

        const config = {
          headers: { Authorization: `Bearer ${at}` },
        };

        const content = {
          cycleErgometerId: selectedCiclo.cycleErgometerId,
          state: `Pronto para uso`,
        };

        setPreloading(true);
        api
          .put(`/CycleErgometer/`, content, config)
          .then(async (res) => {
            api
              .get(
                `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`,
                config
              )
              .then(async (res2) => {
                console.log(
                  "Log ciclo",
                  `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
                );

                setPreloading(false);
              })
              .catch((error) => {
                console.log(error);
                setPreloading(false);
              });
            console.log(
              "Estado Ciclo",
              `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
            );
          })
          .catch((error) => {
            console.log(error);
            setPreloading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setPreloading(false);
      });
  };

  const forcarEncerraSessaoApp = async () => {
    finalizarCorridaEmergencia(false)
      .then(async (response) => {
        endSession()
          .then(async (response) => {
            setTimeout(() => {
              window.location.href = `${getBaseUrl()}/paciente/${pacienteid}`;
            }, 3000);
          })
          .catch((err) => {
            console.log("end session error");
          });
      })
      .catch((err) => {
        console.log(err);
        setPreloading(false);
      });
  };

  const voltarPaciente = () => {
    navigate(`/paciente/${pacienteid}`);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  useEffect(() => {
    window.aliveTime = 1;
    getCiclos();
  }, []);

  useEffect(() => {}, [ciclos, myConnection]);

  const salvarConfiguracao = async () => {
    let sendConfig = await sendConfiguraSessao();
    console.log(sendConfig);

    let at = await getJwtToken();
    console.log("sendConfig", sendConfig);
    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };
    setPreloading(true);
    return api
      .post(`/MQTT/PostConfiguraSessao`, sendConfig, config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setAlertText("Configuração da sessão salva!");
          setAlertType("success");
          setClasseAlert("on");
          setPreloading(false);
          return sendConfig;
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertText("Erro ao salvar a configuração da sessão no servidor!");
        setAlertType("error");
        setClasseAlert("on");
        setPreloading(false);
        return false;
      });
  };

  const concluirSessao = async () => {
    let selectedCiclo = await getSelectedCiclo();

    let at = await getJwtToken();

    const config = {
      headers: { Authorization: `Bearer ${at}` },
    };

    const content = {
      cycleErgometerId: selectedCiclo.cycleErgometerId,
      state: `Pronto para uso`,
    };

    setPreloading(false);

    api
      .put(`/CycleErgometer/`, content, config)
      .then(async (res) => {
        api
          .get(
            `/CycleErgometer/AddLog/${selectedCiclo.cycleErgometerId}/Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`,
            config
          )
          .then(async (res2) => {
            console.log(
              "Log ciclo",
              `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
            );
            setPreloading(false);
            endSession()
              .then(async (response) => {
                setTimeout(() => {
                  window.location.href = `${getBaseUrl()}/paciente/${pacienteid}`;
                }, 3000);
              })
              .catch((err) => {
                console.log("end session error");
              });
          })
          .catch((error) => {
            console.log(error);
            setPreloading(false);
          });
        console.log(
          "Estado Ciclo",
          `Estado alterado para: Pronto para uso, sessão finalizada id: ${sessaoid}`
        );
      })
      .catch((error) => {
        console.log(error);
        setPreloading(false);
      });
  };

  const sendIsAlive = async () => {
    window.aliveTime = window.aliveTime + 1;
    let atuacao = {
      Sessao_Contagem: sessaoid,
      SobeCadeira: 0,
      DesceCadeira: 0,
      InclinaCima: 0,
      InclinaBaixa: 0,
      Frente: 0,
      Atras: 0,
      Inicia: 0,
      Pausa: window.aliveTime,
      Encerra: 0,
    };

    let selectedCiclo = await getSelectedCiclo();

    if (selectedCiclo) {
      publishMqtt(
        `LucyIO/Ciclo/STATUS/${selectedCiclo.macAddXxx.dbapP_TAGC}/ATUACAO`,
        atuacao,
        1,
        false
      )
        .then((result2) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (session.data && session.data.ACOMPANHAMENTO) {
      let temp = session.data.ACOMPANHAMENTO.filter(
        (value) => value.key === "FaseCorrida"
      );
      let tempEstado = estadosCiclo.filter(
        (value) => value.value === parseInt(temp[0].value)
      )[0];
      if (tempEstado) {
        let lastEstado = estadoCiclo;
        setLastEstadoCiclo(lastEstado);
        setEstadoCiclo(tempEstado.name);
      }

      if (status === "doing") {
        if (!canceled) {
          if (
            estadoCiclo === "Alarme sonora da Falha" ||
            estadoCiclo === "Alarme sonoro da emergência" ||
            estadoCiclo === "Alarme sonoro espasmo"
          ) {
            playAlarm();
            cancelarSessaoEmergencia(estadoCiclo);
          }
          if (
            estadoCiclo === "Emergência sem alarme" ||
            estadoCiclo === "Falha sem alarme"
          ) {
            cancelarSessaoEmergencia(estadoCiclo);
          }
          if (
            estadoCiclo ===
              "Deteção de espasmo sem alarme com freio acionado" ||
            estadoCiclo === "Deteção de espasmo sem alarne pedal liberado"
          ) {
            cancelarSessaoEmergencia(estadoCiclo);
          }
        }

        if (
          session.state &&
          configMqtt &&
          session.state !== "" &&
          session.state === "Corrida" &&
          session.data &&
          session.data.ACOMPANHAMENTO_RAW &&
          parseInt(
            session.data.ACOMPANHAMENTO_RAW[
              session.data.ACOMPANHAMENTO_RAW.length - 1
            ].Rot
          ) < 10
        ) {
          setAlertText("Fadiga detectada! Fase atual: Resfriamento");
          setAlertType("warning");
          setClasseAlert("on");
          proximaEtapa();
        }

        if (estadoCiclo === "Final de corrida") {
          if (
            sessaoCancelada &&
            session.data &&
            session.data.ESTATISTICASESSAO_RAW &&
            session.data.ESTATISTICASESSAO_RAW["Rot"]
          ) {
            cancelarSessaoCancelada();
          }
          if (
            sessaoFinalizada &&
            session.data &&
            session.data.ESTATISTICASESSAO_RAW &&
            session.data.ESTATISTICASESSAO_RAW["Rot"]
          ) {
            finalizarSessaoFinalizada();
          }
        }
      } else {
        setCanceled(false);
      }
    }
  }, [session]);

  useEffect(() => {
    if (currentBottomMenu) {
      if (estadoCiclo === "Configuração") {
        currentBottomMenu.map((obj, index) => {
          if (obj.name === "Configurações") {
            setAutoChangeTabIndex(index);
          }
        });
      } else if (estadoCiclo === "Aquecimento") {
        setAutoChangeTabIndex(2);
        currentBottomMenu.map((obj, index) => {
          if (obj.name === "Sessão") {
            console.log("jhun", index);
            setAutoChangeTabIndex(index);
          }
        });
      } else if (estadoCiclo === "Final de corrida") {
        currentBottomMenu.map((obj, index) => {
          if (obj.name === "Resumo") {
            setAutoChangeTabIndex(index);
          }
        });
      }
    }

    if (status === "doing") {
      if (estadoCiclo === "Configuração") {
        setDoing();
      }

      if (
        estadoCiclo === "Falha Eletrodo Quadríceps Direito" ||
        estadoCiclo === "Falha Eletrodo Glúteo Direito" ||
        estadoCiclo === "Falha Eletrodo Ísquio Direito" ||
        estadoCiclo === "Falha Eletrodo Quadríceps Esquerdo" ||
        estadoCiclo === "Falha Eletrodo Glúteo Esquerdo" ||
        estadoCiclo === "Falha Eletrodo Ísquio Esquerdo"
      ) {
        setAlertText(
          `${estadoCiclo}! Escolha entre Continuar ou Cancelar a Sessão.`
        );
        setAlertType("autoCancel");
        setClasseAlert("on");
      }

      if (!canceled) {
        if (
          estadoCiclo === "Alarme sonora da Falha" ||
          estadoCiclo === "Alarme sonoro da emergência" ||
          estadoCiclo === "Alarme sonoro espasmo"
        ) {
          playAlarm();
        }

        if (
          estadoCiclo === "Alarme sonora da Falha" ||
          estadoCiclo === "Falha sem alarme"
        ) {
          setAlertText(
            "Falha no equipamento! Sessão finalizada automaticamente."
          );
          setAlertType("autoCancel");
          setClasseAlert("on");
          cancelarSessaoEmergencia(estadoCiclo);
        }
        if (
          estadoCiclo === "Alarme sonoro da emergência" ||
          estadoCiclo === "Emergência sem alarme"
        ) {
          setAlertText(
            "Botão de Emergência acionado! Sessão finalizada automaticamente."
          );
          setAlertType("autoCancel");
          setClasseAlert("on");
          cancelarSessaoEmergencia(estadoCiclo);
        }
        if (
          estadoCiclo === "Alarme sonoro espasmo" ||
          estadoCiclo === "Deteção de espasmo sem alarme com freio acionado" ||
          estadoCiclo === "Deteção de espasmo sem alarne pedal liberado"
        ) {
          setAlertText("Espamo detectado! Sessão finalizada automaticamente.");
          setAlertType("autoCancel");
          setClasseAlert("on");
          cancelarSessaoEmergencia(estadoCiclo);
        }
      }
    } else {
      setCanceled(false);
    }

    if (estadoCiclo !== lastEstadoCiclo) {
      try {
        clearTimeout(proximaEtapaClickedTimer.current);
      } catch (err) {}
      setProximaEtapaClicked(false);
    }
  }, [estadoCiclo]);

  useEffect(() => {
    if (itemCiclo.config) {
      salvarConfiguracao();
    }
  }, [itemCiclo]);

  useEffect(() => {
    if (status === "doing") {
      sendIsAlive();
      window.timerAlive = setInterval(() => {
        console.log("sending status");
        sendIsAlive();
      }, 10000);
    } else {
      window.aliveTime = 1;
      try {
        clearInterval(window.timerAlive);
      } catch (e) {}
    }

    return () => {
      window.aliveTime = 1;
      try {
        clearInterval(window.timerAlive);
      } catch (e) {}
    };
  }, [status]);

  useEffect(() => {
    if (mqttFailed) {
      playAlarm();
      cancelarSessaoEmergencia("Sem comunicação com o MQTT!");
      setMqttFailed(false);
    }
  }, [mqttFailed]);

  return status === "canceled" ? (
    <MenuLines>
      <MenuFirstLine>
        <InputText
          width="500px"
          id="estado-ciclo"
          placeholder="Estado do ciclo:"
          value={`Estado Cicloergômetro: ${estadoCiclo}`}
          readOnly={true}
          fontSize="12px"
        />
        <Button
          width="100px"
          title="Voltar"
          colorStroke="white"
          color="transparent"
          onClick={voltar}
        />
      </MenuFirstLine>
      <MenuSecondLine>
        <Button
          width="240px"
          title="Refazer Sessão (Forçar)"
          colorStroke="red"
          color="danger-stroke"
          onClick={refazerSessao}
        />
      </MenuSecondLine>
    </MenuLines>
  ) : status === "pending" ? (
    <MenuLines>
      <MenuFirstLine>
        {ciclos && (
          <>
            <InputText
              width="500px"
              id="estado-ciclo"
              placeholder="Estado do ciclo:"
              value={`Estado Cicloergômetro: ${
                proximaEtapaClicked
                  ? "Aguardando resposta do Cicloergômetro..."
                  : estadoCiclo
              }`}
              readOnly={true}
              fontSize="12px"
            />
            {proximaEtapaClicked === false && (
              <>
                <SelectCycleErgometer
                  id="select-cycleergometer"
                  name="select-cycleergometer"
                  options={ciclos.filter(
                    (value) => value.active && value.status === "Operando"
                  )}
                  current={undefined}
                  width="200px"
                  height="40px"
                  title="Sair"
                  color="grey"
                  colorStroke="orange"
                  margin="auto 20px"
                  onChange={changeCiclo}
                />
              </>
            )}
          </>
        )}
      </MenuFirstLine>
      <MenuSecondLine>
        {proximaEtapaClicked === false && (
          <Button
            width="240px"
            title="Cancelar Agendamento"
            colorStroke="red"
            color="danger-stroke"
            onClick={setCancelSchedule}
          />
        )}
        {session.state &&
          session.state !== "" &&
          session.state === "Cicloergômetro Disponível" &&
          estadoCiclo !== "Aguardando estado cicloergômetro..." &&
          proximaEtapaClicked === false && (
            <>
              <Button
                width="200px"
                title="Iniciar Sessão"
                colorStroke="transparent"
                color="danger"
                onClick={configurarSessao}
              />
            </>
          )}
        {proximaEtapaClicked === false && (
          <Button
            width="100px"
            margin="auto 20px auto 10px"
            title="Voltar"
            colorStroke="white"
            color="transparent"
            onClick={goAgenda}
          />
        )}
      </MenuSecondLine>
    </MenuLines>
  ) : status === "doing" ? (
    <MenuLines>
      <MenuFirstLine>
        {session.state === "Falha iniciar sessão" &&
          !proximaEtapaClicked &&
          goPending()}
        <InputText
          width="500px"
          id="estado-ciclo"
          placeholder="Estado do ciclo:"
          value={`Estado Cicloergômetro: ${
            proximaEtapaClicked
              ? "Aguardando resposta do Cicloergômetro..."
              : estadoCiclo
          }`}
          readOnly={true}
          fontSize="12px"
        />
        {session.state &&
          session.state !== "" &&
          session.state === "Configuração" &&
          session.sessionEnd === false &&
          proximaEtapaClicked === false &&
          session.state !== "Pedal freado" && (
            <>
              <Button
                width="170px"
                title={`Iniciar Corrida ${session.currentRun + 1}`}
                colorStroke="transparent"
                color="danger"
                onClick={iniciarProximaCorrida}
              />
            </>
          )}

        {session.state &&
          configMqtt &&
          session.state !== "" &&
          (session.state === "Aquecimento" ||
            session.state === "Corrida" ||
            session.state === "Resfriamento" ||
            session.state === "Repouso" ||
            (session.state === "Final de corrida" &&
              session.currentRun < configMqtt["QtdCorridas"] &&
              session.data &&
              session.data.RESUMOCORRIDA_RAW &&
              session.data.RESUMOCORRIDA_RAW["Rot"])) &&
          session.state !== "Pedal freado" &&
          session.sessionEnd === false &&
          // session.runEnd === false &&
          proximaEtapaClicked === false &&
          !sessaoCancelada && (
            <Button
              width="170px"
              title="Próxima Etapa"
              colorStroke="white"
              color="transparent"
              onClick={proximaEtapa}
            />
          )}
        {session.state &&
          configMqtt &&
          session.state !== "" &&
          (session.state === "Falha Eletrodo Quadríceps Direito" ||
            session.state === "Falha Eletrodo Glúteo Direito" ||
            session.state === "Falha Eletrodo Ísquio Direito" ||
            session.state === "Falha Eletrodo Quadríceps Esquerdo" ||
            session.state === "Falha Eletrodo Glúteo Esquerdo" ||
            session.state === "Falha Eletrodo Ísquio Esquerdo") &&
          proximaEtapaClicked === false && (
            <Button
              width="170px"
              title="Continuar Sessão"
              colorStroke="white"
              color="transparent"
              onClick={proximaEtapa}
            />
          )}
        {session.state &&
          session.state !== "" &&
          session.sessionEnd === false &&
          proximaEtapaClicked === false &&
          session.state !== "Pedal freado" &&
          session.state !== "Cicloergômetro Disponível" &&
          session.state !== "Alarme sonora da Falha" &&
          session.state !== "Falha sem alarme" &&
          session.state !== "Alarme sonoro da emergência" &&
          session.state !== "Emergência sem alarme" &&
          session.state !== "Alarme sonoro espasmo" &&
          session.state !==
            "Deteção de espasmo sem alarme com freio acionado" &&
          session.state !== "Deteção de espasmo sem alarne pedal liberado" &&
          !sessaoCancelada && (
            <Button
              width="150px"
              title="Cancelar sessão"
              colorStroke="transparent"
              color="primary"
              onClick={cancelarSessao}
            />
          )}
        {session.state &&
          session.state !== "" &&
          session.sessionEnd === false &&
          proximaEtapaClicked === false &&
          session.state === "Final de corrida" &&
          session.data &&
          session.data.RESUMOCORRIDA_RAW &&
          session.data.RESUMOCORRIDA_RAW["Rot"] && (
            <Button
              width="150px"
              title="Finalizar Sessão"
              colorStroke="transparent"
              color="primary"
              onClick={finalizarSessao}
            />
          )}
        {session.state &&
          session.state !== "" &&
          proximaEtapaClicked === false &&
          (session.state === "Final de corrida" ||
            session.state === "Cicloergômetro Disponível" ||
            session.state === "Alarme sonora da Falha" ||
            session.state === "Falha sem alarme" ||
            session.state === "Alarme sonoro da emergência" ||
            session.state === "Emergência sem alarme" ||
            session.state === "Alarme sonoro espasmo" ||
            session.state ===
              "Deteção de espasmo sem alarme com freio acionado" ||
            session.state === "Deteção de espasmo sem alarne pedal liberado") &&
          session.runEnd &&
          session.sessionEnd && (
            <Button
              width="150px"
              title="Concluir Sessão"
              colorStroke="transparent"
              color="primary"
              onClick={concluirSessao}
            />
          )}
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <ButtonSettings />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdownSettings">
            <Dropdown.Item onClick={forcarEncerraSessaoApp}>
              Forçar Encerrar Sessão (App)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </MenuFirstLine>
    </MenuLines>
  ) : status === "done" ? (
    <MenuLines>
      <MenuFirstLine>
        <InputText
          width="500px"
          id="estado-ciclo"
          placeholder="Estado do ciclo:"
          value={`Estado Cicloergômetro: ${estadoCiclo}`}
          readOnly={true}
          fontSize="12px"
        />
        <Button
          width="100px"
          title="Voltar"
          colorStroke="white"
          color="transparent"
          onClick={voltarPaciente}
        />
      </MenuFirstLine>
      <MenuSecondLine>
        <Button
          width="240px"
          title="Refazer Sessão (Forçar)"
          colorStroke="red"
          color="danger-stroke"
          onClick={refazerSessao}
        />
      </MenuSecondLine>
    </MenuLines>
  ) : (
    <></>
  );
};

export default HeaderPacienteSessao;
