import { useState } from "react";
import { useAlert } from "../../contexts/alert.jsx";
import { AlertWrapper } from "./styled.js";
export const Alert = () => {
  const {
    classeAlert,
    setClasseAlert,
    alertText,
    setAlertText,
    alertType,
    setAlertType,
  } = useAlert();
  const closeAlert = () => {
    setClasseAlert("");
  };

  return (
    <AlertWrapper className={[alertType, classeAlert]} onClick={closeAlert}>
      <div className="close-bt"></div>
      {alertText}
    </AlertWrapper>
  );
};
