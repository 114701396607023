import React, { useEffect } from "react";
import { SectionMenuWrapper, MenuItem } from "./styled";
import { useNavigate } from "react-router-dom";
import { useBottomMenuSections } from "../../contexts/bottomMenuSections";

export const SectionMenu = ({}) => {
  const navigate = useNavigate();
  const { currentBottomMenu, setCurrentBottomMenu } = useBottomMenuSections();
  const clone = require("rfdc")();
  const sectionMenuClick = (name, route) => {
    console.log(name);

    let currentBottomMenuTemp = clone(currentBottomMenu);

    currentBottomMenuTemp.map((obj) => {
      obj.selected = false;
    });

    currentBottomMenuTemp.map((obj) => {
      if (obj.name === name) {
        obj.selected = true;
      }
    });

    setCurrentBottomMenu(currentBottomMenuTemp);

    if (route !== null) {
      navigate(route);
    }
  };

  useEffect(() => {}, [currentBottomMenu]);
  return (
    <SectionMenuWrapper>
      {currentBottomMenu &&
        currentBottomMenu.map((value, index) => (
          <MenuItem
            key={index}
            className={value.selected && "selected"}
            onClick={() => sectionMenuClick(value.name, value.route)}
          >
            {value.name}
          </MenuItem>
        ))}
    </SectionMenuWrapper>
  );
};
