import {
  FullwidthCenterCenter,
  FullwidthLeft,
  MainContainerLeft,
} from "./styled";

export const ContainerFullwidthCenterCenter = ({ children }) => {
  return <FullwidthCenterCenter>{children}</FullwidthCenterCenter>;
};

export const ContainerFullwidthLeft = ({ children }) => {
  return <FullwidthLeft>{children}</FullwidthLeft>;
};

export const MainContainer = ({ children }) => {
  return <MainContainerLeft>{children}</MainContainerLeft>;
};
