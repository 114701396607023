import { useRef, useEffect, useState, useContext, createContext } from "react";
import { Amplify, Auth } from "aws-amplify";
import { mqtt, iot, auth } from "aws-iot-device-sdk-v2";
import * as AWS from "aws-sdk";
import amplifyConfig from "../services/amplify-config";
import _ from "lodash";
import { usePreload } from "../contexts/preloading";
import api, { apiGeneral } from "../services/Api";
import { useUser } from "./user";
import { useAlert } from "./alert";
import {
  setStorageWithExpiry,
  getStorageWithExpiry,
  clearStorage,
} from "../shared/utils/functions.js";
import { mqttData } from "../assets/mqttData.js";

const Settings = require("../assets/settings");

Amplify.configure(amplifyConfig);
const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const { user, setUser } = useUser();
  const { setPreloading } = usePreload();
  const { setClasseAlert, setAlertText, setAlertType } = useAlert();
  const myConnection = useRef(null);
  const [mqttUpdate, setMqttUpdate] = useState(null);
  const timerMqttAlive = useRef(null);
  const [mqttFailed, setMqttFailed] = useState(false);

  const login = async (usuario, senha) => {
    const username = usuario.trim();
    const password = senha.trim();

    setPreloading(true);
    Auth.signIn({
      username,
      password,
    })
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setPreloading(false);

          const { requiredAttributes, userAttributes } = user.challengeParam;
          console.log(requiredAttributes, user);
          Auth.completeNewPassword(user, password, {})
            .then((res) => {
              console.log(res);
              setPreloading(false);
            })
            .catch((err) => {
              console.log(err);
              setPreloading(false);
            });
        } else {
          setPreloading(false);
          console.log("Login realizado com sucesso");
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err);
        setPreloading(false);
        let index = String(err).indexOf(":");
        let cleanError = String(err).substring(0, index);
        if (cleanError === "UserNotConfirmedException") {
          setAlertText("Cadastro não validado ainda.");
        } else {
          setAlertText("Ocorreu um erro.");
        }
        setAlertType("error");
        setClasseAlert("on");
      });
  };

  const logout = () => {
    setPreloading(true);
    Auth.signOut();
    setTimeout(() => {
      setPreloading(false);
      clearStorage("@currentRole");
      window.location.href = "/";
    }, 1000);
  };

  const connectMqtt = async () => {
    if (!myConnection.current) {
      setPreloading(true);
      let info = await Auth.currentCredentials();
      console.log("info", info);
      const cognitoIdentityId = info.identityId;
      let content = {
        id: cognitoIdentityId,
      };
      let at = await getJwtToken();
      const config = {
        headers: { Authorization: `Bearer ${at}` },
      };

      /*
       * 2024-06-04, Ivan Casa
       * o "/iot/AddUserOnIoTCore" pode ser tanto para o publicado pelo Adriano direto na
       * AWS pelo Adriano (sem código fonte) quanto para a função que esta no backend completo da SPI
       * que publicamos frequentemente, basta estar configurado corretamente em:
       * "\frontend\src\services\Api.js" na variavel "apiGeneral"!
       */
      let iotRegister = await apiGeneral.post(`/iot/AddUserOnIoTCore`, content, config);
      console.log(iotRegister);

      if (iotRegister.status === 200) {
        const provider = new AWSCognitoCredentialsProvider({
          identityId: cognitoIdentityId,
          IdentityPoolId: Settings.AWS_COGNITO_IDENTITY_POOL_ID,
          Region: Settings.AWS_REGION,
        });
        await provider.refreshCredentialAsync();
        return connect_websocket(provider)
          .then((connection) => {
            myConnection.current = connection;
            setPreloading(false);
            return "iot connected";
          })
          .catch((reason) => {
            console.log(`Error while connecting: ${reason}`);
            setPreloading(false);
            return "iot connected error";
          });
      } else {
        console.log("problem with mqttConnect, but trying to connect again.");
        const provider = new AWSCognitoCredentialsProvider({
          identityId: cognitoIdentityId,
          IdentityPoolId: Settings.AWS_COGNITO_IDENTITY_POOL_ID,
          Region: Settings.AWS_REGION,
        });

        /*
         * 2024-06-04, Ivan Casa
         * A variavel "refreshCredential" não é utilizada em nenhum local do codigo
         */
        let refreshCredential = await provider.refreshCredentialAsync();

        return connect_websocket(provider)
          .then((connection) => {
            myConnection.current = connection;
            setPreloading(false);
            return "iot connected";
          })
          .catch((reason) => {
            console.log(`Error while connecting: ${reason}`);
            setPreloading(false);
            return "iot connected error";
          });
      }
    } else {
      console.log("mqtt already connected");
    }
  };

  const publishMqtt = async (topic, message, qos, retained) => {
    if (!myConnection.current) {
      await connectMqtt();
      publishMqtt(topic, message, qos, retained);
    } else {
      for (let key in message) {
        message[key] = message[key].toString();
      }
      return await myConnection.current.publish(
        topic,
        JSON.stringify(message),
        qos,
        retained
      );
    }
  };

  function replaceToNum(key, value) {
    if (typeof value === "string") {
      const valueNumber = Number(value);
      if (!Number.isNaN(valueNumber)) {
        return valueNumber.toString();
      }
    }
    return key === "TimeStamp" ? value.toString() + "Z" : value;
  }

  const subscribeMqtt = async (myTopic, qos) => {
    if (myConnection.current === null || myConnection.current === undefined) {
      connectMqtt()
        .then((response) => {
          subscribeMqtt(myTopic, qos);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let lastMessage = {};
      myConnection.current
        .subscribe(myTopic, qos, async (topic, payload, dup, qos, retain) => {
          const decoder = new TextDecoder("utf8");
          let message = decoder.decode(new Uint8Array(payload));
          let filterTopic = topic.split("/");
          filterTopic = filterTopic[filterTopic.length - 1];

          if (filterTopic !== "STS" && filterTopic !== "ATUACAO") {
            let mqttTemp = _.cloneDeep(mqttData);
            let tempSession = await getStorageWithExpiry("@session");

            if (filterTopic !== "CONFIGURASESSAO") {
              if (
                ((filterTopic === "ACOMPANHAMENTO" ||
                  filterTopic === "INFORMACOESCORPORAIS") &&
                  !_.isEqual(lastMessage, JSON.parse(message, replaceToNum))) ||
                filterTopic === "RESUMOCORRIDA" ||
                filterTopic === "ESTATISTICASESSAO"
              ) {
                lastMessage = JSON.parse(message, replaceToNum);
                if (filterTopic === "ACOMPANHAMENTO") {
                  mqttTemp[filterTopic + "_RAW"] = JSON.parse(
                    message,
                    replaceToNum
                  );
                  mqttTemp[filterTopic].forEach((itemTopic) => {
                    Object.entries(JSON.parse(message)).forEach(
                      ([key, value]) => {
                        if (key === itemTopic.key) {
                          itemTopic.value = !Number.isNaN(Number(value))
                            ? key === "TimeStamp"
                              ? value.toString() + "Z"
                              : Number(value).toString()
                            : key === "TimeStamp"
                            ? value.toString() + "Z"
                            : value;
                        }
                      }
                    );
                  });
                  if (
                    tempSession &&
                    tempSession.data &&
                    tempSession.data[filterTopic + "_RAW"]
                  ) {
                    if (
                      !_.isEqual(
                        tempSession.data[filterTopic + "_RAW"][
                          tempSession.data[filterTopic + "_RAW"].length - 1
                        ],
                        mqttTemp[filterTopic + "_RAW"]
                      )
                    ) {
                      let updateTemp = {
                        [filterTopic + "_RAW"]: mqttTemp[filterTopic + "_RAW"],
                        [filterTopic]: mqttTemp[filterTopic],
                      };
                      setMqttUpdate(updateTemp);
                    }
                  } else {
                    let updateTemp = {
                      [filterTopic + "_RAW"]: mqttTemp[filterTopic + "_RAW"],
                      [filterTopic]: mqttTemp[filterTopic],
                    };
                    setMqttUpdate(updateTemp);
                  }
                }
                if (filterTopic === "INFORMACOESCORPORAIS") {
                  mqttTemp[filterTopic + "_RAW"] = JSON.parse(
                    message,
                    replaceToNum
                  );
                  mqttTemp[filterTopic].forEach((itemTopic) => {
                    Object.entries(JSON.parse(message)).forEach(
                      ([key, value]) => {
                        if (key === itemTopic.key) {
                          if (key === "Temperatura") {
                            itemTopic.value = !Number.isNaN(Number(value))
                              ? (Number(value) / 10).toString()
                              : value;
                          } else {
                            itemTopic.value = !Number.isNaN(Number(value))
                              ? Number(value).toString()
                              : value;
                          }
                        }
                      }
                    );
                  });

                  if (
                    tempSession &&
                    tempSession.data &&
                    tempSession.data[filterTopic + "_RAW"]
                  ) {
                    if (
                      !_.isEqual(
                        tempSession.data[filterTopic + "_RAW"][
                          tempSession.data[filterTopic + "_RAW"].length - 1
                        ],
                        mqttTemp[filterTopic + "_RAW"]
                      )
                    ) {
                      let updateTemp = {
                        [filterTopic + "_RAW"]: mqttTemp[filterTopic + "_RAW"],
                        [filterTopic]: mqttTemp[filterTopic],
                      };
                      setMqttUpdate(updateTemp);
                    }
                  } else {
                    let updateTemp = {
                      [filterTopic + "_RAW"]: mqttTemp[filterTopic + "_RAW"],
                      [filterTopic]: mqttTemp[filterTopic],
                    };
                    setMqttUpdate(updateTemp);
                  }
                }
                if (filterTopic === "RESUMOCORRIDA") {
                  mqttTemp[filterTopic + "_RAW"] = {
                    ...JSON.parse(message, replaceToNum),
                  };

                  setMqttUpdate({
                    [filterTopic + "_RAW"]: mqttTemp[filterTopic + "_RAW"],
                  });
                }
                if (filterTopic === "ESTATISTICASESSAO") {
                  mqttTemp[filterTopic + "_RAW"] = {
                    ...JSON.parse(message, replaceToNum),
                  };
                  setMqttUpdate({
                    [filterTopic + "_RAW"]: mqttTemp[filterTopic + "_RAW"],
                  });
                }
              } else {
                JSON.parse(message, replaceToNum);
              }
            } else {
              JSON.parse(message, replaceToNum);
            }

            try {
              clearTimeout(timerMqttAlive.current);
            } catch (err) {}
            timerMqttAlive.current = setTimeout(() => {
              mqttIsNotAlive();
            }, 60000);
          }
        })
        .then((res) => {
          console.log("conn mqtt", res);
        })
        .catch((err) => {
          console.log("conn err", err);
        });
    }
  };

  const unsubscribeMqtt = async (myTopic) => {
    try {
      clearTimeout(timerMqttAlive.current);
    } catch (err) {}
    if (!myConnection.current) {
      await connectMqtt();
      unsubscribeMqtt(myTopic);
    } else {
      myConnection.current
        .unsubscribe(myTopic)
        .then((mqttRequest) => {})
        .catch((error) => console.log(error));
    }
  };

  const mqttIsNotAlive = () => {
    setMqttFailed(true);
  };

  useEffect(() => {
    if (mqttUpdate) {
    }
  }, [mqttUpdate]);

  useEffect(() => {}, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        myConnection,
        connectMqtt,
        publishMqtt,
        subscribeMqtt,
        unsubscribeMqtt,
        mqttUpdate,
        setMqttUpdate,
        mqttFailed,
        setMqttFailed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within a AuthProvider");
  const {
    isAuth,
    user,
    setUser,
    login,
    logout,
    myConnection,
    connectMqtt,
    publishMqtt,
    subscribeMqtt,
    unsubscribeMqtt,
    mqttUpdate,
    setMqttUpdate,
    mqttFailed,
    setMqttFailed,
  } = context;
  return {
    isAuth,
    user,
    setUser,
    login,
    logout,
    myConnection,
    connectMqtt,
    publishMqtt,
    subscribeMqtt,
    unsubscribeMqtt,
    mqttUpdate,
    setMqttUpdate,
    mqttFailed,
    setMqttFailed,
  };
}

export const protectedRoute =
  (Comp, route = "/login") =>
  (props) => {
    const { setPreloading } = usePreload();
    const { setUser } = useUser();

    async function checkAuthState() {
      setPreloading(true);
      Auth.currentAuthenticatedUser()
        .then(async (res) => {
          loadUserBasic()
            .then((res2) => {
              console.log(res.data, res2);
              setUser({
                ...res,
                ...res2,
              });
              setPreloading(false);
            })
            .catch((err) => {
              console.log(err);
              setPreloading(false);
              window.location.href = route;
            });
        })
        .catch((err) => {
          console.log(err);
          setPreloading(false);
          window.location.href = route;
        });
    }

    useEffect(() => {
      checkAuthState();
    }, []);
    return <Comp {...props} />;
  };

export const getJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession();
  return session.idToken.jwtToken;
};

export const loadUserBasic = async () => {
  let at = await getJwtToken();
  const config = {
    headers: { Authorization: `Bearer ${at}` },
  };

  const config2 = {
    headers: { Authorization: `Bearer ${at}` },
    // responseType: "arraybuffer",
  };

  return (
    api
      // .get(`/Account/GetLoggedInAccount`, config)
      .get(`/Application/GetCurrentUserBasic`, config)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          let tempData = res.data;

          let currentRole = getStorageWithExpiry("@currentRole");

          if (!currentRole) {
            setStorageWithExpiry(
              "@currentRole",
              res.data.roles[0].id,
              86400000
            );
          } else {
            let roleExist =
              res.data.roles.filter((role) => role.id === currentRole).length >
              0;
            if (!roleExist) {
              setStorageWithExpiry(
                "@currentRole",
                res.data.roles[0].id,
                86400000
              );
            }
          }

          tempData.currentRole = currentRole;

          if (res.data.photo !== null) {
            return api
              .get(res.data.photo, config2)
              .then(async (res2) => {
                tempData.photo = `data:image/jpeg;base64,${res2.data}`;
                return tempData;
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            return tempData;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 403) {
          Auth.signOut();
          setTimeout(() => {
            clearStorage("@currentRole");
            window.location.href = "/";
          }, 1000);
        }
      })
  );
};

async function connect_websocket(provider) {
  return new Promise((resolve, reject) => {
    let config =
      iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
        .with_clean_session(true)
        .with_client_id("")
        .with_endpoint(Settings.AWS_IOT_ENDPOINT)
        .with_credential_provider(provider)
        .with_use_websockets()
        .with_keep_alive_seconds(30)
        .build();

    const client = new mqtt.MqttClient();

    const connection = client.new_connection(config);
    connection.on("connect", (session_present) => {
      resolve(connection);
    });
    connection.on("interrupt", (error) => {});
    connection.on("resume", (return_code, session_present) => {});
    connection.on("disconnect", () => {});
    connection.on("error", (error) => {
      reject(error);
    });
    connection.connect();
  });
}

export class AWSCognitoCredentialsProvider extends auth.CredentialsProvider {
  constructor(options, expire_interval_in_ms) {
    super();
    this.options = options;
    AWS.config.region = options.Region;
    this.source_provider = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: options.IdentityPoolId,
    });
    this.aws_credentials = {
      aws_region: options.Region,
      aws_access_id: this.source_provider.accessKeyId,
      aws_secret_key: this.source_provider.secretAccessKey,
      aws_sts_token: this.source_provider.sessionToken,
    };

    setInterval(async () => {
      await this.refreshCredentialAsync();
    }, expire_interval_in_ms ?? 3600 * 1000);
  }

  getCredentials() {
    return this.aws_credentials;
  }

  async refreshCredentialAsync() {
    return new Promise((resolve, reject) => {
      this.source_provider.get((err) => {
        if (err) {
          reject("Failed to get cognito credentials.");
        } else {
          this.aws_credentials.aws_access_id = this.source_provider.accessKeyId;
          this.aws_credentials.aws_secret_key =
            this.source_provider.secretAccessKey;
          this.aws_credentials.aws_sts_token =
            this.source_provider.sessionToken;
          this.aws_credentials.aws_region = this.options.Region;
          resolve(this);
        }
      });
    });
  }
}
