export const graficoAcompanhamento = [
  {
    id: "tempo",
    label: "Tempo",
    unit: "",
    category: "sessao",
    data: [],
    borderColor: "#ff0000",
    backgroundColor: "#ff0000",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "rot",
    label: "Rotação (RPM)",
    unit: "RPM",
    category: "hardware",
    data: [],
    borderColor: "#00ff00",
    backgroundColor: "#00ff00",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "carga",
    label: "Carga (%)",
    unit: "%",
    category: "hardware",
    data: [],
    borderColor: "#0000ff",
    backgroundColor: "#0000ff",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "estimQuaD",
    label: "Estímulo Quadril Direito (mA)",
    unit: "mA",
    category: "estimulo",
    data: [],
    borderColor: "#ffff00",
    backgroundColor: "#ffff00",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "estimGluD",
    label: "Estímulo Glúteo Direito (mA)",
    unit: "mA",
    category: "estimulo",
    data: [],
    borderColor: "#FF00FF",
    backgroundColor: "#FF00FF",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "estimIsqD",
    label: "Estímulo Ísquio Direito (mA)",
    unit: "mA",
    category: "estimulo",
    data: [],
    borderColor: "#00ffff",
    backgroundColor: "#00ffff",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "estimQuaE",
    label: "Estímulo Quadril Esquerdo (mA)",
    unit: "mA",
    category: "estimulo",
    data: [],
    borderColor: "#00aaff",
    backgroundColor: "#00aaff",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "estimGluE",
    label: "Estímulo Glúteo Esquerdo (mA)",
    unit: "mA",
    category: "estimulo",
    data: [],
    borderColor: "#ff6600",
    backgroundColor: "#ff6600",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "estimIsqE",
    label: "Estímulo Ísquio Esquerdo (mA)",
    unit: "mA",
    category: "estimulo",
    data: [],
    borderColor: "#006600",
    backgroundColor: "#006600",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  // {
  //   id: "faseCorrida",
  //   label: "Fase da Corrida",
  //   unit: "",
  //   category: "sessao",
  //   data: [],
  //   borderColor: "#6600ff",
  //   backgroundColor: "#6600ff",
  //   borderWidth: 2,
  //   hitRadius: 10,
  //   pointHoverRadius: 3,
  //   pointRadius: 2,
  //   tension: 0.3,
  //   hidden: false,
  // },
];

export const graficoInformacoesCorporais = [
  {
    id: "sistolica",
    label: "Sistólica (mmHg)",
    unit: "mmHg",
    category: "corpo",
    data: [],
    borderColor: "#ff0000",
    backgroundColor: "#ff0000",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "diastolica",
    label: "Diastólica (mmHg)",
    unit: "mmHg",
    category: "corpo",
    data: [],
    borderColor: "#00ff00",
    backgroundColor: "#00ff00",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "saturaOxi",
    label: "Saturação Oxigênio (%)",
    unit: "%",
    category: "corpo",
    data: [],
    borderColor: "#0000ff",
    backgroundColor: "#0000ff",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "freqCardiaca",
    label: "Frequência Cardíaca (BPM)",
    unit: "BPM",
    category: "corpo",
    data: [],
    borderColor: "#ffff00",
    backgroundColor: "#ffff00",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "temperatura",
    label: "Temperatura (°C)",
    unit: "°C",
    category: "corpo",
    data: [],
    borderColor: "#FF00FF",
    backgroundColor: "#FF00FF",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  // {
  //   id: "gpS_LAT",
  //   label: "GPS Lat",
  //   unit: "",
  //   category: "hardware",
  //   data: [],
  //   borderColor: "#00ffff",
  //   backgroundColor: "#00ffff",
  //   borderWidth: 2,
  //   hitRadius: 10,
  //   pointHoverRadius: 3,
  //   pointRadius: 2,
  //   tension: 0.3,
  //   hidden: false,
  // },
  // {
  //   id: "gpS_LONG",
  //   label: "GPS Long",
  //   unit: "",
  //   category: "hardware",
  //   data: [],
  //   borderColor: "#00aaff",
  //   backgroundColor: "#00aaff",
  //   borderWidth: 2,
  //   hitRadius: 10,
  //   pointHoverRadius: 3,
  //   pointRadius: 2,
  //   tension: 0.3,
  //   hidden: false,
  // },
];

export const graficoEstatisticas = [
  {
    id: "tempo",
    label: "Tempo",
    unit: "",
    category: "sessao",
    data: [],
    borderColor: "#ff0000",
    backgroundColor: "#ff0000",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "dist",
    label: "Distância (m)",
    unit: "m",
    category: "sessao",
    data: [],
    borderColor: "#6600ff",
    backgroundColor: "#6600ff",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "rot",
    label: "Rotação (BPM)",
    unit: "RPM",
    category: "hardware",
    data: [],
    borderColor: "#00ff00",
    backgroundColor: "#00ff00",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "carga",
    label: "Carga (%)",
    unit: "%",
    category: "hardware",
    data: [],
    borderColor: "#0000ff",
    backgroundColor: "#0000ff",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "estMed",
    label: "Estímulo Médio (mA)",
    unit: "mA",
    category: "estimulo",
    data: [],
    borderColor: "#ffff00",
    backgroundColor: "#ffff00",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "sistMax",
    label: "Sistólica Máxima (mmHg)",
    unit: "mmHg",
    category: "corpo",
    data: [],
    borderColor: "#00ffaa",
    backgroundColor: "#00ffaa",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "sistMin",
    label: "Sistólica Mínima (mmHg)",
    unit: "mmHg",
    category: "corpo",
    data: [],
    borderColor: "#555555",
    backgroundColor: "#555555",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "diastMax",
    label: "Diastólica Máxima (mmHg)",
    unit: "mmHg",
    category: "corpo",
    data: [],
    borderColor: "#ff2255",
    backgroundColor: "#ff2255",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "diastMin",
    label: "Diastólica Mínima (mmHg)",
    unit: "mmHg",
    category: "corpo",
    data: [],
    borderColor: "#22aa55",
    backgroundColor: "#22aa55",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "saturaOxiMin",
    label: "Saturação Oxigênio Mínima (%)",
    unit: "%",
    category: "corpo",
    data: [],
    borderColor: "#881030",
    backgroundColor: "#881030",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "freqCardMax",
    label: "Frequência Cardíaca Máxima (BPM)",
    unit: "BPM",
    category: "corpo",
    data: [],
    borderColor: "#331111",
    backgroundColor: "#331111",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "freqCardMin",
    label: "Frequência Cardíaca Mínima (BPM)",
    unit: "BPM",
    category: "corpo",
    data: [],
    borderColor: "#ff8800",
    backgroundColor: "#ff8800",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "tempCorpMax",
    label: "Temperatura Máxima (°C)",
    unit: "°C",
    category: "corpo",
    data: [],
    borderColor: "#FF00FF",
    backgroundColor: "#FF00FF",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
  {
    id: "tempCorpMin",
    label: "Temperatura Mínima (°C)",
    unit: "°C",
    category: "corpo",
    data: [],
    borderColor: "#77bbff",
    backgroundColor: "#aaddff",
    borderWidth: 2,
    hitRadius: 10,
    pointHoverRadius: 3,
    pointRadius: 2,
    tension: 0.3,
    hidden: false,
  },
];
