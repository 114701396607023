import React, { useEffect, useContext, useState, createContext } from "react";

const LightboxContext = createContext();

export default function LightboxProvider({ children }) {
  const [lightbox, setLightbox] = useState("");

  useEffect(() => {}, [lightbox]);

  return (
    <LightboxContext.Provider value={{ lightbox, setLightbox }}>
      {children}
    </LightboxContext.Provider>
  );
}

export function useLightbox() {
  const context = useContext(LightboxContext);
  if (!context)
    throw new Error("useLightbox must be used within a LightboxProvider");

  const { lightbox, setLightbox } = context;

  return { lightbox, setLightbox };
}
