export const sessaoConfig = [
  {
    name: "Disabled",
    type: "category",
    value: [
      {
        key: "RotAquec",
        name: "Rotação Aquecimento",
        type: "slider",
        min: 0,
        max: 0,
        unit: "RPM",
        value: 0,
      },
      {
        key: "RotResfr",
        name: "Rotação Resfriamento",
        type: "slider",
        min: 0,
        max: 0,
        unit: "RPM",
        value: 0,
      },
      {
        key: "TAcelRot",
        name: "Tempo de aceleração de Rotação",
        type: "slider",
        min: 0,
        max: 0,
        unit: "s",
        value: 0,
      },
      {
        key: "TDesacelRot",
        name: "Tempo de desaceleração de Rotação",
        type: "slider",
        min: 0,
        max: 0,
        unit: "s",
        value: 0,
      },
      {
        key: "UmidAcao",
        name: "Umidade",
        type: "slider",
        min: 0,
        max: 0,
        unit: "%",
        value: 0,
      },
    ],
  },
  {
    name: "Geral",
    type: "category",

    value: [
      {
        key: "accountId",
        name: "ID Paciente",
        type: "text",
        value: "GFH374489",
        readOnly: true,
      },
      {
        key: "configuraSessaoId",
        name: "ID da Configuração",
        type: "text",
        value: 110,
        readOnly: true,
      },
      {
        key: "sessao_Contagem",
        name: "Código da Sessão",
        type: "text",
        value: "BGY938011",
        readOnly: true,
      },
      {
        key: "qtdCorridas",
        name: "Corridas por sessão",
        type: "slider",
        min: 1,
        max: 3,
        unit: "corrida(s)",
        value: 0,
      },
      {
        key: "qtdCorridasExe",
        name: "Corridas executadas na sessão atual",
        type: "text",
        value: 1,
        readOnly: true,
      },
    ],
  },
  {
    name: "Corrida",
    type: "category",
    value: [
      {
        key: "tempoCorrida",
        name: "Duração da Corrida",
        type: "slider",
        min: 1,
        max: 60,
        unit: "min",
        value: 30,
      },
      {
        key: "durAquec",
        name: "Duração do Aquecimento",
        type: "slider",
        min: 0,
        max: 60,
        unit: "min",
        value: 10,
      },
      {
        key: "durResfr",
        name: "Duração do Resfriamento",
        type: "slider",
        min: 0,
        max: 60,
        unit: "min",
        value: 10,
      },
      {
        key: "durRepouso",
        name: "Duração do Repouso",
        type: "slider",
        min: 0,
        max: 60,
        unit: "min",
        value: 10,
      },
    ],
  },
  {
    name: "Velocidade",
    type: "category",
    value: [
      {
        key: ["rotMin", "rotMax"],
        name: "Velocidade",
        type: "slider-range",
        min: 10,
        max: 60,
        unit: "RPM",
        valuemin: 10,
        valuemax: 40,
      },
    ],
  },
  {
    name: "Configuração",
    type: "category",
    value: [
      {
        key: "tipoFibra",
        name: "Tipo de Fibra",
        type: "select",
        values: [
          { name: "I", value: 1 },
          { name: "II", value: 2 },
          { name: "Manual", value: 3 },
        ],
        value: 1,
      },
      {
        key: "frequencia",
        name: "Frequência do Pulso",
        type: "slider",
        min: 20,
        max: 70,
        unit: "Hz",
        value: 20,
      },
      {
        key: "larguraPulso",
        name: "Largura do Pulso",
        type: "slider",
        min: 50,
        max: 1000,
        unit: "us",
        value: 600,
      },
      {
        key: "tipoCarga",
        name: "Tipo de Carga",
        type: "select",
        values: [
          { name: "Manual", value: 0 },
          { name: "Automática", value: 1 },
        ],
        value: 0,
      },
    ],
  },
  {
    name: "Estimulação Elétrica",
    type: "category",
    value: [
      {
        key: ["estimMinQuaD", "estimMaxQuaD"],
        name: "Quadriceps Direito",
        type: "slider-range",
        min: 0,
        max: 150,
        unit: "mA",
        valuemin: 20,
        valuemax: 140,
      },
      {
        key: ["estimMinQuaE", "estimMaxQuaE"],
        name: "Quadriceps Esquerdo",
        type: "slider-range",
        min: 0,
        max: 150,
        unit: "mA",
        valuemin: 20,
        valuemax: 140,
      },
      {
        key: ["estimMinGluD", "estimMaxGluD"],
        name: "Gluteo Máximo Direito",
        type: "slider-range",
        min: 0,
        max: 150,
        unit: "mA",
        valuemin: 20,
        valuemax: 140,
      },
      {
        key: ["estimMinGluE", "estimMaxGluE"],
        name: "Gluteo Máximo Esquerdo",
        type: "slider-range",
        min: 0,
        max: 150,
        unit: "mA",
        valuemin: 20,
        valuemax: 140,
      },
      {
        key: ["estimMinIsqD", "estimMaxIsqD"],
        name: "Ísquio Direito",
        type: "slider-range",
        min: 0,
        max: 150,
        unit: "mA",
        valuemin: 20,
        valuemax: 140,
      },
      {
        key: ["estimMinIsqE", "estimMaxIsqE"],
        name: "Ísquio Esquerdo",
        type: "slider-range",
        min: 0,
        max: 150,
        unit: "mA",
        valuemin: 20,
        valuemax: 140,
      },
    ],
  },
  {
    name: "Alarmes",
    type: "category",
    value: [
      {
        key: "saturaOxiMin",
        name: "Saturação de O2 Mínima",
        type: "slider",
        min: 0,
        max: 100,
        unit: "%",
        value: 80,
      },
      {
        key: ["freqCardMin", "freqCardMax"],
        name: "Frequência Cardiaca",
        type: "slider-range",
        min: 0,
        max: 200,
        unit: "BPM",
        valuemin: 50,
        valuemax: 150,
      },
      {
        key: ["sistMin", "sistMax"],
        name: "Pressão Arterial Sistólica",
        type: "slider-range",
        min: 0,
        max: 200,
        unit: "mmHg",
        valuemin: 120,
        valuemax: 140,
      },
      {
        key: ["diastMin", "diastMax"],
        name: "Pressão Arterial Diastólica",
        type: "slider-range",
        min: 0,
        max: 200,
        unit: "mmHg",
        valuemin: 60,
        valuemax: 80,
      },
      {
        key: ["tempCorpMin", "tempCorpMax"],
        name: "Temperatura Corpórea",
        type: "slider-range",
        min: 0,
        max: 50,
        unit: "°C",
        valuemin: 35,
        valuemax: 39,
      },
    ],
  },
];
