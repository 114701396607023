import React, { useEffect, useState } from "react";
import { GridThumb } from "../../../../ui/General";
import { useNavigate } from "react-router-dom";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { useCiclos } from "../../../../contexts/ciclos";
import {
  CicloItemWrapper,
  CicloTitle,
  CicloName,
  CicloValue,
} from "./styled.js";

const CicloItem = ({ id, name, status, state, clickFunction }) => {
  return (
    <CicloItemWrapper
      onClick={(e) => {
        e.preventDefault();
        clickFunction(id);
      }}
    >
      <CicloTitle>{name}</CicloTitle>
      <CicloName>Status:</CicloName>
      <CicloValue>{status}</CicloValue>
      <CicloName>Estado Atual:</CicloName>
      <CicloValue>{state}</CicloValue>
    </CicloItemWrapper>
  );
};

const Ciclos = () => {
  const { ciclos, getCiclos, ciclosFiltered, getCiclosFiltered } = useCiclos();
  const navigate = useNavigate();
  const { bottomMenuSections, setBottomMenuSections } = useBottomMenuSections();

  const clickThumb = (id) => {
    navigate(`/ciclo/${id}`);
  };

  useEffect(() => {
    getCiclos();
    setBottomMenuSections("manutencaoCiclos");
  }, []);

  return (
    <>
      {ciclos &&
        ciclos.map(
          (value, index) =>
            value.active && (
              <CicloItem
                key={index}
                id={value.cycleErgometerId}
                name={value.name}
                status={value.status}
                state={value.state}
                clickFunction={clickThumb}
              />
            )
        )}
    </>
  );
};

export default Ciclos;
