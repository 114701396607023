import React, { useEffect, useState } from "react";
import { useBottomMenuSections } from "../../../../contexts/bottomMenuSections";
import { useItemCiclo } from "../../../../contexts/itemCiclo";
import { useUser } from "../../../../contexts/user";
import ConfigCiclo from "../ConfigCiclo";

const SessaoPendente = ({ pacienteid, sessaoid }) => {
  const { itemCiclo, setItemCiclo, loadConfig, sendConfiguraSessao } =
    useItemCiclo();
  const { user } = useUser();
  let { bottomMenuSections, setBottomMenuSections, currentBottomMenu } =
    useBottomMenuSections();
  const [currentTabScreen, setCurrentTabScreen] = useState(null);

  useEffect(() => {
    setBottomMenuSections("terapeutaPacientes");
  }, []);

  useEffect(() => {
    if (currentBottomMenu) {
      let selectedIndex = currentBottomMenu.findIndex(
        (obj, index) => obj.selected
      );
      setCurrentTabScreen(currentBottomMenu[selectedIndex]);
    }
  }, [currentBottomMenu]);

  const initialConfig = () => {
    loadConfig(pacienteid, sessaoid)
      .then((response) => {
        setItemCiclo((values) => ({
          ...values,
          pacienteid: pacienteid,
          sessaoid: sessaoid,
        }));
        setBottomMenuSections("terapeutaPacientes");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (itemCiclo.config) {
    }
  }, [itemCiclo]);

  useEffect(() => {
    if (!itemCiclo.config) {
      initialConfig();
    }
    setBottomMenuSections("terapeutaPacientes");
  }, []);

  return (
    <>
      {currentTabScreen && currentTabScreen.name === "Configurações" && (
        <ConfigCiclo pacienteid={pacienteid} sessaoid={sessaoid} />
      )}
    </>
  );
};

export default SessaoPendente;
